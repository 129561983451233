import { parseISO, isValid } from 'date-fns';
// Formats valid date formats into date object, returns null date if format not found
export const getDateObject = (inputDate) => {
  let outputDate = null;
  if (inputDate instanceof Date) {
    outputDate = inputDate;
  } else if (isISOFormat(inputDate)) {
    outputDate = parseISO(inputDate);
  } else if (isUnixTimestampFormat(inputDate)) {
    outputDate = new Date(inputDate);
  }
  return outputDate;
};

// Returns true if the input is a valid format for MUI's date pickers
export const isValidDatePickerValue = (inputDate) => {
  if (inputDate instanceof Date) {
    return true;
  } else if (isISOFormat(inputDate)) {
    return false;
  } else if (isUnixTimestampFormat(inputDate)) {
    return true;
  } else if (!inputDate) {
    //if null
    return true;
  } else {
    return false;
  }
};

// Returns true if input is a ISO8601 date
const isISOFormat = (inputDate) => {
  if (isValid(parseISO(inputDate))) {
    return true;
  }
  return false;
};

// Returns true if input is a Unix timestamp
const isUnixTimestampFormat = (inputDate) => {
  if (isValid(new Date(parseInt(inputDate)))) {
    return true;
  }
  return false;
};
