import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classes from './Company.module.scss';
import { Textfield } from '../../components/Textfield';
import { Buttons } from '../../components/Buttons';
import { locationList } from '../../actions/LocationAction';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Paper,
  Box,
  Modal,
  Button,
} from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay';
import BeatLoader from 'react-spinners/BeatLoader';
import { DataApi, DataGetApi } from '../../helpers/PostDataApi';
import config from '../../constants/config';
import message from '../../constants/message';
import { ErrorMessage } from '../../components/Message/ErrorMessage';

class AddApiKeyForm extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      keyName: '',
      apiUsers: [],
      isLoading: false,
      misMatchError: [],
      isSubmit: false,
      marginBottom: '10',
      selectedUser: '',
      apikeyGenerated: false,
      apiKey: null,
      userId: null,
      openModal: false,
    };
  }

  componentDidMount() {
    if (!this.props?.isApiUser) {
      this.getApiUserList();
    }
    this.resetState();
  }

  resetState = () => {
    this.setState({
      keyName: '',
      selectedLocation: '',
      apikeyGenerated: false,
      apiKey: null,
    });
  };

  getApiUserList() {
    const misMatchError = [];
    let user = JSON.parse(localStorage.getItem('user'));
    let token = user.token;
    const pathSegments = window.location.pathname.split('/');
    const id = pathSegments[pathSegments.length - 1];
    const params = { companyId: id };

    DataGetApi(config.BASEURL + 'getApiUserList', params, token)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          this.setState({ apiUsers: response?.data?.response, isLoading: false });
        } else {
          this.handleMismatchError(response.data.message, misMatchError);
        }
      })
      .catch((err) => {
        console.log(err);
        this.handleMismatchError(err.message, misMatchError);
      });
  }

  handleChange = (event, _t) => {
    const target = event.target;
    this.setState({
      [target.id]: target.value,
    });
  };

  handleMismatchError = (error, misMatchError) => {
    misMatchError.push(error);
    this.setState({
      misMatchError,
      isLoading: false,
      apikeyGenerated: false,
      openModal: false,
    });

    return null;
  };

  handleSubmit(_e, _t) {
    let misMatchError = [];
    let user = JSON.parse(localStorage.getItem('user'));
    let token = user?.token;
    let state = this.state;
    this.setState({ isLoading: true });
    const payload = {
      userId: this.props.userId ? this.props.userId : state.selectedUser,
      keyName: state.keyName,
    };

    DataApi(payload, config.BASEURL + 'generateApiKey', token, true)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          this.setState({
            isLoading: false,
            misMatchError: [],
            apikeyGenerated: true,
            apiKey: response.data.response?.apiKey,
            userId: response.data.response?.userId,
            openModal: true,
          });
        } else {
          this.handleMismatchError(response.data?.message, misMatchError);
        }
      })
      .catch((err) => {
        console.log(err.message);
        this.handleMismatchError(err?.message ?? 'Problem generating API Key', misMatchError);
      });
  }

  goBack(e) {
    this.props.handleChangeTab(e, 0);
  }

  handleSelectChange = (e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      selectedUser: value,
    }));
  };

  handleCloseModal = () => {
    window.location.reload();
  };

  ApiKeyPreview = () => {
    const modalStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 500,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: '8px',
    };

    return (
      <Modal
        open={this.state.openModal}
        onClose={() => this.setState({ apikeyGenerated: false, keyName: null, selectedLocation: '' })}
        aria-labelledby="api-key-modal-title"
        aria-describedby="api-key-modal-description"
      >
        <Box sx={modalStyle}>
          <Paper elevation={0} style={{ padding: '24px' }}>
            <Typography
              id="api-key-modal-title"
              variant="h5"
              style={{ fontWeight: 'bold', marginBottom: '16px', color: '#2E7D32' }}
            >
              ✅ API Key Generated Successfully
            </Typography>

            <Typography variant="body1" style={{ color: '#444', marginBottom: '8px' }}>
              <strong>Key Name:</strong> {this.state.apiKeyName}
            </Typography>

            <Typography variant="body1" style={{ color: '#444', marginBottom: '8px' }}>
              <strong>User Id:</strong> {this.state.userId}
            </Typography>

            <Typography variant="body1" style={{ color: '#444', marginBottom: '8px' }}>
              <strong>Your API Key:</strong>
            </Typography>

            <Box
              component="code"
              sx={{
                display: 'block',
                padding: '8px 12px',
                backgroundColor: '#F5F5F5',
                color: '#2E7D32',
                fontSize: '1rem',
                wordBreak: 'break-all',
                borderRadius: '4px',
              }}
            >
              {this.state.apiKey}
            </Box>

            <Typography variant="body2" style={{ color: '#888', marginTop: '16px' }}>
              {message.API_KEY_USAGE_POLICY}
            </Typography>

            <Button variant="contained" color="primary" onClick={this.handleCloseModal} style={{ marginTop: '24px' }}>
              Close
            </Button>
          </Paper>
        </Box>
      </Modal>
    );
  };

  ApiKeyForm = () => {
    const { t } = this.props;
    return (
      <Grid
        container
        spacing={4}
        direction="column"
        style={{ maxWidth: '600px', padding: '40px', backgroundColor: 'transparent', borderRadius: '0px' }}
      >
        {/* Header */}
        <Grid item xs={12}>
          <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '16px' }}>
            Generate API Key
          </Typography>
          <Typography variant="body2" style={{ color: '#666' }}>
            Please fill out the fields below to generate your API key.
          </Typography>
        </Grid>

        {/* Key Name Input */}
        <Grid item xs={12}>
          <Textfield
            required
            id="keyName"
            type="text"
            labels="Key Name"
            value={this.state.keyName}
            fullWidth
            onChange={(e) => this.handleChange(e, t)}
            focus
            style={{
              backgroundColor: '#fff',
              borderRadius: '8px',
            }}
          />
        </Grid>

        {/* Location Selector */}
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="select-location-label">Select API user</InputLabel>
            <Select
              labelId="select-location-label"
              id="selectedUser"
              value={this.state.selectedUser}
              onChange={this.handleSelectChange}
            >
              {this.state.apiUsers &&
                this.state.apiUsers.map((user) => (
                  <MenuItem key={user._id} value={user._id}>
                    {user?.firstName + ' ' + user?.lastName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Buttons */}
        <Grid item container spacing={2} xs={12}>
          <Grid item>
            <Buttons
              id="generateKey"
              type="button"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={(e) => this.handleSubmit(e, t)}
              text="Generate Key"
              style={{
                padding: '10px 20px',
                fontWeight: 'bold',
                textTransform: 'none',
              }}
            />
          </Grid>
          <Grid item>
            <Buttons
              className={classes.ml_2}
              variant="contained"
              color="secondary"
              text={t('Back')}
              onClick={(e) => this.goBack(e)}
              style={{
                padding: '10px 20px',
                fontWeight: 'bold',
                textTransform: 'none',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  ApiKeyIntegratorForm = () => {
    const { t } = this.props;
    return (
      <Grid
        container
        spacing={4}
        direction="column"
        style={{ maxWidth: '600px', padding: '40px', backgroundColor: 'transparent', borderRadius: '0px' }}
      >
        <Grid item xs={12}>
          <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '16px' }}>
            Generate API Key
          </Typography>
          <Typography variant="body2" style={{ color: '#666' }}>
            Please fill out the fields below to generate your API key.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Textfield
            required
            id="keyName"
            type="text"
            labels="Key Name"
            value={this.state.keyName}
            fullWidth
            onChange={(e) => this.handleChange(e, t)}
            focus
            style={{
              backgroundColor: '#fff',
              borderRadius: '8px',
            }}
          />
        </Grid>

        <Grid item container spacing={2} xs={12}>
          <Grid item>
            <Buttons
              id="generateKey"
              type="button"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={(e) => this.handleSubmit(e, t)}
              text="Generate Key"
              style={{
                padding: '10px 20px',
                fontWeight: 'bold',
                textTransform: 'none',
              }}
            />
          </Grid>
          <Grid item>
            <Buttons
              className={classes.ml_2}
              variant="contained"
              color="secondary"
              text={t('Back')}
              onClick={(e) => this.goBack(e)}
              style={{
                padding: '10px 20px',
                fontWeight: 'bold',
                textTransform: 'none',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  displayMismatchError = () => {
    return this.state.misMatchError && this.state.misMatchError.length > 0
      ? this.state.misMatchError.map((error, index) => {
          return <ErrorMessage errors={[error]} key={index} />;
        })
      : '';
  };

  renderApiKeyForm = () => {
    if (this.props?.isApiUser) {
      return this.ApiKeyIntegratorForm();
    }
    return this.ApiKeyForm();
  };
  render() {
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner={<BeatLoader color="gray" size={10} />}
        text="Generating API key"
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'transparent',
            color: 'gray',
            display: 'flex',
            justifySelf: 'end',
            border: 'none',
          }),
        }}
      >
        {this.displayMismatchError()}
        {this.state.apikeyGenerated ? this.ApiKeyPreview() : this.renderApiKeyForm()}
      </LoadingOverlay>
    );
  }
}
AddApiKeyForm.propTypes = {
  t: PropTypes.func,
  match: PropTypes.any,
  info: PropTypes.any,
  handleChangeTab: PropTypes.func,
  props: PropTypes.any,
  userId: PropTypes.any,
  isApiUser: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { locationList })(withTranslation()(AddApiKeyForm));
