import { ActionType } from './../constants';
import { DataApi, DataGetApi } from './../helpers/PostDataApi';
import { config } from './../constants';

/**
 * location info Begin Action
 */
export const locationStart = () => ({
  type: ActionType.LOCATION_START,
});

/**
 * location info Success Action
 */
export const locationSuccess = (locationData) => {
  return {
    type: ActionType.LOCATION_SUCCESS,
    payload: { locationData },
  };
};

/**
 * location info Failure Action
 */
export const locationError = (error) => ({
  type: ActionType.LOCATION_ERROR,
  payload: { error },
});

/**
 * location driver info
 */
export const locationAdd = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(locationStart());
    return DataApi(data, config.BASEURL + 'locationAdd', token, true)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(locationSuccess(response));
        } else {
          return dispatch(locationError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const locationList = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(locationStart());
    return DataGetApi(config.BASEURL + 'locationList', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(locationSuccess(response));
        } else {
          return dispatch(locationError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const locationDetailById = (id) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(locationStart());
    return DataGetApi(config.BASEURL + 'locationDetailById', { id: id }, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(locationSuccess(response));
        } else {
          return dispatch(locationError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const locationEdit = (data, locationId) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(locationStart());
    return DataApi(data, `${config.BASEURL}locationEdit?id=${locationId}`, token, true)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(locationSuccess(response));
        } else {
          return dispatch(locationError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const addPaxTerminalsForLocation = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(locationStart());
    return DataApi(data, `${config.BASEURL}addPaxTerminalsForLocation`, token, true)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(locationSuccess(response));
        } else {
          return dispatch(locationError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const locationDelete = (data) => {
  return (dispatch) => {
    dispatch(locationStart());
    return DataApi(data, config.BASEURL + 'locationDelete')
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(locationSuccess(response));
        } else {
          return dispatch(locationError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const locationStatus = (data) => {
  return (dispatch) => {
    dispatch(locationStart());
    return DataApi(data, config.BASEURL + 'locationStatus')
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(locationSuccess(response));
        } else {
          return dispatch(locationError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const setTsepAuth = (data) => {
  return (dispatch) => {
    dispatch({
      type: ActionType.SET_TSEP_AUTH,
      payload: data,
    });
  };
};

export const setLocation = (data) => {
  return (dispatch) => {
    dispatch({
      type: ActionType.SET_LOCATION,
      payload: data,
    });
  };
};
export const setAllLocation = () => {
  return (dispatch) => {
    dispatch({
      type: ActionType.SET_All_LOCATION,
    });
  };
};

export const getLocationSettingsForSelfPayByLocId = (data) => {
  const hash = { locationId: data };
  return (dispatch) => {
    dispatch(locationStart());
    return DataGetApi(config.BASEURL + 'getLocationSettingsForSelfPayByLocId', hash)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(locationSuccess(response));
        } else {
          return dispatch(locationError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
