import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import Work from '@material-ui/icons/Work';
import Face from '@material-ui/icons/Face';
import LocationCity from '@material-ui/icons/LocationCity';
import { config } from '../../constants';
import MenuList from '@material-ui/core/MenuList';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PagesIcon from '@material-ui/icons/Pages';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { UserDetailById } from '../../actions/AdminUserAction';
import { whichLocationSelected } from '../../helpers/getLocationFromLocalStorage';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { get } from 'lodash';

const menuItemsSuperAdmin = ['Dashboard', 'Company', 'SubAdmin', 'Tickets', 'Pages'];
const menuItemsSubAdmin = ['Dashboard', 'Company'];
const menuItemsCustomers = [
  { menuItem: 'Quick Pay', to: 'QuickPay' },
  { menuItem: 'Invoices', to: 'Invoices' },
  { menuItem: 'Payment History', to: 'Payment/History' },
  { menuItem: 'Profile', to: 'Profile' },
];
const menuItemsAdmin = [
  { menuItem: 'Dashboard', to: 'Dashboard' },
  { menuItem: 'Terminal', to: 'Terminal' },
  { menuItem: 'Invoices', to: 'Invoices' },
  { menuItem: 'Recurring', to: 'Recurring' },
  { menuItem: 'Transactions', to: 'Transactions' },
  { menuItem: 'Customers', to: 'Customers' },
  { menuItem: 'Users', to: 'Users' },
  { menuItem: 'Locations', to: 'Locations' },
  { menuItem: 'Products', to: 'Products' },
  { menuItem: 'Settings', to: 'Settings' },
  { menuItem: 'API Keys', to: 'ApiKeys' },
];
const menuItemsManager = [
  'Dashboard',
  'Terminal',
  'Invoices',
  'Recurring',
  'Transactions',
  'Customers',
  'Users',
  'Locations',
  'Products',
  'Settings',
  'Tickets',
];

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      position: 'relative',
    },
    menuitem: {
      color: '#aaa',
    },
    menuicon: {
      marginRight: theme.spacing(1.5),
    },
    iconLoader: {
      color: '#ffffff',
      position: 'absolute',
      top: '8%',
      left: '50%',
      // marginTop: '-12px',
      marginLeft: '-18px',
    },
  })
);

class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
      active: 'Dashboard',
      isMobile: false,
      locationCurrent: null,
    };
  }
  componentDidMount() {
    let data =
      this.state.user.role === config.CUSTOMER_USER_ROLE
        ? { id: this.state.user._id, isCustomerUser: true }
        : { id: this.state.user._id };
    let locationCurrent = null;
    this.setState({ isLoading: true });

    if (this.props.info.userId === '') {
      this.props.UserDetailById(data).then((res) => {
        this.setState({ ...res.payload.userData.data });
        let data = this.state.location;
        if (data && data.length > 0) {
          this.setState({ locationSelected: Object.keys(data).map((k, _v) => data[k].id) });
        }
      });
    }
    this.setState({ active: this.props.location.pathname.substring(1, this.props.location.pathname.length) });

    setTimeout(() => {
      const allLocation = JSON.parse(localStorage.getItem('selected')) === 0 ? true : false;
      if (!allLocation) {
        locationCurrent = whichLocationSelected();
      }
      this.setState({ locationCurrent: locationCurrent, isLoading: false });
    }, 3000);

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.addEventListener('resize', this.handleResize);
      this.setState({ active: this.props.location.pathname.substring(1, this.props.location.pathname.length) });
    }
  }

  handleResize = () => {
    const isMobile = window.innerWidth < 768;
    this.setState({ isMobile });
  };

  handleClick = (menuItem) => {
    if (this.state.isMobile) {
      document.body.classList.toggle('toggle');
    }
    this.setState({ active: menuItem });
  };

  handleCustomerClick = (to) => {
    if (this.state.isMobile) {
      document.body.classList.toggle('toggle');
    }
    this.setState({ active: `Customer/${to}` });
  };

  render() {
    const { classes } = this.props;
    const activeStyle = { background: 'rgb(68, 56, 56)' };
    const modiFiedAdmin = menuItemsAdmin.filter((item) => {
      if (
        (this.props.info.permissions.displayProducts && item.menuItem === 'Products') ||
        (this.props.info.permissions.displayTickets && item.menuItem === 'Tickets') ||
        (this.props.info.permissions.displaySettings && item.menuItem === 'Settings') ||
        (this.props.info.permissions.displayLocations && item.menuItem === 'Locations') ||
        (this.props.info.permissions.displayUsers && item.menuItem === 'Users') ||
        item.menuItem === 'Customers' ||
        (this.props.info.permissions.displayTransactions && item.menuItem === 'Transactions') ||
        (this.props.info.permissions.virtualTerminal && item.menuItem === 'Terminal') ||
        item.menuItem === 'Invoices' ||
        (this.props.info.permissions.recurring && item.menuItem === 'Recurring') ||
        (this.props.info.permissions.displayDashboard && item.menuItem === 'Dashboard') ||
        (this.props.info.permissions.addApiKeys && item.menuItem === 'API Keys')
      ) {
        return item;
      }
    });
    const modiFiedManager = menuItemsManager.filter((item) => {
      if (
        (this.props.info.permissions.displayTickets && item === 'Tickets') ||
        (get(this.props, 'info.userInfo.data.permissions.feeEdit') &&
          (this.state.user.role === config.REGULAR_USER_ROLE || this.state.user.role === config.MANAGER_ROLE) &&
          item === 'Settings') ||
        (this.props.info.permissions.displayLocations && item === 'Locations') ||
        (this.state.user.role === config.MANAGER_ROLE &&
          this.props.info.permissions.displayUsers &&
          item === 'Users') ||
        item === 'Customers' ||
        (this.props.info.permissions.displayTransactions && item === 'Transactions') ||
        (this.props.info.permissions.virtualTerminal && item === 'Terminal') ||
        item === 'Invoices' ||
        (this.props.info.permissions.recurring && item === 'Recurring') ||
        (this.props.info.permissions.displayDashboard && item === 'Dashboard') ||
        (this.props.info.permissions.displayProducts && item === 'Products')
      ) {
        return item;
      }
    });

    return (
      <div className="sidebar">
        {this.state.isLoading && <CircularProgress className={classes.iconLoader} />}
        <div className="logoBox cbr-logo-round">
          <Link component={RouterLink} to={'/Dashboard'}>
            <img
              alt="logo"
              src={
                this.state.locationCurrent &&
                this.state.locationCurrent.image &&
                this.state.locationCurrent.image !== 'null'
                  ? this.props.PAYTRACE_AWS_PATH + this.state.locationCurrent.image
                  : config.APPURL + '/assets/images/logo.png'
              }
            />
          </Link>
        </div>
        <div className="sidebarMenu">
          {this.state.user.role === config.ADMIN_ROLE ? (
            <MenuList>
              {modiFiedAdmin.map(({ menuItem, to }, i) => (
                <Link
                  data-cy={`${menuItem}`}
                  key={menuItem + i}
                  component={RouterLink}
                  onClick={() => this.handleClick(menuItem)}
                  to={'/' + to}
                >
                  <MenuItem style={this.state.active === menuItem ? activeStyle : {}} className={classes.menuitem}>
                    {menuItem === 'Dashboard' ? <Face className={classes.menuicon} /> : ''}
                    {menuItem === 'Terminal' ? <LocalAtmIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Invoices' ? <ReceiptIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Recurring' ? <AccountBoxIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Transactions' ? <CreditCardIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Customers' ? <AccountBoxIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Company' ? <Work className={classes.menuicon} /> : ''}
                    {menuItem === 'Users' ? <SupervisorAccount className={classes.menuicon} /> : ''}
                    {menuItem === 'Locations' ? <LocationCity className={classes.menuicon} /> : ''}
                    {menuItem === 'Products' ? <BusinessCenterIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Settings' ? <SettingsIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Tickets' ? <ContactSupportIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'API Keys' ? <SettingsEthernetIcon className={classes.menuicon} /> : ''}
                    {menuItem}
                  </MenuItem>
                </Link>
              ))}
            </MenuList>
          ) : (
            ''
          )}
          {this.state.user.role === config.MANAGER_ROLE || this.state.user.role === config.REGULAR_USER_ROLE ? (
            <MenuList>
              {modiFiedManager.map((menuItem, i) => (
                <Link
                  key={menuItem + i}
                  component={RouterLink}
                  onClick={() => this.handleClick(menuItem)}
                  to={'/' + menuItem}
                >
                  <MenuItem style={this.state.active === menuItem ? activeStyle : {}} className={classes.menuitem}>
                    {menuItem === 'Dashboard' ? <Face className={classes.menuicon} /> : ''}
                    {menuItem === 'Terminal' ? <LocalAtmIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Invoices' ? <ReceiptIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Recurring' ? <AccountBoxIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Transactions' ? <CreditCardIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Customers' ? <AccountBoxIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Users' ? <SupervisorAccount className={classes.menuicon} /> : ''}
                    {menuItem === 'Products' ? <BusinessCenterIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Locations' ? <LocationCity className={classes.menuicon} /> : ''}
                    {menuItem === 'Settings' ? <SettingsIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Tickets' ? <ContactSupportIcon className={classes.menuicon} /> : ''}
                    {menuItem}
                  </MenuItem>
                </Link>
              ))}
            </MenuList>
          ) : (
            ''
          )}

          {this.state.user.role === config.SUPER_ADMIN_ROLE ? (
            <MenuList>
              {menuItemsSuperAdmin.map((menuItem, i) => (
                <Link
                  key={menuItem + i}
                  component={RouterLink}
                  onClick={() => this.handleClick(menuItem)}
                  to={'/' + menuItem}
                >
                  <MenuItem style={this.state.active === menuItem ? activeStyle : {}} className={classes.menuitem}>
                    {menuItem === 'Dashboard' ? <Face className={classes.menuicon} /> : ''}
                    {menuItem === 'Company' ? <Work className={classes.menuicon} /> : ''}
                    {menuItem === 'SubAdmin' ? <SupervisorAccount className={classes.menuicon} /> : ''}
                    {menuItem === 'Tickets' ? <ContactSupportIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Pages' ? <PagesIcon className={classes.menuicon} /> : ''}
                    {menuItem}
                  </MenuItem>
                </Link>
              ))}
            </MenuList>
          ) : (
            ''
          )}
          {this.state.user.role === config.SUB_ADMIN_ROLE ? (
            <MenuList>
              {menuItemsSubAdmin.map((menuItem, i) => (
                <Link
                  key={menuItem + i}
                  component={RouterLink}
                  onClick={() => this.handleClick(menuItem)}
                  to={'/' + menuItem}
                >
                  <MenuItem style={this.state.active === menuItem ? activeStyle : {}} className={classes.menuitem}>
                    {menuItem === 'Dashboard' ? <Face className={classes.menuicon} /> : ''}
                    {menuItem === 'Company' ? <Work className={classes.menuicon} /> : ''}
                    {menuItem}
                  </MenuItem>
                </Link>
              ))}
            </MenuList>
          ) : (
            ''
          )}
          {this.state.user.role === config.CUSTOMER_USER_ROLE ? (
            <MenuList>
              {menuItemsCustomers.map(({ menuItem, to }, i) => (
                <Link
                  key={menuItem + i}
                  component={RouterLink}
                  onClick={() => this.handleCustomerClick(to)}
                  to={`/Customer/${to}`}
                >
                  <MenuItem
                    style={this.state.active === `Customer/${to}` ? activeStyle : {}}
                    className={classes.menuitem}
                  >
                    {menuItem === 'Quick Pay' ? <LocalAtmIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Invoices' ? <ReceiptIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Payment History' ? <CreditCardIcon className={classes.menuicon} /> : ''}
                    {menuItem === 'Profile' ? <AccountBoxIcon className={classes.menuicon} /> : ''}
                    {menuItem}
                  </MenuItem>
                </Link>
              ))}
            </MenuList>
          ) : (
            ''
          )}
        </div>
        <div className="bottomLogo">
          <img alt="logo" src={config.APPURL + '/assets/images/bottomLogo.png'} />
          <span style={{ color: '#ffff' }}>Version {config.VERSION} </span>
        </div>
      </div>
    );
  }
}
const ApplyingStylesOnClasses = (props) => {
  const classes = useStyles();
  return <SideMenu classes={classes} {...props} />;
};

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
    PAYTRACE_AWS_PATH: state.EnvironmentVariablesReducer.data.PAYTRACE_AWS_PATH,
    LocationDetails: state.LocationReducer,
  };
}

SideMenu.propTypes = {
  classes: PropTypes.any,
  info: PropTypes.any,
  PAYTRACE_AWS_PATH: PropTypes.any,
  location: PropTypes.any,
  UserDetailById: PropTypes.func,
  LocationDetails: PropTypes.any,
};

export default connect(mapStateToProps, { UserDetailById })(withTranslation()(withRouter(ApplyingStylesOnClasses)));
