import React from 'react';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

const CompanySettings = ({ settings, onChange }) => {
  const handleChangePAXCheckbox = (e) => {
    onChange({ ...settings, paxSettings: { ...settings.paxSettings, [e.target.id]: e.target.checked } });
  };

  return (
    <Grid container spacing={1}>
      <Grid style={{ marginLeft: 10, marginTop: 10 }} item xs={12} sm={12}>
        <h5>PAX Terminal</h5>
        <label>
          <Checkbox
            value={settings.paxSettings.hidePayTraceLocations}
            checked={settings.paxSettings.hidePayTraceLocations}
            id="hidePayTraceLocations"
            color="primary"
            onChange={(e) => {
              handleChangePAXCheckbox(e);
            }}
            inputProps={{
              'aria-label': 'secondary checkbox',
            }}
          />
          <span>Hide PayTrace Locations</span>
        </label>
      </Grid>
    </Grid>
  );
};

CompanySettings.propTypes = {
  onChange: PropTypes.func,
  settings: PropTypes.object,
};

export default CompanySettings;
