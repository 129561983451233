import React, { Component } from 'react';
import { changeFocus } from '../../helpers/changeFocusCard';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import classes from './Terminal.module.scss';
import Container from '@material-ui/core/Container';
import initialState from './terminalState';
import { validateZipCode } from '../../helpers/functions/validateZipCode';
import { Grid, Input, Box, Typography } from '@material-ui/core/';
import { setupPaymentGatewayProperties } from '../../helpers/functions/paymentGateway.properties.js';
import Link from '@material-ui/core/Link';
import { UserDetailById, settingDataByCompanyId } from '../../actions/AdminUserAction';
import config from '../../constants/config';
import TsepCard from './components/TsepCard';
import PaytraceCard from './components/PaytraceCard';
import { getFeeCalculations, getCustomFeeOne, getSurchargeFee } from '../../helpers/functions/feeCalculations';
import AddressFieldsDropDown from '../../components/AddressFieldsDropDown/AddressFieldsDropDown';
import { checkAddressEnabled, validateCityInAddress } from '../../helpers/functions/addressFieldsEnabled';
import autoFillMultipassFields from '../../helpers/functions/autoFillCardFields';
import cleanTsepForm from '../../helpers/functions/cleanTsepForm';
import { ErrorMessage } from '../../components/Message/ErrorMessage';
import {
  refundAuthManual,
  getRefundedDailyData,
  refundAuth,
  getTransactionsDataForRefund,
  getCsrfToken,
  getTransactionListTodayData,
  getInvoiceNumber,
  InvoiceDetailById,
  getPendingStatusInvoiceList,
} from '../../actions/PaymentAction';
import { countryDataList } from '../../actions/CompanyAction';
import { customerDetailsByCompany, customerDetailsByCompanyCustom } from '../../actions/CustomerAction';
import { updateInvoice, emailInvoice, payLink, textPendingInvoice } from '../../actions/RequestFormAction';
import { Buttons } from './../../components/Buttons';
import { Textfield } from './../../components/Textfield';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { CloseableAlerts } from './../../components/Message/CloseableAlerts';
import { ALL_LOCATION_ERROR, ACH_WARNING_MSG } from './../../constants/message';
import classNames from 'classnames';
import { Checkbox } from '@material-ui/core';
import validator from '../../helpers/checkValidations';
import { handleAddressSettingError } from '../../helpers/functions/validateAddressfields';
import TypableSelectDetails from './../../helpers/TypableSelectDetails';
import InputAdornment from '@material-ui/core/InputAdornment';
import LoadingOverlay from 'react-loading-overlay';
import Tooltip from '@material-ui/core/Tooltip';
import { DialogBox } from './../../components/DialogBox/DialogBox';
import AdjustSettings from './AdjustSettings';
import Icon from '@material-ui/core/Icon';
import { ContentCopy } from '@mui/icons-material';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import _ from 'lodash';
import {
  validateNotesMarkUp,
  doesFileExist,
  getLocationPaymentGateway,
  maskCardNumber,
  getRoundedExacts,
  getCustomerNamePlaceholderValue,
} from '../../helpers/commonFunction';
import CreatableSelect from 'react-select/creatable';
import { formLabelsTheme } from '../../helpers/formUtils';
import { withStyles, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { whichLocationSelected } from '../../helpers/getLocationFromLocalStorage';
import Stack from '@mui/material/Stack';
import { checkCreditOrDebit, DataGetApi } from '../../helpers/PostDataApi';
import { rules } from '../../constants/IntakeFormConstants';
import { CurrencyFormatterUS } from '../../helpers/currencyFormatters';
import { makePhoneNumberForDB } from '../../helpers/util';
import { isDueDatePassed } from '../../helpers/InvoiceHelper';
import QRModal from './QRModal';
import { setTsepAuth, locationDetailById } from '../../actions/LocationAction';
import { QrCodeScanner } from '@mui/icons-material';
// components
import CurrencyDisplay from './components/CurrencyDisplay';
import PaxTerminals from './components/PaxTerminals.js';
import SubmittedToPaxModal from './components/SubmittedToPaxModal';
import CashPaymentModal from './CashPaymentModal.js';
import ACHForm from '../../components/ACHForm/ACHForm';

LoadingOverlay.propTypes = undefined;

const mapToFormat = (values) => {
  return values.map((Obj) => ({
    value: Obj._id,
    label: `${Obj?.firstName} ${Obj.lastName ? Obj.lastName : ''}`,
  }));
};
const mapToFormatInvoice = (values) => {
  return values?.map((Obj) => ({
    value: Obj.jobId ?? Obj.invoice,
    label: (
      <div>
        <div>
          {Obj.jobId ?? Obj.invoice} - {Obj.STATUS} -{' '}
          {Obj.customerId && Obj.customerId?.firstName
            ? `${Obj.customerId?.firstName} ${Obj?.lastName ? Obj?.lastName : ''}`
            : Obj.NAME1}{' '}
          - {Obj.amount ? '$' + Obj.amount.toFixed(2) : '$0.00'}
        </div>
      </div>
    ),
  }));
};

const getInvoiceName = (invoice) => {
  return invoice.customerId && invoice.customerId?.firstName
    ? `${invoice.customerId?.firstName} ${invoice.customerId?.lastName ? invoice.customerId?.lastName : ''}`
    : invoice.NAME1;
};
const getInvoiceAmount = (invoice) => (invoice.amount ? '$' + invoice.amount.toFixed(2) : '$0.00');

const shouldDeletekeys = (state, key1, value) => {
  const keysTodeleteFromState = [
    'svcFee',
    'timestamp_PAID',
    'invoice',
    'email',
    'name',
    'notes',
    'mask_number',
    'currentTransactionAmount',
    'selectedInvoice',
    'payerName',
    'phone',
    'publicNotes',
    'privateNotes',
    'customerName',
    'oneTimeCustomerInvoice',
  ];
  if (
    (state[key1] === '' && keysTodeleteFromState.includes(key1)) ||
    (typeof state[key1] === 'undefined' && keysTodeleteFromState.includes(key1)) ||
    (state[key1] === undefined && keysTodeleteFromState.includes(key1)) ||
    (state[key1] === null && keysTodeleteFromState.includes(key1)) ||
    (value === '' && keysTodeleteFromState.includes(key1)) ||
    (value === 0 && keysTodeleteFromState.includes(key1) && key1 === 'zip')
  ) {
    return true;
  }
  return false;
};

const getUserSettings = (userData, prevState) => {
  return userData?.settings
    ? userData?.settings
    : {
        ...prevState.feeSettings,
        InvoiceDiscount: prevState.feeSettings?.virtualTerminalSettings?.InvoiceDiscount,
        DiscountFeeName: prevState.feeSettings?.virtualTerminalSettings?.DiscountFeeName ?? 'Svc Fee',
        InvoiceSVCFee: prevState.feeSettings?.virtualTerminalSettings?.InvoiceSVCFee ?? 0,
        InvoiceSVCFeeType: prevState.feeSettings?.virtualTerminalSettings?.InvoiceSVCFeeType ?? '$',
        InvoiceTax: prevState.feeSettings?.virtualTerminalSettings?.InvoiceTax ?? false,
        InvoiceSalesTaxFeeType: prevState.feeSettings?.virtualTerminalSettings?.InvoiceSalesTaxFeeType ?? '%',
        InvoiceSalesTaxFee: prevState.feeSettings?.virtualTerminalSettings?.InvoiceSalesTaxFee ?? 0,
        DualFeeEnabled: prevState.feeSettings?.DualFeeEnabled ?? false,
      };
};

const getSelectedInvoice = (values, invoiceId, searchByInvoiceNumber = false) => {
  if (values && values.length > 0) {
    const invoice = values.find((inv) =>
      searchByInvoiceNumber ? inv.invoice === invoiceId || inv.jobId === invoiceId : inv._id === invoiceId
    );
    if (invoice) {
      return {
        value: invoice.jobId ?? invoice.invoice,
        label: (
          <div>
            <div>
              {invoice.jobId ?? invoice.invoice} - {invoice.STATUS} - {getInvoiceName(invoice)} -{' '}
              {getInvoiceAmount(invoice)}
            </div>
          </div>
        ),
      };
    } else {
      return '';
    }
  }
};

const mapToFormatPendingTransaction = (values, inputString) => {
  const arrValueSum = [];
  let sum = 0.0;
  values.forEach((item) => {
    if (Object.prototype.hasOwnProperty.call(item, 'REFUNDED')) {
      if (item.REFUNDED.length > 0) {
        item.REFUNDED.forEach((ritem) => {
          sum = sum + parseFloat(ritem.amt);
        });
        arrValueSum.push({ TXNID: item.TXNID, totalRefund: sum });
        sum = 0.0;
      }
    }
  });

  return values
    .filter((items) => {
      let indexofvalue = _.findIndex(arrValueSum, { TXNID: items.TXNID });
      if (items.status_code === 'P' || items.status_code === 'V') {
        return false;
      }
      if (inputString) {
        inputString = inputString.toLowerCase();
        const value = (
          items.TXNID.toString() +
          (items.CUSTOMERDATA?.firstName ? ' ' + items.CUSTOMERDATA?.firstName : '') +
          (items.CUSTOMERDATA?.lastName ? ' ' + items.CUSTOMERDATA?.lastName : '')
        ).toLowerCase();
        if (!value.includes(inputString)) {
          return false;
        }
      }
      if (indexofvalue !== -1) {
        return arrValueSum[indexofvalue].totalRefund < items.AMOUNT;
      } else {
        return true;
      }
    })
    .slice(0, 100)
    .map((Obj) => ({
      value:
        Obj.TXNID +
        (Obj.CUSTOMERDATA?.firstName ? ' ' + Obj.CUSTOMERDATA?.firstName : '') +
        (Obj.CUSTOMERDATA?.lastName ? ' ' + Obj.CUSTOMERDATA?.lastName : ''),
      amount: Obj.AMOUNT,
      label: (
        <div>
          <p>
            {Obj.TXNID} - {new Date(Obj.DATETIME).toLocaleDateString()} {new Date(Obj.DATETIME).toLocaleTimeString()} -{' '}
            {Obj.status_code === 'P' ? 'Unsettled' : Obj.status_code === 'V' ? 'Voided' : 'Settled'} -{' '}
            {Obj.CUSTOMERDATA?.firstName || Obj.CUSTOMERDATA?.lastName
              ? Obj.CUSTOMERDATA?.firstName + ' ' + Obj.CUSTOMERDATA?.lastName
              : ''}{' '}
            -
            {_.findIndex(arrValueSum, { TXNID: Obj.TXNID }) !== -1 &&
            arrValueSum[_.findIndex(arrValueSum, { TXNID: Obj.TXNID })].totalRefund !== 'undefined'
              ? '$' +
                (
                  parseFloat(Obj.AMOUNT) -
                  parseFloat(arrValueSum[_.findIndex(arrValueSum, { TXNID: Obj.TXNID })].totalRefund)
                ).toFixed(2)
              : '$' + Obj.AMOUNT.toFixed(2)}
          </p>
        </div>
      ),
    }));
};
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },

  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},

  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
const IOSSwitchRed = withStyles((theme) => ({
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#FF0000',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#FF0000',
      border: '6px solid #fff',
    },
  },

  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  checked: {},
  focusVisible: {},

  thumb: {
    width: 24,
    height: 24,
  },

  track: {
    transition: theme.transitions.create(['background-color', 'border']),
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[400]}`,
    opacity: 1,
    borderRadius: 26 / 2,
  },
}))(({ classes, ...props }) => {
  return (
    <Switch
      disableRipple
      focusVisibleClassName={classes.focusVisible}
      classes={{
        switchBase: classes.switchBase,
        track: classes.track,
        checked: classes.checked,
        thumb: classes.thumb,
        root: classes.root,
      }}
      {...props}
    />
  );
});

class Terminal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState, isMobile: false, maskedInputClassName: 'pt-encrypt', openCashPaymentModal: false };
    this.paymentRef = React.createRef();
    this.cardRef = React.createRef();
    this.msgRef = React.createRef();
    this.expiryDateRef = React.createRef();
    this.cscCardRef = React.createRef();
    this.achFormValidationRef = React.createRef(null);
    this.handleChangeCard = this.handleChangeCard.bind(this);
    this.backToMaskCard = this.backToMaskCard.bind(this);
    this.handleChangeCheckboxUpdate = this.handleChangeCheckboxUpdate.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleSubmitAddNewCard = this.handleSubmitAddNewCard.bind(this);
    this.handleSubmitAddCardTsep = this.handleSubmitAddCardTsep.bind(this);
    this.injectEventListeners = this.injectEventListeners.bind(this);
    this.setUserData = this.setUserData.bind(this);
    this.getCardType = this.getCardType.bind(this);
    this.checkIfAllLocation = this.checkIfAllLocation.bind(this);
    this.setPaymentGatewayProperties = this.setPaymentGatewayProperties.bind(this);
    this.getInvoicePendingList = this.getInvoicePendingList.bind(this);
    this.handleTimeout = this.handleTimeout.bind(this);
    this.validateCardField = this.validateCardField.bind(this);

    this.fieldRef = {
      client: React.createRef(),
      customerNameRef: React.createRef(),
    };
    const payDetails = localStorage.getItem('locationPaydetails');
    if (payDetails !== 'undefined') {
      this.currentLocation = JSON.parse(payDetails);
      this.currentLocation = whichLocationSelected();
    }
  }

  componentDidMount() {
    const paymentGateway = getLocationPaymentGateway();
    this.setPaymentGatewayProperties(paymentGateway);
    this.setCurrentLocation();
    this.getCustomerList();
    this.getCsrfTokens();
    this.getInvoiceData();
    this.getTransactionListDailyData();
    this.getTransactionsDataForRefund();
    this.getRefundedDailyData();
    this.settingDataByCompanyId();
    this.fetchAddressData();
    this.dualFeeEnabled();
    this.setUserData();
    this.injectEventListeners();
    this.handleResize();
    this.getInvoicePendingList();
    this.checkIfAllLocation();
    this.checkIfPaxTransactionEnable();
    this.fetchLocationData();

    if (this.state.invoiceNumberAutoGenerate) {
      this.getUniqueInvoiceNumber().catch((error) => {
        console.log('Error fetching unique invoice number:', error);
      });
    }
  }

  componentWillUnmount() {
    const scriptElement = document.getElementById('manifest-script');
    if (scriptElement) {
      scriptElement.parentNode.removeChild(scriptElement);
    }
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.addEventListener('resize', this.handleResize);
    }
  }

  dualFeeEnabled() {
    this.setState({ dualFeeEnabled: this.state.feeSettings?.DualFeeEnabled });
  }

  setCurrentLocation() {
    const currentLocation = whichLocationSelected();
    this.setState({ locationId: currentLocation?.id });
  }

  fetchLocationData() {
    const currentLocation = whichLocationSelected();
    this.props.locationDetailById(currentLocation?.id).then((result) => {
      if (result.payload.locationData.data.success) {
        const { paxTerminals } = result.payload.locationData.data.response;
        this.setState({ paxTerminalsList: paxTerminals });
      }
    });
  }

  setUserData() {
    let userData = JSON.parse(localStorage.getItem('user'));
    this.setState({ createdBy: userData._id, companyId: userData.company._id, isLoading: false });
  }

  injectEventListeners = () => {
    window.addEventListener('resize', this.handleResize.bind(this));
    document.addEventListener('tsepEventUpdate', this.handleTsepEventUpdated);
  };

  checkIfAllLocation() {
    let misMatchErrors = [];
    if (this.checkAllLocationSelected()) {
      misMatchErrors.push(ALL_LOCATION_ERROR);
      this.setState({ misMatchErrors, allLocationError: true });
    } else {
      this.setState({ allLocationError: false });
    }
  }

  checkIfPaxTransactionEnable() {
    const locationDetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    if (locationDetails.gateway === 'multipass' && locationDetails.paxTransactionEnable) {
      this.setState({ isPaxTransactionEnable: true });
    }
  }

  setPaymentGatewayProperties(paymentGatewayVal) {
    const { misMatchErrors, paytraceCredentials, multipassCredentials } =
      setupPaymentGatewayProperties(paymentGatewayVal);
    if (misMatchErrors) {
      this.setState({ misMatchErrors });
    } else {
      paymentGatewayVal === 'paytrace'
        ? this.setState({ paytraceCredentials })
        : this.setState({ multipassCredentials });
    }
  }

  handleTimeout(payload, timeout = 2000) {
    setTimeout(() => {
      this.setState(payload);
    }, timeout);
  }

  handleTsepEventUpdated = (event) => {
    const tsepEventDetail = event.detail;
    if (tsepEventDetail.eventType === 'FieldValidationErrorEvent') {
      this.setState({ tsepFieldError: tsepEventDetail.event.message });
    } else if (tsepEventDetail.eventType === 'TokenEvent' && tsepEventDetail.event.status === 'PASS') {
      this.setState({
        tsepSuccessResponse: { ...tsepEventDetail.event },
        tsepFieldSuccess: true,
        tsepFieldError: null,
      });
      // Handle other cases if needed
    } else if (tsepEventDetail.eventType === 'TokenEvent' && tsepEventDetail.event.status === 'FAILURE') {
      this.setState({ tsepFieldError: tsepEventDetail.event.message });
    } else if (tsepEventDetail.eventType === 'FocusOutEvent' && tsepEventDetail.event.fieldName === 'tsep-cardNum') {
      this.setTsepCardPaymentType();
    } else if (
      tsepEventDetail.eventType === 'ErrorEvent' &&
      tsepEventDetail.event.status === 'FAIL' &&
      tsepEventDetail.event.responseCode === 'TSEPERR911'
    ) {
      this.props.setTsepAuth(false);
    }
    console.log('Tsep catched event:', tsepEventDetail);
  };

  handleResize = () => {
    const isMobile = window.innerWidth < 768;
    this.setState({ isMobile });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { userData } = nextProps.UserDetail;
    const currentLocation = whichLocationSelected();
    const customFieldsEnabled = currentLocation?.customFieldsEnabled;
    const jobIdCustomField = currentLocation?.customFields?.find((field) => field?.enabled == true);
    const jobIdCustomFieldRequired = !!currentLocation?.customFields?.some((field) => field?.required === true);
    const jobIdCustomFieldAutoGenerate =
      customFieldsEnabled &&
      !!currentLocation?.customFields?.some((field) => field?.autoGenerate === true && field.required === true);
    if (nextProps !== prevState) {
      if (userData.permissions && !userData.permissions.virtualTerminal) {
        alert('Access denied!');
        nextProps.history.push('/Dashboard');
      }
      const {
        InvoiceDiscount,
        DiscountFeeName,
        InvoiceSVCFee,
        InvoiceSVCFeeType,
        InvoiceTax,
        InvoiceSalesTaxFee,
        InvoiceSalesTaxFeeType,
        DualFeeEnabled,
      } = getUserSettings(userData, prevState);

      let feeSettings = {};
      if (userData?.settings) {
        feeSettings = {
          ...userData.settings,
          InvoiceDiscount,
          DiscountFeeName,
          InvoiceSVCFee,
          InvoiceSVCFeeType,
          InvoiceTax,
          InvoiceSalesTaxFee,
          InvoiceSalesTaxFeeType,
          DualFeeEnabled,
        };
      } else {
        feeSettings = {
          ...prevState.feeSettings,
          InvoiceDiscount,
          DiscountFeeName,
          InvoiceSVCFee,
          InvoiceSVCFeeType,
          InvoiceTax,
          InvoiceSalesTaxFee,
          InvoiceSalesTaxFeeType,
          DualFeeEnabled,
        };
      }

      return {
        userData: userData,
        userDetails: userData,
        feeSettings: feeSettings,
        invoiceLabel: customFieldsEnabled && jobIdCustomField ? jobIdCustomField.value : 'Invoice Number',
        invoiceLabelRequired: jobIdCustomFieldRequired,
        invoiceNumberAutoGenerate: jobIdCustomFieldAutoGenerate,
        isJobIdenabled: true,
        dualFeeEnabled: DualFeeEnabled,
      };
    }
    return false;
  }

  getCardType = (cardInfo) => {
    if (cardInfo.type === 'CHARGE CARD' || cardInfo.card === 'AMERICAN EXPRESS') {
      return 'CREDIT';
    }
    return cardInfo.type;
  };

  checkCardPaymentType = (cardNumber) => {
    checkCreditOrDebit(config.BIN_CHECKER_URL, config.BIN_API_KEY, cardNumber.substring(0, 6))
      .then((data) => {
        console.log(data);
        this.setState(
          {
            mask_number: maskCardNumber(cardNumber),
            cardType: data.data.card ? data.data.card : null,
            creditOrDebit: data.data.type ? this.getCardType(data.data) : null,
            binCodesResponse: data.data,
          },
          () => {
            this.setAmount(this.state?.amount);
          }
        );
      })
      .catch((error) => {
        console.log('checkCardError error in catchblock', error);

        if (this.state.creditOrDebit !== null) {
          this.setState({ creditOrDebit: null, binCodesResponse: { error: JSON.stringify(error) } }, () => {
            this.setAmount(this.state?.amount);
          });
        }
      });
  };

  setTsepCardPaymentType = () => {
    const cardNumberElement = document.getElementById('tsep-cardNum');
    if (cardNumberElement) {
      const cardNumberValue = cardNumberElement?.value.replace(/\s/g, '');
      this.checkCardPaymentType(cardNumberValue);
    }
  };

  checkAllLocationSelected() {
    const selectedLocation = JSON.parse(localStorage.getItem('selected'));
    const userDetails = JSON.parse(localStorage.getItem('user'));
    if (selectedLocation === 0 && userDetails.permissions.allLocaton) {
      this.setState({ allLocationError: true });
      return true;
    } else {
      this.setState({ allLocationError: false });
      return false;
    }
  }

  deleteUnUsedKeys() {
    if (this.state.name === '' || this.state.mask_number === '' || this.state.notes === '') {
      delete this.state.endAddress;
      delete this.state.problemType;
      delete this.state.currentTransactionAmount;
    }
    if (!this.state?.creditOrDebit) {
      delete this.state.cardType;
    }
    for (const [key1, value] of Object.entries(this.state)) {
      if (shouldDeletekeys(this.state, key1, value)) {
        delete this.state[key1];
      }
    }
  }

  onCreateOption(val) {
    let misMatchError = [];
    const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    if (val.length > 99) {
      misMatchError.push('Invoice length is not valid');
      this.setState({ misMatchError });
      return false;
    } else if (specialChars.test(val)) {
      misMatchError.push('Special characters are not allowed');
      this.setState({ misMatchError, newJobIdNotValid: true });
      return false;
    }
    this.setState({ selectedInvoice: val });
    this.setState({
      selectedInvoice: {
        value: val,
        label: (
          <div>
            <div>{val}</div>
          </div>
        ),
      },
      isNewInvoiceFromJobId: true,
      newJobId: val,
      newJobIdNotValid: false,
    });
  }

  // paylink
  generatepayLink(e, type) {
    e.preventDefault();
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true });

    if (this.state.allLocationError) {
      misMatchError.push(ALL_LOCATION_ERROR);
      this.setState({ misMatchError });
      return false;
    }

    if (!this.state.isOneTimeCustomer && !this.state.customerDetails.firstName) {
      misMatchError.push('From the customer name field, please select or create a customer.');
      this.setState({ misMatchError, required: true });
      window.scrollTo(0, 0);
      return false;
    }

    if (this.state.invoiceLabelRequired) {
      const val = this.state.selectedInvoice;
      if (val === '' || val === null) {
        misMatchError.push(`Please enter a value for the ${this.state.invoiceLabel} field.`);
        this.setState({ misMatchError });
        window.scrollTo(0, 0, 0);
        return false;
      }
    }

    if (this.state.isOneTimeCustomer && !this.state.customerName) {
      misMatchError.push('Enter Customer Name.');
      this.setState({ misMatchError });
      return false;
    }

    this.deleteUnUsedKeys();
    if (!this.state.isJobIdenabled) {
      delete this.state.selectedInvoice;
    }

    Object.keys(this.state).forEach((key) => {
      if (
        validator(key, this.state[key]) ||
        this.state[key] === '' ||
        (typeof this.state[key] === 'string' && this.state[key].trim() === '')
      ) {
        error[`${key} Error`] = true;
        errorFlag = true;
      } else {
        error[`${key} Error`] = false;
      }
    });
    if (errorFlag) {
      misMatchError.push(Object.keys(error).find((key) => error[key] === true));
      this.setState({ misMatchError, logoError, error, successMessages });
      this.forceUpdate();
      return false;
    } else {
      this.setState({ isLoading: true, creditOrDebit: this.state?.creditOrDebit }, async () => {
        await this.handlePayLink(type);
      });
    }
  }

  getCompanyLogoPath() {
    let companyLogoPath = this.props.PAYTRACE_AWS_PATH + 'titanium-logo.png';
    let locationsPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    if (locationsPaydetails && locationsPaydetails?.image !== 'null') {
      companyLogoPath = this.props.PAYTRACE_AWS_PATH + locationsPaydetails?.image;
    }
    return companyLogoPath;
  }
  async handlePayLink(type) {
    let misMatchError = [];
    let successMessages = false;
    if (this.state.amount) {
      //------
      try {
        this.setState({ companyLogoPath: this.getCompanyLogoPath() }, async () => {
          if (this.state.newJobIdNotValid) {
            await this.getUniqueInvoiceNumber();
          }
          const result = await this.props.payLink(this.state);
          window.scrollTo(0, 0, 0);
          if (result && result.type === 'PAYLINK_SUCCESS') {
            this.setState(
              {
                isLoading: false,
                successMessages: true,
                misMatchError,
                GetPayLink: result.payload.payLinkData.data.response.hash,
                invoice: result.payload.payLinkData.data.response.invoice,
                paymentGenerationSuccess: true,
                paymentLinkMessage: 'Pay Link successfully generated and added to your clipboard.',
                isPaid: false,
              },
              () => {
                navigator.clipboard.writeText(window.location.origin + '/payment/' + this.state.GetPayLink);
                if (type === 'qr') {
                  this.setState({ openQRModal: true });
                } else {
                  this.setState({ showTextLink: true });
                }
                setTimeout(() => {
                  this.setState({
                    successMessages: false,
                    paymentLinkMessage: false,
                    paymentGenerationSuccess: false,
                  });
                }, 2000);
              }
            );
          }
          if (result && result.type === 'PAYLINK_ERROR') {
            let message;
            const status = result.payload?.error?.response?.status ?? result.payload?.error?.data?.statusCode;
            if (status !== 200) {
              message = result.payload?.error?.data?.message || 'Error while creating Pay Link';
            }

            if (message) misMatchError.push(message);
            this.setState({ misMatchError, isLoading: false, successMessages, isPaid: false }, () => {
              setTimeout(() => {
                this.setState({ misMatchError });
              }, 3000);
            });
          }
        });
      } catch (err) {
        console.log('could not generate paylink successfully');
      }
    }
  }
  getCsrfTokens() {
    this.props.getCsrfToken().then((res) => {
      this.setState({ csrf: { ...res.payload.paymentData.data } });
    });
  }

  getTsepManifestToken() {
    let user = JSON.parse(localStorage.getItem('user'));
    let location = JSON.parse(localStorage.getItem('locationPaydetails'));
    let token = user.token;
    return DataGetApi(config.BASEURL + `getTsepManifestToken/${location._id}`, location, token)
      .then((response) => response)
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  settingDataByCompanyId() {
    let locationId = '';
    if (localStorage.getItem('locationArrIds') !== null) {
      locationId =
        JSON.parse(localStorage.getItem('locationArrIds')).length > 1
          ? null
          : JSON.parse(localStorage.getItem('locationArrIds'))[0];
    }

    let userDetails = JSON.parse(localStorage.getItem('user'));

    if (locationId === null && userDetails.permissions.allLocaton === false) {
      locationId = JSON.parse(localStorage.getItem('locationArrIds'))[0];
    }
    let param = {
      id: userDetails?.company?.id,
      userId: userDetails?._id,
      locationId: locationId,
    };
    this.props.settingDataByCompanyId(param).then((res) => {
      this.setState({ isLoading: true });
      if (res.payload.error) {
        //svc disabled
        this.setState(
          {
            feeSettings: {
              virtualTerminalSettings: {
                InvoiceDiscount: false,
                InvoiceTax: false,
                DiscountFeeName: '',
              },
              DualFeeEnabled: false,
              invoiceSettings: {
                CustomFeeOneName: '',
                CustomFeeOneEnabled: false,
              },
            },
          },
          () => {}
        );
        this.setState({ isLoading: false });
      } else {
        this.setState(
          {
            feeSettings: {
              ...(res.payload.userData.data ? res.payload.userData.data.response : {}),
            },
          },
          () => {
            if (this.state.amount > 0) {
              const feeComponents = getFeeCalculations(
                this.state?.feeSettings,
                this.state.amount,
                this.state?.creditOrDebit,
                this.state?.billingAddress.state
              );
              // Set calculations based on fee settings here
              this.setState(
                {
                  total: feeComponents.totalAmount,
                  tax: feeComponents.salesTaxFee,
                  svcFee: parseFloat(feeComponents.svcFee).toFixed(2),
                  salesTaxTotal: feeComponents.salesTaxTotal,
                  isStateSVCApplied: feeComponents.isStateSVCApplied,
                  stateSVCAppliedRate: feeComponents.stateSVCAppliedRate,
                },
                () => {
                  // Handle lateFee calculation based on CustomFeeOneEnabled here
                  if (this.state.dualFeeEnabled) {
                    const lateFee1 = getCustomFeeOne(this.state?.feeSettings, this.state.amount);
                    const cashTotal1 =
                      parseFloat(this.state.amount) + (this.state.salesTaxTotal || 0) + this.getLateFee();
                    this.setState({ lateFee: lateFee1, cashTotal: cashTotal1 });
                  }
                }
              );
            }
            if (!this.props.location.search.split('?')[1] && this.state.selectedInvoice) {
              this.setState({ isLoading: false });
            }
          }
        );
      }
      if (this.state.isLoading === true) {
        this.setState({ isLoading: false });
      }
    });
  }

  getTransactionsDataForRefund() {
    this.setState({ isLoading: true });
    let userData = JSON.parse(localStorage.getItem('user'));
    let data = {
      date: new Date(),
      companyId: userData.company ? userData.company._id : '',
      role: userData.role,
      userId: userData._id,
    };
    this.props.getTransactionsDataForRefund(data).then((res) => {
      if (res) {
        this.setState({
          RefundPendingData: res.payload.paymentData.data.response,
          isLoading: false,
        });
      }
    });
  }

  getTransactionListDailyData() {
    this.setState({ isLoading: true });
    let userData = JSON.parse(localStorage.getItem('user'));
    let data = {
      date: new Date(),
      companyId: userData.company ? userData.company._id : '',
      role: userData.role,
      userId: userData._id,
    };
    this.props.getTransactionListTodayData(data).then((res) => {
      if (res) {
        this.setState({
          TransactionData: res.payload.paymentData.data.response,
          isLoading: false,
        });
      }
    });
  }

  getCustomerList() {
    this.setState({ isLoading: true });
    const misMatchError = [];
    let userData = JSON.parse(localStorage.getItem('user'));
    let currentLocation = whichLocationSelected();
    let locations = userData.company.shareCustomer
      ? []
      : currentLocation
      ? JSON.stringify([{ locationId: currentLocation.id }])
      : userData.location
      ? userData.location.map((item) => {
          return { locationId: item.id };
        })
      : [];
    this.props
      .customerDetailsByCompany({
        companyId: userData.company._id,
        locations,
        shareCustomer: userData.company.shareCustomer,
      })
      .then((res) => {
        if (res.payload.customerData?.data?.statusCode === 200) {
          this.setState({
            CoustmerData: res.payload.customerData.data.response.filter((customer) => customer.status === true),
            isLoading: false,
          });
        } else {
          const error = 'Unable to fetch customers';
          misMatchError.push(error);
          this.setState({ misMatchError, isLoading: false });
        }
      })
      .catch((error) => {
        console.log(error, 'Unable to fetch customers');
        this.setState({ isLoading: false });
      });
  }

  getCustomerListCoustmize = async (name) => {
    if (name.length > 3) {
      this.setState({ isLoading: true });
      let customerData = [];
      let userData = JSON.parse(localStorage.getItem('user'));
      customerData = await this.props
        .customerDetailsByCompanyCustom({
          companyId: userData.company._id,
          name: name,
        })
        .then((res) => {
          if (res.payload.customerData.data.statusCode === 200) {
            this.setState(
              {
                CoustmerData: res.payload.customerData.data.response,
                isLoading: false,
              },
              () => {
                return mapToFormat(this.state.CoustmerData);
              }
            );
          }
        });
      return customerData;
    }
  };

  validateMaskedCardNumber(card) {
    return card !== null && card !== '' && card !== undefined;
  }

  getInvoiceData() {
    let invoice = this.props.location.search.split('?');
    const params = { id: invoice[1] };
    this.setState({ isLoading: true });
    this.props.InvoiceDetailById(params).then((res) => {
      if (res.payload.paymentData.data.statusCode === 200) {
        const { ...other } = res.payload.paymentData.data.response;
        if (Object.prototype.hasOwnProperty.call(other, 'achDetails') && other.achDetails == '') {
          delete other.achDetails;
        }
        this.setState({ ...other, isLoading: false }, () => {
          let total = this.state.total;
          let cashTotal = this.state.cashTotal;
          if (!this.state.feeSettings?.virtualTerminalSettings?.InvoiceTax) {
            if (res.payload.paymentData.data.response.tax !== 0 && res.payload.paymentData.data.response.tax) {
              total = parseFloat(total) - parseFloat(res.payload.paymentData.data.response.tax);
              cashTotal = parseFloat(total) - parseFloat(res.payload.paymentData.data.response.tax);
            }
            this.setState({ tax: 0 });
          }
          if (!this.state.feeSettings?.virtualTerminalSettings?.InvoiceDiscount) {
            if (res.payload.paymentData.data.response.svcFee !== 0 && res.payload.paymentData.data.response.svcFee) {
              total = parseFloat(total) - parseFloat(res.payload.paymentData.data.response.svcFee);
              cashTotal = parseFloat(total) - parseFloat(res.payload.paymentData.data.response.svcFee);
            }
            this.setState({ svcFee: 0 });
          }

          this.setState({ total: parseFloat(total) });
          this.setState({ cashTotal: parseFloat(cashTotal) });
          const data = {
            customerDetails: {
              ...res.payload.paymentData.data.response.customerId,
            },
            email: this.state?.customerId?.email,
            phone: this.state?.customerId?.phone,
          };
          if (this.validateMaskedCardNumber(this.state?.customerId?.masked_card_number)) {
            data.cardPanelShow = true;
            data.cardPanelCustomerShow = false;
            data.mask_number = this.state?.customerId?.masked_card_number;
          }
          this.setState({ ...data });
          let user = JSON.parse(localStorage.getItem('user'));
          this.setState({
            createdBy: user._id,
            companyId: user.company._id,
          });
        });

        if (this.validateMaskedCardNumber(this.state?.customerId?.masked_card_number)) {
          const cardDataInital = {
            isOpen: false,
            cardCsc: '___',
            cardNumber: '______________',
            cardExpiryDate: '__/____',
            customerToken: false,
            customerTokenUpdate: false,
          };
          this.setState({
            customerId: this.state?.customerId,
            customerDetails: this.state.customerId || undefined,
            ach: { ...this.state.ach, isOpen: false },
            card: { ...cardDataInital, isOpen: true },
            cardPanelShow: false,
            cardPanelCustomerShow: true,
            email: this.state?.customerId.email,
            phone: this.state?.customerId.phone,
            cardType: this.state?.customerId.cardType,
            creditOrDebit: this.state?.customerId.creditOrDebit,
            mask_number: this.state?.customerId.masked_card_number,
            notes: res.payload.paymentData.data.response.publicNotes,
            name: `${this.state?.customerId.firstName} ${
              this.state?.customerId.lastName !== undefined ? this.state.customerId.lastName : ''
            }`,
          });
        }
      }
    });
  }

  getInvoicePendingList() {
    this.setState({ isLoading: true });
    let userData = JSON.parse(localStorage.getItem('user'));
    let locationID = JSON.parse(localStorage.getItem('locationId'));
    if (!locationID) {
      locationID = userData.defaultLocation;
    }
    this.props
      .getPendingStatusInvoiceList({
        companyId: userData.company._id,
        permissions: userData.permissions,
        userId: userData._id,
        locationId: locationID,
      })
      .then((res) => {
        if (this.props.location.search.split('?')[1] && !this.state.selectedInvoice) {
          const selectedInvoice = getSelectedInvoice(
            res.payload.paymentData.data.response,
            this.props.location.search.split('?')[1]
          );
          if (selectedInvoice) {
            this.setState({ selectedInvoice: selectedInvoice });
          }
        }
        this.setState({
          InvoicePendingData: res.payload.paymentData.data.response,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log(error, 'pending invoice errors');
      });
  }

  getRefundedDailyData() {
    let userData = JSON.parse(localStorage.getItem('user'));
    let data = {
      date: new Date(),
      companyId: userData.company ? userData.company._id : '',
      role: userData.role,
      userId: userData._id,
    };
    this.props.getRefundedDailyData(data).then((res) => {
      if (res) {
        this.setState({
          TransactionRefundData: res.payload.paymentData.data.response,
          updatedBy: userData._id,
        });
      }
    });
  }

  handleLateFeeType(value) {
    if (this.state.feeSettings?.invoiceSettings?.CustomFeeOneEnabled) {
      let lateFee =
        this.state.feeSettings?.invoiceSettings?.CustomFeeOneType === `$`
          ? this.state.feeSettings?.invoiceSettings?.CustomFeeOne
          : (this.state.feeSettings?.invoiceSettings?.CustomFeeOne / 100) * value;
      this.setState(
        {
          lateFee,
        },
        () => {
          this.setState({
            total: parseFloat(this.state.amount) + this.state.tax + this.getLateFee(),
            cashTotal: parseFloat(this.state.amount) + this.state.tax + this.getLateFee(),
          });
        }
      );
    } else {
      this.setState({
        lateFee: 0,
        total: parseFloat(value),
        cashTotal: parseFloat(value),
      });
    }
  }

  setAmount(value) {
    this.setState({ amount: value });
    const feeComponents1 = getFeeCalculations(
      this.state?.feeSettings,
      value,
      this.state.creditOrDebit,
      this.state?.billingAddress.state
    );
    // Set calculations based on fee settings here
    this.setState(
      {
        total: feeComponents1.totalAmount,
        tax: feeComponents1.salesTaxFee,
        svcFee: (Math.round(parseFloat(feeComponents1.svcFee) * 100) / 100).toFixed(2),
        salesTaxTotal: feeComponents1.salesTaxTotal,
        surcharge: feeComponents1.surchargeFee,
        isStateSVCApplied: feeComponents1.isStateSVCApplied,
        stateSVCAppliedRate: feeComponents1.stateSVCAppliedRate,
      },
      () => {
        if (this.getLateFee() !== 0) {
          const totalFee = getRoundedExacts(this.state.total + getCustomFeeOne(this.state?.feeSettings, value));
          this.setState({ total: totalFee });
        }
        // Handle lateFee calculation based on CustomFeeOneEnabled here
        if (this.state.dualFeeEnabled) {
          const lateFee2 = getCustomFeeOne(this.state?.feeSettings, value);
          const cashTotal2 = parseFloat(value) + (this.state.salesTaxTotal || 0) + this.getLateFee();
          const cardAmount = parseFloat(value) + parseFloat(this.state.svcFee || 0);
          this.setState({ lateFee: lateFee2, cashTotal: cashTotal2, cardAmount: cardAmount });
        }

        if (this.state.ach.isOpen && this.state.dualFeeEnabled) {
          this.setState({ tax: this.state.salesTaxTotal || 0 });
        }
        if (this.state.ach.isOpen && !this.state.dualFeeEnabled) {
          const total = getRoundedExacts(parseFloat(this.state.amount) + (this.state.salesTaxTotal || 0));
          this.setState({ total: total, svcFee: 0, tax: this.state.salesTaxTotal || 0 });
        }
        if (this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurcharge) {
          this.setState({
            surcharge: getSurchargeFee(
              this.state.feeSettings,
              parseFloat(value),
              this.state.creditOrDebit,
              this.state?.billingAddress.state
            ),
          });
        }
      }
    );
  }
  handleSVCChange(value) {
    let error = {};
    validator('svcFee', value) ? (error['svcFee' + 'Error'] = true) : (error['svcFee' + 'Error'] = false);
    this.setState({ svcFee: value }, () => {
      let svc = this.state.svcFee === '' ? 0 : parseFloat(this.state.svcFee);
      this.setState({
        total: parseFloat(this.state.amount) + this.state.tax + parseFloat(svc),
        cashTotal: parseFloat(this.state.amount) + this.state.tax,
      });
    });
  }

  checkNotesValidity(id, value) {
    let error = {};
    let errorMessage = {};
    if (id === 'notes' && value.length > rules.notes.rule.notesCharacterLimit) {
      error[id + 'Error'] = true;
      errorMessage[id + 'ErrorMessage'] = rules.notes.messages.characterLimit;
    }
    if (id === 'notes' && validateNotesMarkUp(value)) {
      error[id + 'Error'] = true;
      errorMessage[id + 'ErrorMessage'] = rules.notes.messages.invalidCharacters;
    }
    return {
      error,
      errorMessage,
    };
  }

  handleChange(event, type = null) {
    let error = {};
    let errorMessage = {};
    let misMatchError = [];
    let { id, value } = event.target;
    const amountValue = type === null ? value : this.state?.amount;
    const validity = this.checkNotesValidity(id, value);
    error = validity.error;
    errorMessage = validity.errorMessage;
    if (id === 'notes' && error[id + 'Error'] === true) {
      this.setState({ error, errorMessage });
      return false;
    } else {
      error = {};
      errorMessage = {};
      this.setState({ error, errorMessage });
    }
    validator(id, amountValue) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({ [id]: amountValue, error, misMatchError, isSubmit: false });
    if (
      !this.state.feeSettings?.virtualTerminalSettings?.InvoiceTax &&
      !this.state.feeSettings?.virtualTerminalSettings?.InvoiceDiscount &&
      id === 'amount'
    ) {
      this.setState({ total: parseFloat(amountValue), cashTotal: parseFloat(amountValue) });
    } else if (id === 'amount' || (id === 'state' && type === 'billingAddress')) {
      const feeCalculations = getFeeCalculations(
        this.state?.feeSettings,
        amountValue,
        this.state.creditOrDebit,
        this.state?.billingAddress.state
      );
      // Set calculations based on fee settings here
      this.setState(
        {
          total: feeCalculations.totalAmount,
          tax: feeCalculations.salesTaxFee,
          svcFee: parseFloat(feeCalculations.svcFee).toFixed(2),
          salesTaxTotal: feeCalculations.salesTaxTotal,
          surcharge: feeCalculations.surchargeFee,
          isStateSVCApplied: feeCalculations.isStateSVCApplied,
          stateSVCAppliedRate: feeCalculations.stateSVCAppliedRate,
        },
        () => {
          // Handle lateFee calculation based on CustomFeeOneEnabled here
          if (this.state.dualFeeEnabled) {
            const lateFees = getCustomFeeOne(this.state?.feeSettings, amountValue);
            const cashTotals = parseFloat(amountValue) + (this.state.salesTaxTotal || 0) + this.getLateFee();
            this.setState({ lateFee: lateFees, cashTotal: cashTotals });
          }
        }
      );
      if (this.state.ach.isOpen) {
        this.setState({ total: parseFloat(amountValue), cashTotal: parseFloat(amountValue) });
      }
    }
  }

  handleFeeSettingsType = (e, name) => {
    const settingsName = name;
    const settingsValue = e.target.value;
    const settings = {
      ...this.state.feeSettings,
      virtualTerminalSettings: {
        ...this.state.feeSettings.virtualTerminalSettings,
        [settingsName]: settingsValue,
      },
    };
    this.setState({ feeSettings: settings });
  };

  handleFeeSettingsValue = (e, name) => {
    const settingsName = name;
    let settingsValue = parseFloat(e.target.value);
    if (settingsValue < 0) {
      settingsValue = 0;
    }
    if (settingsValue > 10000) {
      return;
    }

    if (isNaN(parseFloat(e.target.value))) {
      return;
    }
    const settings = {
      ...this.state.feeSettings,
      virtualTerminalSettings: {
        ...this.state.feeSettings.virtualTerminalSettings,
        [settingsName]: settingsValue,
      },
    };
    this.setState({ feeSettings: settings });
  };

  handleFeeSettingsToggles = (e, name) => {
    const settingsName = name;
    const checked = e.target.checked;
    const settings = {
      ...this.state.feeSettings,
      virtualTerminalSettings: {
        ...this.state.feeSettings.virtualTerminalSettings,
        [settingsName]: checked,
      },
    };
    this.setState({ feeSettings: settings }, () => {
      this.setAmount(this.state?.amount);
    });
  };

  handleAddressChanges = (event, type, isBillingAddressSameAsShippingAddress = false) => {
    let { id, value } = event.target;
    if (type === 'shippingAddress' || isBillingAddressSameAsShippingAddress) {
      const shippingAddresses = {
        ...this.state.shippingAddress,
        [id]: value,
      };
      this.setState({ shippingAddress: shippingAddresses });
    }
    if (type === 'billingAddress') {
      const billingAddress = {
        ...this.state.billingAddress,
        [id]: value,
      };
      this.setState({ billingAddress }, () => {
        if (id === 'state') {
          this.handleChange(event, type);
        }
      });
    }
  };

  handleIsBillingAddressSameAsShippingAddress = (isBillingAddressSameAsShippingAddress = false) => {
    if (isBillingAddressSameAsShippingAddress) {
      this.setState({ shippingAddress: { ...this.state.billingAddress } });
    }
  };

  setCustomerDetails(data) {
    if (data[0].amount) {
      this.setState({ amount: data[0].amount }, () => {
        this.setState(
          { customerDetails: { ...data[0].customerId }, creditOrDebit: data[0]?.customerId?.creditOrDebit },
          () => {
            if (data[0]?.customerId?.masked_card_number) {
              this.setState(
                {
                  customerId: {
                    ...this.state.customerDetails,
                    firstName: data[0]?.customerId?.firstName,
                    lastName: data[0]?.customerId?.lastName,
                  },
                  customerDetails: {
                    ...this.state.customerDetails,
                    firstName: data[0]?.customerId?.firstName,
                    lastName: data[0]?.customerId?.lastName,
                  },
                  email: data[0]?.customerId?.email,
                  phone: data[0]?.customerId?.phone,
                  notes: data[0].publicNotes ?? '',
                  cardType: data[0]?.customerId?.cardType,
                  cardWalletID: data[0]?.customerId?.cardWalletID,
                  mask_number: data[0]?.customerId?.masked_card_number,
                  creditOrDebit: data[0]?.customerId?.creditOrDebit,
                  cardPanelShow: false,
                  cardPanelCustomerShow: true,
                  shippingAddress: data[0]?.shippingAddress ? data[0].shippingAddress : initialState.shippingAddress,
                  billingAddress: validateCityInAddress(
                    data[0]?.billingAddress ? data[0].billingAddress : initialState.billingAddress
                  ),
                },
                () => this.setAmount(data[0]?.amount)
              );
            } else {
              this.setState(
                {
                  customerId: {
                    ...this.state.customerDetails,
                    firstName: data[0]?.customerId?.firstName,
                    lastName: data[0]?.customerId?.lastName,
                  },
                  customerDetails: {
                    ...this.state.customerDetails,
                    firstName: data[0]?.customerId?.firstName,
                    lastName: data[0]?.customerId?.lastName,
                  },
                  email: data[0]?.customerId?.email,
                  phone: data[0]?.customerId?.phone,
                  cardType: data[0]?.customerId?.cardType,
                  cardWalletID: data[0]?.customerId?.cardWalletID,
                  mask_number: data[0]?.customerId?.masked_card_number,
                  creditOrDebit: null,
                  cardPanelShow: true,
                  cardPanelCustomerShow: false,
                  shippingAddress: data[0]?.shippingAddress ? data[0].shippingAddress : initialState.shippingAddress,
                  billingAddress: validateCityInAddress(
                    data[0]?.billingAddress ? data[0].billingAddress : initialState.billingAddress
                  ),
                },
                this.setAmount(data[0]?.amount)
              );
            }
          }
        );
        if (
          !this.state.feeSettings?.virtualTerminalSettings?.InvoiceTax &&
          !this.state.feeSettings?.virtualTerminalSettings?.InvoiceDiscount
        ) {
          let total = this.state.amount;
          let cashTotal = this.state.amount;
          if (data[0].svcFee !== 0 && data[0].svcFee) {
            total = parseFloat(total) - parseFloat(data[0].svcFee);
            cashTotal = parseFloat(cashTotal) - parseFloat(data[0].svcFee);
          }
          if (data[0].tax !== 0 && data[0].tax) {
            total = parseFloat(total) - parseFloat(data[0].tax);
            cashTotal = parseFloat(cashTotal) - parseFloat(data[0].tax);
          }
          this.setState({ total: parseFloat(total), cashTotal: parseFloat(cashTotal) });
        } else if (this.state.amount !== '0') {
          const feeComponents12 = getFeeCalculations(
            this.state?.feeSettings,
            data[0].amount,
            this.state.creditOrDebit,
            this.state?.billingAddress.state
          );
          // Set calculations based on fee settings here
          this.setState(
            {
              total: feeComponents12.totalAmount,
              tax: feeComponents12.salesTaxFee,
              svcFee: parseFloat(feeComponents12.svcFee).toFixed(2),
              salesTaxTotal: feeComponents12.salesTaxTotal,
              isStateSVCApplied: feeComponents12.isStateSVCApplied,
              stateSVCAppliedRate: feeComponents12.stateSVCAppliedRate,
            },
            () => {
              // Handle lateFee calculation based on CustomFeeOneEnabled here
              if (this.state.dualFeeEnabled) {
                const lateFees = getCustomFeeOne(this.state?.feeSettings, this.state.amount);
                const cashTotals = parseFloat(data[0]?.amount) + (this.state.salesTaxTotal || 0) + this.getLateFee();
                const cardAmount = parseFloat(data[0]?.amount) + parseFloat(this.state.svcFee || 0);
                this.setState({ lateFee: lateFees, cashTotal: cashTotals, cardAmount: cardAmount });
              }
              if (this.state.ach.isOpen && this.state.dualFeeEnabled) {
                this.setState({ tax: this.state?.salesTaxTotal });
              }
              if (this.state.ach.isOpen && !this.state.dualFeeEnabled) {
                const totals = parseFloat(data[0]?.amount) + this.state?.salesTaxTotal;
                this.setState({ total: totals, tax: this.state?.salesTaxTotal, svcFee: 0 });
              }
              if (this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurcharge) {
                this.setState({
                  surcharge: getSurchargeFee(
                    this.state.feeSettings,
                    parseFloat(data[0]?.amount),
                    this.state.creditOrDebit,
                    this.state?.billingAddress.state
                  ),
                });
              }
            }
          );
        }
      });
    }
  }

  fetchAddressData() {
    let userDetails = JSON.parse(localStorage.getItem('user'));
    this.setState({
      addressSettings: {
        ...this.state.addressSettings,
        shippingAddress: userDetails?.settings?.virtualTerminalSettings?.shippingAddress,
        billingAddress: validateCityInAddress(userDetails?.settings?.virtualTerminalSettings?.billingAddress),
      },
    });
  }

  setSelectedInvoice(value) {
    if (value !== null) {
      const selectedInvoice = getSelectedInvoice(this.state.InvoicePendingData, value?.value, true);
      this.setState({ selectedInvoice: selectedInvoice, isInvoice: true, newJobIdNotValid: false });
      const data = this.state.InvoicePendingData.filter(
        (item) => item.invoice === value?.value || item?.jobId === value?.value
      );
      if (data.length == 1) {
        this.setState({
          invoice: data[0].invoice,
          submitedToPax: data[0].submitedToPax,
          isCancelledFromPax: data[0].isCancelledFromPax,
        });
        if (data[0]?.isAddedInBulk) {
          this.setState({
            jobId: data[0]?.jobId ? data[0].jobId : undefined,
            isAddedInBulk: true,
          });
        } // if inv is not added in bulk then set it to false
        else if (this.state.isAddedInBulk) {
          this.setState({
            jobId: undefined,
            isAddedInBulk: false,
          });
        }
      }
      const isLateFeeApplicable = isDueDatePassed(
        data[0],
        this.state.feeSettings?.invoiceSettings?.CustomFeeOneDueDays
      );
      this.setState({ isLateFeeApplicable: isLateFeeApplicable });
      this.setCustomerDetails(data);
    } else {
      const cardDataInitalValue = {
        cardCsc: '___',
        isOpen: true,
        cardNumber: '______________',
        cardExpiryDate: '__/____',
        customerToken: false,
        customerTokenUpdate: false,
        creditOrDebit: this.state.card?.creditOrDebit,
      };
      this.setState({ invoice: '', selectedInvoice: '' }, () => {
        this.setState({
          customerDetails: {
            ...this.state.customerDetails,
            firstName: '',
            lastName: '',
          },
          ach: { ...initialState.ach, isOpen: false },
          cardPanelShow: true,
          cardPanelCustomerShow: false,
          addNewCard: false,
          cardWalletId: initialState.cardWalletID,
          card: { ...cardDataInitalValue },
          email: initialState.email,
          mask_number: initialState.mask_number,
          name: initialState.name,
          customerId: initialState.customerId,
          amount: '',
          creditOrDebit: initialState?.creditOrDebit,
          total: initialState.total,
          cashTotal: initialState.cashTotal,
          phone: initialState.phone,
          cardData: initialState.cardData,
          notes: initialState.notes,
          svcFee: initialState.svcFee,
          tax: 0.0,
          shippingAddress: initialState.shippingAddress,
          billingAddress: validateCityInAddress(initialState.billingAddress),
        });
      });
    }
  }

  setClientValueInvoice(val) {
    let misMatchError = [];
    let error = {};
    if (val !== null) {
      this.setState({ isInvoice: true, error, misMatchError });
      this.setState({
        invoice: val.value,
        selectedInvoice: val.value,
        error: {
          ...this.state.error,
          invoiceError: false,
          amountError: false,
          emailError: false,
        },
      });
      const data = this.state.InvoicePendingData.filter(
        (item) => item.invoice === val.value || item?.jobId === val.value
      );
      this.setCustomerDetails(data);
    } else {
      this.setState({ invoice: '' }, () => {
        this.setState({
          customerDetails: {
            ...this.state.customerDetails,
            firstName: '',
            lastName: '',
          },
          tax: 0.0,
        });
      });
    }
  }

  handleInputForRefundTransactions(value) {
    this.setState({ inputForRefundTransactions: value });
  }

  handleOpenDialogue() {
    this.setState({ isOpenDialogue: true });
  }

  handleCloseDialogue() {
    this.setState({ isOpenDialogue: false });
  }

  selectPaxTerminal(e, value) {
    if (value) {
      this.setState({ paxSelectError: false });
    }
    this.setState({ selectedPaxTerminal: value });
  }

  setClientValuePendingTxn(val) {
    if (val !== null) {
      let data = this.state.RefundPendingData.filter(
        (res) =>
          res.TXNID +
            (res.CUSTOMERDATA?.firstName ? ' ' + res.CUSTOMERDATA?.firstName : '') +
            (res.CUSTOMERDATA?.lastName ? ' ' + res.CUSTOMERDATA?.lastName : '') ==
          val.value
      );
      let oldAmount =
        Object.prototype.hasOwnProperty.call(data[0], 'REFUNDED') && data[0].REFUNDED.length > 0
          ? data[0].REFUNDED.reduce((a, b) => {
              return parseFloat(a) + parseFloat(b.amt);
            }, 0.0)
          : 0.0;
      this.setState({ customerDetails: { ...data[0].CUSTOMERDATA } }, () => {
        this.setState({
          customerId: {
            ...this.state.customerDetails,
            firstName: data[0].CUSTOMERDATA?.firstName,
            lastName: data[0].CUSTOMERDATA?.lastName,
          },
          customerDetails: {
            ...this.state.customerDetails,
            firstName: data[0].CUSTOMERDATA?.firstName,
            lastName: data[0].CUSTOMERDATA?.lastName,
          },
          email: data[0].CUSTOMERDATA.email,
          phone: data[0].CUSTOMERDATA.phone,
          cardType: data[0].CUSTOMERDATA.cardType,
          mask_number: data[0].MASKCARDNUMBER || data[0].CUSTOMERDATA?.masked_card_number,
          cardWalletID: data[0].CUSTOMERDATA.cardWalletID ? data[0].CUSTOMERDATA.cardWalletID : null,
          cardPanelShow: false,
          cardPanelCustomerShow: true,
          addNewCardDisabled: true,
        });
      });
      this.setState({
        ...this.state,
        transactionDetails: {
          ...data[0],
          updatedBy: this.state.userDetails._id,
          oldAmount: parseFloat(data[0].AMOUNT - parseFloat(oldAmount)).toFixed(2),
          AMOUNT: parseFloat(data[0].AMOUNT - parseFloat(oldAmount)).toFixed(2),
        },
        currentTransactionAmount: data[0].AMOUNT,
      });
    } else {
      this.setState({
        transactionDetails: initialState.transactionDetails,
        customerDetails: '',
        customerId: '',
        email: '',
        phone: '',
        cardType: '',
        mask_number: '',
        cardPanelShow: true,
        cardPanelCustomerShow: false,
        currentTransactionAmount: '',
        inputForRefundTransactions: '',
      });
    }
  }

  setClientValue = (val, actionMeta = null) => {
    if (actionMeta && actionMeta.action === 'create-option') {
      if (validator('payerName', val?.value)) {
        this.setState({
          isNewCustomer: true,
          warning: true,
          warningMessage: rules.payerName.messages.characterlimit,
        });

        return;
      }
      this.setState(
        {
          isNewCustomer: true,
          warning: false,
          warningMessage: 'warning message',
        },
        () => {
          this.updateNewCustomerDetails(val.value);
        }
      );
    } else {
      this.setState({ isNewCustomer: false }, () => {
        const data = {
          customerId: {
            firstName: '',
            lastName: '',
            ...this.state.customerDetails,
          },
          customerDetails: {
            firstName: '',
            lastName: '',
            ...this.state.customerDetails,
          },
          warning: false,
          warningMessage: 'warning message',
        };
        if (this.state.customerDetails.masked_card_number) {
          const customer_idPresent = data.customerId.customer_id ? true : false;
          data.cardPanelShow = !customer_idPresent;
          data.cardPanelCustomerShow = customer_idPresent ? true : false;
          if (!customer_idPresent) {
            data.warning = true;
            data.warningMessage = 'Due to security reasons this card needs to be updated.';
          }
        }
        this.setState(data);
      });
    }
    if (val !== null) {
      const cardDataInital = {
        isOpen: false,
        cardCsc: '___',
        cardNumber: '______________',
        cardExpiryDate: '__/____',
        customerToken: false,
        customerTokenUpdate: false,
      };
      const data = this.state.CoustmerData.filter((item) => item._id === val.value);
      if (data.length > 0 && data[0].customer_id) {
        if (data[0].masked_card_number && data[0].masked_card_number != '') {
          this.setState(
            {
              customerId: data[0],
              customerDetails: data[0],
              ach: { ...this.state.ach, isOpen: false },
              card: { ...cardDataInital, isOpen: true, creditOrDebit: this.state?.creditOrDebit },
              cardPanelShow: false,
              cardPanelCustomerShow: true,
              email: data[0].email,
              creditOrDebit: data[0]?.creditOrDebit,
              cardWalletID: data[0]?.cardWalletID,
              phone: data[0].phone,
              cardType: data[0].cardType,
              mask_number: data[0].masked_card_number,
              name: `${data[0]?.firstName} ${data[0]?.lastName ? data[0]?.lastName : ''}`,
              shippingAddress: data[0]?.shippingAddress ? data[0].shippingAddress : initialState.shippingAddress,
              billingAddress: validateCityInAddress(
                data[0]?.billingAddress ? data[0].billingAddress : initialState.billingAddress
              ),
            },
            () => this.setAmount(this.state?.amount)
          );
        } else {
          this.setState(
            {
              customerId: data[0],
              customerDetails: data[0],
              ach: { ...this.state.ach, isOpen: false },
              card: { ...cardDataInital, isOpen: true, creditOrDebit: this.state?.creditOrDebit },
              creditOrDebit: data[0]?.creditOrDebit,
              cardPanelShow: true,
              cardPanelCustomerShow: false,
              cardWalletID: data[0]?.cardWalletID,
              email: data[0].email,
              phone: data[0].phone,
              cardType: data[0].cardType,
              mask_number: data[0].masked_card_number,
              name: `${data[0].firstName} ${data[0].lastName !== undefined ? data[0].lastName : ''}`,
              shippingAddress: data[0]?.shippingAddress ? data[0].shippingAddress : initialState.shippingAddress,
              billingAddress: validateCityInAddress(
                data[0]?.billingAddress ? data[0].billingAddress : initialState.billingAddress
              ),
            },
            () => this.setAmount(this.state?.amount)
          );
        }
      } else {
        this.setState(
          {
            customerId: data[0],
            customerDetails: data[0],
            ach: { ...this.state.ach, isOpen: false },
            cardPanelShow: true,
            cardPanelCustomerShow: false,
            email: data[0] ? data[0].email : '',
            phone: data[0]?.phone,
            mask_number: '',
            creditOrDebit: data[0]?.creditOrDebit ? data[0]?.creditOrDebit : this.state.creditOrDebit,
            cardWalletID: data[0]?.cardWalletID ?? null,
            name: '',
            updateCard: false,
            shippingAddress: data[0]?.shippingAddress ? data[0].shippingAddress : initialState.shippingAddress,
            billingAddress: validateCityInAddress(
              data[0]?.billingAddress ? data[0].billingAddress : initialState.billingAddress
            ),
          },
          () => this.setAmount(this.state?.amount)
        );
      }
    } else {
      let misMatchError = [];
      const errorCard = {
        cardNumber: false,
        cardHolderName: false,
        cardExpiryDate: false,
        cardCsc: false,
      };
      const cardDataInitalValue = {
        cardCsc: '___',
        isOpen: true,
        cardNumber: '______________',
        cardExpiryDate: '__/____',
        customerToken: false,
        customerTokenUpdate: false,
        creditOrDebit: this.state?.creditOrDebit,
      };
      this.setState(
        {
          ach: { ...initialState.ach, isOpen: false },
          cardPanelShow: true,
          cardPanelCustomerShow: false,
          misMatchError,
          errorCard: { ...errorCard },
          addNewCard: false,
          card: { ...cardDataInitalValue },
          email: initialState.email,
          mask_number: initialState.mask_number,
          cardWalletID: null,
          name: initialState.name,
          customerId: initialState.customerId,
          creditOrDebit: initialState?.creditOrDebit,
          amount: '',
          total: initialState.total,
          cashTotal: initialState.cashTotal,
          phone: initialState.phone,
          cardData: initialState.cardData,
          customerDetails: { ...initialState.customerDetails },
          notes: initialState.notes,
          svcFee: initialState.svcFee,
          dualFee: initialState.dualFee,
        },
        () => {
          this.setAmount(this.state?.amount);
        }
      );
    }
  };
  updateCardState(id, value, error) {
    let misMatchError = [];
    this.setState(
      {
        card: { ...this.state.card, [id]: value },
        errorCard: { ...this.state.errorCard, [id]: error[id + 'Error'] },
        error,
        misMatchError,
        isSubmit: false,
      },
      () => {
        let firstNumber = this.state.card.cardNumber.substring(0, 14);
        if (firstNumber.split('_')[0].length > 5 && id == 'cardNumber') {
          this.checkCardPaymentType(firstNumber);
        }

        if (firstNumber.split('_')[0].length < 6 && id == 'cardNumber' && this.state.creditOrDebit !== null) {
          this.setState({ creditOrDebit: null }, () => {
            this.setAmount(this.state?.amount);
          });
        }

        this.setState({ card: { ...this.state.card } });
        if (firstNumber[0] === '3') {
          this.setState({ americanExpress: true });
        } else {
          this.setState({ americanExpress: false });
        }
      }
    );
  }
  handleChangeCard(event, _t) {
    let error = {};
    let { id, value } = event.target;
    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    if (id === 'cardNumber' && value[0] === '3' && value.length === 16 && value[value.length - 1] === '_') {
      value = value.slice(0, -1);
    }
    if (id == 'cardExpiryDate') {
      const month = value.split('/')[0];
      if (month && month.length == 2 && (parseInt(month) <= 0 || parseInt(month) > 12)) {
        error[id + 'Error'] = true;
      }
    }
    this.updateCardState(id, value, error);
    changeFocus(id, value, this.expiryDateRef.current, this.cscCardRef.current);
  }
  handleChangeAch(event, _t) {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({
      ach: { ...this.state.ach, [id]: value },
      errorCard: { ...this.state.errorCard, [id]: false },
      error,
      misMatchError,
      isSubmit: false,
    });
  }

  HandleChangedTotal = (event, _t) => {
    let { value } = event.target;
    this.setState({
      isChange: true,
      transactionDetails: {
        ...this.state.transactionDetails,
        AMOUNT: String(value),
      },
    });
  };
  deleteUnUsedKeysFromState() {
    const misMatchError = [];
    if (this.checkAllLocationSelected()) {
      this.setState({ allLocationError: true });
      misMatchError.push(ALL_LOCATION_ERROR);
      this.setState({ misMatchError });
    }
    const keysTodeleteFromState = [
      'timestamp_PAID',
      'notes',
      'invoice',
      'zip',
      'name',
      'InvoiceSalesTax',
      'InvoiceSalesTaxFee',
      'svcFee',
      'dualFee',
      'selectedInvoice',
      'phone',
      'publicNotes',
      'privateNotes',
      'customerName',
      'oneTimeCustomerInvoice',
    ];
    for (const [key, value] of Object.entries(this.state)) {
      if (
        (this.state[key] == '' && keysTodeleteFromState.includes(key)) ||
        (typeof this.state[key] === 'undefined' && keysTodeleteFromState.includes(key)) ||
        (value === '' && keysTodeleteFromState.includes(key))
      ) {
        delete this.state[key];
      }
      if (key == 'invoice' && this.state.invoice === '' && !this.state.isJobIdenabled) {
        this.setState({ isInvoice: false });
      }
    }
  }

  checkCurrentTransactionAmount() {
    if (!this.state.isRefund) {
      delete this.state.currentTransactionAmount;
    }
  }
  checkMaskNumber() {
    if (this.state.mask_number === '') {
      delete this.state.name;
      delete this.state.mask_number;
    }
  }

  validateStateElements(error, misMatchError = null) {
    let errorFlag = null;
    if (this.state.email === '' || this.state.email === null || this.state.email === undefined) {
      delete this.state['email'];
    }
    if (!this.state.phone) {
      delete this.state['phone'];
    }
    if (!this.state.zip) {
      delete this.state.zip;
    }
    if (!this.state.inputForRefundTransactions) {
      delete this.state.inputForRefundTransactions;
    }
    if (this.state.card.cardExpiryDate) {
      const month = this.state.card.cardExpiryDate.split('/')[0];
      if (month && month.length == 2 && (parseInt(month) <= 0 || parseInt(month) > 12)) {
        errorFlag = true;
        error['card Error'] = true;
        misMatchError.push('Invalid card expiry date');
      }
    }
    Object.keys(this.state).forEach((key) => {
      if (
        validator(key, this.state[key]) ||
        this.state[key] === '' ||
        (typeof this.state[key] === 'string' && this.state[key].trim() === '')
      ) {
        error[`${key} Error`] = true;
        misMatchError.push(`Please enter a valid ${key} value`);
        errorFlag = true;
      } else {
        error[`${key} Error`] = false;
      }
    });
    return [errorFlag, misMatchError];
  }

  checkPaytraceFileExistence(misMatchError, errorF) {
    let errorFlag = errorF;
    if (this.state.paytraceCredentials.paytraceKey) {
      let file = doesFileExist(this.props.PAYTRACE_AWS_PATH + this.state.paytraceCredentials.paytraceKey);
      if (!file) {
        window.scrollTo(0, 0, 0);
        let msg = 'Paytrace file not exist. Kindly contact to super admin to re-upload the key.';
        misMatchError.push(msg);
        this.setState({ misMatchError });
        errorFlag = true;
      }
    }
    return errorFlag;
  }

  checkPayTraceCreds(misMatchError, errorF) {
    let errorFlag = errorF;
    if (!Object.prototype.hasOwnProperty.call(this.state.paytraceCredentials, 'password')) {
      window.scrollTo(0, 0, 0);
      misMatchError.push(ALL_LOCATION_ERROR);
      this.setState({ misMatchError });
      errorFlag = true;
    }
    return errorFlag;
  }

  checkMultipassCreds(misMatchError, errorF) {
    let errorFlag = errorF;
    if (
      !Object.prototype.hasOwnProperty.call(this.state.multipassCredentials, 'mutipassDeviceId') &&
      !Object.prototype.hasOwnProperty.call(this.state.multipassCredentials, 'transactionKey')
    ) {
      window.scrollTo(0, 0, 0);
      misMatchError.push(ALL_LOCATION_ERROR);
      this.setState({ misMatchError });
      errorFlag = true;
    }
    return errorFlag;
  }
  handleInvoiceSuccess(misMatchError, isPAXTerminalRequest = false) {
    this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
      setTimeout(() => {
        this.setState({ successMessages: false });
        if (isPAXTerminalRequest) {
          this.setState({ redirectInvoice: true });
        } else if (this.state.isPaid) {
          this.setState({ redirectInvoice: true });
        } else {
          this.setState({ redirectTransaction: true });
        }
      }, 2000);
    });
  }
  handleInvoiceErrorCodes(result, misMatchError) {
    let message = result.payload.error.data.message;
    if (message) {
      return message;
    }
    let responseErrors = JSON.parse(result.payload.error.data.response);
    if (responseErrors.response_code === 102) {
      message = false;
      misMatchError.push(
        responseErrors.status_message +
          ' ' +
          responseErrors.response_code +
          ' \n' +
          responseErrors.approval_message +
          '\n'
      );
    }
    if (responseErrors.response_code === 1) {
      if (Object.keys(responseErrors.errors).length === 1) {
        let key = Object.keys(responseErrors.errors)[0];
        message = `This transaction was declined due to ${key},${responseErrors.errors[key][0]}`;
      } else {
        for (let key in responseErrors.errors) {
          let i = 0;
          misMatchError.push(responseErrors.errors[key][i] + '\n');
        }
      }
    }
    if (responseErrors.response_code === 1001) {
      misMatchError.push(responseErrors.status_message);
    }
    return message;
  }
  handleInvoiceError(result, misMatchError, successMessages) {
    if (result.payload.error.data) {
      let message =
        result.payload.error.data.message ??
        'Please verify whether your transaction was successful on the transactions table. Unknown error occurred';
      if (result.payload.error.data.response && result.payload.error.data.response.length > 0) {
        message = this.handleInvoiceErrorCodes(result, misMatchError);
      }
      if (message) {
        misMatchError.push(message);
      }

      misMatchError.reverse();
      this.setState({
        isLoading: false,
        misMatchError,
        successMessages,
      });
    }
  }

  checkIsValid() {
    let isValid = true;

    if (this.state.ach.isOpen) {
      isValid = this.achFormValidationRef.current && this.achFormValidationRef.current.validate();
    }

    return isValid;
  }

  handleSubmit(e, cashOption = false) {
    e.preventDefault();
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true });

    if (!this.checkIsValid()) {
      return false;
    }

    if (
      this.state.submitedToPax &&
      !this.state.isCancelledFromPax &&
      !this.state.proceedAnyway &&
      ['cashPayment', 'chargeCard', 'pax_terminal'].includes(e.currentTarget.id)
    ) {
      this.setState({
        submitedToPaxModal: true,
      });
      return false;
    }
    if (!this.state.proceedAnyway && ['cashPayment'].includes(e.currentTarget.id) && this.paymentDetails()) {
      this.setState({ openCashPaymentModal: true });
      return false;
    }

    if (this.state.allLocationError) {
      misMatchError.push(ALL_LOCATION_ERROR);
      this.setState({ misMatchError });
      return false;
    }

    if (this.state.invoiceLabelRequired && !this.state.isOneTimeCustomer) {
      const inputValue = this.state.isOneTimeCustomer ? this.state.oneTimeCustomerInvoice : this.state.selectedInvoice;
      if (inputValue === '' || inputValue === null) {
        misMatchError.push(`Please enter a value for the ${this.state.invoiceLabel} field.`);
        this.setState({ misMatchError });
        window.scrollTo(0, 0, 0);
        return false;
      }
    }

    if (
      (!Object.prototype.hasOwnProperty.call(this.state.customerDetails, 'firstName') ||
        this.state.customerDetails?.firstName === undefined ||
        (this.state.customerDetails?.firstName === '' && !this.state.customerId)) &&
      !this.state.isOneTimeCustomer
    ) {
      misMatchError.push('Enter or Select a Customer.');
      this.setState({ misMatchError });
      window.scrollTo(0, 0, 0);
      return false;
    }

    if (e?.currentTarget?.name !== 'PAX_Terminal') {
      const addressError = handleAddressSettingError(this.state);
      if (addressError.length > 0) {
        misMatchError = addressError;
        this.setState({ misMatchError });
        return false;
      }
    }

    if (this.props.UserDetail.permissions.emailVisibilityTerminal) {
      if (this.state.email !== '' && this.state.email !== null && this.state.email !== undefined) {
        if (!this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
          misMatchError.push('Enter a valid email address.');
          this.setState({ misMatchError });
          window.scrollTo(0, 0, 0);
          return false;
        }
      }
    }

    const zipCodeErrors = validateZipCode(this.state);
    if (zipCodeErrors.length > 0) {
      this.setState({ misMatchError: zipCodeErrors });
      return false;
    }
    if (
      this.state.card.customerTokenUpdate === false &&
      this.state.updateCard === true &&
      this.state.addNewCard === true
    ) {
      this.setState({
        card: { ...this.state.card, customerToken: false },
      });
    }

    this.deleteUnUsedKeysFromState();

    if (this.state.isOneTimeCustomer && !this.state.customerName) {
      misMatchError.push('Enter Customer Name.');
      this.setState({ misMatchError });
      return false;
    }

    if (this.state.isOneTimeCustomer && this.state.invoice) {
      this.setState({ isInvoice: false, invoice: false });
    }

    if (!this.state.isNewCustomer && !this.state.isOneTimeCustomer) {
      const dataCustomer = this.state.CoustmerData.filter(
        (item) => item._id === (this.state.customerId?._id || this.state.customerId?.id)
      );

      this.setState({
        CoustmerData: dataCustomer,
      });
    } else {
      this.setState({
        CoustmerData: [],
      });
    }

    const dataInvoice = this.state.InvoicePendingData.filter(
      (item) => item.invoice === this.state.selectedInvoice || item?.jobId === this.state.selectedInvoice
    );

    if (this.state.isInvoice) {
      this.setState({
        InvoicePendingData: dataInvoice,
      });
    } else {
      this.setState({
        InvoicePendingData: [],
      });
    }

    this.checkCurrentTransactionAmount();
    const paymentGateway = getLocationPaymentGateway();
    if (
      e?.currentTarget?.name !== 'PAX_Terminal' &&
      paymentGateway === 'paytrace' &&
      this.checkMaxTransactionLimit(this.state.total) &&
      this.checkCardValues(this.state, cashOption)
    ) {
      this.checkMaskNumber();
      [errorFlag, misMatchError] = this.validateStateElements(error, misMatchError);
      this.initiatePaytraceRequest(
        cashOption,
        misMatchError,
        successMessages,
        errorFlag,
        logoError,
        error,
        'updateInvoice'
      );
    }
    if (
      e?.currentTarget?.name !== 'PAX_Terminal' &&
      this.checkMaxTransactionLimit(this.state.total) &&
      paymentGateway === 'multipass'
    ) {
      this.initiateMultipassRequest(
        cashOption,
        misMatchError,
        successMessages,
        errorFlag,
        logoError,
        error,
        'updateInvoice'
      );
    }
    if (e?.currentTarget?.name === 'PAX_Terminal') {
      if (!this.state.selectedPaxTerminal) {
        misMatchError.push('Please Select Terminal');
        this.setState({ misMatchError, paxSelectError: true });
        return false;
      }
      this.setState({ isOpenDialogue: false });
      this.initiatePAXTransactionRequest(
        cashOption,
        misMatchError,
        successMessages,
        e?.currentTarget?.name === 'PAX_Terminal'
      );
    }
    this.setState({ proceedAnyway: false });
  }

  checkTsepFieldErrors(misMatchError, errorFlag) {
    if (this.state.tsepFieldError) {
      window.scrollTo(0, 0, 0);
      misMatchError.push(this.state.tsepFieldError);
      this.setState({ misMatchError });
      errorFlag = true;
    } else {
      errorFlag = false;
    }
    return errorFlag;
  }

  setCardPayload() {
    const paymentGateway = getLocationPaymentGateway();
    let cardPayload = {
      card: { ...this.state.card, cardNumber: '', cardCsc: '' },
      encrypted_number: null,
      encrypted_csc: null,
      isInvoice: !!this.state.invoice,
      locationId: this.state?.locationId,
    };
    if (paymentGateway === 'multipass') {
      const tsepResponse = this.state?.tsepSuccessResponse;
      const walletId = this.state?.cardWalletID;
      cardPayload.encrypted_number = !walletId && tsepResponse ? tsepResponse?.tsepToken : null;
      cardPayload.encrypted_csc = !walletId && tsepResponse ? tsepResponse?.cvv2 : null;
      cardPayload.card = { ...cardPayload?.card, cardExpiryDate: tsepResponse?.expirationDate };
    } else {
      cardPayload.encrypted_number = localStorage.getItem('0');
      cardPayload.encrypted_csc = localStorage.getItem('1');
    }
    return cardPayload;
  }

  sendUpdateInvoiceRequest(gatewayCredentials, misMatchError, successMessages, isPAXTerminalRequest = false) {
    let currentLocation = whichLocationSelected();
    const apiRoute = isPAXTerminalRequest ? 'paxTransaction' : 'updateInvoice';
    this.props
      .updateInvoice(
        { ...this.state, phone: makePhoneNumberForDB(this.state.phone), locationId: currentLocation.id },
        this.state.csrf,
        gatewayCredentials,
        apiRoute
      )
      .then((result) => {
        window.scrollTo(0, 0, 0);
        if (result && result.type === 'INVOICE_SUCCESS') {
          this.handleInvoiceSuccess(misMatchError, isPAXTerminalRequest);
        }
        if (result && result.type === 'INVOICE_ERROR') {
          this.handleInvoiceError(result, misMatchError, successMessages);
        }
      });
  }
  sendRefundRequest(misMatchError, successMessages, errorFlag, logoError, error, credentials) {
    let errorManualRefund = {};

    //case 1 manual refund
    if (this.state.isRefund && this.state.isRefundManual) {
      if (this.checkMaxRefundLimit(this.state.manualRefund.amount)) {
        Object.keys(this.state.manualRefund).forEach((key) => {
          if (
            validator(key, this.state.manualRefund[key]) ||
            this.state.manualRefund[key] === '' ||
            (typeof this.state.manualRefund[key] === 'string' && this.state.manualRefund[key].trim() === '')
          ) {
            errorManualRefund[`${key}Error`] = true;
            errorFlag = true;
          } else {
            errorManualRefund[`${key}Error`] = false;
          }
        });
        if (errorFlag) {
          this.setState({
            misMatchError,
            logoError,
            errorManualRefund,
            successMessages,
          });
          return false;
        } else {
          this.props.refundAuthManual(this.state, this.state.csrf, credentials).then((result) => {
            window.scrollTo(0, 0, 0);
            if (result !== undefined && result.type === 'PAYMENT_SUCCESS') {
              this.setState(
                {
                  isLoading: false,
                  successMessages: true,
                  misMatchError,
                },
                () => {
                  setTimeout(() => {
                    this.setState({ successMessages: false });
                    this.setState({ redirectTransaction: true });
                  }, 2000);
                }
              );
            }
            if (result !== undefined && result.type === 'PAYMENT_ERROR') {
              if (
                result.payload.error.data &&
                (result.payload.error.data.statusCode === 403 ||
                  result.payload.error.data.statusCode === 401 ||
                  result.payload.error.data.statusCode === 500)
              ) {
                let messageError = result.payload.error.data.message;
                misMatchError.push(messageError);
                this.setState({
                  isLoading: false,
                  misMatchError,
                  successMessages,
                });
              }
              if (
                this.props.payment.paymentInfo.data.response.length > 0 &&
                this.props.payment.paymentInfo.data.statusCode !== 401 &&
                this.props.payment.paymentInfo.data.statusCode !== 500
              ) {
                let responseErrors = JSON.parse(this.props.payment.paymentInfo.data.response);

                if (responseErrors.response_code === 1) {
                  for (let keyValue in responseErrors.errors) {
                    let i = 0;
                    misMatchError.push(responseErrors.errors[keyValue][i] + '\n');
                  }
                  this.setState({
                    isLoading: false,
                    misMatchError,
                    successMessages,
                  });
                } else {
                  misMatchError.push(responseErrors.approval_message);
                }
              }

              this.setState({
                isLoading: false,
                misMatchError,
                successMessages,
              });
            }
          });
        }
      }
    } else if (this.state.isRefund && !this.state.isRefundManual) {
      if (
        this.checkMaxRefundLimit(
          this.state.updateCard ? this.state.transactionDetails.AMOUNT : this.state.manualRefund.amount
        )
      ) {
        this.setState({ isLoading: true });
        if (
          (this.state.updateCard ? this.state.transactionDetails.AMOUNT : this.state.manualRefund.amount) >
          this.state.currentTransactionAmount
        ) {
          let message = 'Refund amount can not exceed the original transaction amount';
          misMatchError.push(message);
          window.scrollTo(0, 0);
          this.setState({
            isLoading: false,
            misMatchError,
            successMessages,
          });
          setTimeout(() => {
            this.setState({ misMatchError: [] });
          }, 3000);
          return;
        }
        this.props
          .refundAuth(
            {
              ...this.state.transactionDetails,
              AMOUNT: this.state.isRefundManual ? this.state.manualRefund.amount : this.state.transactionDetails.AMOUNT,
              locationName: this.currentLocation?.locationName || '',
            },
            this.state.csrf,
            credentials
          )
          .then((result) => {
            if (result !== undefined && result.type === 'PAYMENT_SUCCESS') {
              window.scrollTo(0, 0);
              let TXNID =
                result?.payload?.paymentData?.data?.response?.transaction_id ?? this.state.transactionDetails.TXNID;
              this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
                setTimeout(function () {
                  window.location.href = config.APPURL + 'Viewtransaction/' + TXNID;
                }, 2000);
              });
            }
            if (result !== undefined && result.type === 'PAYMENT_ERROR') {
              window.scrollTo(0, 0);
              if (
                result.payload.error.data &&
                (result.payload.error.data.statusCode === 403 || result.payload.error.data.statusCode === 401)
              ) {
                let message = result.payload.error.data.message;
                misMatchError.push(message);
                this.setState({
                  isLoading: false,
                  misMatchError,
                  successMessages,
                });
              }
              if (
                this.props.payment.paymentInfo.data.response.length > 0 &&
                this.props.payment.paymentInfo.data.statusCode !== 401 &&
                this.props.payment.paymentInfo.data.statusCode !== 500
              ) {
                let responseErrors = JSON.parse(this.props.payment.paymentInfo.data.response);
                if (responseErrors.response_code === 1) {
                  for (let key in responseErrors.errors) {
                    let i = 0;
                    misMatchError.push(responseErrors.errors[key][i] + '\n');
                  }
                  this.setState({
                    isLoading: false,
                    misMatchError,
                    successMessages,
                  });
                } else {
                  misMatchError.push(responseErrors.approval_message);
                }
              }
              this.setState({
                isLoading: false,
                misMatchError,
                successMessages,
              });
            }
          })
          .catch((_e) => {
            let message =
              'An error has occurred during the transaction. Please call 877-972-0700 or email techsupport@titaniumpayments.com.';
            misMatchError.push(message);
            this.setState({
              isLoading: false,
              misMatchError,
              successMessages,
            });
          });
      }
    }
  }

  initiateMultipassRequest(cashOption, misMatchError, successMessages, errorFlag, logoError, error, action) {
    errorFlag = this.checkMultipassCreds(misMatchError, errorFlag);
    errorFlag = this.checkTsepFieldErrors(misMatchError, errorFlag);
    if (errorFlag) {
      this.setState({ misMatchError, logoError, error, successMessages });
      return false;
    } else {
      const cardPayload = this.setCardPayload();

      this.setState(
        {
          isLoading: true,
          ...cardPayload,
        },
        () => {
          let total = 0;
          if (this.state.ach.isOpen && this.state.feeSettings.DualFeeEnabled) {
            total = this.state.cashTotal;
          } else {
            total = cashOption ? this.state.cashTotal : this.state.total;
          }

          this.setState(
            {
              svcFee: cashOption ? 0 : this.state.svcFee,
              total: total,
              cash: cashOption ? true : false,
              tax: cashOption ? this.state.salesTaxTotal : this.state.tax,
            },
            () => {
              if (action === 'updateInvoice') {
                this.sendUpdateInvoiceRequest(this.state.multipassCredentials, misMatchError, successMessages);
              } else {
                this.sendRefundRequest(
                  misMatchError,
                  successMessages,
                  errorFlag,
                  logoError,
                  error,
                  this.state.multipassCredentials
                );
              }
            }
          );
        }
      );
    }
  }
  async getUniqueInvoiceNumber() {
    this.setState({ isLoading: true });
    await this.props.getInvoiceNumber().then((res) => {
      this.setState({
        selectedInvoice: res.payload.paymentData.data.invoice,
        newJobId: res.payload.paymentData.data.invoice,
        isLoading: this.state.newJobIdNotValid,
      });
    });
  }

  initiatePAXTransactionRequest(cashOption, misMatchError, successMessages, isPAXTerminal = false) {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        let total = 0;
        total = cashOption ? this.state.cashTotal : this.state.total;
        if (!this.state.isOneTimeCustomer && this.state.newJobId === undefined) {
          await this.getUniqueInvoiceNumber();
        }
        if (
          this.state.isOneTimeCustomer &&
          this.state.newJobId === undefined &&
          this.state.oneTimeCustomerInvoice === undefined
        ) {
          await this.getUniqueInvoiceNumber();
        }
        // Here IsInvoice checked is invoice selected from already created invoice or not.
        let invoice = this.state.isInvoice ? this.state.invoice : undefined;

        // newjobId created when user creates new Invoice from selector
        let newJobId = '';
        if (this.state.isOneTimeCustomer && this.state.oneTimeCustomerInvoice !== undefined) {
          newJobId = this.state.oneTimeCustomerInvoice;
        } else {
          newJobId = !this.state.isInvoice ? this.state.newJobId : this.state.invoice;
        }
        // It's true when new invoice created from selector
        let isNewInvoiceFromJobId = true;

        this.setState(
          {
            svcFee: cashOption ? 0 : this.state.svcFee,
            total: total,
            cash: !!cashOption,
            tax: cashOption ? this.state.salesTaxTotal : this.state.tax,
            invoice,
            newJobId,
            isNewInvoiceFromJobId,
            ...(this.state.isOneTimeCustomer && { oneTimeCustomerInvoice: newJobId }),
          },
          () => {
            this.setState({}, () => {
              this.sendUpdateInvoiceRequest(
                this.state.multipassCredentials,
                misMatchError,
                successMessages,
                isPAXTerminal
              );
            });
          }
        );
      }
    );
  }

  initiatePaytraceRequest(cashOption, misMatchError, successMessages, errorFlag, logoError, error, action) {
    errorFlag = this.checkPaytraceFileExistence(misMatchError, errorFlag);
    errorFlag = this.checkPayTraceCreds(misMatchError, errorFlag);
    if (errorFlag) {
      this.setState({ misMatchError, logoError, error, successMessages });
      return false;
    } else {
      window.paytrace.submitEncrypted('#DemoFormTerminal');
      const cardPayload = this.setCardPayload();
      this.setState(
        {
          // card: { ...this.state.card, cardNumber: '', cardCsc: '' },
          isLoading: true,
          ...cardPayload,
        },
        () => {
          localStorage.removeItem('0');
          localStorage.removeItem('1');
          let total = 0;
          if (this.state.ach.isOpen && this.state.feeSettings.DualFeeEnabled) {
            total = this.state.cashTotal;
          } else {
            total = cashOption ? this.state.cashTotal : this.state.total;
          }

          this.setState(
            {
              svcFee: cashOption ? 0 : this.state.svcFee,
              total: total,
              cash: cashOption ? true : false,
              tax: cashOption ? this.state.salesTaxTotal : this.state.tax,
            },
            () => {
              if (action === 'updateInvoice') {
                this.sendUpdateInvoiceRequest(this.state.paytraceCredentials, misMatchError, successMessages);
              } else {
                this.sendRefundRequest(
                  misMatchError,
                  successMessages,
                  errorFlag,
                  logoError,
                  error,
                  this.state.paytraceCredentials
                );
              }
            }
          );
        }
      );
    }
  }

  handleSubmitAddCardTsep(_e) {
    this.setState({ updateCard: true, cardWalletID: null, tsepFieldSuccess: false });
  }
  checkMaxTransactionLimit(val) {
    let maxTransactionLimit = this.props.UserDetail.permissions.perTransactionLimit
      ? this.props.UserDetail.permissions.perTransactionLimit
      : 0;
    let maxDailyTransactionLimit = this.props.UserDetail.permissions.dailyTransactionLimit
      ? this.props.UserDetail.permissions.dailyTransactionLimit
      : 0;
    let maxDailyAmount = this.state.TransactionData.reduce((item, curr) => {
      return item + curr.AMOUNT;
    }, 0);

    if (val > maxDailyTransactionLimit - maxDailyAmount && maxDailyAmount > 0 && maxDailyTransactionLimit > 0) {
      this.setState({ limit: { ...this.state.limit, dailyTransactionLimit: true } }, () => {
        window.scrollTo(0, 0, 0);
        setTimeout(() => {
          this.setState({
            limit: { ...this.state.limit, dailyTransactionLimit: false },
          });
        }, 5000);
        return false;
      });
    } else if (val > maxTransactionLimit && maxTransactionLimit > 0) {
      this.setState({ limit: { ...this.state.limit, perTransactionLimit: true } }, () => {
        window.scrollTo(0, 0, 0);
        setTimeout(() => {
          this.setState({
            limit: { ...this.state.limit, perTransactionLimit: false },
          });
        }, 5000);
        return false;
      });
    } else {
      return true;
    }
  }
  handleSubmitEmailInvoice(e) {
    e.preventDefault();
    let misMatchError = [];
    const emaillValidationMessage = !this.props.UserDetail.permissions.emailVisibilityTerminal
      ? 'Customer does not have a valid email address'
      : 'Enter a valid email address.';

    if (this.state.allLocationError) {
      misMatchError.push(ALL_LOCATION_ERROR);
      this.setState({ misMatchError });
      return false;
    }

    if (this.state.email === undefined || (this.state.email === '' && this.state.email === null)) {
      misMatchError.push(emaillValidationMessage);
      this.setState({ misMatchError });
      window.scrollTo(0, 0, 0);
      return false;
    }

    if (!this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      misMatchError.push(emaillValidationMessage);
      this.setState({ misMatchError });
      window.scrollTo(0, 0, 0);
      return false;
    }
    const zipCodeValidation = validateZipCode(this.state);
    if (zipCodeValidation.length > 0) {
      this.setState({ misMatchError: zipCodeValidation });
      return false;
    }
    if (
      this.state.submitedToPax &&
      !this.state.isCancelledFromPax &&
      !this.state.proceedAnyway &&
      e.currentTarget.id === 'emailInvoice'
    ) {
      this.setState({
        submitedToPaxModal: true,
      });
      return false;
    }

    let logoError = [];
    let successMessages = false;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true });

    if (this.state.name === '' || this.state.mask_number === '' || this.state.notes === '') {
      delete this.state.name;
      delete this.state.notes;
      delete this.state.mask_number;
      delete this.state.endAddress;
      delete this.state.problemType;
    }
    this.deleteUnUsedKeysFromState();
    this.checkCurrentTransactionAmount();
    if (this.state.allLocationError) {
      return false;
    }
    Object.keys(this.state).forEach((key1) => {
      if (
        validator(key1, this.state[key1]) ||
        this.state[key1] === '' ||
        (typeof this.state[key1] === 'string' && this.state[key1].trim() === '')
      ) {
        error[`${key1} Error`] = true;
        errorFlag = true;
        misMatchError.push(`Error: ${key1}`);
      } else {
        error[`${key1} Error`] = false;
      }
    });

    if (errorFlag) {
      this.setState({ misMatchError, logoError, error, successMessages });
      return false;
    } else {
      this.setState(
        {
          saveDraft: true,
          emailSent: true,
          isLoading: true,
          companyLogoPath: this.getCompanyLogoPath(),
          locationId: this.state.locationId,
        },
        () => {
          const payloadData = {
            ...this.state,
            ...this.props.termsAndConditions,
          };
          this.props
            .emailInvoice(payloadData)
            .then((result) => {
              window.scrollTo(0, 0, 0);
              if (result !== undefined && result.type === 'INVOICE_SUCCESS') {
                this.setState(
                  { isLoading: false, successMessages: true, misMatchError },
                  this.handleTimeout({ successMessages: false })
                );
              }
              if (result !== undefined && result.type === 'INVOICE_ERROR') {
                let message = this.props.info.invoiceInfo.data.message;
                misMatchError.push(message);
                this.setState(
                  { misMatchError, isLoading: false, successMessages },
                  this.handleTimeout({ misMatchError }, 3000)
                );
              }
              this.setState({ proceedAnyway: false });
            })
            .catch((e) => console.log(' invoice Error ', e));
        }
      );
    }
  }
  handleSubmitTextInvoice(e) {
    e.preventDefault();
    let misMatchError = [];
    const phoneValidationMessage = 'Please add a phone number to this customer’s profile.';

    if (this.state.allLocationError) {
      misMatchError.push(ALL_LOCATION_ERROR);
      this.setState({ misMatchError });
      return false;
    }

    if (this.state.phone === undefined || this.state.phone === '' || this.state.phone === null) {
      misMatchError.push(phoneValidationMessage);
      this.setState({ misMatchError });
      window.scrollTo(0, 0, 0);
      return false;
    }
    const zipCodeValid = validateZipCode(this.state);
    if (zipCodeValid.length > 0) {
      this.setState({ misMatchError: zipCodeValid });
      return false;
    }

    if (
      this.state.submitedToPax &&
      !this.state.isCancelledFromPax &&
      !this.state.proceedAnyway &&
      e.currentTarget.id === 'textInvoice'
    ) {
      this.setState({
        submitedToPaxModal: true,
      });
      return false;
    }

    let successMessages = false;
    let errorFlag = false;
    let error = {};
    let logoError = [];
    this.setState({ isSubmit: true });

    if (this.state.name === '' || this.state.mask_number === '' || this.state.notes === '') {
      delete this.state.mask_number;
      delete this.state.notes;
      delete this.state.problemType;
      delete this.state.endAddress;
      delete this.state.name;
    }
    this.deleteUnUsedKeysFromState();
    this.checkCurrentTransactionAmount();
    if (this.state.allLocationError) {
      return false;
    }

    const isStringNotEmpty = (value) => typeof value === 'string' && value.trim() === '';
    Object.keys(this.state).forEach((key) => {
      if (
        key !== 'email' &&
        (validator(key, this.state[key]) || this.state[key] === '' || isStringNotEmpty(this.state[key]))
      ) {
        error[`${key} Error`] = true;
        misMatchError.push(`Error: ${key}`);
        errorFlag = true;
      } else {
        error[`${key} Error`] = false;
      }
    });
    if (errorFlag) {
      this.setState({ successMessages, logoError, error, misMatchError });
      return false;
    } else {
      this.setState({ saveDraft: true, textSent: true, emailSent: true, isLoading: true }, () => {
        this.props
          .textPendingInvoice(this.state)
          .then((res) => {
            window.scrollTo(0, 0, 0);
            if (res?.type === 'INVOICE_SUCCESS') {
              this.setState(
                { misMatchError, isLoading: false, successMessages: true },
                this.handleTimeout({ successMessages: false }, 3000)
              );
            }
            if (res?.type === 'INVOICE_ERROR') {
              let message = this.props.info.invoiceInfo.data.message;
              misMatchError.push(message);
              this.setState(
                { successMessages, misMatchError, isLoading: false },
                this.handleTimeout({ misMatchError }, 3000)
              );
            }
            this.setState({ proceedAnyway: false });
          })
          .catch((e) => console.log('Text invoice Error ', e));
      });
    }
  }

  validateCardField(state) {
    if (state.card.cardNumber.indexOf('_') > -1) {
      this.setState({
        errorCard: { ...this.state.errorCard, cardNumber: true },
      });
      window.scrollTo(0, 0);
      return false;
    } else if (state.card.cardExpiryDate.indexOf('_') > -1) {
      this.setState({
        errorCard: { ...this.state.errorCard, cardExpiryDate: true },
      });
      window.scrollTo(0, 0);
      return false;
    } else if (state.card.cardCsc.indexOf('_') > -1 && !state.isRefundManual) {
      this.setState({
        errorCard: { ...this.state.errorCard, cardCsc: true },
      });
      window.scrollTo(0, 0);
      return false;
    } else {
      return true;
    }
  }

  checkCardValues(state, cashOption = false) {
    let mask_number =
      this.state.customerDetails && this.state.customerDetails.masked_card_number
        ? this.state.customerDetails.masked_card_number
        : this.state.mask_number;
    if (cashOption && !state.customerToken) {
      return true;
    }
    if (state.card.isOpen) {
      if (this.state.cardPanelCustomerShow && mask_number) {
        return true;
      }
      return this.validateCardField(state);
    }
    if (state.ach.isOpen) {
      // already validated
      return true;
    }
  }

  async backToMaskCard(_e, _t) {
    //For set mask number
    const errCard = {
      cardNumber: false,
      cardHolderName: false,
      cardExpiryDate: false,
      cardCsc: false,
    };
    const cardDataInital = {
      cardCsc: '___',
      cardNumber: '______________',
      cardExpiryDate: '__/____',
    };
    const data = this.state.CoustmerData.filter(
      (item) => item._id === (this.state.customerId._id || this.state.customerId.id)
    );
    if (data.length > 0 && data[0].customer_id) {
      this.setState({
        card: { ...cardDataInital, isOpen: true },
        errorCard: { ...errCard },
        cardPanelShow: false,
        cardPanelCustomerShow: true,
        updateCard: false,
        mask_number: data[0].masked_card_number,
        ach: { ...this.state.ach, isOpen: false },
        name: `${data[0]?.firstName} ${data[0]?.lastName !== undefined ? data[0]?.lastName : ''}`,
      });
    } else {
      this.setState({
        card: { ...cardDataInital, isOpen: true },
        errorCard: { ...errCard },
        cardPanelShow: false,
        cardPanelCustomerShow: true,
        updateCard: false,
        ach: { ...this.state.ach, isOpen: false },
      });
    }
  }

  handleChangeCheckbox(event) {
    this.setState({
      card: { ...this.state.card, [event.target.id]: event.target.checked },
    });
  }

  closeAchOpenCard() {
    let achDisclaimerError = [];
    let misMatchError = [];
    const feeComponents1 = getFeeCalculations(
      this.state?.feeSettings,
      this.state.amount,
      this.state.creditOrDebit,
      this.state?.billingAddress.state
    );
    // Set calculations based on fee settings here
    this.setState(
      {
        total: feeComponents1.totalAmount,
        tax: feeComponents1.salesTaxFee,
        svcFee: parseFloat(feeComponents1.svcFee).toFixed(2),
        salesTaxTotal: feeComponents1.salesTaxTotal,
        surcharge: feeComponents1.surchargeFee,
        misMatchError,
        achDisclaimerError,
        card: { ...this.state.card, isOpen: true },
        ach: { ...this.state.ach, isOpen: false },
        errorCard: {
          ...this.state.errorCard,
          cardNumber: false,
          cardExpiryDate: false,
          cardCsc: false,
        },
        isStateSVCApplied: feeComponents1.isStateSVCApplied,
        stateSVCAppliedRate: feeComponents1.stateSVCAppliedRate,
      },
      () => {
        // Handle lateFee calculation based on CustomFeeOneEnabled here
        if (this.state.dualFeeEnabled) {
          const lateFee2 = getCustomFeeOne(this.state?.feeSettings, this.state.amount);
          const cashTotal2 = parseFloat(this.state.amount) + (this.state.salesTaxTotal || 0) + this.getLateFee();
          this.setState({ lateFee: lateFee2, cashTotal: cashTotal2 });
        }
        if (this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurcharge) {
          this.setState({
            surcharge: getSurchargeFee(
              this.state.feeSettings,
              parseFloat(this.state?.amount),
              this.state.creditOrDebit,
              this.state?.billingAddress.state
            ),
          });
        }
      }
    );
  }

  getLateFee() {
    if (this.state.isLateFeeApplicable) {
      return this.state.lateFee;
    } else {
      return 0;
    }
  }

  closeCardOpenAch() {
    let misMatchError = [];
    let achDisclaimerError = [];
    if (this.currentLocation?.ACH) {
      achDisclaimerError.push(ACH_WARNING_MSG);
    }
    let InitialsvcFee = (this.state.feeSettings?.virtualTerminalSettings?.InvoiceSVCFee / 100) * this.state.amount;
    this.setState(
      {
        svcFee: this.state.feeSettings.DualFeeEnabled ? parseFloat(InitialsvcFee).toFixed(2) : 0,
        misMatchError,
        achDisclaimerError: achDisclaimerError,
        card: { ...this.state.card, isOpen: false },
        ach: { ...this.state.ach, isOpen: true },
        errorCard: {
          ...this.state.errorCard,
          cardNumber: false,
          cardExpiryDate: false,
          cardCsc: false,
        },
      },
      () => {
        const feeComponentss = getFeeCalculations(
          this.state?.feeSettings,
          this.state.amount,
          this.state.creditOrDebit,
          this.state?.billingAddress.state
        );
        // Set calculations based on fee settings here
        this.setState(
          {
            total: feeComponentss.totalAmount,
            tax: feeComponentss.salesTaxFee,
            svcFee: parseFloat(feeComponentss.svcFee).toFixed(2),
            salesTaxTotal: feeComponentss.salesTaxTotal,
            surcharge: 0,
            isStateSVCApplied: feeComponentss.isStateSVCApplied,
            stateSVCAppliedRate: feeComponentss.stateSVCAppliedRate,
          },
          () => {
            // Handle lateFee calculation based on CustomFeeOneEnabled here
            if (this.state.dualFeeEnabled) {
              const lateFeess = getCustomFeeOne(this.state?.feeSettings, this.state.amount);
              const cashTotalValue =
                parseFloat(this.state.amount) + (this.state.salesTaxTotal || 0) + this.getLateFee();
              this.setState({ lateFee: lateFeess, cashTotal: cashTotalValue });
            }
            if (this.state.ach.isOpen && this.state.dualFeeEnabled) {
              this.setState({ tax: this.state.salesTaxTotal || 0 });
            }
            if (this.state.ach.isOpen && !this.state.dualFeeEnabled) {
              const totalValue = parseFloat(this.state.amount) + (this.state.salesTaxTotal || 0);
              this.setState({ total: totalValue, svcFee: 0, tax: this.state.salesTaxTotal || 0 });
            }
          }
        );
      }
    );
  }
  achPaymentPopUp(_event) {
    if (this.state.ach.isOpen) {
      this.closeAchOpenCard();
    } else {
      this.closeCardOpenAch();
    }
  }

  cardPaymentPopUp(_event) {
    if (this.state.card.isOpen) {
      this.closeCardOpenAch();
    } else {
      this.closeAchOpenCard();
    }
  }
  handleSubmitAddNewCard(_e, _t) {
    const cardDataInital = {
      cardCsc: '___',
      cardNumber: '______________',
      cardExpiryDate: '__/____',
    };
    this.setState({
      card: {
        ...cardDataInital,
        isOpen: true,
        customerTokenUpdate: true,
      },
      ach: { ...this.state.ach, isOpen: false },
      cardPanelShow: true,
      cardPanelCustomerShow: false,
      updateCard: true,
      addNewCard: true,
      cardWalletID: null,
      transactionDetails: initialState.transactionDetails,
      mask_number: initialState.mask_number,
      creditOrDebit: null,
      isRefundManual: false,
    });
  }

  openAdjustSettings() {
    this.setState({ openAdjustSettings: true });
  }

  handleClose = () => {
    this.setState({ isOpen: false, isLoading: true }, () => {
      this.setAmount(this.state?.amount);
    });
  };
  handleCloseSubmitedToPaxModal = () => {
    this.setState({
      submitedToPaxModal: false,
    });
  };

  handleProceedAnyway = (e, activeElement) => {
    this.setState({
      submitedToPaxModal: false,
      proceedAnyway: true,
    });
    switch (activeElement) {
      case 'textInvoice':
        this.handleSubmitTextInvoice(e);
        break;
      case 'emailInvoice':
        this.handleSubmitEmailInvoice(e);
        break;
      case 'cashPayment':
      case 'chargeCard':
      case 'pax_terminal':
      default:
        this.setState({ proceedAnywayAcknowledgement: true });
        this.handleSubmit(e, activeElement === 'cashPayment' || this.state.isPaid);
        break;
    }
  };

  handleProceedCash = (e, activeElement) => {
    this.setState({
      openCashPaymentModal: false,
      proceedAnyway: true,
    });
    if (activeElement === 'cashPayment') {
      this.handleSubmit(e, true);
    }
  };

  changeCreditToInitialState() {
    const cardDataInitalValue = {
      cardCsc: '___',
      isOpen: true,
      cardNumber: '______________',
      cardExpiryDate: '__/____',
      customerToken: false,
      customerTokenUpdate: false,
    };
    this.setState(
      {
        ...this.state,
        customerDetails: {
          ...this.state.customerDetails,
          firstName: '',
          lastName: '',
        },
        selectedInvoice: '',
        invoice: '',
        cardPanelShow: true,
        cardPanelCustomerShow: false,
        addNewCard: false,
        card: { ...cardDataInitalValue },
        email: initialState.email,
        mask_number: initialState.mask_number,
        name: initialState.name,
        customerId: initialState.customerId,
        amount: '',
        total: initialState.total,
        cashTotal: initialState.cashTotal,
        phone: initialState.phone,
        cardData: initialState.cardData,
        notes: initialState.notes,
        svcFee: initialState.svcFee,
        tax: 0.0,
        isCredit: true,
        isRefund: false,
        addNewCardDisabled: false,
        isRefundManual: false,
        errorCard: {
          ...this.state.errorCard,
          cardNumber: false,
          cardExpiryDate: false,
          cardCsc: false,
        },
        transactionDetails: initialState.transactionDetails,
        cardType: this.state.cardType,
        currentTransactionAmount: '',
        inputForRefundTransactions: '',
      },
      () => {
        delete this.state.inputForRefundTransactions;
      }
    );
  }
  handleChangeCreditToggle(_e, name) {
    if (name === 'isCredit') {
      this.changeCreditToInitialState();
    } else {
      this.setState({
        ...this.state,
        mask_number: '',
        customerId: '',
        customerDetails: '',
        isOneTimeCustomer: false,
        isCredit: false,
        cardType: this.state.cardType,
        cardPanelShow: true,
        cardPanelCustomerShow: false,
        isRefund: true,
        isRefundManual:
          !this.props.UserDetail.permissions.refunds && this.props.UserDetail.permissions.refundsManual ? true : false,
        errorCard: {
          ...this.state.errorCard,
          cardNumber: false,
          cardExpiryDate: false,
          cardCsc: false,
        },
      });
      if (this.state.ach.isOpen) {
        this.closeAchOpenCard();
      }
    }
    // } else {
    //   alert('You dont have refund permisson!')
    // }
  }

  handleChangeOneTimeCustomer() {
    this.changeCreditToInitialState();
    this.setState({
      isOneTimeCustomer: !this.state.isOneTimeCustomer,
      isInvoice: false,
      invoice: '',
      addNewCardDisabled: false,
    });
  }

  checkMaxRefundLimit(val) {
    let maxRefundLimit = this.props.UserDetail.permissions.perRefundLimit
      ? this.props.UserDetail.permissions.perRefundLimit
      : 0;
    let maxRefundDailyLimit = this.props.UserDetail.permissions.dailyRefundLimit
      ? this.props.UserDetail.permissions.dailyRefundLimit
      : 0;
    let maxDailyRefundedAmount = this.state.TransactionRefundData.reduce((item, curr) => {
      return item + curr.AMOUNT;
    }, 0);

    if (val > maxRefundDailyLimit - maxDailyRefundedAmount && maxDailyRefundedAmount > 0 && maxRefundDailyLimit > 0) {
      this.setState({ limit: { ...this.state.limit, dailyRefundLimit: true } }, () => {
        window.scrollTo(0, 0, 0);
        setTimeout(() => {
          this.setState({
            limit: { ...this.state.limit, dailyRefundLimit: false },
          });
        }, 4000);
        return false;
      });
    } else if (val > maxRefundLimit && maxRefundLimit > 0) {
      this.setState({ limit: { ...this.state.limit, perRefundLimit: true } }, () => {
        window.scrollTo(0, 0, 0);
        setTimeout(() => {
          this.setState({
            limit: { ...this.state.limit, perRefundLimit: false },
          });
        }, 3000);
        return false;
      });
    } else {
      return true;
    }
  }

  getMaskedNumber(cardNumber) {
    // returns masked number for manual paytrace refunds because the existing masking function serves multiple purposes, offers incorect outcome for this use case.
    let masked;
    if (cardNumber) {
      masked = cardNumber.slice(0, -4).replaceAll(/\d/g, 'x') + cardNumber.slice(-4);
    } else {
      masked = null;
    }
    return masked;
  }

  getCardCarrier() {
    // gets carrier name from refund array in case it does not exist in transaction details.
    let cardType;
    let refund;
    const refunds = this.state?.RefundPendingData;

    if (refunds && refunds.length > 0) {
      refund = refunds.find((item) => item.TXNID === this.state.transactionDetails.TXNID);
    } else refund = null;

    if (refund && refund?.CARDTYPE) {
      cardType = refund.CARDTYPE;
    } else {
      cardType = null;
    }

    return cardType;
  }

  handleSubmitRefund(e, _t) {
    e.preventDefault();
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let errorFlag = false;
    let error = {};
    const paymentGateway = getLocationPaymentGateway();

    if (this.state.allLocationError) {
      misMatchError.push(ALL_LOCATION_ERROR);
      this.setState({ misMatchError });
      return false;
    }

    let maskedCardNumber;
    if (this.state.isRefundManual && paymentGateway === 'paytrace') {
      maskedCardNumber = this.getMaskedNumber(this.state.card.cardNumber);
    } else {
      maskedCardNumber = this.state.tsepSuccessResponse?.maskedCardNumber || this.state.mask_number || '';
    }

    const cardType = this.state?.cardType || this.getCardCarrier() || '';

    if (!maskedCardNumber || !cardType) {
      misMatchError.push('Missing card information');
      this.setState({ misMatchError });
      return false;
    }

    if (window.confirm(`Are you sure you want to refund this transaction on ${cardType} ${maskedCardNumber}?`)) {
      if (paymentGateway === 'paytrace') {
        this.initiatePaytraceRequest(false, misMatchError, successMessages, errorFlag, logoError, error, 'refund');
      } else {
        this.initiateMultipassRequest(false, misMatchError, successMessages, errorFlag, logoError, error, 'refund');
      }
    }
  }
  handleCheckRefundManual(event) {
    // remove TXN and card data when toggling manual refund
    let updatedState = { ...this.state };
    updatedState.transactionDetails = initialState.transactionDetails;
    updatedState.card = initialState.card;
    updatedState.mask_number = initialState.mask_number;
    updatedState.manualRefund = initialState.manualRefund;
    this.setState({ ...updatedState, [event.target.id]: event.target.checked });
  }

  handleChangeManualRefund(event, _t) {
    let errorManualRefund = {};
    let misMatchError = [];
    let { id, value } = event.target;

    validator(id, value) ? (errorManualRefund[id + 'Error'] = true) : (errorManualRefund[id + 'Error'] = false);
    this.setState({
      manualRefund: { ...this.state.manualRefund, [id]: value },
      misMatchError,
      errorManualRefund,
    });
  }
  handleChangeName(e, _t) {
    let { value } = e.target;
    if (this.state.customerId.lastName !== '') {
      let firstName = value.split(' ')[0] ? value.split(' ')[0] : '';
      let lastName = value.split(' ')[1] ? value.split(' ')[1] : '';
      this.setState({
        customerId: {
          ...this.state.customerId,
          firstName: firstName,
          lastName: lastName,
        },
      });
    } else {
      this.setState({
        customerId: {
          ...this.state.customerId,
          firstName: value,
          lastName: '',
        },
      });
    }
  }
  handleChangeOneTimeCustomerInvoice(e, _t) {
    let { value } = e.target;
    this.setState({
      oneTimeCustomerInvoice: value,
    });
  }
  handleOneTimeCustomerName(e) {
    let { value } = e.target;
    this.setState({
      customerName: value,
    });
  }
  handleCheckAsPaid(event) {
    this.setState({ ...this.state, [event.target.id]: event.target.checked }, () => {
      let misMatchError = [];
      let InitialsvcFee = (this.state.feeSettings?.virtualTerminalSettings?.InvoiceSVCFee / 100) * this.state.amount;

      if (this.state.isPaid) {
        this.setState(
          {
            svcFee: 0,
            dualFee: 0,
            errorCard: {
              ...this.state.errorCard,
              cardNumber: false,
              cardExpiryDate: false,
              cardCsc: false,
            },
          },
          () => {
            this.setState({
              svcFee: this.state.svcFee.toFixed(2),
              total: parseFloat(this.state.amount) + this.state.tax + this.state.svcFee,
              cashTotal: parseFloat(this.state.amount) + this.state.tax,
            });
          }
        );
      } else {
        this.setState(
          {
            svcFee: InitialsvcFee,
            misMatchError,
            errorCard: {
              ...this.state.errorCard,
              cardNumber: false,
              cardExpiryDate: false,
              cardCsc: false,
            },
          },
          () => {
            this.setState({
              svcFee: this.state.svcFee.toFixed(2),
              total: parseFloat(this.state.amount) + this.state.tax + this.state.svcFee,
              cashTotal: parseFloat(this.state.amount) + this.state.tax,
            });
          }
        );
      }
    });
  }

  handleChangeCheckboxUpdate(event) {
    this.setState({
      card: { ...this.state.card, [event.target.id]: event.target.checked },
    });
  }

  handleDebounce(e) {
    this.debounce(this.getCustomerListCoustmize(e.target.value), 200);
  }
  debounce = (func, delay, immediate) => {
    let timerId;
    return (...args) => {
      const boundFunc = func.bind(this, ...args);
      clearTimeout(timerId);
      if (immediate && !timerId) {
        boundFunc();
      }
      const calleeFunc = immediate
        ? () => {
            timerId = null;
          }
        : boundFunc;
      timerId = setTimeout(calleeFunc, delay);
    };
  };
  cutomerDataPromise = (inputValue) => {
    let userData = JSON.parse(localStorage.getItem('user'));

    return new Promise((resolve) => {
      this.props
        .customerDetailsByCompanyCustom({
          companyId: userData.company._id,
          name: inputValue,
        })
        .then((res) => {
          if (res.payload.customerData.data.statusCode === 200) {
            this.setState({
              CoustmerData: res.payload.customerData.data.response,
            });
            resolve(mapToFormat(res.payload.customerData.data.response));
          }
        });
    });
  };

  promiseOptions = _.debounce(async (inputValue) => {
    let arrData = await this.cutomerDataPromise(inputValue);
    return arrData;
  }, 200);
  updateNewCustomerDetails(inputValue) {
    if (this.state.isNewCustomer) {
      let inputArray = inputValue.split(' ');
      let firstName = inputArray[0] || '';
      let lastName = inputArray.slice(1).join(' ') || '';
      this.setState({
        creditOrDebit: this.state.creditOrDebit,
        customerDetails: {
          ...this.state.customerDetails,
          firstName: firstName,
          lastName: lastName,
        },
      });
    } else {
      this.setState({
        creditOrDebit: this.state.creditOrDebit,
        customerDetails: {
          ...this.state.customerDetails,
          firstName: '',
          lastName: '',
        },
      });
    }
  }

  handleInputClientChange = (_inputValue, _actionMeta) => {
    let error = {};
    let misMatchError = [];
    this.setState({ error, misMatchError });
  };

  removeCloseableMessage = (index) => {
    let misMatchError = this.state.misMatchError;
    delete misMatchError[index];
    this.setState({ misMatchError });
  };

  getNextField = (field, incrementedIndex) => {
    const fieldArray = [
      'client',
      'customerNameRef',
      'amount',
      'email',
      'phone',
      'svcFee',
      'tax',
      'address',
      'zip',
      'totalRef',
      'notes',
    ];
    let fieldIndex = fieldArray.indexOf(field);
    let nextFieldIndex = fieldIndex + incrementedIndex;
    let nextFieldName = fieldArray[nextFieldIndex];
    return nextFieldName;
  };

  getReference = (fieldName) => {
    let reference = null;
    if (fieldName === 'customerNameRef') {
      reference = this.fieldRef[fieldName].current;
    } else {
      reference = document.getElementById(fieldName);
    }
    return reference;
  };

  findFocusField = (field, condition) => {
    let i = 1;
    while (condition) {
      const nextField = this.getNextField(field, i);
      const reference = this.getReference(nextField);
      if (reference && !reference.disabled) {
        reference.focus();
        condition = false;
        break;
      }
      i++;
    }
  };

  handleKeyDown = (event, field) => {
    if (event.keyCode === 13) {
      // 13 represents the Enter key
      event.preventDefault(); // Prevent form submission
      const reference = this.getReference(field);
      if (!reference) {
        this.findFocusField(field, true);
      } else if (!reference.disabled) {
        reference.focus();
      } else {
        this.findFocusField(field, true);
      }
    }
  };

  handleEnterKeyPress = (event, field) => {
    if (event.keyCode === 13) {
      // 13 represents the Enter key
      const reference = this.getReference(field);
      if (!reference) {
        this.findFocusField(field, true);
      } else if (!reference.disabled) {
        reference.focus();
      } else {
        this.findFocusField(field, true);
      }
    } else if (field === 'client') {
      this.setState({ newJobId: event.target.value + event.key });
    }
  };

  handleBlur = (_e, _field) => {
    if (this.state.newJobId) {
      this.onCreateOption(this.state.newJobId);
    }
  };

  getCardSalesTaxLabel = (name) => {
    const type = this.state.feeSettings?.virtualTerminalSettings?.InvoiceSalesTaxFeeType;
    const value = this.state.feeSettings?.virtualTerminalSettings?.InvoiceSalesTaxFee?.toFixed(2);

    if (type === '$') {
      return `${name} Sales Tax ${type}${value}`;
    } else {
      return `${name} Sales Tax ${value}${type}`;
    }
  };

  capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  renderSuccessMessages() {
    if (this.state.successMessages && !this.state.paymentGenerationSuccess) {
      return (
        <SuccessMessage
          successes={[
            this.props.info.invoiceInfo && !this.state.isRefund
              ? this.props.info.invoiceInfo.data.message
              : this.props.payment.paymentInfo.data.message,
          ]}
        />
      );
    }

    if (this.state.successMessages && this.state.paymentGenerationSuccess) {
      return <SuccessMessage successes={[this.state.paymentLinkMessage]} />;
    }
  }

  paymentType() {
    const { t } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));
    const { creditOrDebit } = this.state;
    const paymentGateway = getLocationPaymentGateway();
    return (
      <>
        <input type="hidden" name="_csrf" value={this.state.csrf['csrf-token']} />
        <input type="hidden" name="_csrfSecret" value={this.state.csrf['csrf-secret']} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Tooltip title="Fill Card Details" className="card-credit">
              <span
                className={classes.cursorPointer}
                onClick={(e) => {
                  this.cardPaymentPopUp(e);
                }}
              >
                {this.currentLocation?.ACH && (this.state.ach.isOpen ? '+' : '-')}
                {creditOrDebit ? this.capitalizeFirstLetter(creditOrDebit) + ' Card' : 'Credit Card/Debit Card'}
              </span>
            </Tooltip>
            {paymentGateway === 'multipass' ? (
              <TsepCard
                handleChangeCheckbox={this.handleChangeCheckbox}
                tsepFieldError={this.state.tsepFieldError}
                tsepSuccessResponse={this.state.tsepSuccessResponse}
                customerToken={this.state.card?.customerToken}
                customerTokenUpdate={this.state.card?.customerTokenUpdate}
                cardWalletID={this.state?.cardWalletID}
                maskedCardNumber={this.state.mask_number}
                cardPanelCustomerShow={this.state.cardPanelCustomerShow}
                cardPanelShow={this.state.cardPanelShow}
                handleChangeCheckboxUpdate={this.handleChangeCheckboxUpdate}
                handleSubmitAddCard={this.handleSubmitAddCardTsep}
                updateCard={this.state.updateCard}
                tsepAuth={this.props.CurrentLocation?.tsepAuth}
                isOneTimeCustomer={this.state.isOneTimeCustomer}
                addNewCardDisabled={this.state.addNewCardDisabled}
                isRefundManual={this.state.isRefundManual}
                isRefund={this.state.isRefund}
                card={this.state.card}
                isMobile={this.state.isMobile}
              />
            ) : (
              <>
                <PaytraceCard
                  card={this.state.card}
                  cardPanelShow={this.state.cardPanelShow}
                  updateCard={this.state.updateCard}
                  cardPanelCustomerShow={this.state.cardPanelCustomerShow}
                  addNewCard={this.state.addNewCard}
                  backToMaskCard={this.backToMaskCard}
                  cardRef={this.cardRef}
                  errorCard={this.state.errorCard}
                  handleChangeCheckbox={this.handleChangeCheckbox}
                  americanExpress={this.state.americanExpress}
                  handleChangeCard={this.handleChangeCard}
                  isRefund={this.state.isRefund}
                  isRefundManual={this.state.isRefundManual}
                  cscCardRef={this.cscCardRef}
                  isOneTimeCustomer={this.state.isOneTimeCustomer}
                  customerDetails={this.state.customerDetails}
                  handleChangeCheckboxUpdate={this.handleChangeCheckboxUpdate}
                  expiryDateRef={this.expiryDateRef}
                  handleSubmitAddNewCard={this.handleSubmitAddNewCard}
                  name={this.state.name}
                  t={t}
                />
              </>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            {this.currentLocation?.ACH && user?.permissions?.ach && !this.state.isRefund ? (
              <Tooltip title="Fill Account  Details">
                <span
                  className={classes.cursorPointer}
                  onClick={(e) => {
                    this.achPaymentPopUp(e);
                  }}
                >
                  {this.state.ach.isOpen ? '-' : '+'}ACH Payment
                </span>
              </Tooltip>
            ) : (
              ''
            )}
          </Grid>

          {!this.state.isRefund && this.state.ach.isOpen && this.currentLocation?.ACH ? (
            <Grid item xs={12} sm={12} md={12} className={classNames(['achBakground'])}>
              <Typography variant="h6" component="h3">
                ACH Details
              </Typography>
              <ACHForm
                t={t}
                ach={this.state.ach}
                handleChangeAch={(e, val) => this.handleChangeAch(e, val)}
                variant="tight"
                location={this.currentLocation}
                ref={this.achFormValidationRef}
              />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </>
    );
  }

  renderSalesTax(t) {
    if (this.state.feeSettings.DualFeeEnabled) {
      return (
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <CurrencyDisplay
                label={t(this.getCardSalesTaxLabel('Cash'))}
                value={this.state.salesTaxTotal > 0 ? this.state.salesTaxTotal.toFixed(2) : '0.00'}
                id="tax"
                disabled={true}
                required={true}
                error={this.state.error.taxError}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <CurrencyDisplay
                label={t(this.getCardSalesTaxLabel('Card'))}
                value={this.state.tax > 0 ? this.state.tax.toFixed(2) : '0.00'}
                id="cardtax"
                required={true}
                disabled={true}
                error={this.state.error.taxError}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12} sm={12} md={12}>
          <Grid item xs={8} sm={8} md={8}>
            <Tooltip title="Update Setting" aria-label="Update">
              <Buttons
                variant="outlined"
                color="primary"
                disabled={true}
                onClick={(e) => {
                  this.openAdjustSettings(e);
                }}
                text={t('SalesTax')}
              />
            </Tooltip>
            &nbsp; (
            {this.state.feeSettings?.virtualTerminalSettings?.InvoiceSalesTaxFeeType === '$'
              ? this.state.feeSettings?.virtualTerminalSettings?.InvoiceSalesTaxFeeType +
                this.state.feeSettings?.virtualTerminalSettings?.InvoiceSalesTaxFee?.toFixed(2)
              : this.state.feeSettings?.virtualTerminalSettings?.InvoiceSalesTaxFee?.toFixed(2) +
                this.state.feeSettings?.virtualTerminalSettings?.InvoiceSalesTaxFeeType}
            )
          </Grid>
          {this.state.feeSettings?.virtualTerminalSettings?.InvoiceTax ? (
            ''
          ) : (
            <Buttons
              variant="outlined"
              color="primary"
              onClick={(e) => {
                this.openAdjustSettings(e);
              }}
              text={t('SalesTaxDisabled')}
            />
          )}
          <CurrencyDisplay
            label={t(this.state.feeSettings.DualFeeEnabled ? 'Tax' : '')}
            value={this.state.tax > 0 ? this.state.tax.toFixed(2) : '0.00'}
            id="tax"
            disabled={true}
            required={false}
            error={this.state.error.taxError}
          />
        </Grid>
      );
    }
  }

  handleOnChangeAmount = (e, value) => {
    e.preventDefault();
    if (value.length >= 13) {
      return;
    } else {
      this.setAmount(value);
    }
  };

  handleSubmitTsep = (e) => {
    e.preventDefault();
    console.log('tsep clicked');
  };

  cardDetails(card) {
    if (card.cardNumber === '' && card.cardExpiryDate === '' && card.cardCsc === '') {
      return false;
    }
    if (
      (card.cardNumber !== '_______________' && card.cardExpiryDate !== '__/____' && card.cardCsc !== '___') ||
      this.state.mask_number !== ''
    ) {
      return true;
    }
  }

  paymentDetails() {
    const { card } = this.state;
    if (card.isOpen) {
      return this.cardDetails(card);
    }
  }

  getRunRefundDisabledStatus() {
    // returns boolean to disable or enable run refund button
    const paymentGateway = getLocationPaymentGateway();
    const manualRefund = this.state.isRefundManual;
    const manualRefundAmount = parseFloat(this.state.manualRefund?.amount || '0');
    const transactionDetails = this.state.transactionDetails || null;
    const initialTransactionDetails = initialState.transactionDetails;
    const cardWalletID = this.state.cardWalletID || null;
    const achIsOpen = this.state?.ach?.isOpen;
    const isPaid = this.state?.isPaid;
    const cardNumber = parseFloat(this.state.card.cardNumber).toString() || '';
    const cardExpiryDate = this.state.card?.cardExpiryDate
      ? parseFloat(this.state.card.cardExpiryDate.replace('/', '')).toString()
      : '';
    const cardInfoIsFilledOutManualPaytrace = cardNumber?.length > 13 && cardExpiryDate?.length === 6;
    const tsepSuccessMessage = (this.state?.tsepSuccessResponse?.message || '').toLowerCase() === 'success';
    const cardType = this.state.cardType;
    const maskNumber = this.state.mask_number?.replaceAll('x', '');
    const tsepMaskedNumber =
      paymentGateway.toLowerCase() === 'multipass' ? this.state.tsepSuccessResponse?.maskedCardNumber : '';
    let disabled = true;

    if (paymentGateway === 'multipass' && !cardWalletID && !achIsOpen && !isPaid) {
      if (manualRefund && manualRefundAmount > 0 && tsepSuccessMessage && cardType && tsepMaskedNumber)
        disabled = false;
      else if (!manualRefund && transactionDetails.TXNID !== initialTransactionDetails.TXNID) disabled = false;
    } else if (paymentGateway === 'paytrace') {
      if (
        manualRefund &&
        ((manualRefundAmount > 0 && cardInfoIsFilledOutManualPaytrace) || (maskNumber && maskNumber.length > 13))
      ) {
        disabled = false;
      } else if (!manualRefund && transactionDetails.TXNID !== initialTransactionDetails.TXNID) {
        disabled = false;
      }
    }

    return disabled;
  }

  render() {
    const maxDailyAmount = this.state.TransactionData.reduce((item, curr) => {
      return item + curr.AMOUNT;
    }, 0);
    const maxDailyRefundAmount = this.state.TransactionRefundData.reduce((item, curr) => {
      return item + curr.AMOUNT;
    }, 0);
    const paymentGateway = getLocationPaymentGateway();
    const { t } = this.props;
    const {
      redirectInvoice,
      RefundPendingData,
      inputForRefundTransactions,
      CoustmerData,
      InvoicePendingData,
      redirectTransaction,
    } = this.state;
    const getIsCreditCardButtonText = () => {
      if (this?.state?.ach?.isOpen && this.props.UserDetail.permissions.ach) {
        return t('ProcessACH');
      } else {
        return t('ChargeCard');
      }
    };
    const getCashPaymentModalText = () => {
      if (this.state?.card?.isOpen && this.cardDetails(this.state.card)) {
        return 'Credit Card';
      }
      if (this.state?.ach?.isOpen && this.cardDetails(this.state.ach)) {
        return 'Bank';
      }
    };
    autoFillMultipassFields();
    cleanTsepForm();
    let selectedInvoiceValue;
    if (this.state.selectedInvoice) {
      selectedInvoiceValue =
        typeof this.state.selectedInvoice === 'string'
          ? { value: this.state.selectedInvoice, label: this.state.selectedInvoice }
          : this.state.selectedInvoice;
    } else {
      selectedInvoiceValue = null;
    }
    return (
      <MuiThemeProvider theme={formLabelsTheme}>
        <div className="rightPanel">
          {redirectTransaction ? <Redirect to="/Transactions" /> : ''}
          {redirectInvoice ? <Redirect to="/Invoices" /> : ''}
          <Topheader />
          <div className="contentPart">
            <Container component="main" maxWidth={false} className="terminal-container term-add">
              <Stack sx={{ width: '100%' }} spacing={2}>
                {this.state.misMatchError.map((e, i) => (
                  <CloseableAlerts
                    key={i}
                    index={i}
                    message={e}
                    severity="error"
                    closer={this.removeCloseableMessage}
                  />
                ))}
                {this.state.tsepFieldError ? <ErrorMessage errors={[this.state.tsepFieldError]} /> : ''}
                {this.state.achDisclaimerError.map((e, i) => (
                  <CloseableAlerts
                    key={i}
                    index={i}
                    message={e}
                    severity="error"
                    closer={this.removeCloseableMessage}
                  />
                ))}
              </Stack>
              {this.renderSuccessMessages()}
              <LoadingOverlay
                className={classes.tpTableFullW}
                active={this.state.isLoading}
                spinner
                text="Fetching your response ..."
              >
                <div className="terminal-inner">
                  {this.state.limit.perRefundLimit ? (
                    <h3 className="errorMsg">
                      <span>Warning : </span>Your per refund transaction limit is $
                      {this.props.UserDetail.permissions.perRefundLimit
                        ? this.props.UserDetail.permissions.perRefundLimit
                        : 0}{' '}
                    </h3>
                  ) : (
                    ''
                  )}
                  {this.state.limit.dailyRefundLimit ? (
                    <h3 className="errorMsg">
                      <span>Warning : </span>Your daily refund transaction limit is $
                      {this.props.UserDetail.permissions.dailyRefundLimit
                        ? CurrencyFormatterUS(this.props.UserDetail.permissions.dailyRefundLimit)
                        : 0}
                      . Make a refund amount of $
                      {
                        new Intl.NumberFormat(
                          Math.abs(this.props.UserDetail.permissions.dailyRefundLimit - maxDailyRefundAmount)
                        )
                      }
                      {CurrencyFormatterUS(this.props.UserDetail.permissions.dailyRefundLimit - maxDailyRefundAmount)}.
                    </h3>
                  ) : (
                    ''
                  )}

                  {this.state.limit.perTransactionLimit ? (
                    <h3 className="errorMsg">
                      <span>Warning : </span>Your per transaction limit is $
                      {this.props.UserDetail.permissions.perTransactionLimit
                        ? this.props.UserDetail.permissions.perTransactionLimit
                        : 0}{' '}
                    </h3>
                  ) : (
                    ''
                  )}
                  {this.state.limit.dailyTransactionLimit ? (
                    <h3 className="errorMsg">
                      <span>Warning : </span>Your daily transaction limit is{' '}
                      {this.props.UserDetail.permissions.dailyTransactionLimit
                        ? CurrencyFormatterUS(this.props.UserDetail.permissions.dailyTransactionLimit)
                        : 0}
                      . You have already made the payment of {CurrencyFormatterUS(maxDailyAmount)}, Make a payment less
                      than{' '}
                      {CurrencyFormatterUS(this.props.UserDetail.permissions.dailyTransactionLimit - maxDailyAmount)}.
                    </h3>
                  ) : (
                    ''
                  )}
                  {this.state.warning && this.state.warningMessage && (
                    <Box
                      p={1}
                      style={{
                        backgroundColor: '#e44545',
                        color: 'white',
                        margin: '10px auto',
                        maxWidth: '500px',
                      }}
                    >
                      <Typography variant="body1" color="inherit" align="center">
                        {this.state.warningMessage}
                      </Typography>
                    </Box>
                  )}

                  <h3 className="terminal-title">Virtual Terminal</h3>
                  <Grid item xs={12} sm={12}>
                    <label className={classes.mr_2} htmlFor="credit-toggle">
                      Credit
                    </label>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          id="credit-toggle"
                          checked={this.state.isCredit}
                          onChange={(e) => {
                            this.handleChangeCreditToggle(e, 'isCredit');
                          }}
                          value={this.state.isCredit}
                        />
                      }
                    />
                    <label className={classes.mr_2} htmlFor="oneTimeCustomer-toggle">
                      One Time Customer
                    </label>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          id="oneTimeCustomer-toggle"
                          checked={this.state.isOneTimeCustomer}
                          onChange={() => {
                            this.handleChangeOneTimeCustomer();
                          }}
                          value={this.state.isOneTimeCustomer}
                        />
                      }
                    />
                    {this.props.UserDetail.permissions.refunds || this.props.UserDetail.permissions.refundsManual ? (
                      <>
                        {' '}
                        <label className={classes.mr_2} htmlFor="refund-toggle">
                          Refund
                        </label>{' '}
                        <FormControlLabel
                          control={
                            <IOSSwitchRed
                              id="refund-toggle"
                              checked={this.state.isRefund}
                              onChange={(e) => {
                                this.handleChangeCreditToggle(e, 'isRefund');
                              }}
                              value={this.state.isRefund}
                            />
                          }
                        />{' '}
                      </>
                    ) : (
                      ''
                    )}
                  </Grid>

                  <SubmittedToPaxModal
                    open={this.state.submitedToPaxModal}
                    handleClose={this.handleCloseSubmitedToPaxModal}
                    handleProceed={this.handleProceedAnyway}
                    activeElement={document.activeElement.id}
                  />
                  <form className={classes.form} id="DemoFormTerminal">
                    <DialogBox open={this.state.isOpen} handleClose={this.handleClose} message={<AdjustSettings />} />
                    <Grid container spacing={3}>
                      <PaxTerminals
                        open={this.state.isOpenDialogue}
                        handleClose={this.handleCloseDialogue.bind(this)}
                        selectPaxTerminal={this.selectPaxTerminal.bind(this)}
                        handleSubmit={this.handleSubmit.bind(this)}
                        paxTerminalsList={this.state.paxTerminalsList}
                        parentClasses={classes}
                        paxSelectError={this.state.paxSelectError}
                      />
                      <Grid item xs={12} sm={12} md={4} style={{ display: this.state.isMobile ? 'flex' : '' }}>
                        {this.paymentType()}
                      </Grid>
                      {this.state.isCredit ? (
                        <Grid item xs={12} sm={12} md={8}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} />
                            {!this.state.isOneTimeCustomer ? (
                              <Grid item xs={12} sm={12} md={12} className="terminal-select">
                                {!this.state.isJobIdenabled ? (
                                  <TypableSelectDetails
                                    autoFocus={false}
                                    onKeyDown={(e) => this.handleEnterKeyPress(e, 'client')}
                                    // openMenuOnClick={true}
                                    placeholder={t(
                                      this.state.invoiceLabelRequired
                                        ? `${this.state.invoiceLabel} *`
                                        : this.state.invoiceLabel
                                    )}
                                    ref={this.fieldRef.client}
                                    suggestions={mapToFormatInvoice(InvoicePendingData)}
                                    onChange={(val) => this.setClientValueInvoice(val)}
                                    isSelected={this.state.invoice ? this.state.invoice : ''}
                                    isClearable={true}
                                    id="client"
                                  />
                                ) : (
                                  <CreatableSelect
                                    autoFocus={false}
                                    placeholder={t(
                                      this.state.invoiceLabelRequired
                                        ? `${this.state.invoiceLabel} *`
                                        : this.state.invoiceLabel
                                    )}
                                    isClearable={true}
                                    options={mapToFormatInvoice(InvoicePendingData)}
                                    onInputChange={this.handleInputClientChange}
                                    onCreateOption={(val) => this.onCreateOption(val)}
                                    onChange={(val) => {
                                      this.setSelectedInvoice(val);
                                      this.setState({ isNewInvoiceFromJobId: true, newJobId: val?.value });
                                    }}
                                    ref={this.fieldRef.client}
                                    id="client"
                                    onKeyDown={(e) => this.handleEnterKeyPress(e, 'client')}
                                    onBlur={(e) => this.handleBlur(e, 'client')}
                                    value={selectedInvoiceValue}
                                  />
                                )}
                              </Grid>
                            ) : (
                              <Grid item xs={12} sm={12} md={12} className="terminal-select">
                                <Textfield
                                  id="oneTimeCustomerInvoiceRef"
                                  ref={this.fieldRef.customerNameRef}
                                  type="text"
                                  labels={t('Invoice Number')}
                                  value={this.state.oneTimeCustomerInvoice}
                                  width={true}
                                  onChange={(e) => this.handleChangeOneTimeCustomerInvoice(e, t)}
                                  variant="outlined"
                                />
                              </Grid>
                            )}
                            {!this.state.isOneTimeCustomer ? (
                              <Grid item xs={12} sm={12} md={12} className="terminal-select">
                                {this.state.invoice ? (
                                  <Textfield
                                    data-cy="customer-name"
                                    id="customerNameRef"
                                    ref={this.fieldRef.customerNameRef}
                                    type="text"
                                    onKeyDown={(e) => this.handleKeyDown(e, 'amount')}
                                    disabled={true}
                                    labels={t('CustomerName')}
                                    value={
                                      this.state.customerDetails
                                        ? this.state.customerDetails?.firstName && this.state.customerDetails?.lastName
                                          ? this.state.customerDetails?.firstName +
                                            ' ' +
                                            this.state.customerDetails?.lastName
                                          : this.state.customerDetails?.firstName
                                        : ''
                                    }
                                    width={true}
                                    required={true}
                                    onChange={(e) => this.handleChangeName(e)}
                                    variant="outlined"
                                  />
                                ) : (
                                  <CreatableSelect
                                    placeholder={t(getCustomerNamePlaceholderValue(this.state.locationId))}
                                    ref={this.fieldRef.customerNameRef}
                                    id="customerNameRef"
                                    onFocus={false}
                                    required={true}
                                    onKeyDown={(e) => this.handleEnterKeyPress(e, 'amount')}
                                    isClearable={true}
                                    options={mapToFormat(CoustmerData)}
                                    onInputChange={this.handleInputClientChange}
                                    onChange={this.setClientValue}
                                  />
                                )}
                              </Grid>
                            ) : (
                              <Grid item xs={12} sm={12} md={12} className="terminal-select">
                                <Textfield
                                  id="customerNameRef"
                                  value={this.state.customerName}
                                  type="text"
                                  labels={t('CustomerName')}
                                  width={true}
                                  required={true}
                                  onChange={(e) => this.handleOneTimeCustomerName(e, t)}
                                  variant="outlined"
                                />
                              </Grid>
                            )}
                            {!this.state.feeSettings.DualFeeEnabled ? (
                              <Grid item xs={12} sm={12} md={12}>
                                <CurrencyTextField
                                  modifyValueOnWheel={false}
                                  label={t('Amount')}
                                  variant="outlined"
                                  value={this.state.amount}
                                  currencySymbol="$"
                                  minimumValue="0"
                                  outputFormat="string"
                                  decimalCharacter="."
                                  digitGroupSeparator=","
                                  id="amount"
                                  onKeyDown={(e) => this.handleKeyDown(e, 'email')}
                                  textAlign="left"
                                  onChange={(event, value) => this.handleOnChangeAmount(event, value)}
                                  onBlur={(event, value) => this.handleOnChangeAmount(event, value)}
                                  style={{ width: '100%' }}
                                />
                              </Grid>
                            ) : (
                              <Grid item xs={12} sm={12} md={12}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={12} md={6}>
                                    <CurrencyTextField
                                      modifyValueOnWheel={false}
                                      label={t('Cash Amount')}
                                      variant="outlined"
                                      value={this.state.amount}
                                      currencySymbol="$"
                                      //minimumValue="0"
                                      outputFormat="string"
                                      decimalCharacter="."
                                      digitGroupSeparator=","
                                      id="amount"
                                      onKeyDown={(e) => this.handleKeyDown(e, 'email')}
                                      textAlign="left"
                                      onChange={(event, value) => this.handleOnChangeAmount(event, value)}
                                      onBlur={(event, value) => this.handleOnChangeAmount(event, value)}
                                      style={{ width: '100%', marginRight: 14 }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={6}>
                                    <CurrencyDisplay
                                      label={t('Card Amount')}
                                      value={this.state.cardAmount}
                                      id="cardamount"
                                      disabled={true}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                            {this.props.UserDetail.permissions.emailVisibilityTerminal && (
                              <Grid item xs={12} sm={12} md={12}>
                                <Textfield
                                  required={false}
                                  error={this.state.error.emailError ? this.state.error.emailError : false}
                                  id="email"
                                  type="text"
                                  labels={t('Email')}
                                  onKeyDown={(e) => this.handleKeyDown(e, 'phone')}
                                  value={this.state.email}
                                  width={true}
                                  onChange={(e) => this.handleChange(e)}
                                  variant="outlined"
                                />
                              </Grid>
                            )}
                            {this.props.UserDetail.settings.isPhoneNumber ? (
                              <Grid item xs={12} sm={12} md={12}>
                                <Textfield
                                  error={this.state.error.phoneError}
                                  id="phone"
                                  onKeyDown={(e) => this.handleKeyDown(e, 'svcFee')}
                                  type="text"
                                  labels={t('Phone Number')}
                                  value={this.state.phone ? this.state.phone : ''}
                                  width={true}
                                  onChange={(e) => this.handleChange(e)}
                                  variant="outlined"
                                  required={false}
                                />
                              </Grid>
                            ) : (
                              ''
                            )}
                            {
                              // start of fee settings
                              // Sales Tax if apply tax last is disabled
                              this.state.feeSettings?.virtualTerminalSettings?.InvoiceTax &&
                              !this.state.feeSettings?.applyTaxLast
                                ? this.renderSalesTax(t)
                                : ''
                            }

                            {this.state.feeSettings?.virtualTerminalSettings?.InvoiceDiscount &&
                            this.state.feeSettings?.virtualTerminalSettings?.InvoiceSVCFee > 0 &&
                            this.state.feeSettings?.virtualTerminalSettings?.DiscountFeeName !== '' &&
                            !this.state.feeSettings.DualFeeEnabled ? (
                              <Grid item xs={12} sm={12} md={12}>
                                {this.state.feeSettings?.virtualTerminalSettings?.InvoiceDiscount ? (
                                  <Grid item xs={8} sm={8} md={8}>
                                    <Tooltip title="Update Setting">
                                      <Buttons
                                        variant="outlined"
                                        color="primary"
                                        text={this.state.feeSettings?.virtualTerminalSettings?.DiscountFeeName}
                                        // onClick={(e) => {
                                        //   this.openAdjustSettings(e);
                                        // }}
                                        disabled={this.props.UserDetail.permissions.feeEdit ? false : true}
                                      />
                                    </Tooltip>
                                    &nbsp; (
                                    {this.state.feeSettings?.virtualTerminalSettings?.InvoiceSVCFeeType === '$'
                                      ? this.state.feeSettings?.virtualTerminalSettings?.InvoiceSVCFeeType +
                                        this.state.feeSettings?.virtualTerminalSettings?.InvoiceSVCFee
                                      : this.state.feeSettings?.virtualTerminalSettings?.InvoiceSVCFee +
                                        this.state.feeSettings?.virtualTerminalSettings?.InvoiceSVCFeeType}
                                    )
                                  </Grid>
                                ) : (
                                  <Buttons
                                    variant="outlined"
                                    color="primary"
                                    text={
                                      this.state.feeSettings?.virtualTerminalSettings?.DiscountFeeName + ' Disabled'
                                    }
                                    disabled={true}
                                    onClick={(e) => {
                                      this.openAdjustSettings(e);
                                    }}
                                  />
                                )}
                                <CurrencyTextField
                                  error={this.state.error.svcFeeError}
                                  id="svcFee"
                                  onKeyDown={(e) => this.handleKeyDown(e, 'tax')}
                                  type="text"
                                  value={this.state.svcFee ? parseFloat(this.state.svcFee).toFixed(2) : '0.00'}
                                  width={true}
                                  onBlur={(event, value) => this.handleSVCChange(value)}
                                  variant="outlined"
                                  currencySymbol="$"
                                  outputFormat="string"
                                  decimalCharacter="."
                                  disabled={this.props.UserDetail.permissions.feeEdit ? false : true}
                                  digitGroupSeparator=","
                                  textAlign="left"
                                  style={{ width: '100%' }}
                                />
                              </Grid>
                            ) : (
                              ''
                            )}

                            {this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurcharge ? (
                              <Grid item xs={12} sm={12} md={12}>
                                <Grid item xs={8} sm={8} md={8}>
                                  <Tooltip title="Update Setting" aria-label="Update">
                                    <Buttons
                                      variant="outlined"
                                      color="primary"
                                      disabled={true}
                                      text={t('Surcharge')}
                                      onClick={(e) => {
                                        this.openAdjustSettings(e);
                                      }}
                                    />
                                  </Tooltip>
                                  &nbsp;{' '}
                                  {!this.state.isStateSVCApplied
                                    ? this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurchargeFeeType === '$'
                                      ? this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurchargeFeeType +
                                        this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurchargeFee.toFixed(2)
                                      : this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurchargeFee.toFixed(
                                          2
                                        ) + this.state.feeSettings?.virtualTerminalSettings?.InvoiceSurchargeFeeType
                                    : this.state.stateSVCAppliedRate}
                                </Grid>
                                <CurrencyDisplay
                                  value={this.state.surcharge ? this.state.surcharge : 0}
                                  id="surcharge"
                                  required={true}
                                  disabled={true}
                                  error={this.state.error.surchargeError}
                                />
                              </Grid>
                            ) : (
                              ''
                            )}

                            {this.state.feeSettings?.invoiceSettings?.CustomFeeOneEnabled &&
                            this.state.isLateFeeApplicable ? (
                              <Grid item xs={12} sm={12} md={12}>
                                <Grid item xs={8} sm={8} md={8}>
                                  <Tooltip title="Late Fee" aria-label="Update">
                                    <Buttons variant="outlined" color="primary" text="Late Fee" disabled={true} />
                                  </Tooltip>
                                  &nbsp; (
                                  {this.state.feeSettings?.invoiceSettings?.CustomFeeOneType === '$'
                                    ? this.state.feeSettings?.invoiceSettings?.CustomFeeOneType +
                                      this.state.feeSettings?.invoiceSettings?.CustomFeeOne?.toFixed(2)
                                    : this.state.feeSettings?.invoiceSettings?.CustomFeeOne?.toFixed(2) +
                                      this.state.feeSettings?.invoiceSettings?.CustomFeeOneType}
                                  )
                                </Grid>

                                <CurrencyDisplay
                                  label={t('Late Fee')}
                                  value={this.getLateFee() > 0 ? this.getLateFee().toFixed(2) : '0.00'}
                                  id="latefee"
                                  required={true}
                                  error={this.state.error.surchargeError}
                                />
                              </Grid>
                            ) : (
                              ''
                            )}

                            {this.state.feeSettings?.virtualTerminalSettings?.InvoiceTax &&
                            this.state.feeSettings?.applyTaxLast
                              ? this.renderSalesTax(t)
                              : ''}

                            {checkAddressEnabled(this.state.addressSettings?.billingAddress) && (
                              <Grid item xs={12} sm={12} md={12}>
                                <AddressFieldsDropDown
                                  type="billing"
                                  state={this.state}
                                  handleChange={this.handleAddressChanges}
                                  handleSameAddress={this.handleIsBillingAddressSameAsShippingAddress}
                                />
                              </Grid>
                            )}
                            {checkAddressEnabled(this.state.addressSettings?.shippingAddress) && (
                              <Grid item xs={12} sm={12} md={12}>
                                <AddressFieldsDropDown
                                  type="shipping"
                                  state={this.state}
                                  handleChange={this.handleAddressChanges}
                                  handleSameAddress={this.handleIsBillingAddressSameAsShippingAddress}
                                />
                              </Grid>
                            )}

                            {!this.state.feeSettings.DualFeeEnabled && (
                              <Grid item xs={12} sm={12} md={12}>
                                <CurrencyDisplay
                                  label={this.state.feeSettings.DualFeeEnabled ? 'Card Total' : t('Total')}
                                  value={
                                    this.state.amount > 0
                                      ? typeof this.state.total !== 'string'
                                        ? this.state.total.toFixed(2)
                                        : parseFloat(this.state.total).toFixed(2)
                                      : '0.00'
                                  }
                                  id="totalRef"
                                  required={true}
                                  error={this.state.error.amountError}
                                />
                                <div>
                                  {this.state?.feeSettings?.virtualTerminalSettings?.InvoiceSurcharge &&
                                    !this.state.creditOrDebit && (
                                      <Link
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => this.handleSubmitAddNewCard(e)}
                                      >
                                        <p className={classes.surchargeWarning}>
                                          Card type not found. Please enter or update card to apply surcharge in total
                                        </p>
                                      </Link>
                                    )}
                                </div>
                              </Grid>
                            )}
                            {this.state.feeSettings.DualFeeEnabled && (
                              <Grid item xs={12} sm={12} md={12}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={12} md={6}>
                                    <CurrencyDisplay
                                      label={t('Cash Total')}
                                      value={this.state.amount > 0 ? this.state.cashTotal.toFixed(2) : '0.00'}
                                      id="cashtotal"
                                      required={true}
                                      disabled={true}
                                      error={this.state.error.surchargeError}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={6}>
                                    <CurrencyDisplay
                                      label={t('Card Total')}
                                      value={
                                        this.state.amount > 0
                                          ? typeof this.state.total !== 'string'
                                            ? this.state.total.toFixed(2)
                                            : parseFloat(this.state.total).toFixed(2)
                                          : '0.00'
                                      }
                                      id="cardtotal"
                                      required={true}
                                      disabled={true}
                                      error={this.state.error.surchargeError}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                            {this.props.UserDetail.permissions.notesVisibilityTerminal && (
                              <Grid item xs={12} sm={12} md={12}>
                                <Textfield
                                  id="notes"
                                  type="text"
                                  handleKeyDown={(e) => this.handleKeyDown(e, 'client')}
                                  labels={t('Notes')}
                                  error={this.state.error && this.state.error.notesError}
                                  helperText={
                                    this.state.errorMessage && this.state.errorMessage.notesErrorMessage
                                      ? this.state.errorMessage.notesErrorMessage
                                      : ''
                                  }
                                  value={this.state.notes}
                                  width={true}
                                  onChange={(e) => this.handleChange(e)}
                                  variant="outlined"
                                />
                              </Grid>
                            )}

                            {this.state.invoice && !this.state.feeSettings.DualFeeEnabled ? (
                              <Grid item xs={12} sm={12} md={12}>
                                <label>
                                  <Checkbox
                                    id="isPaid"
                                    labels="markAsPaid"
                                    checked={this.state.isPaid}
                                    onChange={(e) => {
                                      this.handleCheckAsPaid(e);
                                    }}
                                    value={this.state.isPaid}
                                    color="primary"
                                    inputProps={{
                                      'aria-label': 'secondary checkbox',
                                    }}
                                  />
                                  <span>Mark As Paid</span>
                                </label>
                              </Grid>
                            ) : (
                              ''
                            )}
                          </Grid>
                        </Grid>
                      ) : (
                        ''
                      )}
                      {this.state.isRefund ? (
                        <Grid item xs={12} sm={12} md={8}>
                          <Grid container spacing={3}>
                            {this.props.UserDetail.permissions.refundsManual ? (
                              <Grid item xs={12} sm={12} md={12} className={classes.manualcheckbox}>
                                <label>
                                  <Checkbox
                                    id="isRefundManual"
                                    labels="manualRefund"
                                    checked={
                                      this.props.UserDetail.permissions.refundsManual &&
                                      !this.props.UserDetail.permissions.refunds
                                        ? true
                                        : this.state.isRefundManual
                                    }
                                    onChange={(e) => {
                                      this.handleCheckRefundManual(e);
                                    }}
                                    value={this.state.isRefundManual}
                                    color="primary"
                                    inputProps={{
                                      'aria-label': 'secondary checkbox',
                                    }}
                                  />
                                  <span>Manual Refund</span>
                                </label>
                              </Grid>
                            ) : (
                              ''
                            )}
                            {this.state.isRefundManual ||
                            (this.props.UserDetail.permissions.refundsManual &&
                              !this.props.UserDetail.permissions.refunds) ? (
                              <>
                                <Grid item xs={12} sm={12} md={12} className="terminal-select">
                                  <Grid item xs={12} sm={12} md={12}>
                                    <Textfield
                                      required={true}
                                      error={this.state.errorManualRefund.amountError}
                                      id="amount"
                                      type="text"
                                      InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                      }}
                                      onChange={(e) => this.handleChangeManualRefund(e, t)}
                                      onFocus={({ target }) =>
                                        this.setState({
                                          manualRefund: {
                                            amount: String(target.value),
                                          },
                                        })
                                      }
                                      onBlur={({ target }) =>
                                        this.setState({
                                          manualRefund: {
                                            amount: Number(target.value).toFixed(2),
                                          },
                                        })
                                      }
                                      labels={t('Amount')}
                                      value={this.state.manualRefund ? this.state.manualRefund.amount : ''}
                                      width={true}
                                      variant="outlined"
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            ) : (
                              ''
                            )}
                            {this.props.UserDetail.permissions.refunds && !this.state.isRefundManual ? (
                              <>
                                <Grid item xs={12} sm={12} md={12} className={classes.terminalSelect}>
                                  <TypableSelectDetails
                                    // openMenuOnClick={true}
                                    placeholder={'Transaction Number'}
                                    suggestions={mapToFormatPendingTransaction(
                                      RefundPendingData,
                                      inputForRefundTransactions
                                    )}
                                    onChange={(val) => this.setClientValuePendingTxn(val)}
                                    onInputChange={(val) => this.handleInputForRefundTransactions(val)}
                                    // isSelected={(this.state.transactionDetails.TXNID) ? this.state.transactionDetails.TXNID : ''}
                                    // value={(this.state.transactionDetails.TXNID) ? this.state.transactionDetails.TXNID : ''}
                                    isClearable={true}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                  <Textfield
                                    id="firstName"
                                    type="text"
                                    labels={t('FirstName')}
                                    value={
                                      this.state.transactionDetails.CUSTOMERDATA
                                        ? this.state.transactionDetails.CUSTOMERDATA?.firstName
                                        : ''
                                    }
                                    width={true}
                                    disabled={true}
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Textfield
                                    id="lastName"
                                    type="text"
                                    labels={t('LastName')}
                                    value={
                                      this.state.transactionDetails.CUSTOMERDATA
                                        ? this.state.transactionDetails.CUSTOMERDATA.lastName
                                        : ''
                                    }
                                    disabled={true}
                                    width={true}
                                    variant="outlined"
                                  />
                                </Grid>
                                {this.props.UserDetail.permissions.emailVisibilityTerminal && (
                                  <Grid item xs={12} sm={12} md={12}>
                                    <Textfield
                                      id="email"
                                      type="text"
                                      labels={t('Email')}
                                      value={
                                        this.state.transactionDetails.CUSTOMERDATA
                                          ? this.state.transactionDetails.CUSTOMERDATA.email
                                          : ''
                                      }
                                      disabled={true}
                                      width={true}
                                      variant="outlined"
                                    />
                                  </Grid>
                                )}

                                <Grid item xs={12} sm={12} md={12}>
                                  <Textfield
                                    id="cardNumber"
                                    type="text"
                                    labels={t('CardNumber')}
                                    value={this.state.mask_number ? this.state.mask_number : ''}
                                    disabled={true}
                                    width={true}
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Textfield
                                    id="total"
                                    type="text"
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    labels={t('Total')}
                                    value={
                                      this.state.isChange
                                        ? this.state.transactionDetails.AMOUNT
                                        : this.state.transactionDetails.oldAmount
                                    }
                                    disabled={false}
                                    onChange={(e) => this.HandleChangedTotal(e, t)}
                                    width={true}
                                    variant="outlined"
                                    placeholder="0.00"
                                    onFocus={({ target }) => {
                                      this.setState({
                                        transactionDetails: {
                                          ...this.state.transactionDetails,
                                          AMOUNT: String(target.value),
                                        },
                                      });
                                    }}
                                    onBlur={({ target }) => {
                                      this.setState({
                                        transactionDetails: {
                                          ...this.state.transactionDetails,
                                          AMOUNT: Number(target.value).toFixed(2),
                                        },
                                      });
                                    }}
                                  />
                                </Grid>
                              </>
                            ) : (
                              ''
                            )}
                          </Grid>
                        </Grid>
                      ) : (
                        ''
                      )}

                      <Grid
                        container
                        xs={12}
                        s={12}
                        m={12}
                        lg={12}
                        style={{
                          display: this.state.isMobile ? 'none' : 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Grid item s={4} m={4} xs={12} lg={4}>
                          {this.state.isCredit && !(this.state.isOneTimeCustomer || this.state.isRefund) && (
                            <>
                              <Buttons
                                variant="contained"
                                color="primary"
                                text={'QR Code'}
                                onClick={(e) => this.generatepayLink(e, 'qr')}
                                className={'emailInvoiceTerminal'}
                                startIcon={<QrCodeScanner />}
                              />
                              <Tooltip title="Create and copy to clipboard" placement="top" arrow>
                                <Buttons
                                  className={classes.paylink}
                                  style={{ marginLeft: 20 }}
                                  variant="contained"
                                  color="primary"
                                  startIcon={<ContentCopy />}
                                  text={t('PayLink')}
                                  onClick={(e) => this.generatepayLink(e, 'textfield')}
                                />
                              </Tooltip>
                              {this.state.GetPayLink && this.state.GetPayLink.length > 1 && this.state.showTextLink ? (
                                <Input
                                  className="emailInvoiceTerminal payLink"
                                  placeholder="Link"
                                  type="text"
                                  value={window.location.origin + '/payment/' + this.state.GetPayLink}
                                  readOnly
                                />
                              ) : (
                                ''
                              )}
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12} s={6} m={6} lg={8} className={classes.grid} style={{ textAlign: 'right' }}>
                          {this.state?.userData?.permissions?.textInvoice &&
                            this.state.invoice &&
                            !this.state.isRefund && (
                              <Buttons
                                id="textInvoice"
                                type="submit"
                                variant="contained"
                                className={'emailInvoiceTerminal textInvoiceBtn'}
                                onClick={(e) => this.handleSubmitTextInvoice(e, t)}
                                startIcon={<Icon>message</Icon>}
                                text={t('TextInvoice')}
                              />
                            )}
                          {this.state.invoice && !this.state.isRefund ? (
                            <Buttons
                              id="emailInvoice"
                              type="submit"
                              variant="contained"
                              className={'emailInvoiceTerminal email-invoice'}
                              onClick={(e) => this.handleSubmitEmailInvoice(e)}
                              startIcon={<Icon>send</Icon>}
                              text={t('EmailInvoice')}
                            />
                          ) : (
                            ''
                          )}
                          {this.state.isPaxTransactionEnable ? (
                            <Buttons
                              id="pax_terminal"
                              type="button"
                              variant="contained"
                              color="primary"
                              className={'emailInvoiceTerminal'}
                              onClick={(_e) => this.handleOpenDialogue()}
                              name="PAX_Terminal"
                              text={'PAX Terminal'}
                            />
                          ) : (
                            ''
                          )}

                          {this.state.feeSettings.DualFeeEnabled && this.state.isCredit ? (
                            <Buttons
                              id="cashPayment"
                              type="submit"
                              variant="contained"
                              className={'emailInvoiceTerminal'}
                              onClick={(e) => this.handleSubmit(e, true)}
                              text="Cash Payment"
                            />
                          ) : (
                            ''
                          )}

                          {this.state.isCredit ? (
                            <Buttons
                              id="chargeCard"
                              type="button"
                              variant="contained"
                              disabled={
                                paymentGateway === 'multipass' &&
                                !this.state.cardWalletID &&
                                !this.state.tsepFieldSuccess &&
                                !this?.state?.ach?.isOpen &&
                                !this.state.isPaid
                              }
                              color="primary"
                              className={'emailInvoiceTerminal'}
                              onClick={(e) => this.handleSubmit(e, this.state.isPaid ? true : false)}
                              text={this.state.isPaid ? 'Update' : getIsCreditCardButtonText()}
                            />
                          ) : (
                            <Buttons
                              id="signUp"
                              type="button"
                              variant="contained"
                              disabled={this.getRunRefundDisabledStatus()}
                              // color="secondary"
                              className={'emailInvoiceTerminal btnRed'}
                              onClick={(e) => this.handleSubmitRefund(e, t)}
                              text={t('RunRefund')}
                            />
                          )}

                          <Buttons
                            id="back-button"
                            className={'emailInvoiceTerminal'}
                            variant="contained"
                            color="secondary"
                            text={t('Back')}
                            onClick={this.props.history.goBack}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        xs={12}
                        s={12}
                        m={12}
                        lg={12}
                        style={{
                          display: this.state.isMobile ? 'flex' : 'none',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Grid item xs={12} s={6} m={6} lg={8} className={classes.grid} style={{ textAlign: 'right' }}>
                          {this.state.isCredit ? (
                            <Buttons
                              id="signUp"
                              type="button"
                              variant="contained"
                              color="primary"
                              className={classes.chargeCard}
                              onClick={(e) => this.handleSubmit(e, this.state.isPaid ? true : false)}
                              text={this.state.isPaid ? 'Update' : getIsCreditCardButtonText()}
                            />
                          ) : (
                            <Buttons
                              id="signUp"
                              type="button"
                              variant="contained"
                              disabled={this.getRunRefundDisabledStatus()}
                              // color="secondary"
                              className={'emailInvoiceTerminal btnRed'}
                              onClick={(e) => this.handleSubmitRefund(e, t)}
                              text={t('RunRefund')}
                            />
                          )}
                        </Grid>
                        {this.state.feeSettings.DualFeeEnabled ? (
                          <Grid item s={4} m={4} xs={12} lg={4}>
                            {!this.state.isRefund && (
                              <>
                                {!this.state.isOneTimeCustomer ? (
                                  <>
                                    <Buttons
                                      variant="contained"
                                      color="primary"
                                      text={'QR Code'}
                                      onClick={(e) => this.generatepayLink(e, 'qr')}
                                      className={classes.paylinkBtn}
                                    />
                                    <Buttons
                                      className={classes.paylinkBtn}
                                      style={{ marginLeft: 20 }}
                                      variant="contained"
                                      color="primary"
                                      text={'+' + t('PayLink')}
                                      onClick={(e) => this.generatepayLink(e, 'textfield')}
                                    />
                                  </>
                                ) : (
                                  <>
                                    {this.state.feeSettings.DualFeeEnabled ? (
                                      <Buttons
                                        id="cashPayment"
                                        type="submit"
                                        variant="contained"
                                        className={classes.cashbtn}
                                        onClick={(e) => this.handleSubmit(e, true)}
                                        text="Cash"
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </>
                                )}

                                {this.state.feeSettings.DualFeeEnabled && !this.state.isOneTimeCustomer ? (
                                  <Buttons
                                    id="cashPayment"
                                    type="submit"
                                    variant="contained"
                                    className={classes.paylinkBtn}
                                    onClick={(e) => this.handleSubmit(e, true)}
                                    text="Cash"
                                  />
                                ) : (
                                  ''
                                )}
                                {this.state.GetPayLink &&
                                this.state.GetPayLink.length > 1 &&
                                this.state.showTextLink ? (
                                  <Input
                                    className={classes.generatepaylink}
                                    placeholder="Link"
                                    type="text"
                                    value={window.location.origin + '/payment/' + this.state.GetPayLink}
                                    readOnly
                                  />
                                ) : (
                                  ''
                                )}
                              </>
                            )}
                          </Grid>
                        ) : (
                          <Grid item s={4} m={4} xs={12} lg={4}>
                            {this.state.isCredit && !(this.state.isOneTimeCustomer || this.state.isRefund) && (
                              <>
                                <Buttons
                                  variant="contained"
                                  color="primary"
                                  text={'QR Code'}
                                  onClick={(e) => this.generatepayLink(e, 'qr')}
                                  className={classes.dualFeePaylink}
                                />
                                <Buttons
                                  className={classes.dualFeePaylink}
                                  style={{ marginLeft: 20 }}
                                  variant="contained"
                                  color="primary"
                                  text={'+' + t('PayLink')}
                                  onClick={(e) => this.generatepayLink(e, 'textfield')}
                                />
                                {this.state.GetPayLink &&
                                this.state.GetPayLink.length > 1 &&
                                this.state.showTextLink ? (
                                  <Input
                                    className={classes.generatepaylink}
                                    placeholder="Link"
                                    type="text"
                                    value={window.location.origin + '/payment/' + this.state.GetPayLink}
                                    readOnly
                                  />
                                ) : (
                                  ''
                                )}
                              </>
                            )}
                          </Grid>
                        )}

                        <Grid item xs={12} s={12} m={12} lg={8} className={classes.grid} style={{ textAlign: 'right' }}>
                          {this.state?.userData?.permissions?.textInvoice &&
                            this.state.invoice &&
                            !this.state.isRefund && (
                              <Buttons
                                id="textInvoice"
                                type="submit"
                                variant="contained"
                                className={classes.textInvoiceBtn}
                                onClick={(e) => this.handleSubmitTextInvoice(e, t)}
                                startIcon={<Icon>message</Icon>}
                                text={t('TextInvoice')}
                              />
                            )}
                          {this.state.invoice && !this.state.isRefund ? (
                            <Buttons
                              id="emailInvoice"
                              type="submit"
                              variant="contained"
                              className={classes.textInvoiceBtn}
                              onClick={(e) => this.handleSubmitEmailInvoice(e)}
                              startIcon={<Icon>send</Icon>}
                              text={t('EmailInvoice')}
                            />
                          ) : (
                            ''
                          )}
                        </Grid>
                        <Grid item xs={12} s={6} m={6} lg={8} style={{ textAlign: 'right' }}>
                          <Buttons
                            className={classes.backbtn}
                            variant="contained"
                            color="secondary"
                            text={t('Back')}
                            onClick={this.props.history.goBack}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </LoadingOverlay>
            </Container>
          </div>
        </div>
        <CashPaymentModal
          open={this.state.openCashPaymentModal}
          handleClose={() => this.setState({ openCashPaymentModal: false })}
          handleProceedCash={this.handleProceedCash}
          activeElement={document.activeElement.id}
          CashPaymentModalText={getCashPaymentModalText()}
        />
        <QRModal
          open={this.state.openQRModal}
          handleClose={() => this.setState({ openQRModal: false })}
          GetPayLink={this.state.GetPayLink}
        />
        <AdjustSettings
          open={this.state.openAdjustSettings}
          handleClose={() => this.setState({ openAdjustSettings: false }, () => this.setAmount(this.state?.amount))}
          settings={this.state?.feeSettings}
          handleFeeSettingsType={this.handleFeeSettingsType}
          handleFeeSettingsToggles={this.handleFeeSettingsToggles}
          handleFeeSettingsValue={this.handleFeeSettingsValue}
          svcEdit={this.props.UserDetail.permissions.feeEdit}
        />
      </MuiThemeProvider>
    );
  }
}
function mapStateToProps(state) {
  return {
    info: state.InvoiceReducer,
    payment: state.PaymentReducer,
    UserDetail: state.AdminUserReducer,
    CurrentLocation: state.LocationReducer,
    PAYTRACE_AWS_PATH: state.EnvironmentVariablesReducer.data.PAYTRACE_AWS_PATH,
    termsAndConditions: state.TermsConditionReducer,
  };
}

Terminal.propTypes = {
  PAYTRACE_AWS_PATH: PropTypes.any,
  history: PropTypes.any,
  payLink: PropTypes.any,
  info: PropTypes.any,
  getCsrfToken: PropTypes.func,
  settingDataByCompanyId: PropTypes.func,
  getTransactionsDataForRefund: PropTypes.func,
  getTransactionListTodayData: PropTypes.func,
  customerDetailsByCompany: PropTypes.func,
  payment: PropTypes.any,
  UserDetail: PropTypes.object,
  customerDetailsByCompanyCustom: PropTypes.func,
  location: PropTypes.any,
  InvoiceDetailById: PropTypes.func,
  updateInvoice: PropTypes.func,
  getPendingStatusInvoiceList: PropTypes.func,
  getInvoiceNumber: PropTypes.func,
  getRefundedDailyData: PropTypes.func,
  t: PropTypes.any,
  refundAuth: PropTypes.any,
  refundAuthManual: PropTypes.any,
  emailInvoice: PropTypes.any,
  textPendingInvoice: PropTypes.any,
  termsAndConditions: PropTypes.any,
  CurrentLocation: PropTypes.any,
  isMobile: PropTypes.bool,
  locationInfo: PropTypes.any,
  setTsepAuth: PropTypes.func,
  locationDetailById: PropTypes.func,
};

export default connect(mapStateToProps, {
  settingDataByCompanyId,
  getRefundedDailyData,
  refundAuth,
  refundAuthManual,
  getCsrfToken,
  emailInvoice,
  textPendingInvoice,
  payLink,
  UserDetailById,
  getPendingStatusInvoiceList,
  InvoiceDetailById,
  updateInvoice,
  customerDetailsByCompanyCustom,
  customerDetailsByCompany,
  getTransactionListTodayData,
  getInvoiceNumber,
  countryDataList,
  getTransactionsDataForRefund,
  setTsepAuth,
  locationDetailById,
})(withTranslation()(Terminal));
