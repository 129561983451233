import { ActionType } from './../constants';
import { DataApi } from './../helpers/PostDataApi';
import { config } from './../constants';
import { cardTotalAmountWhenDualPricingIsEnabled, getRoadsideTotalAmount, getSVCFee } from '../helpers/svcFeeAmount';
import fetch from 'isomorphic-unfetch';
import { getCost } from './getCost';
import { error } from './Common';
import { DataGetApi } from './../helpers/PostDataApi';

/**
 * user info Begin Action
 */
export const invoiceStart = () => ({
  type: ActionType.INVOICE_START,
});

export const payLinkStart = () => ({
  type: ActionType.PAYLINK_START,
});

/**
 * user info Success Action
 */
export const invoiceSuccess = (invoiceData) => {
  return {
    type: ActionType.INVOICE_SUCCESS,
    payload: { invoiceData },
  };
};

/**
 * user info Failure Action
 */
export const invoiceError = (error) => ({
  type: ActionType.INVOICE_ERROR,
  payload: { error },
});

export const PayLinkSuccess = (payLinkData) => {
  return {
    type: ActionType.PAYLINK_SUCCESS,
    payload: { payLinkData },
  };
};

export const payLinkError = (error) => ({
  type: ActionType.PAYLINK_ERROR,
  payload: { error },
});

/**
 * user driver info
 */

const handleInvoiceRequest = (data, url, isMultiPartUpload = false, csrf = null, paytraceCredentials = null) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(invoiceStart());
    return DataApi(data, url, token, true, isMultiPartUpload, csrf, paytraceCredentials)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(invoiceSuccess(response));
        } else {
          return dispatch(invoiceError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

const handleScheduleBulkInvoices = (data, url, csrf = null, paytraceCredentials = null) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(invoiceStart());
    return DataApi(data, url, token, true, false, csrf, paytraceCredentials)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(invoiceSuccess(response));
        } else {
          return dispatch(invoiceError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const csvFilesImport = (data, csrf = null, paytraceCredentials = null) => {
  return handleInvoiceRequest(data, config.BASEURL + 'csvFilesImport', csrf, paytraceCredentials);
};

export const addInvoice = (data, csrf = null, paytraceCredentials = null) => {
  return handleInvoiceRequest(data, config.BASEURL + 'addInvoice', false, csrf, paytraceCredentials);
};

export const getInvoiceByInvoiceNumber = (invoiceNumber) => {
  const url = config.BASEURL + `invoice/${invoiceNumber}`;

  return (dispatch) => {
    dispatch(invoiceStart());
    return DataGetApi(url)
      .then((response) => {
        if (response.status === 200) {
          return dispatch({ type: 'INVOICE_DETAILS', payload: { value: response.data.response } });
        } else {
          return dispatch(invoiceError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
        return dispatch(invoiceError(err));
      });
  };
};

export const textInvoice = (data, csrf = null, paytraceCredentials = null) => {
  return handleInvoiceRequest(data, config.BASEURL + 'textInvoice', false, csrf, paytraceCredentials);
};

export const uploadInvoiceFile = (data, isMultipartUpload = false, csrf = null, paytraceCredentials = null) => {
  return handleInvoiceRequest(data, config.BASEURL + 'uploadInvoiceFile', isMultipartUpload, csrf, paytraceCredentials);
};

export const emailInvoice = (data) => {
  return handleInvoiceRequest(data, config.BASEURL + 'emailInvoice');
};

export const emailBulkInvoices = (data) => {
  return handleInvoiceRequest(data, config.BASEURL + 'emailBulkInvoices');
};

export const uploadBulkInvoices = (data) => {
  return handleInvoiceRequest(data, config.BASEURL + 'uploadBulkInvoices');
};

export const scheduleBulkInvoices = (data) => {
  return handleScheduleBulkInvoices(data, config.BASEURL + 'scheduleBulkInvoices');
};

export const textBulkInvoices = (data) => {
  return handleInvoiceRequest(data, config.BASEURL + 'textBulkInvoices');
};

export const textPendingInvoice = (data) => {
  return handleInvoiceRequest(data, config.BASEURL + 'textPendingInvoice');
};

export const intakeInvoiceLink = (data) => {
  return handleInvoiceRequest(data, config.BASEURL + 'intakeInvoiceLink');
};

export const updateInvoice = (data, csrf = null, paytraceCredentials = null, apiRoute = 'updateInvoice') => {
  return handleInvoiceRequest(data, config.BASEURL + apiRoute, false, csrf, paytraceCredentials);
};

export const updatePendingInvoice = (data, csrf = null, paytraceCredentials = null) => {
  let localUser = JSON.parse(localStorage.getItem('user'));
  let token = localUser.token;
  return (dispatch) => {
    dispatch(invoiceStart());
    return DataApi(data, config.BASEURL + 'updatePendingInvoice', token, true, false, csrf, paytraceCredentials)
      .then((res) => {
        if (res.status === 200 && res.data.statusCode === 200) {
          return dispatch(invoiceSuccess(res));
        } else {
          return dispatch(invoiceError(res));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// payLink
export const payLink = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(payLinkStart());
    return DataApi(data, config.BASEURL + 'payLink', token, true)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(PayLinkSuccess(response));
        } else {
          return dispatch(payLinkError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const payByLink = (data, csrf, paytraceCredentials = null, isMultiInvoicePay = false) => {
  const endPoint = isMultiInvoicePay ? 'payMultipleInvoicesByLink' : 'payByLink';
  return (dispatch) => {
    dispatch(invoiceStart());
    return DataApi(data, config.BASEURL + endPoint, null, true, false, csrf, paytraceCredentials)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(invoiceSuccess(response));
        } else {
          return dispatch(invoiceError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const fetchHashDetails = (token) => (dispatch) => {
  fetch(config.BASEURL + `fetchHashDetails?hash=${token}`)
    .then((response) => response.json())
    .then((myJson) => {
      dispatch({
        type: 'HASH',
        payload: { property: 'users', value: myJson.response },
      });
    })
    .catch((_err) => {
      // console.log('Error', err);
      //error(dispatch, err);
    });
};

export const addressAutoSuggest = (address) => (dispatch) => {
  fetch(config.BASEURL + `addressAutocomplete?input=${address}`)
    .then((response) => response.json())
    .then((myJson) => {
      dispatch({
        type: 'SET_STATE',
        payload: { property: 'suggestionsData', value: myJson },
      });
    })
    .catch((_err) => {
      console.log('Error', _err);
      error(dispatch, _err);
    });
};
export const appendToNotes = (value) => ({
  type: 'APPEND_STATE',
  payload: { value },
});

export const fetchDetails = (token, _history) => (dispatch) => {
  // let user = cookie.load('user')
  fetch(config.BASEURL + `userDetails`, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // 'Authorization': 'bearer ' + user.token
    },

    body: JSON.stringify({ token }),
  })
    .then((response) => response.json())
    .then(
      (response) => {
        const users = response;
        if (users.status === 401) {
          //alert('Auth time out');
          // cookie.remove('token')
        }
        dispatch({
          type: 'USERS_DETAILS',
          payload: { property: 'user', value: users.data },
        });
      },
      (errorV) => {
        error(errorV);
      }
    )
    .catch((err) => {
      error('User not found', err);
    });
};

export const getDrivingDetails =
  (origin, destination, zipCode, serviceType, pickUpLocation, newPrice) => (dispatch) => {
    if (origin && destination) {
      fetch(config.BASEURL + `getDistance?origin=${origin}&destination=${destination}`, { method: 'GET' })
        .then((response) => response.json())
        .then((myJson) => {
          if (myJson.distance && zipCode && serviceType) {
            dispatch(getCost(myJson.distance, zipCode, serviceType, pickUpLocation, newPrice));
          } else {
            dispatch(getCost(-1, zipCode, serviceType, pickUpLocation, newPrice));
          }
          dispatch({
            type: 'SET_STATE',
            payload: { property: 'distanceDetails', value: myJson },
          });
        })
        .catch((err) => {
          console.log('Error', err);
          //error(dispatch, err);
        });
    } else if (origin) {
      if (zipCode && serviceType) {
        dispatch(getCost(-1, zipCode, serviceType, pickUpLocation, newPrice));
      } else {
        if (!zipCode) alert('zip code not found');
      }
    }
  };

const generateInvoice = () => Math.floor(Math.random() * 1000000000);

export const invoiceGenerate = () => async (dispatch) => {
  let rand = generateInvoice(),
    count = 0;
  let retry = true;
  while (retry) {
    const resp = await fetch(config.BASEURL + `invoiceCheck?invoice=${rand}`);
    const jsonV = await resp.json();
    if (!jsonV.isFound) {
      dispatch(setInputState('invoice', `${rand}`));
      break;
    }
    count++;
    if (count > 25) {
      retry = false;
      break;
    }
    rand = generateInvoice();
  }
};
// export const reset = () => (
// 	async dispatch => {
// 		console.log('<<<<','RESET')
// 		await dispatch({ type: 'RESET' });
// 		//dispatch();
// 	}
// );
export const reset = () => async (dispatch) => {
  await dispatch({ type: 'RESET' });
  dispatch(invoiceGenerate());
};

export const setInputState = (property, value, key = null) => ({
  type: 'SET_STATE',
  payload: { property, value, key },
});
export const setInputErrorState = (property, value) => ({
  type: 'SET_STATE_ERROR',
  payload: { property, value },
});

export const saveFormData = (state) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let locationsPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
  const paytraceCredentials = {
    paytraceKey: locationsPaydetails.paytraceKey,
    userName: locationsPaydetails.paytraceUserName,
    password: locationsPaydetails.paytracePassword,
  };
  let token = user.token;
  let data = JSON.stringify({
    invoice: state.invoice,
    field1: state.field1,
    field2: state.field2,
    field3: state.field3,
    field4: state.field4,
    field5: state.field5,
    field6: { key: state.field6.key, value: state.field6.value.value },
    field7: { key: state.field7.key, value: state.field7.value.value },
    serviceType: state.serviceType,
    problemType: state.problemType,
    pickUpLocation: state.pickUpLocation,
    pickUpNotes: state.pickUpNotes,
    startAddress: state.startAddress,
    endAddress: state.endAddress,
    email: state.email,
    notes: state.notes,
    sendTo: state.sendTo,
    zipForCalc: state.zipForCalc,
    error: state.error,
    distanceDetails: state.distanceDetails,
    userAccount: state.userAccount,
    system: state.system,
    svcFee: state.InvoiceDiscount ? getSVCFee(state.InvoiceSVCFeeType, state.settingsInvoiceFee, state.amount) : '',
    discountFeeName: state.DiscountFeeName,
    svcType: state.InvoiceSVCFeeType,
    amount: state.amount ? parseFloat(state.amount).toFixed(2) : '',
    type: state.type,
    ...(state.cashTotal ? { cashTotal: state.cashTotal } : {}),
    total: state.isDualPricingEnabled
      ? cardTotalAmountWhenDualPricingIsEnabled(
          state.InvoiceDiscount,
          state.InvoiceTax,
          state.InvoiceSVCFeeType,
          state.settingsInvoiceFee,
          state.InvoiceSalesTaxFee,
          state.InvoiceSalesTaxFeeType,
          state.amount
        )
      : getRoadsideTotalAmount(
          state.InvoiceDiscount,
          state.InvoiceTax,
          state.InvoiceSVCFeeType,
          state.settingsInvoiceFee,
          state.InvoiceSalesTaxFee,
          state.InvoiceSalesTaxFeeType,
          state.amount
        ),
    saveAsDraft: state.saveAsDraft,
    companyId: state.companyId,
    createdBy: state.createdBy,
    locationId: locationsPaydetails?._id,
    paytraceCredentials: paytraceCredentials,
    isPaid: false,
    tax: state.tax,
    InvoiceDiscount: state.InvoiceDiscount,
    InvoiceTax: state.InvoiceTax,
  });

  return (dispatch) => {
    dispatch(invoiceStart());
    return DataApi(data, config.BASEURL + 'saveFormData', token, true, null, null, paytraceCredentials)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(invoiceSuccess(response));
        } else {
          return dispatch(invoiceError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const updateFormData = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;
  return (dispatch) => {
    dispatch(invoiceStart());
    return DataApi(data, config.BASEURL + 'updateFormData', token, true, null)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(invoiceSuccess(response));
        } else {
          return dispatch(invoiceError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const InvoiceDetailById = (data) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.token;

  return (dispatch) => {
    dispatch(invoiceSuccess());
    return DataGetApi(config.BASEURL + 'InvoiceDetailById', data, token)
      .then((response) => {
        if (response.status === 200) {
          return dispatch({ type: 'INVOICE_DETAILS', payload: { value: response.data.response } });
        } else {
          return dispatch(invoiceError(response.response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getNyTaxReports = (data) => {
  return (dispatch) => {
    dispatch(invoiceStart());
    return DataGetApi(config.BASEURL + 'getCutomizeRecordsOfTax', data)
      .then((response) => {
        if (response.status === 200) {
          return dispatch(invoiceSuccess(response));
        } else {
          return dispatch(invoiceError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const createRecurringByLink = (data, csrf = null, paytraceCredentials = null) => {
  console.log(data);
  return (dispatch) => {
    dispatch(invoiceStart());
    return DataApi(data, config.BASEURL + 'createRecurringByLink', null, true, false, csrf, paytraceCredentials)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(invoiceSuccess(response));
        } else {
          return dispatch(invoiceError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const selfPayByLink = (data, csrf = null, paytraceCredentials = null) => {
  console.log(data);
  return (dispatch) => {
    dispatch(invoiceStart());
    return DataApi(data, config.BASEURL + 'selfPayByLink', null, true, false, csrf, paytraceCredentials)
      .then((response) => {
        if (response.status === 200 && response.data.statusCode === 200) {
          return dispatch(invoiceSuccess(response));
        } else {
          return dispatch(invoiceError(response));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
