import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getDateObject } from './dateutils';

const RangedDatePicker = ({
  id,
  fromValue,
  toValue,
  fromOnChange,
  toOnChange,
  closeOnSelect,
  format,
  required,
  error,
  fromLabel,
  toLabel,
  minDate,
  maxDate,
}) => {
  fromValue = getDateObject(fromValue);
  toValue = getDateObject(toValue);
  minDate = getDateObject(minDate);
  maxDate = getDateObject(maxDate);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={fromLabel || 'From Date'}
        value={fromValue}
        onChange={(newValue) => {
          fromOnChange(newValue);
        }}
        closeOnSelect={closeOnSelect ? closeOnSelect : true}
        format={format}
        margin="normal"
        minDate={minDate}
        maxDate={toValue}
        styles={{ width: '100%', justifyContent: 'center', padding: '40px' }}
        slotProps={{
          textField: {
            id: id + '-from',
            required: required,
            error: error,
            variant: 'standard',
            style: {
              padding: '4px',
              marginLeft: '19px',
              marginRight: '6px',
              marginTop: '7px',
            },
            inputProps: { 'data-cy': id + '-from' },
          },
          inputAdornment: { position: 'end' },
        }}
      />
      <DatePicker
        label={toLabel || 'To Date'}
        value={toValue}
        onChange={(newValue) => {
          toOnChange(newValue);
        }}
        closeOnSelect={closeOnSelect ? closeOnSelect : true}
        format={format}
        margin="normal"
        minDate={fromValue}
        maxDate={maxDate}
        styles={{ width: '100%', justifyContent: 'center' }}
        slotProps={{
          textField: {
            id: id + '-to',
            required: required,
            error: error,
            variant: 'standard',
            style: {
              padding: '4px',
              marginLeft: '19px',
              marginRight: '6px',
              marginTop: '7px',
            },
            inputProps: { 'data-cy': id + '-to' },
          },
          inputAdornment: { position: 'end' },
        }}
      />
    </LocalizationProvider>
  );
};

RangedDatePicker.propTypes = {
  id: PropTypes.any,
  fromValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  toValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fromOnChange: PropTypes.func,
  toOnChange: PropTypes.func,
  closeOnSelect: PropTypes.bool,
  format: PropTypes.any,
  required: PropTypes.any,
  error: PropTypes.any,
  fromLabel: PropTypes.string,
  toLabel: PropTypes.string,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
};

export default RangedDatePicker;
