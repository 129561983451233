import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { PrivateRoute } from './helpers';
import { Login } from './pages/Login';
import { Home } from './pages/Home';
import { Invoice } from './pages/Invoice';
import AddInvoice from './pages/Invoice/AddInvoice';
import EditInvoice from './pages/Invoice/EditInvoice';
import { ForgotPassword, ResetPassword } from './pages/ForgotPassword';
import { Forgot } from './pages/Forgot';
import { initializeStore } from './store';
import { Dashboard } from './pages/Dashboard';
import { SignUp } from './pages/SignUp';
import { Company } from './pages/Company';
import AddCompany from './pages/Company/AddCompany';
import EditCompany from './pages/Company/EditCompany';
import CompanyDetails from './pages/Company/CompanyDetails';
import Demo from './pages/Company/Demo';
import { Discovery } from './pages/Discovery';
import { Users } from './pages/Users';
import AddAdmin from './pages/Users/AddAdmin';
import AddSubAdmin from './pages/Users/AddSubAdmin';
import EditSubAdmin from './pages/Users/EditSubAdmin';
import SubAdmin from './pages/Users/SubAdmin';
import EditAdmin from './pages/Users/EditAdmin';
import AddManager from './pages/Users/AddManager';
import EditManager from './pages/Users/EditManager';
import AddUser from './pages/Users/AddUser';
import EditUser from './pages/Users/EditUser';
import EditUserCompanyDetails from './pages/CompanyDetails/EditUserCompanyDetails';
import AddUserCompanyDetails from './pages/CompanyDetails/AddUserCompanyDetails';
import AddLocationsCompanyDetails from './pages/CompanyDetails/AddLocationsCompanyDetails';
import Profile from './pages/Users/Profile';
import userActivation from './pages/Users/userActivation';
import CustomerCreation from './pages/CustomerUsers/CustomerCreation';
import SelfCustomerCreation from './pages/CustomerUsers/SelfCustomerCreation';
import { Locations } from './pages/Locations';
import { Terminal } from './pages/Terminal';
import { Settings } from './pages/Settings';
import { SecureUserCard } from './pages/SecureUserCard';
import AddLocations from './pages/Locations/AddLocations';
import EditLocations from './pages/Locations/EditLocations';
import { TermCondition } from './pages/TermCondition';
import { Transactions } from './pages/Transactions';
import Viewtransaction from './pages/Transactions/Viewtransaction';
import { Tickets } from './pages/Tickets';
import AddTickets from './pages/Tickets/AddTickets';
import { Customers } from './pages/Customers';
import AddCustomers from './pages/Customers/AddCustomers';
import EditCustomers from './pages/Customers/EditCustomers';
import CustomerDetails from './pages/Customers/CustomerDetails';
import { Products } from './pages/Products';
import AddProducts from './pages/Products/AddProducts';
import EditProducts from './pages/Products/EditProducts';
import { Recurring } from './pages/Recurring';
import RecurrenceDetails from './pages/Recurring/RecurrenceDetails';
import { Payment } from './pages/Payment';
import PaymentAuth from './pages/Payment/PaymentAuth';
import { Pages } from './pages/Pages';
import { RequestForm } from './pages/RequestForm';
import AddPages from './pages/Pages/AddPages';
import AdminPortal from './pages/Transactions/AdminPortal';
import { InvoiceEdit } from './pages/InvoiceEdit';
import { NyTaxReports } from './pages/NyTaxReports';
import { getEnvironmentVariablesList } from './actions/EnvironmentVariablesAction';
import OnTimeRecurringPayment from './pages/Payment/OnTimeRecurringPayment';
import LocationSelfPayment from './pages/Payment/LocationSelfPayment';
import BatchTransactionList from './pages/Transactions/components/BatchTransactionList';
import { CustomerInvoice } from './pages/CustomerPortal/Invoice';
import { CustomerProfile } from './pages/CustomerPortal/Profile';
import ApiKey from './pages/Users/ApiKey';

import IdleTimer from 'react-idle-timer';

import './App.css';
import CustomerHistory from './pages/Customers/CustomerHistory';
import { PaymentHistory } from './pages/CustomerPortal/PaymentHistory';
import PaymentDetails from './pages/CustomerPortal/PaymentHistory/PaymentDetails';
import { CustomerPayment } from './pages/CustomerPortal/Payment';
import QuickPay from './pages/CustomerPortal/Payment/QuickPay';
import MultiInvoicePayment from './pages/CustomerPortal/Payment/MultiInvoicePayment';

const store = initializeStore();

class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  componentDidMount() {
    store.dispatch(getEnvironmentVariablesList());
  }

  logOut(_event) {
    localStorage.removeItem('user');
    localStorage.removeItem('locationId', { path: '/' });
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('locationId');
    localStorage.removeItem('locationPaydetails');
    localStorage.removeItem('selected');
    localStorage.removeItem('locationArrIds');
    window.location.replace('/Login');
  }

  //logout the user in 20min of in activity.
  handleOnIdle(_event) {
    const excludeLogoutRoutes = ['/Login', '/ForgotPassword', '/SignUp', '/selfPayment'];
    const isExcludedRoute = excludeLogoutRoutes.some((route) => window.location.pathname.includes(route));
    if (!isExcludedRoute) {
      this.logOut();
    }
  }

  render() {
    return (
      <div>
        <IdleTimer ref={this.idleTimer} timeout={1000 * 60 * 20} onIdle={this.handleOnIdle} debounce={250} />
        <Provider store={store}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Home} />
              <PrivateRoute exact path="/Demo" component={Demo} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/discovery" component={Discovery} />
              <Route exact path="/signMeUp" component={SignUp} />
              <Route exact path="/resetPassword" component={ResetPassword} />
              <Route exact path="/forgotPassword" component={ForgotPassword} />
              <Route exact path="/forgot" component={Forgot} />
              <PrivateRoute exact path="/Dashboard" component={Dashboard} />
              <PrivateRoute exact path="/Company" component={Company} />
              <PrivateRoute exact path="/AddCompany" component={AddCompany} />
              <PrivateRoute exact path="/EditCompany/:id" component={EditCompany} />
              <PrivateRoute exact path="/CompanyDetails/:id" component={CompanyDetails} />
              <PrivateRoute exact path="/Users" component={Users} />
              <PrivateRoute exact path="/AddAdmin" component={AddAdmin} />
              <PrivateRoute exact path="/EditAdmin/:id" component={EditAdmin} />
              <PrivateRoute exact path="/SubAdmin" component={SubAdmin} />
              <PrivateRoute exact path="/AddSubAdmin" component={AddSubAdmin} />
              <PrivateRoute exact path="/EditSubAdmin/:id" component={EditSubAdmin} />
              <PrivateRoute exact path="/EditUserCompanyDetails" component={EditUserCompanyDetails} />
              <PrivateRoute exact path="/AddUserCompanyDetails" component={AddUserCompanyDetails} />
              <PrivateRoute exact path="/AddLocationsCompanyDetails" component={AddLocationsCompanyDetails} />
              <PrivateRoute exact path="/AddManager" component={AddManager} />
              <PrivateRoute exact path="/EditManager/:id" component={EditManager} />
              <PrivateRoute exact path="/AddUser" component={AddUser} />
              <PrivateRoute exact path="/EditUser/:id" component={EditUser} />
              <PrivateRoute exact path="/Profile/:id" component={Profile} />
              <PrivateRoute exact path="/Locations" component={Locations} />
              <PrivateRoute exact path="/ApiKeys" component={ApiKey} />
              <PrivateRoute path="/Terminal" component={Terminal} />
              <PrivateRoute path="/Settings" component={Settings} />
              <PrivateRoute exact path="/Invoices" component={Invoice} />
              <PrivateRoute exact path="/AddInvoices" component={AddInvoice} />
              <PrivateRoute exact path="/EditInvoices/:id" component={EditInvoice} />
              <PrivateRoute exact path="/InvoiceEdit/:id" component={InvoiceEdit} />
              <PrivateRoute exact path="/SecureUserCard" component={SecureUserCard} />
              <PrivateRoute exact path="/AddLocations" component={AddLocations} />
              <PrivateRoute exact path="/EditLocations/:id" component={EditLocations} />
              <PrivateRoute exact path="/Transactions" component={Transactions} />
              <PrivateRoute exact path="/Transactions/Batches" component={Transactions} />
              <PrivateRoute exact path="/AdminPortal" component={AdminPortal} />
              <PrivateRoute exact path="/Viewtransaction/:id" component={Viewtransaction} />
              <PrivateRoute exact path="/Tickets" component={Tickets} />
              <PrivateRoute exact path="/AddTickets" component={AddTickets} />
              <PrivateRoute exact path="/Pages" component={Pages} />
              <PrivateRoute exact path="/AddPages" component={AddPages} />
              <PrivateRoute exact path="/Customers" component={Customers} />
              <PrivateRoute exact path="/CustomerDetails/:id" component={CustomerDetails} />
              <PrivateRoute exact path="/CustomerHistory/:id" component={CustomerHistory} />
              <PrivateRoute exact path="/AddCustomers" component={AddCustomers} />
              <PrivateRoute exact path="/EditCustomers/:id" component={EditCustomers} />
              <PrivateRoute exact path="/Products" component={Products} />
              <PrivateRoute exact path="/AddProducts" component={AddProducts} />
              <PrivateRoute exact path="/EditProducts/:id" component={EditProducts} />
              <PrivateRoute exact path="/Recurring" component={Recurring} />
              <PrivateRoute exact path="/RecurrenceDetails/:id" component={RecurrenceDetails} />
              <PrivateRoute exact path="/batch/:id/:locationId/:locationMid" component={BatchTransactionList} />
              <Route exact path="/userActivation" component={userActivation} />
              <Route exact path="/userCreation" component={CustomerCreation} />
              <Route exact path="/customerSelfSignUp/:locationId" component={SelfCustomerCreation} />
              <Route exact path="/TermCondition/:id" component={TermCondition} />
              <Route exact path="/Payment/:id" component={Payment} />
              <Route exact path="/SelfPayment/:locationId" component={LocationSelfPayment} />
              <Route exact path="/PaymentAuth/:id" component={PaymentAuth} />
              <PrivateRoute exact path="/RequestForm" component={RequestForm} />
              <PrivateRoute exact path="/NyTaxReports" component={NyTaxReports} />
              <Route exact path="/OnTimeRecurringPayment/:id" component={OnTimeRecurringPayment} />
              {/* customer router */}
              <PrivateRoute exact path="/Customer/Invoices" component={CustomerInvoice} />
              <PrivateRoute exact path="/Customer/Payment/History" component={PaymentHistory} />
              <PrivateRoute exact path="/Customer/PaymentDetails/:id" component={PaymentDetails} />
              <PrivateRoute exact path="/Customer/QuickPay" component={QuickPay} />
              <PrivateRoute exact path="/Customer/Profile" component={CustomerProfile} />
              <PrivateRoute exact path="/Customer/Payment/:id" component={CustomerPayment} />
              <PrivateRoute exact path="/Customer/Invoices/Payment" component={MultiInvoicePayment} />
              <PrivateRoute exact path="/Customer/EditInvoices/:id" component={EditInvoice} />
              <Redirect to="/Dashboard" />
            </Switch>
          </BrowserRouter>
        </Provider>
      </div>
    );
  }
}
export default withRouter(App);
