import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classes from './User.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { addSubAdmin } from '../../actions/AdminUserAction';
import { Topheader } from './../../components/Topheader';
import validator from './../../helpers/checkValidations';
import Checkbox from '@material-ui/core/Checkbox';
import { validateHtmlMarkUp } from '../../helpers/commonFunction';
import { subAdminInitialState } from './CommonFunction';

class AddAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = subAdminInitialState;
  }

  handleClickMenu(_e) {
    if (this.state.sideShow) this.setState({ sideShow: false });
    else this.setState({ sideShow: true });
  }
  handleChangeCheckbox(event) {
    this.setState({ permissions: { ...this.state.permissions, [event.target.id]: event.target.checked } });
  }

  handleChange = (event, _t) => {
    let successMessages = false;
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    const isHtmlMarkupValid = validateHtmlMarkUp(value);
    if (isHtmlMarkupValid) {
      return false;
    }
    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);

    this.setState({ [id]: value, error, successMessages, misMatchError, isSubmit: false });
  };

  handleSubmit(_e, _t) {
    let misMatchError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true });

    Object.keys(state).map((key, _value) => {
      if (validator(key, state[key])) {
        return validator(key, state[key])
          ? ((error[key + 'Error'] = true), (errorFlag = true))
          : (error[key + 'Error'] = false);
      } else {
        return state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')
          ? ((error[key + 'Error'] = true), (errorFlag = true))
          : '';
      }
    });

    if (errorFlag) {
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      this.props.addSubAdmin(this.state).then((result) => {
        if (result !== undefined && result.type === 'USER_SUCCESS') {
          this.setState({ successMessages: true }, () => {
            this.props.history.goBack();
          });
        }
        if (result !== undefined && result.type === 'USER_ERROR') {
          this.setState({ misMatchError: this.props.info.userInfo.data.message, successMessages });
        }
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="rightPanel">
        <Topheader />
        <Container component="main" maxWidth="xl">
          {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
          {this.state.successMessages ? <SuccessMessage successes={[this.props.info.userInfo.data.message]} /> : ''}

          <form className={classes.form} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
                <Textfield
                  required={true}
                  error={this.state.error.userNameError}
                  id="userName"
                  type="text"
                  labels={t('Username')}
                  value={this.state.userName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <Textfield
                  required={true}
                  error={this.state.error.firstNameError}
                  id="firstName"
                  type="text"
                  labels={t('FirstName')}
                  value={this.state.firstName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <Textfield
                  required={true}
                  error={this.state.error.lastNameError}
                  id="lastName"
                  type="text"
                  labels={t('LastName')}
                  value={this.state.lastName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <Textfield
                  required={true}
                  error={this.state.error.emailError}
                  id="email"
                  type="email"
                  labels={t('Email')}
                  value={this.state.email}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3} style={{ marginLeft: -10 }}>
                <label>
                  <Checkbox
                    id="enableBatchTransactionEmailNotification"
                    color="primary"
                    onChange={(event) => {
                      this.setState({ enableBatchTransactionReportNotification: event.target.checked });
                    }}
                    checked={this.state.enableBatchTransactionReportNotification}
                    value={this.state.enableBatchTransactionReportNotification}
                    inputProps={{
                      'aria-label': 'secondary checkboxes',
                    }}
                  />
                  <span>{t('BatchTransactionNotification')}</span>
                </label>
              </Grid>
              <Grid item xs={12}>
                {/* permission for locations */}
                <h3>
                  <b>Permissions</b>
                </h3>
                <Grid item xs={6} sm={3}>
                  <Checkbox
                    checked={this.state.permissions.addLocation}
                    id="addLocation"
                    labels="addLocation"
                    onChange={(e) => {
                      this.handleChangeCheckbox(e);
                    }}
                    value={this.state.addLocation}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                  <label>
                    <span>Add Location</span>
                  </label>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Checkbox
                    checked={this.state.permissions.editLocation}
                    id="editLocation"
                    labels="editLocation"
                    onChange={(e) => {
                      this.handleChangeCheckbox(e);
                    }}
                    value={this.state.editLocation}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                  <label>
                    <span>Edit Location</span>
                  </label>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Checkbox
                    checked={this.state.permissions.isActiveLocation}
                    id="isActiveLocation"
                    labels="isActiveLocation"
                    onChange={(e) => {
                      this.handleChangeCheckbox(e);
                    }}
                    value={this.state.isActiveLocation}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                  <label>
                    <span>Active-Inactive Location</span>
                  </label>
                </Grid>
                {/* permission for user */}
                <Grid item xs={6} sm={3}>
                  <Checkbox
                    checked={this.state.permissions.addUser}
                    id="addUser"
                    labels="addUser"
                    onChange={(e) => {
                      this.handleChangeCheckbox(e);
                    }}
                    value={this.state.addUser}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                  <label>
                    <span>Add User</span>
                  </label>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <Checkbox
                    checked={this.state.permissions.addApiKeys}
                    id="addApiKeys"
                    labels="addApiKeys"
                    onChange={(e) => {
                      this.handleChangeCheckbox(e);
                    }}
                    value={this.state.addApiKeys}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                  <label>
                    <span>Add Api Keys</span>
                  </label>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Checkbox
                    checked={this.state.permissions.editUser}
                    id="editUser"
                    labels="editUser"
                    onChange={(e) => {
                      this.handleChangeCheckbox(e);
                    }}
                    value={this.state.editUser}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                  <label>
                    <span>Edit User</span>
                  </label>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Checkbox
                    checked={this.state.permissions.isActiveUser}
                    id="isActiveUser"
                    labels="isActiveUser"
                    onChange={(e) => {
                      this.handleChangeCheckbox(e);
                    }}
                    value={this.state.isActiveUser}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                  <label>
                    <span>Active-Inactive User</span>
                  </label>
                </Grid>
                {/* permission for company */}
                <Grid item xs={6} sm={3}>
                  <Checkbox
                    checked={this.state.permissions.addCompany}
                    id="addCompany"
                    labels="addCompany"
                    onChange={(e) => {
                      this.handleChangeCheckbox(e);
                    }}
                    value={this.state.addCompany}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                  <label>
                    <span>Add Company</span>
                  </label>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Checkbox
                    checked={this.state.permissions.editCompany}
                    id="editCompany"
                    labels="addCompany"
                    onChange={(e) => {
                      this.handleChangeCheckbox(e);
                    }}
                    value={this.state.editCompany}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                  <label>
                    <span>Edit Company</span>
                  </label>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Checkbox
                    checked={this.state.permissions.isActiveCompany}
                    id="isActiveCompany"
                    labels="isActiveCompany"
                    onChange={(e) => {
                      this.handleChangeCheckbox(e);
                    }}
                    value={this.state.isActiveCompany}
                    color="primary"
                    inputProps={{
                      'aria-label': 'secondary checkbox',
                    }}
                  />
                  <label>
                    <span>Active-Inactive Company</span>
                  </label>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Buttons
                  id="signUp"
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={(e) => this.handleSubmit(e, t)}
                  text={t('Save')}
                />
                <Buttons
                  className={classes.ml_2}
                  variant="contained"
                  color="secondary"
                  text={t('Back')}
                  onClick={this.props.history.goBack}
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </div>
    );
  }
}

AddAdmin.propTypes = {
  t: PropTypes.func,
  info: PropTypes.object,
  history: PropTypes.object,
  addSubAdmin: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    info: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { addSubAdmin })(withTranslation()(AddAdmin));
