import React from 'react';
import { Textfield } from '../Textfield';
import Grid from '@mui/material/Unstable_Grid2'; // Grid2 in mui 6
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';

const ACHForm = React.forwardRef(function ACHForm(props, ref) {
  const { t, ach, handleChangeAch, location, variant = '' } = props;
  const [confirmAccountNumber, setConfirmAccountNumber] = React.useState('');
  const [achError, setAchError] = React.useState({
    accountName: false,
    accountNumber: false,
    confirmAccountNumber: false,
    routingNumber: false,
  });
  const [isValidNumber, setIsValidNumber] = React.useState(true);
  const [pasteAttempted, setPasteAttempted] = React.useState(false);
  const [pasteDisabled, setPasteDisabled] = React.useState(true);

  let spacing = 3;
  if (variant === 'tight') {
    spacing = 1;
  }

  React.useImperativeHandle(ref, () => {
    return {
      validate: () => {
        let tempAchError = {
          accountName: false,
          accountNumber: false,
          confirmAccountNumber: false,
          routingNumber: false,
        };
        let isValid = true;
        if (
          location?.confirmAccountNumber &&
          (confirmAccountNumber === '' || ach.accountNumber !== confirmAccountNumber)
        ) {
          isValid = false;
          tempAchError.confirmAccountNumber = true;
        }
        if (
          !ach.accountName ||
          ach.accountName === '' ||
          (typeof ach.accountName === 'string' && ach.accountName.trim() === '')
        ) {
          isValid = false;
          tempAchError.accountName = true;
        }
        if (
          !ach.accountNumber ||
          ach.accountNumber === '' ||
          (typeof ach.accountNumber === 'string' && ach.accountNumber.trim() === '')
        ) {
          isValid = false;
          tempAchError.accountNumber = true;
        }
        if (
          !ach.routingNumber ||
          ach.routingNumber === '' ||
          (typeof ach.routingNumber === 'string' && ach.routingNumber.trim() === '')
        ) {
          isValid = false;
          tempAchError.routingNumber = true;
        }
        setAchError(tempAchError);
        return isValid;
      },
    };
  });

  const handleChangeAccountNum = (e, val) => {
    handleChangeAch(e, val);
    if (location?.confirmAccountNumber) {
      setPasteDisabled(true); // if any typing then disable paste again

      if (confirmAccountNumber === val) {
        setIsValidNumber(true);
      } else {
        setIsValidNumber(false);
      }
    }
  };

  const handleChangeConfirmAccountNum = (e, val) => {
    setConfirmAccountNumber(val);
    if (location?.confirmAccountNumber) {
      if (pasteAttempted) setPasteAttempted(false);
      if (ach.accountNumber === val) {
        setIsValidNumber(true);
      } else {
        setIsValidNumber(false);
      }
    }
  };

  const handleNumberPaste = (_e) => {
    setPasteDisabled(false);
  };

  const handleConfirmPaste = (e) => {
    if (pasteDisabled) {
      e.preventDefault();
      setPasteAttempted(true);
    }
  };

  return (
    <Grid container spacing={spacing}>
      <Grid md={12}>
        <Textfield
          required={true}
          error={achError.accountName}
          id="accountName"
          type="text"
          labels={t('AccountName')}
          value={ach?.accountName}
          width={true}
          autoFocus={true}
          onChange={(e, val) => handleChangeAch(e, val)}
          variant="outlined"
        />
      </Grid>
      <Grid md={12}>
        <Textfield
          required={true}
          error={achError.accountNumber}
          id="accountNumber"
          type="text"
          labels={t('AccountNumber')}
          value={ach?.accountNumber}
          width={true}
          onChange={(e, val) => handleChangeAccountNum(e, val)}
          variant="outlined"
          InputProps={{
            onPaste: (e) => {
              handleNumberPaste(e);
            },
          }}
        />
      </Grid>
      {location?.confirmAccountNumber && (
        <Grid md={12}>
          <Textfield
            required={true}
            error={achError.confirmAccountNumber}
            id="confirmAccountNumber"
            type="text"
            labels={t('ConfirmAccountNumber')}
            value={confirmAccountNumber}
            width={true}
            onChange={(e, val) => handleChangeConfirmAccountNum(e, val)}
            variant="outlined"
            InputProps={{
              onPaste: (e) => {
                handleConfirmPaste(e);
              },
            }}
          />
        </Grid>
      )}
      <Grid md={12}>
        <Textfield
          required={true}
          error={achError.routingNumber}
          id="routingNumber"
          type="text"
          labels={t('RoutingNumber')}
          value={ach?.routingNumber}
          width={true}
          onChange={(e, val) => handleChangeAch(e, val)}
          variant="outlined"
        />
      </Grid>
      {pasteAttempted && (
        <Grid md={12}>
          <Alert severity="error">Paste is disabled. Please type in your account number to confirm.</Alert>
        </Grid>
      )}
      {!isValidNumber && (
        <Grid md={12}>
          <Alert severity="error">Account numbers do not match.</Alert>
        </Grid>
      )}
    </Grid>
  );
});

ACHForm.propTypes = {
  t: PropTypes.func,
  ach: PropTypes.shape({
    accountName: PropTypes.string,
    accountNumber: PropTypes.string,
    routingNumber: PropTypes.any,
  }),
  handleChangeAch: PropTypes.any,
  location: PropTypes.object,
  variant: PropTypes.string,
};

export default ACHForm;
