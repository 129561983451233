import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from '../../../components/Topheader';
import LoadingOverlay from 'react-loading-overlay';
import config from '../../../constants/config';
import { DataGetApi } from '../../../helpers/PostDataApi';
import { formatCurrency } from '../../../helpers/commonFunction';
import { generateDataSource } from '../../../helpers/transactionsTableSource';
import { NyTaxReports } from '../../../pages/NyTaxReports';
import RangedDatePicker from '../../../components/DatePicker/RangedDatePicker';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import DataTable from 'react-data-table-component';
import DataTableExtensions from '../../../components/TableExtensions/index';
import '../../../components/TableExtensions/index.css';
import { setViewPreferences, fetchUserPreferences, markTransaction } from '../../../actions/AdminUserAction';
import { CustomTransactionColumn } from './CustomTransactionColumn';
import classes from './PaymentHistory.module.scss';
import { CloseableAlerts } from '../../../components/Message/CloseableAlerts';
import Stack from '@mui/material/Stack';
import Container from '@material-ui/core/Container';
import { UserDetailById } from '../../../actions/AdminUserAction';

const message = require('../../../constants/message');
class PaymentHistory extends Component {
  constructor(props) {
    const date = new Date();
    super(props);
    this.state = {
      sideShow: true,
      isLoading: true,
      reload: false,
      toDate: new Date(),
      fromDate: new Date(date.setDate(date.getDate() - 30)),
      data1: [],
      data: [],
      columns: [],
      misMatchError: [],
      columnsPreference: null,
      invoiceLable: 'Invoice',
      value: window.location.href.includes('/Batches') ? 1 : 0,
      locationId: '',
      gateway: '',
      userDetails: this.getUserDetails(),
    };
  }

  componentDidMount() {
    window.addEventListener('preferencesReceived', this.setColumnsPreference.bind(this));
    this.props.fetchUserPreferences();
    this.getData();
  }
  componentWillUnmount() {
    window.removeEventListener('preferencesReceived', this.setColumnsPreference);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.UserDetail &&
      nextProps.UserDetail.userViewPreferences &&
      nextProps.UserDetail.userViewPreferences.ViewColumns
    ) {
      const preferenceReceivedEvent = new CustomEvent('preferencesReceived', {
        detail: nextProps.UserDetail?.userViewPreferences?.ViewColumns,
      });
      window.dispatchEvent(preferenceReceivedEvent);
      this.setState({ columnsPreference: nextProps?.UserDetail?.userViewPreferences?.ViewColumns });
    }

    const locationCurrent = JSON.parse(localStorage.getItem('locationPaydetails'));
    this.setState({ gateway: locationCurrent.gateway });
    if (
      locationCurrent &&
      locationCurrent?.customFieldsEnabled &&
      locationCurrent?.customFields &&
      locationCurrent?.customFields.length > 0
    ) {
      const customFieldEnabled = locationCurrent.customFields.find((customField) => customField?.enabled);
      if (customFieldEnabled) {
        this.setState({ invoiceLable: customFieldEnabled?.value });
      }
    }

    if (
      locationCurrent &&
      locationCurrent.customTransactionColumnEnabled &&
      locationCurrent.customTransactionColumnFields.length > 0
    ) {
      const customTransactionColumn = locationCurrent.customTransactionColumnFields.find(
        (customField) => customField?.enabled
      );
      if (customTransactionColumn) {
        this.setState({ transactionColumn: customTransactionColumn?.value });
      }
    }
  }

  getUserDetails() {
    let userData = JSON.parse(localStorage.getItem('user'));
    this.props.UserDetailById({ id: userData?._id, isCustomerUser: true }).then((data) => {
      if (data.type === 'USER_SUCCESS' && data.payload.userData.status === 200) {
        this.setState({ userDetails: data.payload.userData.data });
      }
    });
  }

  setColumnsPreference(event) {
    this.setState({ columnsPreference: event.detail });
  }
  handleDateChange = (date, key) => {
    if (date == 'Invalid Date' || date == null) {
      return false;
    } else {
      this.setState({ [key]: date }, () => {
        key === 'toDate' ? this.toDateFilter(this.state.toDate) : this.fromDateFilter(this.state.fromDate);
        this.getData();
      });
    }
  };
  toDateFilter(date) {
    this.setState({ toDate: date });
  }
  fromDateFilter(date) {
    this.setState({ fromDate: date });
  }
  handleChangeId(id) {
    this.props.history.push('/Customer/PaymentDetails/' + id, { parentPage: '/Customer/Payment/History' });
  }

  tableReload(status) {
    this.setState({ reload: status });
  }

  getData = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = user.token;
      const url = config.BASEURL;
      const locationId = localStorage.getItem('locationArrIds');
      this.setState({ locationId: locationId });
      const orderBy = '';
      const orderDirection = '';

      const param = this.constructApiParams(user, locationId, orderBy, orderDirection);

      const result = await DataGetApi(url + '/customer/transactions', param, token);
      if (result.status === 200) {
        const modifiedData = this.transformData(result.data.response);
        this.setState({ data1: modifiedData, isLoading: false });
        this.generateTableDataSource(modifiedData);

        return {
          data: modifiedData,
          page: result?.data?.pagination?.page - 1,
          totalCount: result?.data?.pagination?.totalCount,
        };
      } else {
        const errorMsg = message.FETCH_TRANSACTION_ERROR;
        this.handleError(errorMsg);
        return Promise.reject(errorMsg);
      }
    } catch (error) {
      console.error(error);
      this.setState({ isLoading: false });
      return Promise.reject(error);
    }
  };

  constructApiParams = (user, locationId, orderBy, orderDirection) => {
    return {
      locationIds: locationId,
      role: user.role,
      userId: user._id,
      companyId: user.company._id,
      defaultLocation: JSON.parse(locationId).length ? JSON.parse(locationId)[0] : null,
      per_page: '',
      page: '',
      searchAll: false,
      toDate: this.state.toDate,
      fromDate: this.state.fromDate,
      orderBy: orderBy,
      orderDirection: orderDirection,
      permissions: user.permissions,
      customerId: user?.customerId,
      isCustomerUser: true,
    };
  };
  getCreatedBy = (item) => {
    if (
      item.FRONT_END === 'Self Payment Link' ||
      item.FRONT_END === 'Quick Pay' ||
      item.FRONT_END === 'Customer Portal'
    ) {
      return `${item.CUSTOMERDATA.firstName} ${item.CUSTOMERDATA.lastName}`;
    } else {
      return item.createdBy ? `${item.createdBy.firstName} ${item.createdBy.lastName}` : '';
    }
  };
  transformData = (data) => {
    return (
      data.length > 0 &&
      data.map((item) => ({
        ...item,
        amount: item.AMOUNT ? formatCurrency(item.AMOUNT) : '$0.00',
        inventedBy: this.getCreatedBy(item),
        location: item.locationId ? item.locationId.locationName : '',
        posted: item.posted ?? false,
      }))
    );
  };

  handleError = (errorMsg) => {
    const misMatchError = [errorMsg];
    this.setState({ misMatchError, isLoading: false });
  };

  generateTableDataSource(modifiedData) {
    const result = Object.values(modifiedData);
    let { data, columns } = this.generateDataSource(result);

    if (this.state.transactionColumn) {
      const transactionColumnConfig = {
        name: this.state.transactionColumn,
        selector: (row) => row[this.state.transactionColumn],
        sortable: false,
        omit: this.state.columnsPreference !== null ? this.state.columnsPreference['Posted'].omit : false,
        cell: (row, _index, _column, _id) => {
          return <CustomTransactionColumn row={row} markTransaction={this.markTransaction.bind(this)} />;
        },
      };

      columns.unshift(transactionColumnConfig);
    }

    this.setState({ data: data, columns: columns });
  }

  generateDataSource(result) {
    const { settings } = this.state.userDetails;
    const _params = {
      settings,
      isCustomer: true,
    };
    return generateDataSource(
      result,
      classes.Underline,
      this.handleChangeId.bind(this),
      this.state.invoiceLable,
      _params
    );
  }

  checkColumn(name, checkValue) {
    const columns = this.state.columns.map((col) => {
      if (col.name == name) {
        col['omit'] = !checkValue;
        return col;
      }
      return col;
    });
    this.setState({ columns: columns });
    //the inclusion and exclusion of columns is concluded here.
  }
  saveUserSelection() {
    const columnsPreferences = {};
    this.state.columns.forEach((col) => {
      if (col.name == this.state.invoiceLable) {
        columnsPreferences['Invoice'] = {
          omit: col.omit ?? false,
        };
        return;
      }
      if (col.name == this.state.data1[0]?.svcFeeName) {
        columnsPreferences['SVC_Fee'] = {
          omit: col.omit ?? false,
        };
      }
      if (col.name == this.state.transactionColumn) {
        columnsPreferences['Posted'] = {
          omit: col.omit ?? false,
        };
      } else {
        columnsPreferences[col.name.replaceAll(' ', '_')] = {
          omit: col.omit ?? false,
        };
      }
    });
    this.props.setViewPreferences(columnsPreferences);
  }
  markTransaction(row, markValue) {
    const index = this.state.data.findIndex((rec) => rec['Transaction ID'] == row['Transaction ID']);
    if (index !== -1) {
      const testData = [...this.state.data];
      const item = { ...testData[index], Posted: !row.Posted };
      testData[index] = item;
      this.setState({ data: [...testData] });
      this.props.markTransaction({ txnid: row['Transaction ID'], mark: markValue });
    }
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTypography: {
          root: {
            width: 'auto',
          },
        },
      },
    });
  removeCloseableMessage = (index) => {
    let misMatchError = this.state.misMatchError;
    delete misMatchError[index];
    this.setState({ misMatchError });
  };

  render() {
    const { data, columns } = this.state;
    const tableData = {
      columns,
      data,
      checkColumn: this.checkColumn.bind(this),
      saveUserSelection: this.saveUserSelection.bind(this),
      hideView: true,
    };
    const paginationComponentOptions = {
      rowsPerPageText: 'Rows Per Page',
      rangeSeparatorText: '-',
      selectAllRowsItem: true,
      selectAllRowsItemText: 'All rows',
    };

    if (this.props.UserDetail.companyId._id === '5f4fdbe29bfe9c0e243a7598') {
      columns.push(
        {
          title: 'Base amount',
          field: 'Base amount',
          render: (rowData) =>
            rowData.invoices !== null && rowData.invoices.FIRST_PENALTY_AMOUNT > 0
              ? '$' + parseFloat(rowData.invoices.FIRST_PENALTY_AMOUNT).toFixed(2)
              : '',
        },
        {
          title: 'Penalty amount',
          field: 'Penalty amount',
          render: (rowData) =>
            rowData.invoices !== null && rowData.invoices.FIRST_PENALTY_AMOUNT > 0
              ? parseFloat(rowData.invoices.amount) - parseFloat(rowData.invoices.FIRST_PENALTY_AMOUNT) > 0
                ? `$ ${parseFloat(
                    parseFloat(rowData.invoices.amount) - parseFloat(rowData.invoices.FIRST_PENALTY_AMOUNT)
                  ).toFixed(2)}`
                : parseFloat(
                    parseFloat(rowData.invoices.amount) - parseFloat(rowData.invoices.FIRST_PENALTY_AMOUNT)
                  ).toFixed(2)
              : '',
        },
        { title: 'Amount charged', field: 'amount' }
      );
    }

    const customStyles = {
      rows: {
        style: {
          borderBottomStyle: 'initial !important',
          borderBottomWidth: '0 !important',
          borderBottomColor: 'initial !important',
          ':hover div': {
            backgroundColor: '#EEEEEE',
          },
          div: {
            fontSize: '14px',
          },
        },
      },
      headCells: {
        style: {
          borderBottomStyle: 'solid',
          borderBottomWidth: '2px',
          borderBottomColor: 'rgba(0,0,0,.12)',
          div: {
            overflow: 'initial',
            whiteSpace: 'break-spaces',
            fontSize: '14px',
          },
        },
      },
      cells: {
        style: {
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: 'rgba(0,0,0,.12)',
          'div.parent:hover': {
            background: 'black',
          },
        },
      },
    };

    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
            <Stack sx={{ width: '100%' }} spacing={2}>
              {this.state.misMatchError.map((e, i) => (
                <CloseableAlerts key={i} index={i} message={e} severity="error" closer={this.removeCloseableMessage} />
              ))}
            </Stack>
            <Container component="main" maxWidth="xl" className="desktopPage">
              <RangedDatePicker
                id="ranged-date-picker"
                fromValue={this.state.fromDate}
                toValue={this.state.toDate}
                fromOnChange={(date) => {
                  this.handleDateChange(date, 'fromDate');
                }}
                toOnChange={(date) => {
                  this.handleDateChange(date, 'toDate');
                }}
                closeOnSelect
                format="MM/dd/yyyy"
                maxDate={this.todayDate}
              />
              <MuiThemeProvider theme={this.getMuiTheme()}>
                <DataTableExtensions {...tableData}>
                  <DataTable
                    columns={columns}
                    data={data}
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    highlightOnHover
                    print={true}
                    view={true}
                    customStyles={customStyles}
                  />
                </DataTableExtensions>
              </MuiThemeProvider>
            </Container>
          </LoadingOverlay>

          {this.props.UserDetail.companyId._id === '5f4fdbe29bfe9c0e243a7598' ? <NyTaxReports /> : ''}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    UserDetail: state.AdminUserReducer,
    LocationDetail: state.LocationReducer,
  };
}
PaymentHistory.propTypes = {
  UserDetail: PropTypes.any,
  LocationDetail: PropTypes.object,
  history: PropTypes.any,
  fetchUserPreferences: PropTypes.func,
  setViewPreferences: PropTypes.func,
  markTransaction: PropTypes.func,
  UserDetailById: PropTypes.func,
};

export default connect(mapStateToProps, { setViewPreferences, fetchUserPreferences, markTransaction, UserDetailById })(
  withTranslation()(PaymentHistory)
);
