import React, { useEffect, useState, useRef } from 'react';
import classes from './Payment.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Buttons } from '../../../components/Buttons';
import { SuccessMessage } from '../../../components/Message/SuccessMessage';
import config from '../../../constants/config';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LoadingOverlay from 'react-loading-overlay';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Stack from '@mui/material/Stack';
import { CloseableAlerts } from '../../../components/Message/CloseableAlerts';
import { useDispatch } from 'react-redux';
import validator from '../../../helpers/checkValidations';
import { useTranslation } from 'react-i18next';
import PaymentDetails from './components/PaymentDetails';
import CardForm from './components/CardForm.jsx';
import ACHForm from '../../../components/ACHForm/ACHForm';
import PrivacyStatement from './components/PrivacyStatement';
import CustomerForm from './components/CustomerForm';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { checkAddressEnabled } from '../../../helpers/functions/addressFieldsEnabled';
import AddressFieldsDropDown from '../../../components/AddressFieldsDropDown/AddressFieldsDropDown';
import { getLocationSettingsForSelfPayByLocId } from '../../../actions/LocationAction';
import { getFeeCalculations } from '../../../helpers/functions/feeCalculations';
import { getCardInfoViaBinLookup } from '../../../helpers/functions/checkCardType';
import { selfPayByLink } from '../../../actions/RequestFormAction';
import { getCsrfToken } from '../../../actions/PaymentAction';
import { maskCardNumber, getCustomInvoiceName } from '../../../helpers/commonFunction';
import Topheader from '../../../components/Topheader/Topheader.js';
import { whichLocationSelected } from '../../../helpers/getLocationFromLocalStorage.js';
import { CustomerDetailsId } from '../../../actions/CustomerAction.js';
import PaymentConfirmation from '../../Payment/components/PaymentConfirmation.jsx';

const QuickPay = () => {
  const { t } = useTranslation();
  const loc = whichLocationSelected();
  const locationId = loc.id;

  //QuickPay Component States
  const [csrf, setCsrf] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState('card');
  const [misMatchError, setMisMatchError] = useState([]);
  const [successMessage, setSuccessMessage] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [disabledMultipassSubmit, disableMultipassSubmit] = useState(true);
  const [location, setLocation] = useState({});
  const [paytraceCredentials, setPaytraceCredentials] = useState({
    paytraceKey: '',
    userName: '',
    password: '',
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({
    //#Customer Errors
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    amountError: false,
    invoiceError: false,
    //#CardForm Errors
    cardNumberError: false,
    cardExpiryDateError: false,
    cardCscError: false,
    billingAddressError: false,
    shippingAddressError: false,
    zipError: false,
  });
  const [locationSettings, setLocationSettings] = useState({});
  const [termsAndCondition, setTermsAndConditions] = useState({});
  const initialAddressFields = {
    addressOne: '',
    addressTwo: '',
    state: '',
    zip: '',
    city: '',
  };
  const [billingAddress, setBillingAddress] = useState(initialAddressFields);
  const [shippingAddress, setShippingAddress] = useState(initialAddressFields);
  const [state, setState] = useState(null);
  const [paymentType, setPaymentType] = useState('');
  const [cardMerchantType, setCardMerchantType] = useState('');
  const [creditOrDebit, setCreditOrDebit] = useState('');
  //ACHForm States
  const [ach, setAch] = useState({
    isOpen: false,
    accountName: '',
    accountNumber: '',
    routingNumber: '',
  });

  //CardForm states
  const [card, setCard] = useState({
    isOpen: false,
    cardNumber: '_______________',
    cardExpiryDate: '__/____',
    cardCsc: '___',
    customerToken: false,
    customerTokenUpdate: false,
    encryptedNumber: '',
    encryptedCsc: '',
    cardType: '',
  });

  const [americanExpress, setAmericanExpress] = useState(false);

  //CustomerForm States
  const [customerData, setCustomerData] = useState({});

  //PaymentDetails States
  const [invoice, setInvoice] = useState('');
  const [amount, setAmount] = useState(0);
  const [cardSubTotal, setCardSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [fees, setFees] = useState({
    svcFee: 0,
    tax: 0,
    surcharge: 0,
  });
  const [isStoredCard, setStoredCard] = useState(false);
  const dispatch = useDispatch();
  const [paymentResponse, setPaymentResponse] = useState('');

  const achFormValidationRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([dispatch(getLocationSettingsForSelfPayByLocId(locationId)), dispatch(getCsrfToken())])
      .then((results) => {
        const locationSettingsResponse = results[0].payload.locationData.data.response;
        setLocation(locationSettingsResponse.location);
        if (locationSettingsResponse.location?.gateway == 'multipass') {
          setIsSubmit(true);
        }
        setLocationSettings(locationSettingsResponse.settings);
        setTermsAndConditions(locationSettingsResponse.termsAndConditions);
        setPaytraceCredentials({
          paytraceKey: locationSettingsResponse.location.paytraceKey,
          userName: locationSettingsResponse.location.paytraceUserName,
          password: locationSettingsResponse.location.paytracePassword,
        });
        setCsrf(results[1]?.payload?.paymentData?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
    calculateFeesBySettings(locationSettings);
    getCustomerDetails();
  }, [locationId]);

  useEffect(() => {
    calculateFeesBySettings(locationSettings);
  }, [amount, selectedOption, card, state, paymentType]);

  const calculateFeesBySettings = (locationSettings) => {
    let { virtualTerminalSettings, applyTaxLast } = locationSettings;
    //the following function doesn't handle case where paymentType is ACH so we manually minus the SVC fee from total here in case of ACH
    const calculations = getFeeCalculations(
      { virtualTerminalSettings, applyTaxLast },
      amount,
      paymentType,
      billingAddress.state
    );
    let totalAmount = calculations.totalAmount;
    setFees((prevState) => ({
      ...prevState,
      tax: calculations.salesTaxFee,
      svcFee: selectedOption === 'card' ? calculations.svcFee : 0,
      surcharge: calculations.surchargeFee,
    }));
    if (selectedOption !== 'card') {
      totalAmount = calculations.totalAmount - calculations.svcFee;
    }
    const calCardSubTotal = totalAmount - calculations.salesTaxFee - calculations.surchargeFee;
    setCardSubTotal(calCardSubTotal);
    setTotal(totalAmount);
  };

  useEffect(() => {
    if (paytraceCredentials?.paytraceKey) {
      const localUrl = config.BASEURL + 'getPemFile?fileName=';
      window.paytrace.setKeyAjax(localUrl + paytraceCredentials?.paytraceKey);
    }
  }, [paytraceCredentials]);

  useEffect(() => {
    /*
      since multipass card tokenized information is set in state from with in the child component therefore
      card object doesn't have a plain card number here but a tsep token that's why card number is not lookedup with 
      a binlook up for multipass here as it is already done with in the cardForm component for multipass
    */
    if (
      location?.gateway !== 'multipass' &&
      (!card.cardNumber.slice(0, 6).includes('_') || card.cardNumber.replace(/_/g, '').length >= 6)
    ) {
      try {
        getCardInfoViaBinLookup(card.cardNumber).then((cardInfo) => {
          setCardMerchantType(cardInfo?.data?.card);
          setPaymentType(cardInfo?.data?.type);
        });
      } catch (e) {
        console.log('Error', e);
      }
    }
  }, [card]);

  const getCustomerDetails = async () => {
    const userDetails = JSON.parse(localStorage.getItem('user'));
    dispatch(CustomerDetailsId({ id: userDetails.customerId ?? process.env.CUSTOMER_TEMP_ID })).then((result) => {
      if (result.payload.customerData.data.statusCode === 200) {
        const { firstName, lastName, masked_card_number } = result.payload.customerData.data.response;
        setCustomerData({ ...result.payload.customerData.data.response, name: `${firstName} ${lastName}` });
        setBillingAddress(result.payload.customerData.data.response.billingAddress);
        setShippingAddress(result.payload.customerData.data.response.shippingAddress);
        setPaymentType(result.payload.customerData.data.response?.creditOrDebit);
        if (masked_card_number && masked_card_number !== '') {
          setStoredCard(true);
        }
      }
    });
  };

  const handleAddressChange = (event, type, isBillingAddressSameAsShippingAddress = false) => {
    let errors = { ...error };
    let misMatchErrors = [];
    let { id, value } = event.target;

    if (type === 'billingAddress') {
      setBillingAddress((prevInfo) => ({
        ...prevInfo,
        [id]: value,
      }));
      if (id === 'state') {
        setState(value);
      }
    }
    if (type === 'shippingAddress' || isBillingAddressSameAsShippingAddress) {
      setShippingAddress((prevInfo) => ({
        ...prevInfo,
        [id]: value,
      }));
    }
    setError(errors);
    setMisMatchError(misMatchErrors);
    setIsSubmit(false);
  };

  const handleIsBillingAddressSameAsShippingAddress = (isBillingAddressSameAsShippingAddress = false) => {
    if (isBillingAddressSameAsShippingAddress) {
      setShippingAddress((prevInfo) => ({
        ...prevInfo,
        ...billingAddress,
      }));
    } else {
      setShippingAddress(initialAddressFields);
    }
  };
  const handleChangeOption = (event) => {
    const option = event.target.value;
    if (option === 'bankTransfer') {
      setPaymentType('ACH');
    } else {
      setPaymentType(option);
    }
    setSelectedOption(option);
  };

  const handleChange = (event, formattedValue) => {
    event.preventDefault();
    let errors = {};
    let { id, value } = event.target;
    if (id === 'amount') {
      if (formattedValue.length >= 13) {
        return;
      }
      value = value.replace(/[^\d.]+/g, '');

      if (!isNaN(value)) {
        const floatValue = parseFloat(value);
        const isWholeNumber = floatValue % 1 === 0;
        const finalValue = isWholeNumber ? parseInt(value) : floatValue;
        setAmount(finalValue);
      }
      errors[id + 'Error'] = validator(id, value);
      setError(errors);
    }
    // const customerFormIds = ['address', 'firstName', 'lastName', 'email', 'zip'];
    // errors[id + 'Error'] = validator(id, value);
    // setError(errors);
    // if (customerFormIds.includes(id)) {
    //   setCustomerData((prevInfo) => ({
    //     ...prevInfo,
    //     [id]: value,
    //   }));
    // }
    if (id === 'amount') {
      if (formattedValue.length >= 13) {
        return;
      }
      value = value.replace(/[^\d.]+/g, '');

      if (!isNaN(value)) {
        setAmount(parseFloat(value));
      }
      errors[id + 'Error'] = validator(id, value);
      setError(errors);
    }
    if (id === 'invoice') {
      setInvoice(value);
    }
  };
  const handleChangeCustomerEmail = (event) => {
    let { id, value } = event.target;
    let errors = {};
    if (value !== '' && value !== null && value !== undefined) {
      errors[id + 'Error'] = validator(id, value);
      setError(errors);
    }
    setCustomerData({
      ...customerData,
      email: value,
    });
  };
  const handleChangeCustomer = (event) => {
    let errors = { ...error };
    let { id, value } = event.target;
    errors[id + 'Error'] = validator(id, value);

    // setCustomerData((prevCustomerData) => ({
    //   ...prevCustomerData,
    //   [id]: value,
    // }));
    // setError(errors);
    // setMisMatchError(misMatchErrors);
    // setIsSubmit(false);
  };
  const handleChangeAch = (event, value) => {
    let errors = { ...error };
    let misMatchErrors = [];
    let { id } = event.target;
    errors[id + 'Error'] = validator(id, value);

    setAch((prevAch) => ({
      ...prevAch,
      [id]: value,
      isOpen: selectedOption === 'bankTransfer',
    }));
    setError(errors);
    setMisMatchError(misMatchErrors);
    setIsSubmit(false);
  };

  const handleChangeCard = (event) => {
    let errors = { ...error };
    let misMatchError = [];
    const cardFormFields = ['cardNumber', 'cardExpiryDate', 'cardCsc'];

    let { id, value } = event.target;
    errors[id + 'Error'] = validator(id, value);

    if (cardFormFields.includes(id)) {
      if (id === 'cardNumber') {
        if (value[0] === '3' && value.length === 16 && value[value.length - 1] === '_') {
          value = value.slice(0, -1);
        }
      }
      setCard((prevCard) => ({
        ...prevCard,
        [id]: value,
        customerToken: true,
        isOpen: selectedOption === 'card',
        cardType: cardMerchantType,
      }));
      let firstNumber = card.cardNumber;
      if (firstNumber[0] === '3') {
        setAmericanExpress(true);
      } else {
        setAmericanExpress(false);
      }
    }
    setError(errors);
    setMisMatchError(misMatchError);
    setIsSubmit(false);
  };
  const removeCloseableMessage = (index) => {
    setMisMatchError((prevMisMatchError) => {
      return prevMisMatchError.filter((_, i) => i !== index);
    });
  };
  const checkCardValues = () => {
    if (selectedOption !== 'card') {
      return true;
    }
    if (isStoredCard) {
      return true;
    }
    const updatedMisMatchError = [];
    if (card.cardNumber.indexOf('_') > -1 || card.cardNumber === '') {
      setError((prevErrorCard) => ({ ...prevErrorCard, cardNumberError: true }));
      updatedMisMatchError.push('Enter Card Number');
    }
    if (card.cardExpiryDate.indexOf('_') > -1 || card.cardExpiryDate === '') {
      setError((prevErrorCard) => ({ ...prevErrorCard, cardExpiryDateError: true }));
      updatedMisMatchError.push('Enter Card Expiry Date');
    }
    if (card.cardCsc.indexOf('_') > -1 || card.cardCsc === '') {
      setError((prevErrorCard) => ({ ...prevErrorCard, cardCscError: true }));
      updatedMisMatchError.push('Enter CVV');
    }
    if (updatedMisMatchError.length > 0) {
      setMisMatchError(updatedMisMatchError);
      return false;
    }
    return true;
  };

  const checkCustomerFormValues = () => {
    let updatedMisMatchError = [];
    if (isNaN(amount) || amount === 0) {
      setError((prevError) => ({ ...prevError, amount: true }));
      updatedMisMatchError.push('Enter Amount');
    } else {
      setError((prevError) => ({ ...prevError, amount: false }));
      setMisMatchError([]);
    }
    if (customerData.firstName === '') {
      setError((prevError) => ({ ...prevError, firstNameError: true }));
      updatedMisMatchError.push('Enter First Name');
    } else {
      setError((prevError) => ({ ...prevError, firstNameError: false }));
      setMisMatchError([]);
    }
    if (customerData.email === '' || validator('email', customerData.email)) {
      setError((prevError) => ({ ...prevError, firstNameError: true }));
      updatedMisMatchError.push('Enter Valid Email');
    } else {
      setError((prevError) => ({ ...prevError, firstNameError: false }));
      setMisMatchError([]);
    }
    if (invoice === '') {
      setError((prevError) => ({ ...prevError, invoiceError: true }));
      updatedMisMatchError.push('Enter Invoice Number');
    } else {
      setError((prevError) => ({ ...prevError, invoiceError: false }));
      setMisMatchError([]);
    }
    if (updatedMisMatchError.length > 0) {
      setMisMatchError(updatedMisMatchError);
      return false;
    } else {
      return true;
    }
  };

  const checkBillingAndShippingAddress = () => {
    let updatedMisMatchError = [];
    const checkRequiredField = (settings, address, errorKey, errorMessage) => {
      if (settings && address === '') {
        setError((prevError) => ({ ...prevError, [errorKey]: true }));
        updatedMisMatchError.push(errorMessage);
      }
    };
    const billingSettings = locationSettings?.invoiceSettings?.billingAddress;
    const shippingSettings = locationSettings?.invoiceSettings?.shippingAddress;

    if (billingSettings?.isBillingAddressRequired) {
      checkRequiredField(
        billingSettings.addressOne,
        billingAddress.addressOne,
        'billingAddressError',
        'Billing Address: Address One is Required'
      );
      checkRequiredField(
        billingSettings.addressTwo,
        billingAddress.addressTwo,
        'billingAddressError',
        'Billing Address: Address Two is Required'
      );
      checkRequiredField(
        billingSettings.city,
        billingAddress.city,
        'billingAddressError',
        'Billing Address: City is Required'
      );
      checkRequiredField(
        billingSettings.state,
        billingAddress.state,
        'billingAddressError',
        'Billing Address: State is Required'
      );
      if (billingSettings.isZipRequired) {
        checkRequiredField(
          billingSettings.zip,
          billingAddress.zip,
          'billingAddressError',
          'Billing Address: Zip is Required'
        );
      }
    }
    if (shippingSettings?.isShippingAddressRequired) {
      checkRequiredField(
        shippingSettings.addressOne,
        shippingAddress.addressOne,
        'shippingAddressError',
        'Shipping Address: Address One is Required'
      );
      checkRequiredField(
        shippingSettings.addressTwo,
        shippingAddress.addressTwo,
        'shippingAddressError',
        'Shipping Address: Address Two is Required'
      );
      checkRequiredField(
        shippingSettings.city,
        shippingAddress.city,
        'shippingAddressError',
        'Shipping Address: City is Required'
      );
      checkRequiredField(
        shippingSettings.state,
        shippingAddress.state,
        'shippingAddressError',
        'Shipping Address: State is Required'
      );
      if (shippingSettings.isZipRequired) {
        checkRequiredField(
          shippingSettings.zip,
          shippingAddress.zip,
          'shippingAddressError',
          'Shipping Address: Zip is Required'
        );
      }
    }
    if (updatedMisMatchError.length > 0) {
      setMisMatchError(updatedMisMatchError);
      return false;
    } else {
      return true;
    }
  };

  const collectAllMisMatchError = (res, misMatchError) => {
    if (res.payload.error.data.response) {
      let responseErrors = res.payload.error.data.response;
      if (responseErrors.response_code === 1) {
        for (let key in responseErrors.errors) {
          let i = 0;
          misMatchError.push(responseErrors.errors[key][i] + '\n');
        }
      }
      if (responseErrors.response_code === 1001) {
        misMatchError.push(responseErrors.status_message);
      }

      setMisMatchError(misMatchError);
    }
  };

  const checkIsValid = () => {
    let isValid = true;

    if (paymentType === 'ACH') {
      isValid = achFormValidationRef.current && achFormValidationRef.current.validate();
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid =
      checkCustomerFormValues() && checkCardValues() && checkBillingAndShippingAddress() && checkIsValid();
    if (!isValid) {
      setIsSubmit(false);
      window.scrollTo(0, 0);
      return;
    }

    setIsLoading(true);
    setIsSubmit(true);
    if (location?.gateway !== 'multipass') {
      window.paytrace.submitEncrypted('#DemoForm');
    }

    const encryptedNumber = localStorage.getItem('0');
    const encryptedCsc = localStorage.getItem('1');
    const masked_card_number = maskCardNumber(card.cardNumber);

    const data = {
      card: {
        isOpen: card.isOpen,
        masked_card_number,
        encryptedNumber: selectedOption === 'card' ? encryptedNumber : null,
        encryptedCsc: selectedOption === 'card' ? encryptedCsc : null,
        cardExpiryDate: card.cardExpiryDate,
        cardType: isStoredCard ? customerData.cardType : card.cardType,
      },
      billingAddress,
      shippingAddress,
      customerData,
      customerId: customerData?._id,
      ach,
      paymentDetails: {
        invoice,
        amount,
        cardSubTotal,
        total,
        fees,
      },
      paymentType: isStoredCard ? customerData.creditOrDebit : paymentType,
      creditOrDebit,
      locationId: locationId,
      companyId: location.company,
      company: { _id: location.company },
      isSelfPay: true,
      isOneTimeCustomer: false,
      encrypted_number: selectedOption === 'card' ? encryptedNumber : null,
      encrypted_csc: selectedOption === 'card' ? encryptedCsc : null,
      FRONT_END: 'Customer Portal',
      locationSettings,
      isStoredCard,
      isQuickPay: true,
    };

    if (paymentType === 'ACH') {
      data.paymentType = paymentType;
    }

    dispatch(selfPayByLink(data, csrf, paytraceCredentials))
      .then((res) => {
        if (res !== undefined && res?.type === 'INVOICE_SUCCESS') {
          setSuccess(true);
          setPaymentResponse({
            transactionId:
              res?.payload?.invoiceData?.data?.response?.transaction_id ||
              res?.payload?.invoiceData?.data?.response?.check_transaction_id,
            date: new Date(),
            maskCard: res?.payload?.invoiceData?.data?.response?.masked_card_number || customerData.masked_card_number,
          });
          setIsLoading(false);
          setSuccessMessage(res?.payload?.invoiceData?.data?.message);
          //  history.push('/Customer/Payment/History');
        } else if (res !== undefined && res.type === 'INVOICE_ERROR') {
          if (res?.payload?.error.data) {
            const misMatchError = [];
            let message = res?.payload?.error?.data?.message;
            misMatchError.push(message);
            collectAllMisMatchError(res, misMatchError);
            setSuccess(false);
            setIsLoading(false);
            setIsSubmit(false);
            setSuccessMessage([]);
            setPaymentResponse({});
          }
        } else {
          setSuccess(false);
          setIsLoading(false);
          setIsSubmit(false);
          setSuccessMessage([]);
          setIsSubmit(false);
          setPaymentResponse({});
        }
      })
      .catch((err) => {
        setSuccess(false);
        setIsLoading(false);
        setSuccessMessage([]);
        setMisMatchError(err);
      });
  };

  const getBannerText = () => {
    return <p className={classes.bannerText}>{termsAndCondition?.selfPaymentBanner}</p>;
  };
  const handleCard = () => {
    setStoredCard(false);
    setPaymentType(null);
  };
  return (
    <div className="rightPanel">
      <Topheader />
      <div className="contentPart"></div>
      <Container component="main" maxWidth="xl" className="desktopPage">
        {success ? <SuccessMessage successes={[successMessage]} /> : ''}
        <div className="min-wrap pay-page desktopPage">
          <Stack sx={{ width: '100%' }} spacing={2}>
            {misMatchError.map((e, id) => (
              <CloseableAlerts
                key={id}
                index={id}
                message={e}
                severity="error"
                closer={removeCloseableMessage}
                sx={{ width: '100%', marginBottom: 2 }}
              />
            ))}
          </Stack>
          <LoadingOverlay className={classes.tpTableFullW} active={isLoading} spinner text="Fetching your response ...">
            {success ? (
              <PaymentConfirmation
                invoice={invoice}
                locationSettings={locationSettings?.virtualTerminalSettings}
                customerData={customerData}
                amount={amount}
                total={total}
                fees={fees}
                selectedOption={selectedOption}
                card={
                  isStoredCard
                    ? {
                        masked_card_number: customerData.masked_card_number,
                        cardType: card.cardType || customerData.cardType,
                      }
                    : card
                }
                paymentType={paymentType}
                location={{
                  locationName: location?.locationName,
                  customInvoiceName: getCustomInvoiceName(location),
                  image: location.image,
                }}
                ach={ach}
                paymentResponse={paymentResponse}
              />
            ) : (
              <>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item md={8} lg={6}>
                    <div style={{ marginLeft: '23px', marginTop: '-30px', marginBottom: '10px' }}>
                      <h3 className="terminal-title">Make a quick payment</h3>
                    </div>
                    <Card>
                      <CardContent>
                        <div className="terminal-inner">
                          <Grid container className="terminal-title-grid" spacing={3} alignItems="center">
                            {termsAndCondition?.isSelfPaymentPageBannerEnabled && (
                              <Grid item sm={12}>
                                <div className="borderd">{getBannerText()}</div>
                              </Grid>
                            )}
                          </Grid>
                          <form className={classes.form} id="DemoForm">
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Grid container>
                                  <CustomerForm
                                    invoice={invoice}
                                    amount={amount}
                                    customerData={customerData}
                                    handleChangeCustomer={handleChangeCustomer}
                                    handleChangeCustomerEmail={handleChangeCustomerEmail}
                                    handleChange={handleChange}
                                    error={error}
                                  />
                                  <Grid container spacing={3} justifyContent="center">
                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                      <RadioGroup
                                        row
                                        aria-label="payment-options"
                                        name="payment-options"
                                        value={selectedOption}
                                        onChange={handleChangeOption}
                                      >
                                        <FormControlLabel
                                          value="card"
                                          control={<Radio color="primary" />}
                                          label="Card"
                                        />
                                        {location.ACH && (
                                          <FormControlLabel
                                            value="bankTransfer"
                                            control={<Radio color="primary" />}
                                            label="Bank Transfer"
                                          />
                                        )}
                                      </RadioGroup>
                                    </Grid>
                                    <Grid item xs={12} style={{ marginBottom: '10px' }}>
                                      {selectedOption === 'card' && (
                                        <CardForm
                                          error={error}
                                          handleChangeCard={handleChangeCard}
                                          americanExpress={americanExpress}
                                          card={card}
                                          gateway={location?.gateway}
                                          location={location}
                                          setCard={setCard}
                                          setPaymentType={setPaymentType}
                                          setIsSubmit={setIsSubmit}
                                          disableMultipassSubmit={disableMultipassSubmit}
                                          setCreditOrDebit={setCreditOrDebit}
                                          customerData={customerData}
                                          isStoredCard={isStoredCard}
                                          handleCard={handleCard}
                                        />
                                      )}
                                      {selectedOption === 'bankTransfer' && (
                                        <ACHForm
                                          t={t}
                                          ach={ach}
                                          handleChangeAch={(e, val) => handleChangeAch(e, val)}
                                          location={location}
                                          ref={achFormValidationRef}
                                        />
                                      )}
                                    </Grid>
                                    <Grid item xs={12} style={{ marginBottom: '10px' }}>
                                      {checkAddressEnabled(locationSettings?.invoiceSettings?.billingAddress) && (
                                        <Grid item xs={12} sm={12} md={12} style={{ marginBottom: '20px' }}>
                                          <AddressFieldsDropDown
                                            type="billing"
                                            state={{
                                              addressSettings: {
                                                billingAddress: locationSettings?.invoiceSettings?.billingAddress,
                                                shippingAddress: locationSettings?.invoiceSettings?.shippingAddress,
                                              },
                                              billingAddress,
                                            }}
                                            handleChange={handleAddressChange}
                                            handleSameAddress={handleIsBillingAddressSameAsShippingAddress}
                                          />
                                        </Grid>
                                      )}
                                      {checkAddressEnabled(locationSettings?.invoiceSettings?.shippingAddress) && (
                                        <Grid item xs={12} sm={12} md={12} style={{ marginBottom: '20px' }}>
                                          <AddressFieldsDropDown
                                            type="shipping"
                                            state={{
                                              addressSettings: {
                                                billingAddress: locationSettings?.invoiceSettings?.billingAddress,
                                                shippingAddress: locationSettings?.invoiceSettings?.shippingAddress,
                                              },
                                              shippingAddress,
                                            }}
                                            handleChange={handleAddressChange}
                                            handleSameAddress={handleIsBillingAddressSameAsShippingAddress}
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item md={12} style={{ marginBottom: '20px', textAlign: 'center' }}>
                                  <Buttons
                                    startIcon={<LockOutlinedIcon />}
                                    id="signUp"
                                    type="button"
                                    fullWidth={true}
                                    variant="contained"
                                    color="primary"
                                    className={classes.payBtn}
                                    onClick={handleSubmit}
                                    disabled={location?.gateway !== 'multipass' ? isSubmit : disabledMultipassSubmit}
                                    text={'Pay'}
                                  />
                                </Grid>
                                <PrivacyStatement
                                  activeTab={selectedOption}
                                  locationId={location}
                                  total={total}
                                  amount={amount}
                                  paymentTerms={termsAndCondition}
                                  isLoading={setIsLoading}
                                />
                              </Grid>
                            </Grid>
                          </form>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <PaymentDetails
                    ButtonText={'Payment Details'}
                    invoice={invoice}
                    locationSettings={locationSettings}
                    customerData={customerData}
                    amount={amount}
                    cardSubTotal={cardSubTotal}
                    total={total}
                    fees={fees}
                    selectedOption={selectedOption}
                    card={card}
                    paymentType={paymentType}
                  />
                </Grid>
              </>
            )}
          </LoadingOverlay>
        </div>
      </Container>
    </div>
  );
};

export default QuickPay;
