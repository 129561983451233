import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Topheader } from './../../components/Topheader';
import { getInvoiceList } from '../../actions/PaymentAction';
import { fetchDetails } from '../../actions/AdminUserAction';
import LoadingOverlay from 'react-loading-overlay';
import { Table } from '../../components/Table';
import classes from './Invoice.module.scss';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { uploadBulkInvoices } from '../../actions/RequestFormAction';
import config from '../../constants/config';
import { DataGetApi } from '../../helpers/PostDataApi';
import { formatCurrency } from '../../helpers/commonFunction';
import { ErrorMessage } from '../../components/Message/ErrorMessage';
import { SuccessMessage } from '../../components/Message/SuccessMessage';
import BulkInvoices from '../../components/BulkInvoices';
import { whichLocationSelected } from '../../helpers/getLocationFromLocalStorage';
import Box from '@material-ui/core/Box';
import { Modal } from '@mui/material';
import { Buttons } from './../../components/Buttons';
import { Close } from '@mui/icons-material';
import Typography from '@material-ui/core/Typography';
import sampleBulkInvoice from './sampleBulkInvoice';
import { PAYMENT_STATUS_BUTTON_CONFIG, customFilterAndSearch } from '../../helpers/InvoiceHelper';

class Invoice extends Component {
  constructor(props) {
    const date = new Date();
    super(props);
    this.state = {
      sideShow: true,
      isLoading: false,
      reload: false,
      misMatchError: [],
      userData: { permissions: {} },
      userDetails: { permissions: {} },
      toDate: new Date(),
      fromDate: new Date(date.setDate(date.getDate() - 45)),
      invoiceLabel: localStorage.getItem('invoiceLabel'),
      bulkInvoicesModal: false,
      customFieldsEnabled: false,
      locationId: '',
      selfPayLink: '',
      showSelfPayLink: true,
      invoiceData: [],
      tempInvoices: [],
      isModalOpen: false,
      isUploadble: false,
      validInvoices: [],
      validationErrors: [],
      successMessages: '',
      errorMessages: '',
      locationGateway: '',
    };
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.getData().then((result) => this.setState({ invoiceData: result?.data }));
  }

  UNSAFE_componentWillMount() {
    let user = JSON.parse(localStorage.getItem('user'));
    const locationDetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    this.setState({
      locationId: locationDetails?.id,
    });
    this.setState({
      locationGateway: locationDetails?.gateway,
    });
    if (locationDetails?.customFieldsEnabled) {
      const jobIdCustomField = locationDetails?.customFields?.find((cf) => cf && cf?.enabled);
      if (jobIdCustomField && jobIdCustomField?.enabled) {
        this.setState({
          invoiceLabel: jobIdCustomField.value,
          customFieldsEnabled: true,
        });
      }
    }
    let data = fetchDetails({ id: user._id });
    data.then((res) => {
      if (res.type === 'FETCH_SUCCESS' && this.props.userInfo.userId === '') {
        this.setState({ userData: { ...res.payload.userData } }, () => {});
      }

      if (res.type === 'FETCH_SUCCESS') {
        this.setState({ userDetails: { ...res.payload.userData } }, () => {});
      }
    });
  }
  UNSAFE_componentWillReceiveProps() {
    const currentLocation = whichLocationSelected();
    const jobIdCustomField = currentLocation?.customFields?.find((field) => field?.enabled);
    if (jobIdCustomField && currentLocation?.customFieldsEnabled) {
      this.setState({ invoiceLabel: jobIdCustomField.value, customFieldsEnabled: true });
    }
    // if (nextProps.locationCurrent?.currentLocation?.customFieldsEnabled) {
    //   const jobIdCustomField = nextProps.locationCurrent?.currentLocation?.customFields?.find(
    //     (field) => field && field?.enabled
    //   );
    //   if (jobIdCustomField && jobIdCustomField?.enabled) {
    //     this.setState({ invoiceLabel: jobIdCustomField.value, customFieldsEnabled: true });
    //     this.forceUpdate();
    //     const customFiledsReceivedEvent = new CustomEvent('reloadData', {
    //       detail: true,
    //     });
    //     window.dispatchEvent(customFiledsReceivedEvent);
    //   }
    // }
  }

  generateSelfPayLink = () => {
    const selfPayLink = `${window.location.origin}/selfPayment/${this.state.locationId}`;
    this.setState({
      selfPayLink,
      showSelfPayLink: false,
    });
  };

  toDateFilter(date) {
    const query = { toDate: date };
    this.getData(query).then((result) => {
      this.setState({ invoiceData: result?.data, toDate: date });
    });
  }
  fromDateFilter(date) {
    const query = { fromDate: date };
    this.getData(query).then((result) => {
      this.setState({ invoiceData: result?.data, fromDate: date });
    });
  }
  handleChangeId(invoice, status, id, rowData) {
    const locationDetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    if (rowData?.submitedToPax) {
      this.props.history.push('/EditInvoices/' + id);
    } else if (locationDetails?.customTowingBuild) {
      this.props.history.push('/InvoiceEdit/' + id);
    } else if (status || rowData.STATUS === 'Deleted' || _.lowerCase(rowData.STATUS) === 'paid') {
      this.props.history.push('/EditInvoices/' + id);
    } else if (
      status ||
      _.lowerCase(rowData.STATUS) === 'pending' ||
      _.lowerCase(rowData.STATUS) === 'pastdue' ||
      _.lowerCase(rowData.STATUS) === 'in queue'
    ) {
      if (rowData?.email === '') {
        delete rowData.email;
      }
      if (rowData?.lastName === '') {
        delete rowData.lastName;
      }
      this.props.history.push({ pathname: '/AddInvoices', state: { rowData } });
    } else {
      this.props.history.push('/Terminal/?' + id);
    }
  }

  tableReload(status) {
    this.setState({ reload: status });
  }

  getPaymentStatus(invoice) {
    const [dueDateMonth, dueDateDay, dueDateYear] = new Date(invoice.dueDate).toLocaleDateString('en-US').split('/');
    const dueDate = new Date(dueDateYear, parseInt(dueDateMonth) - 1, dueDateDay);
    dueDate.setHours(23, 59, 59);
    const isPastDueDate = dueDate < new Date();
    const isPending = _.lowerCase(invoice.STATUS) === 'pending';
    if (isPending && isPastDueDate) {
      invoice.STATUS = 'PASTDUE';
      return invoice.STATUS;
    }
    return invoice.STATUS;
  }

  getPaymentType(result) {
    if (result.CARDTYPE === 'ACH' || result.RESPONSECODE === '120' || result?.achDetails?.isOpen) {
      return 'ACH';
    }
    if (result?.CASH) {
      return 'Cash';
    }
    if (result.creditOrDebit !== null && result.creditOrDebit !== '' && typeof result.creditOrDebit === 'string') {
      return _.capitalize(result.creditOrDebit);
    }
    if (result?.creditOrDebit === '') {
      return 'N/A';
    }
    if (result?.submitedToPax && result.creditOrDebit === null) {
      return 'N/A';
    }
    return 'N/A';
  }

  getFirstName(item) {
    let firstName = '';
    if (item?.isOneTimeCustomer && item?.submitedToPax) {
      firstName = item?.oneTimeCustomerDetails?.firstName;
    } else if (item?.firstName) {
      firstName = item.firstName;
    } else {
      firstName = item.customerId?.firstName ?? item?.payerName?.split(' ')[0] ?? item?.field1?.value;
    }
    return firstName;
  }
  getLastName(item) {
    let lastName = '';
    if (item?.isOneTimeCustomer && item?.submitedToPax) {
      lastName = item?.oneTimeCustomerDetails?.lastName;
    } else if (item?.lastName) {
      lastName = item.lastName;
    } else {
      lastName = item.customerId?.lastName ?? item?.payerName?.split(' ')[0] ?? item?.field1?.value;
    }
    return lastName;
  }
  getData(query) {
    const state = this.state ? this.state : query.props.componentState;
    return new Promise((resolve, _reject) => {
      let userDetails = JSON.parse(localStorage.getItem('user'));
      let locationID = localStorage.getItem('locationArrIds');
      let token = userDetails.token;
      let url = config.BASEURL;
      let param = {
        locationIds: locationID,
        role: userDetails.role,
        userId: userDetails._id,
        companyId: userDetails.company._id,
        toDate: query?.toDate ? query.toDate : state.toDate,
        fromDate: query?.fromDate ? query.fromDate : state.fromDate,
        permissions: userDetails.permissions,
      };
      DataGetApi(url + 'getInvoiceList', param, token).then((response) => {
        let modifiedData1 = [];
        modifiedData1 = response.data?.response?.map((item) => ({
          ...item,
          location: item.locationId ? item.locationId.locationName : '',
          firstName: item.firstName
            ? item.firstName
            : item?.customerId?.firstName ??
              item?.payerName?.split(' ')[0] ??
              item?.field1?.value ??
              item?.oneTimeCustomerDetails?.firstName,
          lastName: item.lastName
            ? item?.lastName
            : item?.customerId?.lastName ??
              item?.payerName?.split(' ')[1] ??
              item?.field2?.value ??
              item?.oneTimeCustomerDetails?.lastName,
          paymentStatus: this.getPaymentStatus(item),
          STATUS: this.getPaymentStatus(item),
          paymentType: this.getPaymentType(item),
        }));
        resolve({
          data: modifiedData1,
        });
      });
    });
  }

  getExportData(query) {
    const state = this.state ? this.state : query.props.componentState;
    let orderBy = query.orderBy !== undefined ? query.orderBy.field : '';
    let orderDirection = query.orderDirection !== '' ? query.orderDirection : '';
    return new Promise((resolve, _reject) => {
      let user = JSON.parse(localStorage.getItem('user'));
      let locationId = localStorage.getItem('locationArrIds');
      let token = user.token;
      let url = config.BASEURL;
      let param = {
        locationIds: locationId,
        role: user.role,
        userId: user._id,
        companyId: user.company._id,
        per_page: query.pageSize,
        page: +(query.page + 1),
        searchAll: typeof query.search === 'undefined' || query.search === '' ? false : true,
        toDate: query.toDate ? query.toDate : state.toDate,
        fromDate: query.toDate ? query.fromDate : state.fromDate,
        orderBy: orderBy,
        orderDirection: orderDirection,
        permissions: user.permissions,
      };
      DataGetApi(url + 'getExportList', param, token).then((result) => {
        let modifiedData = [];
        modifiedData = result.data.response.map((item) => ({
          ...item,
          location: item.locationId ? item.locationId.locationName : '',
          firstName: item.firstName
            ? item.firstName
            : item?.customerId?.firstName ??
              item?.payerName?.split(' ')[0] ??
              item?.field1?.value ??
              item?.oneTimeCustomerDetails?.firstName,
          lastName: item.lastName
            ? item?.lastName
            : item?.customerId?.lastName ??
              item?.payerName?.split(' ')[1] ??
              item?.field2?.value ??
              item?.oneTimeCustomerDetails?.lastName,
          paymentStatus: this.getPaymentStatus(item),
          STATUS: this.getPaymentStatus(item),
          paymentType: this.getPaymentType(item),
        }));
        resolve({
          data: modifiedData,
        });
      });
    });
  }

  deleteInvoiceHandler = (event, data) => {
    let url = config.BASEURL;
    let user = JSON.parse(localStorage.getItem('user'));

    let token = user.token;
    let param = {
      invoiceId: data._id,
    };

    DataGetApi(url + 'deleteInvoice', param, token).then((_result) => {
      const invoiceDataPostInvoiceDeletion = this.state.invoiceData.map((invoice) => {
        if (invoice._id == data._id) {
          invoice.STATUS = 'Deleted';
        }
        return invoice;
      });

      this.setState({ reload: true, invoiceData: invoiceDataPostInvoiceDeletion });
    });
  };

  getTotal = (data) => {
    let total = 0;
    if (data.total) {
      total = data.total;
    } else {
      total = data?.amount;
    }

    return formatCurrency(total);
  };
  handleOpenModal() {
    this.setState({ ...this.state, isModalOpen: true, isLoading: false });
  }
  handleCloseModal() {
    const successMsg = this.state.successMessages;
    this.setState({
      ...this.state,
      errorMessages: '',
      successMessages: '',
      validInvoices: '',
      tempInvoices: '',
      isModalOpen: false,
    });
    if (successMsg) {
      this.getData().then((result) => this.setState({ invoiceData: result?.data }));
    }
  }
  handleDownload() {
    const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(sampleBulkInvoice);
    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', 'Sample Bulk Invoice.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  handleSaveFileAndSubmit() {
    this.setState(
      {
        ...this.state,
        isLoading: true,
      },
      () => {
        this.handleOpenModal();
      }
    );
  }
  // Function to validate date format (mm/dd/yy or mm/dd/yyyy)
  isValidDate = (dateString) => {
    const regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d|3[01])\/(19|20)?\d{2}$/;
    return regex.test(dateString);
  };
  isValidAlphaNumeric = (input) => {
    const result = /^[a-zA-Z0-9/(),@.\-&'*’]+(?:\s+[a-zA-Z0-9/(),@.\-&'*’]+)*$/.test(input);
    return result;
  };
  // Function to validate email format
  isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  checkRequiredColumns = (row, rowNumber, validationErrors) => {
    if (!row[1] || !row[2] || !row[3]) {
      this.pushValidationError(validationErrors, true, rowNumber, 'Required columns are missing.');
      return false;
    }
    return true;
  };

  validateInvoice = (value) => {
    return value && !this.isValidAlphaNumeric(value);
  };

  validateDate = (value) => {
    return !this.isValidDate(value);
  };

  validateAlphaNumeric = (value) => {
    return !this.isValidAlphaNumeric(value);
  };

  validateNumericRequired = (value) => {
    return isNaN(parseFloat(value));
  };
  validateNumeric = (value) => {
    return value && isNaN(parseFloat(value));
  };
  validatePaymentMethodType = (value) => {
    return value && !/^(card|ach)$/i.test(value);
  };

  validateBoolean = (value) => {
    return value && !/^(true|false)$/i.test(value);
  };

  validateLength = (value, maxLength) => {
    return value && value.length > maxLength;
  };

  validateEmail = (value) => {
    return value && !this.isValidEmail(value);
  };

  pushValidationError = (errorsArray, isError, rowNumber, message) => {
    if (isError) {
      errorsArray.push(`Row ${rowNumber}: ${message}`);
    }
  };
  validateCSVData = (dataArray) => {
    const validationErrors = [];
    for (let i = 0; i < dataArray.length; i++) {
      const row = dataArray[i];
      const rowNumber = i + 1;
      // Check if all required columns are present
      if (!this.checkRequiredColumns(row, rowNumber, validationErrors)) continue;
      // Validate invoice (Alphanumeric)
      this.pushValidationError(validationErrors, this.validateInvoice(row[0]), rowNumber, 'Invalid invoice format.');
      // Validate billing_date format (mm/dd/yy or mm/dd/yyyy)
      this.pushValidationError(validationErrors, this.validateDate(row[1]), rowNumber, 'Invalid billing_date format.');
      // Validate customer_id (Alphanumeric)
      this.pushValidationError(
        validationErrors,
        this.validateAlphaNumeric(row[2]),
        rowNumber,
        'Invalid customer_id format.'
      );
      // Validate base_amount (Numeric)
      this.pushValidationError(
        validationErrors,
        this.validateNumericRequired(row[3]),
        rowNumber,
        'Invalid base_amount format.'
      );
      // Validate surcharge_amount, tax_amount, custom_fee_amount (Numeric)
      for (let j = 4; j <= 6; j++) {
        this.pushValidationError(
          validationErrors,
          this.validateNumeric(row[j]),
          rowNumber,
          `Invalid value at column ${j + 1}.`
        );
      }
      // Validate total_amount if provided
      this.pushValidationError(
        validationErrors,
        this.validateNumeric(row[7]),
        rowNumber,
        'Invalid total_amount format.'
      );
      // Validate payment_method_type if provided
      this.pushValidationError(
        validationErrors,
        this.validatePaymentMethodType(row[8]),
        rowNumber,
        'Invalid payment_method_type.'
      );
      // Validate surcharge_exempt, tax_exempt, Apply_tax_last, Custom_fee_exempt, email_receipt (Boolean)
      for (let j = 9; j <= 13; j++) {
        this.pushValidationError(
          validationErrors,
          this.validateBoolean(row[j]),
          rowNumber,
          `Invalid value at coulmn ${j + 1}.`
        );
      }
      // Validate public_notes length
      this.pushValidationError(
        validationErrors,
        this.validateLength(row[14], 100),
        rowNumber,
        'Length of public_notes exceeds 100 characters.'
      );
      // Validate po_number length
      this.pushValidationError(
        validationErrors,
        this.validateLength(row[17], 17),
        rowNumber,
        'Length of po_number exceeds 17 characters.'
      );
      // Validate email_address format
      this.pushValidationError(
        validationErrors,
        this.validateEmail(row[18]),
        rowNumber,
        'Invalid email_address format.'
      );
      // Validate address fields length (billing_address_line_1, billing_address_line_2, ...)
      for (let j = 19; j <= 28; j++) {
        this.pushValidationError(
          validationErrors,
          this.validateLength(row[j], 100),
          rowNumber,
          `Length of address field at column ${j + 1} exceeds 100 characters.`
        );
      }
    }

    this.setState({
      ...this.state,
      validationErrors: validationErrors,
    });

    return validationErrors;
  };
  handleFileChange = (e) => {
    this.setState({
      ...this.state,
      errorMessages: '', // Reset error messages at the start
    });
    // Check if the file exists and is a CSV
    const file = e.target.files[0];
    const fileExtension = file?.name.split('.').pop().toLowerCase();
    const acceptedMimeTypes = [
      'text/csv',
      'application/csv',
      'application/vnd.ms-excel',
      'text/comma-separated-values',
      'application/vnd.oasis.opendocument.spreadsheet',
    ];

    if (!file || fileExtension !== 'csv' || !acceptedMimeTypes.includes(file.type)) {
      this.setState({
        ...this.state,
        errorMessages: ['File format not accepted. Please upload a CSV file.'],
        isUploadable: false,
        tempInvoices: [], // Clear any previously loaded data
        validInvoices: [],
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;
      let dataArray = text.split('\n').map((row) => row.split(','));
      dataArray = dataArray.filter((row) => row.length > 1 && row.some((cell) => cell.trim().length > 0)); // Remove empty arrays
      dataArray.shift();

      this.setState({
        ...this.state,
        tempInvoices: dataArray,
      });

      const validationErrors = this.validateCSVData(dataArray);
      if (validationErrors.length === 0) {
        this.setState({
          ...this.state,
          isUploadble: true,
          validInvoices: dataArray,
        });

        // Set state with validated CSV data
      } else {
        // Handle validation errors
        console.error('Validation errors:', validationErrors);
        this.setState({
          ...this.state,
          errorMessages: validationErrors,
        });
      }
    };
    reader.readAsText(file);
  };
  handleInvoicesUploadToBackend = () => {
    // Check if there is any data to upload
    if (this.state.validInvoices.length === 0) {
      // Handle the case where there is no data to upload
      this.setState({
        ...this.state,
        errorMessages: 'No data to upload',
      });
      console.log('No data to upload');
      return;
    }
    // If there are validation errors, handle them and abort upload
    if (this.state.validationErrors.length > 0) {
      // Handle validation errors (e.g., display error messages)
      this.setState({
        ...this.state,
        errorMessages: this.state.validationErrors,
      });
    } else {
      // If no validation errors, proceed with uploading data to the backend
      this.setState({ ...this.state, isLoading: true });
      const invoices = this.state.validInvoices.map((invoice) => ({
        invoice: invoice[0] ? invoice[0] : '',
        billing_date: invoice[1],
        customer_id: invoice[2],
        base_amount: invoice[3],
        surcharge_amount: invoice[4] ? invoice[4] : 0,
        tax_amount: invoice[5] ? invoice[5] : 0,
        custom_fee_amount: invoice[6] ? invoice[6] : 0,
        total_amount: invoice[7] ? invoice[7] : 0,
        payment_method_type: invoice[8] ? invoice[8] : 'card',
        tax_exempt: invoice[9]?.toUpperCase() === 'TRUE' ? true : false,
        apply_tax_last: invoice[10]?.toUpperCase() === 'TRUE' ? true : false,
        custom_fee_exempt: invoice[11]?.toUpperCase() === 'TRUE' ? true : false,
        surcharge_exempt: invoice[12]?.toUpperCase() === 'TRUE' ? true : false,
        email_receipt: invoice[13]?.toUpperCase() === 'TRUE' ? true : false,
        public_notes: invoice[14] ? invoice[14] : '',
        first_name: invoice[15] ? invoice[15] : '',
        last_name: invoice[16] ? invoice[16] : '',
        po_number: invoice[17] ? invoice[17] : '',
        customer_email: invoice[18],
        customer_billing_address_1: invoice[19],
        customer_billing_address_2: invoice[20],
        customer_billing_city: invoice[21],
        customer_billing_state: invoice[22],
        customer_billing_zip: invoice[23],
        customer_shipping_address_1: invoice[24],
        customer_shipping_address_2: invoice[25],
        customer_shipping_city: invoice[26],
        customer_shipping_state: invoice[27],
        customer_shipping_zip: invoice[28],
      }));

      this.props
        .uploadBulkInvoices({
          locationId: this.state.locationId,
          userData: this.state.userData,
          userDetails: this.state.userDetails,
          validInvoices: invoices,
        })
        .then((res) => {
          if (res !== undefined && res.type === 'INVOICE_SUCCESS') {
            this.setState({
              ...this.state,
              successMessages: res?.payload?.invoiceData?.data?.message,
              isLoading: false,
            });
          }
          if (res !== undefined && res.type === 'INVOICE_ERROR') {
            this.setState({
              ...this.state,
              errorMessages:
                'Error occured while uploading!' + res?.payload?.error?.response?.data?.error?.[0]?.message,
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  setCustomValues = () => {
    const locationCurrent = JSON.parse(localStorage.getItem('locationPaydetails'));
    let customInvoicePageFields = locationCurrent?.customInvoicePageFields;
    let isHidden = false;
    if (locationCurrent?.customTowingBuild) {
      isHidden = true;
    } else {
      customInvoicePageFields = [];
    }

    return { locationCurrent, customInvoicePageFields, isHidden };
  };
  render() {
    const { t } = this.props;
    const { locationCurrent, customInvoicePageFields, isHidden } = this.setCustomValues();
    const columns = [
      {
        title: this.state.invoiceLabel,
        field: 'invoice',
        render: (rowData) => (
          <Tooltip title="View Details">
            <span
              data-cy="invoice-link"
              className={classes.Underline}
              onClick={(_e) => this.handleChangeId(rowData.invoice, rowData.isPaid, rowData._id, rowData)}
            >
              {this.state.customFieldsEnabled && rowData.jobId ? rowData.jobId : rowData.invoice}
            </span>
          </Tooltip>
        ),
        customFilterAndSearch: (term, rowData) => {
          const fieldToSearch = this.state.customFieldsEnabled && rowData.jobId ? rowData.jobId : rowData.invoice;
          return customFilterAndSearch(fieldToSearch, term);
        },
      },
      {
        title: customInvoicePageFields.length > 0 && customInvoicePageFields[0]?.value,
        hidden:
          customInvoicePageFields.length > 0 && locationCurrent?.customTowingBuild
            ? !customInvoicePageFields[0]?.enabled
            : true,
        field: customInvoicePageFields.length > 0 && customInvoicePageFields[0]?.value.replace(/\s/g, '').toLowerCase(),
        render: (rowData) =>
          rowData?.field1?.key && rowData?.field1?.value ? rowData?.field1?.value : rowData?.firstName ?? '',
      },
      {
        title: customInvoicePageFields.length > 0 && customInvoicePageFields[1]?.value,
        hidden:
          customInvoicePageFields.length > 0 && locationCurrent?.customTowingBuild
            ? !customInvoicePageFields[1]?.enabled
            : true,
        field: customInvoicePageFields.length > 0 && customInvoicePageFields[1]?.value.replace(/\s/g, '').toLowerCase(),
        render: (rowData) =>
          rowData?.field2?.key && rowData?.field2?.value ? rowData?.field2?.value : rowData?.lastName ?? '',
      },
      {
        title: 'First Name',
        field: 'firstName',
        hidden: isHidden,
        render: (rowData) => this.getFirstName(rowData),
      },
      {
        title: 'Last Name',
        field: 'lastName',
        hidden: isHidden,
        render: (rowData) => this.getLastName(rowData),
      },
      // { title: 'Amount', field: 'amount', render: rowData => (String(rowData.amount).indexOf('.') === -1) ? '$' + String(rowData.amount) + '.00' : '$' + String(rowData.amount) },
      {
        title: 'Amount',
        field: 'amount',
        render: (rowData) => (rowData.amount ? formatCurrency(rowData.amount) : '$0.00'),
      },
      // { title: 'SVC Fee', field: 'svcFee', render: rowData => (rowData.svcFee) ?formatCurrency(rowData.svcFee): '$0.00' },
      // { title: 'Tax', field: 'tax', render: rowData => formatCurrency(rowData.tax)},
      {
        title: 'Total',
        field: 'total',
        render: (rowData) => this.getTotal(rowData),
      },
      { title: 'Location', field: 'location' },
      {
        title: 'Payment Type',
        field: 'paymentType',
      },
      {
        title: 'Email-Status',
        field: 'emailSent',
        lookup: {
          true: (
            <button type="button" className="btn sentBtn">
              Sent
            </button>
          ),
          false: '',
        },
      },
      {
        title: 'Text-Status',
        field: 'textSent',
        lookup: {
          true: (
            <button type="button" className="btn sentBtn">
              Sent
            </button>
          ),
          false: '',
        },
      },
      {
        title: 'Payment-Status',
        field: 'STATUS',
        render: (rowData) => {
          const statusKey = rowData.STATUS?.toLowerCase();
          const statusConfig = PAYMENT_STATUS_BUTTON_CONFIG[statusKey];

          return statusConfig ? (
            <button type="button" className={statusConfig.className}>
              {statusConfig.text}
            </button>
          ) : (
            ''
          );
        },
        customSort: (a, b) => a.STATUS.localeCompare(b.STATUS, undefined, { sensitivity: 'base' }),
        customFilterAndSearch: (term, rowData) => {
          const statusKey = rowData.STATUS?.toLowerCase();
          const fieldToSearch = PAYMENT_STATUS_BUTTON_CONFIG[statusKey]?.text || '';
          return customFilterAndSearch(fieldToSearch, term);
        },
      },
      { title: 'Date', field: 'dateOpened', render: (date) => new Date(date.dateOpened).toLocaleDateString() },
      {
        title: 'Due Date',
        field: 'dueDate',
        render: (date) => {
          if (date.dueDate == 'Invalid Date' || date.dueDate == null) {
            return new Date(date.dateOpened).toLocaleDateString();
          } else {
            return new Date(date.dueDate).toLocaleDateString();
          }
        },
      },
    ];

    const checkLocation = () => {
      /**check location is selected or not */
      let location = JSON.parse(localStorage.getItem('selected'));
      if (location === 0 && this.state.userDetails.permissions.allLocaton) {
        this.setState({ misMatchError: ['Please select a location'] });
        return false;
      } else {
        let currentLocation = JSON.parse(localStorage.getItem('locationPaydetails'));
        if (currentLocation?.customTowingBuild) {
          this.props.history.push('/RequestForm');
        } else {
          this.props.history.push('/AddInvoices');
        }
      }
    };
    const visibleColumns = columns.filter((column) => !column.hidden);
    return (
      <div className="rightPanel">
        <Modal
          open={this.state.isModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="bulkInvoicesModal" style={{ overflowY: 'auto' }}>
            {this.state.successMessages ? <SuccessMessage successes={[this.state.successMessages]} /> : ''}
            {this.state.errorMessages ? <ErrorMessage errors={[this.state.errorMessages]} /> : ''}
            <Close
              style={{ position: 'absolute', right: 25, cursor: 'pointer', marginBottom: '10px' }}
              onClick={() => this.handleCloseModal()}
            />
            {this.state.tempInvoices.length === 0 && (
              <>
                <h3>Upload Files</h3>

                <div className="drop_box">
                  <header>
                    <h4>Select File here</h4>
                  </header>
                  <p> Files Supported: CSV, Max Size: 20MB</p>
                  <Typography
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      fontSize: 18,
                      fontWeight: '700',
                    }}
                  ></Typography>
                  <div
                    className="file-upload-wrapper"
                    data-text="Select your file."
                    style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                  >
                    <input
                      type="file"
                      name="file"
                      multiple={this.state.isModalOpen}
                      accept={'.csv'}
                      id="fileID"
                      onChange={this.handleFileChange}
                      className="input-class file-upload-field "
                    />
                  </div>
                </div>
                <Buttons
                  id="saveSampleFile"
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => this.handleDownload()}
                  text="Download Template"
                  disabled={this.state.misMatchError?.length > 0}
                />
              </>
            )}
            {this.state.tempInvoices.length !== 0 && (
              <>
                <header>
                  <h4>Selected Invoices</h4>
                </header>
                <Typography
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    fontSize: 18,
                    fontWeight: '700',
                  }}
                ></Typography>
                {this.state.tempInvoices.length > 0 && (
                  <div className="bulk_table_display ">
                    <table className="csv-table">
                      <thead>
                        <tr>
                          <th>Invoice Number</th>
                          <th>Billing Date</th>
                          <th>Customer ID</th>
                          <th>Base Amount</th>
                          <th>Surcharge Amount</th>
                          <th>Tax Amount</th>
                          <th>Custom Fee Amount</th>
                          <th>Total Amount</th>
                          <th>Payment Method Type</th>
                          <th>Tax Exempt</th>
                          <th>Apply Tax Last</th>
                          <th>Custom Fee Exempt</th>
                          <th>Surcharge Exempt</th>
                          <th>Email Receipt</th>
                          <th>Public Notes</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>PO Number</th>
                          <th>Customer Email</th>
                          <th>Customer Billing Address 1</th>
                          <th>Customer Billing Address 2</th>
                          <th>Customer Billing City</th>
                          <th>Customer Billing State</th>
                          <th>Customer Billing Zip</th>
                          <th>Customer Shipping Address 1</th>
                          <th>Customer Shipping Address 2</th>
                          <th>Customer Shipping City</th>
                          <th>Customer Shipping State</th>
                          <th>Customer Shipping Zip</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.tempInvoices.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {Object.values(row).map((cell, cellIndex) => (
                              <td key={cellIndex}>{cell}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <div>
                  <Buttons
                    id="downloadSampleFile"
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => this.handleDownload()}
                    text="Download Template"
                    style={{ marginTop: '10px' }}
                    disabled={this.state.misMatchError?.length > 0}
                  />
                  <Buttons
                    id="saveSampleFile"
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={this.handleInvoicesUploadToBackend}
                    style={{ marginTop: '10px', marginLeft: '10px' }}
                    text="Upload Invoices"
                    disabled={!this.state.isUploadble}
                  />
                  {/* <span style={{ marginTop: '10px', marginLeft: '25px' }}></span> */}
                </div>
              </>
            )}
          </Box>
        </Modal>
        <Topheader />
        <div className="contentPart">
          {!this.props.userInfo.permissions && this.props.userInfo.userId === ''}
          {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
          <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
            <div>
              <button
                id="batch-invoices-button"
                className="btn batchBtn"
                onClick={() => {
                  this.setState({ bulkInvoicesModal: true });
                }}
              >
                {t('SCHEDULEINVOICES')}
              </button>
              <button
                id="bulk-invoices-button"
                className="btn batchBtn"
                onClick={() => this.handleSaveFileAndSubmit()}
                style={{ display: 'block', marginLeft: '252px' }}
              >
                {t('BATCHINVOICES')}
              </button>
              {this.state.showSelfPayLink ? (
                <button
                  id="static-pay-link-button"
                  className="btn batchBtn"
                  onClick={this.generateSelfPayLink}
                  style={{ display: 'block', marginLeft: '370px' }}
                >
                  Static Pay Link
                </button>
              ) : (
                <input
                  id="self-pay-link-input"
                  className="textField btn customPlacement"
                  type="text"
                  value={this.state.selfPayLink}
                  readOnly
                  style={{ display: 'block', marginLeft: '370px', width: '30%' }}
                />
              )}
            </div>
            <Table
              tableReload={(e) => this.tableReload(e)}
              options={{
                exportButton: true,
                search: true,
              }}
              columns={visibleColumns}
              permissions={
                this.props.userInfo.userId === '' ? this.state.userData.permissions : this.props.userInfo.permissions
              }
              data={this.state.invoiceData}
              getExportData={this.getExportData.bind(this)}
              componentState={this.state}
              title={'Invoices'}
              checkLocation={checkLocation}
              search={true}
              exports={
                this.props.userInfo.userId === ''
                  ? this.state.userData.permissions.exportRecord
                  : this.props.userInfo.permissions.exportRecord
              }
              paging={true}
              toDateFilter={(e) => this.toDateFilter(e)}
              fromDateFilter={(e) => this.fromDateFilter(e)}
              datefilter={true}
              deleteHandler={(e, data) => this.deleteInvoiceHandler(e, data)}
            />
            <BulkInvoices
              open={this.state.bulkInvoicesModal}
              tableReload={(e) => this.tableReload(e)}
              handleClose={() => this.setState({ bulkInvoicesModal: false })}
              locationCurrent={locationCurrent}
              customInvoicePageFields={customInvoicePageFields}
              isHidden={isHidden}
              options={{
                exportButton: false,
                search: false,
              }}
              invoiceLabel={this.state.invoiceLabel}
              customFieldsEnabled={this.state.customFieldsEnabled}
              permissions={
                this.props.userInfo.userId === '' ? this.state.userData.permissions : this.props.userInfo.permissions
              }
              checkLocation={checkLocation}
            />
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

Invoice.propTypes = {
  userInfo: PropTypes.any,
  history: PropTypes.any,
  uploadBulkInvoices: PropTypes.func,
  t: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    userInfo: state.AdminUserReducer,
    locationCurrent: state.LocationReducer,
  };
}

export default connect(mapStateToProps, { getInvoiceList, uploadBulkInvoices })(withTranslation()(Invoice));
