import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getDateObject } from './dateutils';

// Can accept date objects or ISO format dates as value.
function DatePickerInput({
  id,
  onChange,
  value,
  label,
  views,
  format,
  required,
  error,
  closeOnSelect,
  minDate,
  maxDate,
  disabled,
}) {
  value = getDateObject(value);
  minDate = getDateObject(minDate);
  maxDate = getDateObject(maxDate);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div data-cy={id + '-div'}>
        <DatePicker
          label={label}
          value={value}
          views={views}
          minDate={minDate}
          maxDate={maxDate}
          format={format}
          onChange={(newValue) => {
            onChange(newValue);
          }}
          slotProps={{
            textField: {
              id: id,
              required: required,
              error: error,
              variant: 'standard',
              style: {
                padding: '4px',
                marginLeft: '19px',
                marginRight: '6px',
                marginTop: '7px',
              },
              inputProps: { 'data-cy': id },
            },
            inputAdornment: { position: 'end' },
          }}
          closeOnSelect={closeOnSelect || true}
          disabled={disabled || false}
          styles={{ width: '100%', justifyContent: 'center' }}
        />
      </div>
    </LocalizationProvider>
  );
}

DatePickerInput.propTypes = {
  id: PropTypes.any,
  onChange: PropTypes.func,
  views: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  format: PropTypes.any,
  required: PropTypes.bool,
  error: PropTypes.any,
  label: PropTypes.any,
  closeOnSelect: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  disabled: PropTypes.bool,
};

export default DatePickerInput;
