import React, { Component } from 'react';
import Capitalize from 'lodash/capitalize';
import get from 'lodash/get';

import { makeStyles } from '@material-ui/core/styles';
import { Topheader } from '../../../components/Topheader';
import LoadingOverlay from 'react-loading-overlay';
import { Buttons } from '../../../components/Buttons';

import { Box, Button, Container, createMuiTheme, Grid, MuiThemeProvider, Tooltip } from '@material-ui/core';
import { ErrorMessage } from '../../../components/Message/ErrorMessage';
import { SuccessMessage } from '../../../components/Message/SuccessMessage';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { config } from '../../../constants';
import { DataGetApi } from '../../../helpers/PostDataApi';
import { formatCurrency, formatDate, getPaytraceCreds } from '../../../helpers/commonFunction';
import MUIDataTable, { debounceSearchRender } from 'mui-datatables';
import PropTypes from 'prop-types';
import SpinnerLoader from './SpinnerLoader';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  Underline: {
    color: 'blue',
    cursor: 'pointer',
  },
  rootBatch: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function BatchDetails({ batchInfo, batchData, batchId }) {
  const batchDetails = batchInfo?.batchInfo?.data?.batches || [];
  const batchDetailsArray = batchDetails.find((batch) => batch.number === +batchId) || {};

  const renderDetail = (label, value) => (
    <Grid item xs={3}>
      {label}: {value}
    </Grid>
  );

  return (
    <Box boxShadow={3} bgcolor="background.paper" m={1} p={1} style={{ marginLeft: '1px', marginRight: 0 }}>
      <Container fixed>
        <Grid container spacing={3} alignItems="stretch">
          {renderDetail('Batch number', batchDetailsArray.number || batchData?.number || '')}
          {renderDetail(
            'Transaction Count',
            batchDetailsArray.transaction_count || batchData?.transaction_count || '0'
          )}
          {renderDetail('Net Amount', formatCurrency(batchDetailsArray.net_amount || batchData?.net_amount || ''))}
          {renderDetail('Sales Count', batchDetailsArray.sales_count || batchData?.sales_count || '0')}
          {renderDetail(
            'Sales Amount',
            formatCurrency(batchDetailsArray.sales_amount || batchData?.sales_amount || '')
          )}
          {renderDetail('Refund Count', batchDetailsArray.refund_count || batchData?.refund_count || '0')}
          {renderDetail(
            'Refund Amount',
            formatCurrency(batchDetailsArray.refund_amount || batchData?.refund_amount || '')
          )}
          {renderDetail(
            'Created At',
            moment(batchDetailsArray.created?.at || batchData?.created?.at).format('M/D/YYYY')
          )}
        </Grid>
      </Container>
    </Box>
  );
}

class BatchTransactionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isUpdating: false,
      reload: false,
      misMatchError: [],
      transactionsList: [],
      title: [],
      page: 10,
      userData: JSON.parse(localStorage.getItem('user')),
      error: {},
      batchData: {},
    };
  }

  changePage = (page, offset, sorting, searching) => {
    this.setState({
      isLoading: true,
    });
    this.getTransactionListByBatchId(page, offset, sorting, searching);
  };

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTablePagination: {
          toolbar: {
            width: '160px !important ',
          },
          root: {
            width: '497px !important',
          },
        },
      },
    });
  componentDidMount() {
    this.getTransactionListByBatchId();
  }

  updateBatchTransactionList = () => {
    const {
      match: { params },
    } = this.props;
    let user = JSON.parse(localStorage.getItem('user'));
    this.setState({ isUpdating: true });

    let token = user.token;
    const paytraceCredentials = getPaytraceCreds();
    let companyId = user.role !== config.SUPER_ADMIN_ROLE && user.company._id ? user.company._id : '';
    let url = config.BASEURL;
    DataGetApi(
      url + 'updateBatchTransactions',
      {
        ...{ ...JSON.parse(localStorage.getItem('batchParams')), ...params, ...{ companyId: companyId } },
      },
      token,
      paytraceCredentials
    ).then(() => {
      this.getTransactionListByBatchId();
    });
  };
  getTransactionListByBatchId(pages = 0, offset = 0, sorting = false, searching = false) {
    const {
      match: { params },
    } = this.props;
    let user = JSON.parse(localStorage.getItem('user'));
    let locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    this.setState({ locationPaydetails: locationPaydetails });

    let token = user.token;
    const paytraceCredentials = getPaytraceCreds();
    let companyId = user.role !== config.SUPER_ADMIN_ROLE && user.company._id ? user.company._id : '';
    let url = config.BASEURL;
    DataGetApi(
      url + 'getBatchById',
      {
        ...{ ...JSON.parse(localStorage.getItem('batchParams')), ...params, ...{ companyId: companyId } },
        pages,
        offset,
        sorting,
        searching,
      },
      token,
      paytraceCredentials
    ).then((result) => {
      if (result.data.data.length > 0) {
        this.transformResult(result, pages);
      } else {
        this.updateBatchTransactionList();
      }
    });
  }

  extractCustomerId(items) {
    if (items.CUSTOMERDATA?.firstName) {
      return `${items.CUSTOMERDATA.firstName} ${items.CUSTOMERDATA.lastName}`;
    } else if (items.CUSTOMERDATA) {
      return items.CUSTOMERDATA.id;
    }

    return items.customer_id;
  }

  transformResult(result, pages = 0) {
    const user = JSON.parse(localStorage.getItem('user'));
    let companyId = user.role !== config.SUPER_ADMIN_ROLE && user.company._id ? user.company._id : '';
    if (result.data?.success) {
      const transactionListArray = result.data.data.map((items) => {
        let itemsSet = {
          transaction_id: items.transaction_id,
          customer_id: this.extractCustomerId(items),
          base_amount: items.base_amount ? formatCurrency(items.base_amount) : '$0.00',
          tax_amount: items.tax_amount ? formatCurrency(items.tax_amount) : '$0.00',
          svc_fee: items.svc_fee ? formatCurrency(items.svc_fee) : '$0.00',
          surcharge: items.surcharge ? formatCurrency(items.surcharge) : '$0.00',
          amount: items.amount ? formatCurrency(items.amount) : '$0.00',
          batchNumber: items.batchNumber,
          invoice_id: items.invoice_id,
          paymentType: Capitalize(items.paymentType),
          paymentStatus: items.paymentStatus
            ? items.paymentStatus
            : items.transaction_type === 'SALE'
            ? 'Paid'
            : items.transaction_type === 'REFUND'
            ? 'Refunded'
            : '',
          createdBy: this.getCompletedBy(items, companyId),
          CardType: Capitalize(items.CardType),
          Location: items.location,
          transaction_type: items.transaction_type,
          credit_card: items.credit_card ? items.credit_card.masked_number : '',
          created: formatDate(items.created.at),
          settled: formatDate(items.settled),
          notes: items.description,
          companyId: companyId,
        };
        return Object.values(itemsSet);
      });

      this.setState({
        data: Object.values(transactionListArray),
        selectedData: Object.values(transactionListArray),
        batchData: result.data.batchData[0],
        isLoading: false,
        isUpdating: false,
        page: pages,
        count: result.data.total,
      });
      return Object.values(transactionListArray);
    }
  }

  getCompletedBy(result, companyId) {
    // Specific condition for 48forty
    if (
      result &&
      result.FRONT_END !== 'Self Payment Link' &&
      (companyId === '65ca2952137ba518cb9565f0' || companyId === '650b7f1660368040608cdec7')
    ) {
      return get(result, 'createdBy.userName', 'N/A');
    } else if (
      result &&
      result.FRONT_END === 'Self Payment Link' &&
      (companyId === '65ca2952137ba518cb9565f0' || companyId === '650b7f1660368040608cdec7')
    ) {
      return 'Self Pay';
    } else {
      return result.createdBy ? `${result.createdBy.firstName} ${result.createdBy.lastName}` : 'N/A';
    }
  }

  render() {
    const {
      classes,
      batchInfo,
      match: {
        params: { id },
      },
    } = this.props;

    const { data, selectedData, count, batchData } = this.state;
    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'vertical',
      tableBodyHeight: '40rem',
      tableBodyMaxHeight: '',
      fixedHeader: true,
      serverSide: true,
      downloadOptions: {
        filename: `${this.state.locationPaydetails?.locationName}-Batch:${batchData?.number} ${new Date()}.csv`,
        customCSVdata: data,
      },
      customSearchRender: debounceSearchRender(100),
      onSearchChange: (searchString) => {
        if (searchString === '') {
          this.setState({ selectedData: data });
          return;
        }
        let matchingRecords = [];
        data.forEach((obj) => {
          Object.values(obj).forEach((value) => {
            if (typeof value === 'string' && value.includes(searchString)) {
              matchingRecords.push(obj);
            }
          });
        });
        if (matchingRecords.length > 0) {
          this.setState({ selectedData: matchingRecords });
        }
      },
      onSearchClose: () => {
        this.setState({ selectedData: data });
      },
      count: count,
      pagination: false,
      selectableRows: false,
      onTableChange: (action, tableState) => {
        if (['changeRowsPerPage', 'changePage', 'sort'].indexOf(action) !== -1) {
          this.changePage(tableState.page, tableState.rowsPerPage, tableState.sortOrder);
        }
      },
    };
    const StatusButton = ({ value }) => {
      switch (value) {
        case 'Paid':
          return (
            <button type="button" className="btn paidBtn">
              Paid
            </button>
          );
        case 'Refunded':
          return (
            <button type="button" className="btn RefundedBtn">
              Refunded
            </button>
          );
        case 'Voided':
          return (
            <button type="button" className="btn VoidedBtn">
              Voided
            </button>
          );
        case 'Declined':
          return (
            <button type="button" className="btn DeclinedBtn">
              Declined
            </button>
          );
        case '':
          return 'N/A';
      }
    };

    const handleClickTxnId = (value) => {
      const url = `${window.location.origin}/ViewTransaction/${value}`;
      window.open(url, '_blank');
    };
    const user = JSON.parse(localStorage.getItem('user'));
    const TxnId = ({ value, classes }) => {
      const matchingRecord = data.filter((record) => record.includes(value));
      const [, , , , , , , , , , status, , , locationName] = matchingRecord[0];
      let classname = status && locationName ? classes.Underline : '';
      let onClick = status && locationName ? () => handleClickTxnId(value) : () => {};
      const title = status && locationName ? 'View Transaction' : 'TXN not in Flex';
      return (
        <Tooltip title={title}>
          <span className={classname} onClick={onClick}>
            {value}
          </span>
        </Tooltip>
      );
    };

    const columns = [
      {
        name: 'TXN ID',
        options: {
          filter: false,
          customBodyRender: (value) => <TxnId value={value} classes={this.props.classes} />,
        },
      },
      {
        name: 'Customer',
        options: {
          filter: false,
          sort: false,
        },
      },
      'Base Amount',
      {
        name: 'Tax',
        options: {
          display: user.settings?.virtualTerminalSettings.InvoiceTax,
        },
      },
      {
        name: 'SVC',
        options: {
          display: user.settings?.virtualTerminalSettings.InvoiceDiscount,
        },
      },
      {
        name: 'Surcharge',
        options: {
          display: user.settings?.virtualTerminalSettings.InvoiceSurcharge,
        },
      },
      'Amount',
      {
        name: 'Batch Number',
        options: {
          filter: false,
          sort: false,
        },
      },
      'Invoice Id',
      {
        name: 'Payment Type',
        options: {
          filter: false,
          customBodyRender: (value) => value || 'N/A',
        },
      },
      {
        name: 'Status',
        options: (value) => <StatusButton value={value} />,
      },
      {
        name: 'Completed By',
        options: {
          filter: false,
          customBodyRender: (value) => value || 'N/A',
        },
      },
      {
        name: 'Card Type',
        options: {
          filter: false,
          customBodyRender: (value) => value || 'N/A',
        },
      },
      {
        name: 'Location',
        options: {
          filter: false,
          customBodyRender: (value) => value || 'N/A',
        },
      },
      'TXN Type',
      {
        name: 'Card Number',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'Authorization Date',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'Settled Date',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'Notes',
        options: {
          filter: false,
          sort: false,
        },
      },
    ];

    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay active={this.state.isLoading} spinner text="Please Wait... Loading may take up to one minute">
            <Container component="main" maxWidth="xl">
              {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
              {this.state.successMessages ? (
                <SuccessMessage successes={[this.props.info.paymentInfo.data.message]} />
              ) : (
                ''
              )}
              <BatchDetails classes={classes} batchInfo={batchInfo} batchId={id} batchData={batchData} />
              <React.Fragment>
                <Button
                  className={classes.buttonSearch}
                  style={{ marginBottom: '8px' }}
                  variant="outlined"
                  color="primary"
                  disabled={this.state.isUpdating}
                  onClick={this.updateBatchTransactionList}
                >
                  {this.state.isUpdating ? <SpinnerLoader /> : 'Sync Batch Transactions'}
                </Button>
                {this.state.isUpdating ? ' Syncing Transactions... This may take up to one minute' : ''}
              </React.Fragment>
              <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable title={'Transaction Lists'} data={selectedData} columns={columns} options={options} />
              </MuiThemeProvider>
              <Grid item xs={6} sm={6} md={6} style={{ marginTop: '20px' }}>
                <Buttons
                  className={'emailInvoiceTerminal'}
                  variant="contained"
                  color="secondary"
                  text="Back"
                  onClick={this.props.history.goBack}
                />
              </Grid>
            </Container>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

BatchDetails.propTypes = {
  batchInfo: PropTypes.any,
  batchData: PropTypes.any,
  batchId: PropTypes.any,
};

BatchTransactionList.propTypes = {
  classes: PropTypes.any,
  batchInfo: PropTypes.any,
  info: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  value: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    batchInfo: state.BatchReducer,
  };
}

const ApplyingStylesOnClasses = (props) => {
  const classes = useStyles();
  return <BatchTransactionList classes={classes} {...props} />;
};

export default connect(mapStateToProps, {})(withTranslation()(ApplyingStylesOnClasses));
