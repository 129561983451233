import React from 'react';
import { Textfield } from '../../../components/Textfield';
import Grid from '@material-ui/core/Grid';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const CustomerForm = (props) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={3} style={{ padding: '16px' }}>
      <Grid container spacing={3}>
        <Grid item md={6} className="mb-3">
          <Textfield
            id="invoice"
            type="text"
            variant="outlined"
            labels={t('Invoice Number')}
            value={props.invoice}
            required={true}
            width={true}
            autoFocus={true}
            onChange={props.handleChange}
            error={props.error.invoiceError}
          />
        </Grid>
        <Grid item md={6} className="mb-3">
          <CurrencyTextField
            modifyValueOnWheel={false}
            label={t('Amount')}
            variant="outlined"
            value={props.amount}
            width={true}
            currencySymbol="$"
            decimalCharacter="."
            digitGroupSeparator=","
            id="amount"
            textAlign="left"
            onChange={(e, value) => props.handleChange(e, value)}
            required={true}
            style={{ minWidth: '100%' }}
            inputProps={{ style: { paddingLeft: '10px' } }}
            error={props.error.amount}
          />
        </Grid>
        <Grid item md={6} className="mb-3">
          <Textfield
            error={props.error.firstNameError}
            id="firstName"
            type="text"
            variant="outlined"
            required={true}
            labels={t('FirstName')}
            width={true}
            value={props.customerData.firstName}
            autoFocus={true}
            onChange={props.handleChangeCustomer}
          />
        </Grid>
        <Grid item md={6} className="mb-3">
          <Textfield
            id="lastName"
            type="text"
            variant="outlined"
            labels={t('Last Name')}
            width={true}
            value={props.customerData.lastName}
            autoFocus={true}
            onChange={props.handleChangeCustomer}
          />
        </Grid>
        <Grid item md={12} className="mb-3">
          <Textfield
            error={props.error.emailError}
            id="email"
            type="text"
            variant="outlined"
            labels={t('Email')}
            width={true}
            required={true}
            value={props.customerData.email}
            autoFocus={true}
            onChange={props.handleChangeCustomer}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

CustomerForm.propTypes = {
  invoice: PropTypes.any,
  handleChange: PropTypes.func,
  handleChangeCustomer: PropTypes.func,
  amount: PropTypes.number.isRequired,
  error: PropTypes.object,
  customerData: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string,
  }).isRequired,
};

export default CustomerForm;
