import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { getNyTaxReports } from '../../actions/RequestFormAction';
import PropTypes from 'prop-types';
import { Buttons } from './../../components/Buttons';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import validator from '../../helpers/checkValidations';
import LoadingOverlay from 'react-loading-overlay';
import classes from './NyTaxReports.module.scss';
import Container from '@material-ui/core/Container';
import TypableSelect from './../../helpers/TypableSelect';
import FormControl from '@material-ui/core/FormControl';
import RangedDatePicker from '../../components/DatePicker/RangedDatePicker';

let date = new Date();
const initialState = {
  sideShow: true,
  isLoading: false,
  isOpen: false,
  district: '',
  paymentStatus: 'All',
  recordLimit: 100,
  toDate: new Date(),
  date: new Date(),
  fromDate: new Date(date.setDate(date.getDate() - 30)),
  isSubmit: false,
  isClose: false,
  misMatchError: [],
  successMessages: false,
  error: {},
};

const mapToFormat = (values) => {
  return values.map((Obj) => ({ value: Obj.title, label: Obj.title }));
};

class NyTaxReports extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  setDistictValue(val) {
    if (val !== null) {
      this.setState({ district: val.value });
    } else {
      this.setState({ district: '' });
    }
  }
  setPaymentValue(val) {
    if (val !== null) {
      this.setState({ paymentStatus: val.value });
    } else {
      this.setState({ paymentStatus: 'All' });
    }
  }

  setRecordLimitValue(val) {
    if (val !== null) {
      this.setState({ recordLimit: val.value });
    } else {
      this.setState({ recordLimit: 100 });
    }
  }

  handleChange(event, _t) {
    let successMessages = false;
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
    if (this.state.createdBy === '') {
      let userData = JSON.parse(localStorage.getItem('user'));
      this.setState({ createdBy: userData._id, companyId: userData.company._id });
    }

    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({ [id]: value, error, misMatchError, isSubmit: false, successMessages });
  }

  handleSubmit(_e, _t) {
    this.setState({ isSubmit: true, isLoading: true });
    let getDataArray = {
      district: this.state.district,
      paymentStatus: this.state.paymentStatus,
      recordLimit: this.state.recordLimit,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    };
    this.props.getNyTaxReports(getDataArray).then(
      (response) => {
        let hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/plain;charset=utf-8,' + encodeURI(response.payload.invoiceData.data);
        hiddenElement.target = '_blank';
        hiddenElement.download = new Date().toLocaleString() + 'Ny Reports' + '.dat';
        hiddenElement.click();
        this.setState({ isLoading: false });
        console.log(response);
      },
      (error) => {
        this.setState({ isLoading: false });

        console.log(error);
      }
    );
  }

  handleDateChange = (date, key) => {
    if (date == 'Invalid Date' || date == null) {
      return false;
    } else {
      this.setState({ [key]: date }, () => {
        // (key==="toDate")?this.props.toDateFilter(this.state.toDate):this.props.fromDateFilter(this.state.fromDate)
        setTimeout(() => {
          // this.tableRef.current.onQueryChange()
        }, 1000);
      });
    }
  };

  render() {
    const districtLoc = this.props.UserDetail.locationIds.reduce((acc, curr, _i) => {
      acc.push({ title: curr.locationName });
      return acc;
    }, []);
    const { t } = this.props;
    return (
      <div className="content">
        <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
          <Container component="main" maxWidth="xl">
            <label className="mt5">
              {' '}
              <h2> NY Reports </h2>{' '}
            </label>{' '}
            <br /> <br />
            {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
            {this.state.successMessages ? <SuccessMessage successes={[this.props.info.ticketInfo.data.message]} /> : ''}
            <form className={classes.form} noValidate>
              {this.state.update && this.state.ticketNumber !== 0 ? (
                <h3 className="">
                  <span>Ticket Number : {this.state.ticketNumber ? this.state.ticketNumber : ''}</span>
                </h3>
              ) : (
                ''
              )}
              <Grid container spacing={3} className="ViewTxnFormBox">
                <Grid item xs={12} sm={4} style={{ marginTop: '3rem' }}>
                  <FormControl fullWidth>
                    <TypableSelect
                      placeholder={t('SelectDistrict')}
                      isClearable={true}
                      suggestions={mapToFormat(districtLoc)}
                      onChange={(val) => this.setDistictValue(val)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <RangedDatePicker
                    id="ranged-date-picker"
                    fromValue={this.state.fromDate}
                    toValue={this.state.toDate}
                    fromOnChange={(date) => {
                      this.handleDateChange(date, 'fromDate');
                    }}
                    toOnChange={(date) => {
                      this.handleDateChange(date, 'toDate');
                    }}
                    closeOnSelect
                    format="MM/dd/yyyy"
                  />
                </Grid>
                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={6}></Grid>

                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Buttons
                      variant="contained"
                      color="primary"
                      text={t('Download')}
                      onClick={(e) => this.handleSubmit(e, t)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Container>
        </LoadingOverlay>
      </div>
    );
  }
}

NyTaxReports.propTypes = {
  t: PropTypes.func,
  getNyTaxReports: PropTypes.func,
  UserDetail: PropTypes.any,
  info: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    info: state.CustomerReducer,
    UserDetail: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { getNyTaxReports })(withTranslation()(withRouter(NyTaxReports)));
