import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import { getLocationPaymentGateway } from '../../helpers/commonFunction';
import { getCsrfToken, updateRecurrence, RecurrenceDetailsId, deleteRecurrence } from '../../actions/PaymentAction';
import { Textfield } from './../../components/Textfield';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { setupPaymentGatewayProperties } from '../../helpers/functions/paymentGateway.properties.js';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import classes from './Recurrence.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LoadingOverlay from 'react-loading-overlay';
// // import config from './../../constants/config'
import DatePickerInput from '../../components/DatePicker/DatePickerInput.js';
import validator from '../../helpers/checkValidations';
import TypableSelect from './../../helpers/TypableSelect';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import moment from 'moment';

const frequency = [
  { value: '1', lable: 'Annually', usedInMulitpass: true }, // 4y
  { value: '8', lable: 'Semi-Annually', usedInMulitpass: true }, // y
  { value: 'A', lable: 'Trimesterly', usedInMulitpass: false }, // n
  { value: '2', lable: 'Quarterly', usedInMulitpass: true }, // y
  { value: '9', lable: 'Bi-Monthly', usedInMulitpass: true }, //y
  { value: '3', lable: 'Monthly', usedInMulitpass: true }, // y
  { value: '4', lable: 'Bi-Weekly', usedInMulitpass: true }, //y
  { value: '7', lable: '1st and 15th', usedInMulitpass: false }, // n
  { value: '5', lable: 'Weekly', usedInMulitpass: true }, // y
  { value: '6', lable: 'Daily', usedInMulitpass: true }, // y
];

const mapToFormat = (values) => {
  return values.map((Obj) => ({ value: Obj.value, label: Obj.lable }));
};

const getValidationStyle = (cond) => {
  if (cond) {
    return { width: '100%', border: '2px solid red', padding: '10px 0px' };
  } else {
    return { width: '100%' };
  }
};

class RecurrenceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      sideShow: true,
      REFUND: false,
      isLoading: true,
      isSubmit: false,
      customer_receipt: false,
      NAME: '',
      EMAIL: '',
      PHONE: '',
      amount: 0,
      FREQUENCY: '',
      TOTALCOUNT: '',
      paytraceCredentials: {},
      startDate: new Date(),
      endDate: new Date(),
      deletedAt: new Date(),
      misMatchError: [],
      address: '',
      zip: '',
      locationId: null,
      csrf: { 'csrf-token': '', 'csrf-secret': '' },
      error: {
        amountError: false,
        TOTALCOUNTError: false,
      },
    };
  }

  componentDidMount() {
    const paymentGateway = getLocationPaymentGateway();
    this.setPaymentGatewayProperties(paymentGateway);
    this.getRecurrenceDetails();
    this.getCsrfTokens();
    const location = JSON.parse(localStorage.getItem('locationPaydetails'));
    this.setState({ locationId: location ? location.id : '' });
  }
  getCsrfTokens() {
    this.props.getCsrfToken().then((res) => {
      this.setState({ csrf: { ...res.payload.paymentData.data } });
    });
  }
  handleChange(event, _t) {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'amount' && value === '0') {
      alert('Amount should be greater than zero.');
      return false;
    }
    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({ [id]: value, error, misMatchError, isSubmit: false }, () => {
      if (id === 'frequency' || id === 'StartDate' || id === 'TOTALCOUNT') {
        this.changeFrequency(this.state.FREQUENCY);
      }
    });
  }

  setPaymentGatewayProperties(paymentGatewayVal) {
    const { misMatchErrors, paytraceCredentials, multipassCredentials } =
      setupPaymentGatewayProperties(paymentGatewayVal);
    if (misMatchErrors) {
      this.setState({ misMatchErrors });
    } else {
      paymentGatewayVal === 'paytrace'
        ? this.setState({ paytraceCredentials })
        : this.setState({ multipassCredentials });
    }
  }

  changeFrequency(value) {
    const count = +this.state.TOTALCOUNT > 0 ? this.state.TOTALCOUNT - 1 : 0;
    let endDate = moment(this.state.startDate);
    let EndDate = endDate;
    // console.log("Frequency --> ",value)
    switch (value) {
      case 'A':
        EndDate = endDate.add(count * 3, 'M');
        break;
      case '1':
        EndDate = endDate.add(count, 'Y');
        break;
      case '8':
        EndDate = endDate.add(count / 2, 'Y');
        break;
      case '9':
        EndDate = endDate.add(count * 2, 'M');
        break;
      case '2':
        EndDate = endDate.add(count * 4, 'M');
        break;
      case '3':
        EndDate = endDate.add(count, 'M');
        break;
      case '5':
        EndDate = endDate.add(count, 'W');
        break;
      case '4':
        EndDate = endDate.add(count * 2, 'W');
        break;
      case '7':
        EndDate = endDate.add(count / 2, 'M');
        break;
      default:
        EndDate = endDate.add(count, 'd');
        break;
    }
    // console.log("End Date",  EndDate )
    this.setState({ endDate: EndDate.toDate() });
  }

  getRecurrenceDetails() {
    let misMatchError = [];
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    this.props.RecurrenceDetailsId(params, this.state.paytraceCredentials).then((res) => {
      let transaction = res.payload.paymentData.data;
      console.log(res);
      if (transaction.success) {
        this.setState({
          ...transaction.response,
          endDate: transaction.response?.endDate,
          isLoading: false,
          amount: transaction.response.AMOUNT,
        });
      } else {
        misMatchError.push(transaction.message);
        this.setState({ misMatchError, isLoading: false });
      }
    });
  }

  validateResponse(response, misMatchError, successMessages) {
    if (response.length > 0) {
      let responseErrors = JSON.parse(response);
      if (responseErrors.response_code === 1) {
        for (let key in responseErrors.errors) {
          let i = 0;
          misMatchError.push(responseErrors.errors[key][i] + '\n');
        }
        this.setState({ isLoading: false, misMatchError, successMessages });
      } else {
        misMatchError.push(responseErrors.approval_message);
      }
    }

    return misMatchError;
  }

  handleSubmitDeleteRecurring(e, _t) {
    e.preventDefault();
    let misMatchError = [];
    let successMessages = false;

    if (window.confirm('Are you sure you want to delete this item?')) {
      const paymentGateway = getLocationPaymentGateway();
      this.setState({ isLoading: true, isSubmit: true });
      const gatewayCredentials =
        paymentGateway === 'multipass' ? this.state.multipassCredentials : this.state.paytraceCredentials;
      this.props.deleteRecurrence(this.state, this.state.csrf, gatewayCredentials).then((result) => {
        window.scrollTo(0, 0);
        if (result !== undefined && result.type === 'PAYMENT_SUCCESS') {
          this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
            setTimeout(() => {
              this.setState({ successMessages: false });
              this.setState({ redirect: true });
            }, 3000);
          });
        }
        if (result !== undefined && result.type === 'PAYMENT_ERROR') {
          if (
            result.payload.error.data &&
            (result.payload.error.data.statusCode === 403 || result.payload.error.data.statusCode === 401)
          ) {
            misMatchError.push(result.payload.error.data.message);
            this.setState({ isLoading: false, misMatchError, successMessages });
          }
          misMatchError = this.validateResponse(
            this.props.info.paymentInfo.data.response,
            misMatchError,
            successMessages
          );
          this.setState({ isSubmit: false, isLoading: false, misMatchError, successMessages });
        }
      });
    } else {
      return;
    }
  }

  validateState(error, misMatchError = null) {
    let errorFlag = null;
    if (this.state.PHONE === '' || this.state.PHONE === null || this.state.PHONE === undefined) {
      delete this.state['PHONE'];
    }
    if (this.state.TOTALCOUNT < 1 || this.state.TOTALCOUNT > 999) {
      error['TOTALCOUNTError'] = true;
      misMatchError.push(`Please enter a valid number of billing cycle value`);
      errorFlag = true;
    }
    const state = this.state;
    Object.keys(this.state).forEach((key) => {
      if (
        validator(key, state[key]) ||
        (typeof state[key] === 'string' && state[key].trim() === '') ||
        state[key] === ''
      ) {
        if (key === 'email' && state[key] !== '') {
          error[`${key} Error`] = true;
          errorFlag = true;
          misMatchError.push(`Please enter a valid ${key} value`);
        } else {
          error[`${key} Error`] = false;
        }
      } else {
        error[`${key} Error`] = false;
      }
    });

    return [errorFlag, misMatchError];
  }

  handleSubmitUpdateRecurring(e, _t) {
    e.preventDefault();
    let misMatchError = [];
    let error = {};
    let successMessages = false;
    let errorFlag = false;

    [errorFlag, misMatchError] = this.validateState(error, misMatchError);
    const paymentGateway = getLocationPaymentGateway();
    if (errorFlag) {
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      if (window.confirm('Are you sure you want to update recurring?')) {
        const gatewayCredentials =
          paymentGateway === 'multipass' ? this.state.multipassCredentials : this.state.paytraceCredentials;
        this.setState({ isLoading: true, isSubmit: true });
        this.props.updateRecurrence(this.state, this.state.csrf, gatewayCredentials).then((result) => {
          window.scrollTo(0, 0);
          if (result !== undefined && result.type === 'PAYMENT_SUCCESS') {
            this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
              setTimeout(() => {
                this.setState({ successMessages: false });
                this.setState({ redirect: true });
              }, 2000);
            });
          }
          if (result !== undefined && result.type === 'PAYMENT_ERROR') {
            if (
              (result.payload.error.data && result.payload.error.data.statusCode === 403) ||
              (result.payload.error.data && result.payload.error.data.statusCode === 409)
            ) {
              misMatchError.push(result.payload.error.data.response);
              this.setState({ isLoading: false, misMatchError, successMessages });
            }
            misMatchError = this.validateResponse(
              this.props.info.paymentInfo.data.response,
              misMatchError,
              successMessages
            );
            this.setState({ isSubmit: false, isLoading: false, misMatchError, successMessages });
          }
        });
      } else {
        return;
      }
    }
  }

  handleDateChange = (date, key) => {
    this.setState({ [key]: date }, () => {
      if (key === 'StartDate') this.changeFrequency(this.state.FREQUENCY);
    });
  };

  setClientValue(val) {
    let misMatchError = [];
    if (val !== null) {
      this.setState({ FREQUENCY: val.value, misMatchError }, () => {
        this.changeFrequency(val.value);
      });
    } else {
      this.setState({ FREQUENCY: '', misMatchError });
    }
  }

  handleChangeCheckbox(event) {
    this.setState({ [event.target.id]: event.target.checked });
  }
  getFrequency(val) {
    let fre = frequency.filter((item) => item.value === val);
    return fre[0];
  }

  render() {
    const { t } = this.props;
    const { redirect } = this.state;

    return (
      <div className="rightPanel">
        {redirect ? <Redirect to="/Recurring" /> : ''}
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
            <Container component="main" maxWidth="xl">
              {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
              {this.state.successMessages ? (
                <SuccessMessage successes={[this.props.info.paymentInfo.data.message]} />
              ) : (
                ''
              )}

              <form className={classes.form} noValidate>
                <input type="hidden" name="_csrf" value={this.state.csrf['csrf-token']} />
                <input type="hidden" name="_csrfSecret" value={this.state.csrf['csrf-secret']} />
                {this.state.FINAL_STATUS === 'Deleted' ? (
                  <h3 className="errorMsg">
                    <span>Warning : </span>This transaction has been deleted on{' '}
                    {new Date(this.state.deletedAt).toLocaleString()}.
                  </h3>
                ) : (
                  ''
                )}
                {this.state.FINAL_STATUS === 'Updated' ? (
                  <h3 className="noteMsg">
                    <span>Note : </span>This transaction has been already updated on{' '}
                    {new Date(this.state.updatedAt).toLocaleString()}
                  </h3>
                ) : (
                  ''
                )}
                <Grid container spacing={3} className="ViewTxnFormBox">
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="NAME"
                      type="text"
                      labels={t('Name')}
                      value={this.state.NAME}
                      disabled={true}
                      width={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="EMAIL"
                      type="email"
                      labels={t('Email')}
                      value={this.state.EMAIL}
                      disabled={true}
                      width={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="PHONE"
                      type="text"
                      labels={t('Phone')}
                      value={this.state.PHONE}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Textfield
                      error={this.state.error.amountError}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      required={true}
                      id="amount"
                      type="text"
                      labels={t('Amount')}
                      value={this.state.amount > 0 ? this.state.amount : ''}
                      onChange={(e) => this.handleChange(e, t)}
                      width={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl
                      className="frequencyInput"
                      label="Frequency type"
                      fullWidth
                      style={getValidationStyle(this.state.FREQUENCY === '' && this.state.isSubmit)}
                    >
                      <TypableSelect
                        placeholder={'Frequency type'}
                        suggestions={mapToFormat(frequency)}
                        onChange={(val) => this.setClientValue(val)}
                        isSelected={
                          this.getFrequency(this.state.FREQUENCY)
                            ? this.getFrequency(this.state.FREQUENCY)['lable']
                            : ''
                        }
                        isClearable={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      error={this.state.error.TOTALCOUNTError}
                      InputProps={{ inputProps: { min: 1 } }}
                      required={true}
                      id="TOTALCOUNT"
                      type="number"
                      min="1"
                      max="999"
                      labels={t('Number of Billing Cycles')}
                      value={this.state.TOTALCOUNT}
                      onChange={(e) => this.handleChange(e, t)}
                      width={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DatePickerInput
                      id="StartDate"
                      label={t('Start Date')}
                      value={this.state.startDate}
                      onChange={(date) => {
                        this.handleDateChange(date, 'startDate');
                      }}
                      format="MM/dd/yyyy"
                      closeOnSelect
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    {this.state.endDate ? (
                      <DatePickerInput
                        id="EndDate"
                        label={t('End Date')}
                        value={this.state.endDate ? this.state.endDate : 'N/A'}
                        onChange={(date) => {
                          this.handleDateChange(date, 'endDate');
                        }}
                        format="MM/dd/yyyy"
                        closeOnSelect
                        minDate={this.state.startDate}
                      />
                    ) : (
                      <Textfield
                        id="endDate"
                        type="text"
                        labels={t('End Date')}
                        value={'N/A'}
                        disabled={true}
                        width={true}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="MASKCARDNUMBER"
                      type="text"
                      labels={t('MaskNumber')}
                      value={this.state.MASKCARDNUMBER}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ marginTop: '3.25%' }}></Grid>
                  <Grid item xs={12} sm={12}>
                    <Buttons
                      variant="contained"
                      color="secondary"
                      text={t('Back')}
                      onClick={this.props.history.goBack}
                    />

                    {this.props.user.permissions.recurring &&
                    !this.state.isSubmit &&
                    (this.state.FINAL_STATUS === 'Success' || this.state.FINAL_STATUS === 'Updated') ? (
                      <>
                        <Buttons
                          className={classes.btnRed}
                          variant="contained"
                          text={t('Delete')}
                          onClick={(e) => this.handleSubmitDeleteRecurring(e, t)}
                        />
                        <Buttons
                          className={classes.ml_2}
                          variant="contained"
                          color="primary"
                          text={t('Update')}
                          onClick={(e) => this.handleSubmitUpdateRecurring(e, t)}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </form>
            </Container>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

RecurrenceDetails.propTypes = {
  history: PropTypes.object,
  getCsrfToken: PropTypes.func,
  match: PropTypes.any,
  RecurrenceDetailsId: PropTypes.func,
  info: PropTypes.object,
  deleteRecurrence: PropTypes.func,
  updateRecurrence: PropTypes.func,
  user: PropTypes.object,
  t: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    user: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, { getCsrfToken, updateRecurrence, RecurrenceDetailsId, deleteRecurrence })(
  withTranslation()(RecurrenceDetails)
);
