import React, { Component } from 'react';
import { changeFocus } from '../../helpers/changeFocusCard';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import autoFillMultipassFields from '../../helpers/functions/autoFillCardFields';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { ALL_LOCATION_ERROR } from './../../constants/message';
import LoadingOverlay from 'react-loading-overlay';
import config from '../../constants/config';
import { Textfield } from './../../components/Textfield';
import { setupPaymentGatewayProperties } from '../../helpers/functions/paymentGateway.properties.js';
import { checkCreditOrDebit } from '../../helpers/PostDataApi';
import { setTsepAuth } from '../../actions/LocationAction';
import { makePhoneNumberForDB, isAddressInputValid } from '../../helpers/util';
import { validateZipCode } from '../../helpers/functions/validateZipCode';
import { addCustomer, editCustomer } from '../../actions/CustomerAction';
import { UserDetailById } from '../../actions/AdminUserAction';
import { Buttons } from './../../components/Buttons';
import { Checkbox } from '@material-ui/core';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import TsepCard from '../Terminal/components/TsepCard';
import PaytraceCard from '../Terminal/components/PaytraceCard';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import validator from '../../helpers/checkValidations';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classesCus from './Customers.module.scss';
import { DialogBox } from './../../components/DialogBox/DialogBox';
import TextfieldLable from './TextfieldLable';
import CloseIcon from '@material-ui/icons/Close';
import { validateCardValues, getLocationPaymentGateway } from '../../helpers/commonFunction';
import { initialAddressState, initialAddressSettings } from '../../helpers/addressSettingsState';
import { changeHandler, validateInputFields } from './CommonFunction';
import { rules } from '../../constants/IntakeFormConstants';
import AddressFieldsDropDown from '../../components/AddressFieldsDropDown/AddressFieldsDropDown';
import { checkAddressEnabled } from '../../helpers/functions/addressFieldsEnabled';
import { handleAddressSettingError } from '../../helpers/functions/validateAddressfields';

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});
const initialState = {
  locationId: '',
  sideShow: true,
  isLoading: true,
  isOpen: false,
  firstName: '',
  lastName: '',
  createdBy: '',
  companyId: '',
  email: '',
  phone: '',
  customer_id: '',
  customFields: [],
  customerPortal: false,
  noCard: false,
  isSubmit: false,
  isClose: false,
  misMatchError: [],
  successMessages: false,
  cardPanelShow: true,
  cardWalletID: null,
  tsepFieldError: null,
  tsepFieldSuccess: false,
  tsepSuccessResponse: null,
  tsepAuth: false,
  isProcessWithoutCard: false,
  paytraceCredentials: {},
  customerUserCreation: false,
  card: {
    cardHolderName: '',
    cardNumber: '_______________',
    cardExpiryDate: '__/____',
    cardCsc: '___',
    customerToken: false,
    customerTokenUpdate: false,
    creditOrDebit: null,
    isOpen: true,
  },
  error: {
    firstNameError: false,
    emailError: false,
    amountError: false,
    phoneError: false,
    customer_idError: false,
  },
  errorCard: {
    cardNumber: false,
    cardHolderName: false,
    cardExpiryDate: false,
    cardCsc: false,
  },
  isMobile: window.innerWidth < 768,
  ...initialAddressSettings,
  ...initialAddressState,
};

const getNameError = (state, key) => {
  return state?.error[key + 'Error']
    ? rules[key].messages.blankspace
    : state?.error[key + 'Error']
    ? rules[key].messages.stringEmptySpaces
    : state?.error[key + 'Error']
    ? rules[key].messages.characterlimit
    : '';
};

class AddCustomers extends Component {
  componentWillUnmount() {
    this.unmountActions();
  }

  constructor(props) {
    super(props);
    this.state = initialState;
    this.refExpireDate = React.createRef();
    this.refCscCard = React.createRef();
    this.refCard = React.createRef();
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleChangedCard = this.handleChangedCard.bind(this);
  }

  componentDidMount() {
    const paymentGateway = getLocationPaymentGateway();
    this.setPaymentGatewayProperties(paymentGateway);
    this.fetchAddressDetailss();
    this.fetchDetailsData();
    this.checkSizeMobile();
    this.checkAllLocationError();
    this.setUserData();
    this.injectEventListeners();
  }

  setUserData() {
    let userData = JSON.parse(localStorage.getItem('user'));
    this.setState({ createdBy: userData._id, companyId: userData.company._id, isLoading: false });

    const locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    if (locationPaydetails.customerPortal) {
      this.setState({ customerPortal: locationPaydetails.customerPortal });
    }
  }

  injectEventListeners() {
    window.addEventListener('resize', this.checkSizeMobile.bind(this));
    document.addEventListener('tsepEventUpdate', this.handleTsepEventUpdate);
  }

  checkAllLocationError() {
    let misMatchError = [];
    if (this.checkAllLocationSelected()) {
      misMatchError.push(ALL_LOCATION_ERROR);
      this.setState({ misMatchErrors: misMatchError, allLocationError: true });
    } else {
      this.setState({ allLocationError: false });
    }
  }

  setPaymentGatewayProperties(paymentGatewayName) {
    const { misMatchErrors, paytraceCredentials, multipassCredentials } =
      setupPaymentGatewayProperties(paymentGatewayName);
    if (misMatchErrors) {
      this.setState({ misMatchErrors });
    } else {
      paymentGatewayName === 'paytrace'
        ? this.setState({ paytraceCredentials })
        : this.setState({ multipassCredentials });
    }
  }

  handleTsepEventUpdate = (event) => {
    const tsepevent = event.detail;
    if (tsepevent.eventType === 'FieldValidationErrorEvent') {
      this.setState({ tsepFieldError: tsepevent.event.message });
    } else if (tsepevent.eventType === 'TokenEvent' && tsepevent.event.status === 'PASS') {
      this.setState(
        { tsepSuccessResponse: { ...tsepevent.event }, tsepFieldSuccess: true, tsepFieldError: null },
        () => {
          const saveCustomer = document.getElementById('saveCustomer');
          saveCustomer.disabled = false;
        }
      );
    } else if (tsepevent.eventType === 'TokenEvent' && tsepevent.event.status === 'FAILURE') {
      this.setState({ tsepFieldError: tsepevent.event.message });
    } else if (tsepevent.eventType === 'FocusOutEvent' && tsepevent.event.fieldName === 'tsep-cardNum') {
      this.setTSEPCardPaymentType();
    } else if (
      tsepevent.eventType === 'ErrorEvent' &&
      tsepevent.event.status === 'FAIL' &&
      tsepevent.event.responseCode === 'TSEPERR911'
    ) {
      this.props.setTsepAuth(false);
    }
    console.log('Tsep catched event:', tsepevent);
  };

  setTSEPCardPaymentType = () => {
    const cardNumElement = document.getElementById('tsep-cardNum');
    if (cardNumElement) {
      const cardValue = cardNumElement?.value.replace(/\s/g, '');
      this.checkCardPaymentType(cardValue);
    }
  };

  checkAllLocationSelected() {
    const selectedLocation1 = JSON.parse(localStorage.getItem('selected'));
    const userDetail = JSON.parse(localStorage.getItem('user'));
    if (selectedLocation1 === 0 && userDetail.permissions.allLocaton) {
      this.setState({ allLocationError: true });
      return true;
    } else {
      this.setState({ allLocationError: false });
      return false;
    }
  }

  fetchDetailsData() {
    this.setState({ isLoading: true });
    let userData = JSON.parse(localStorage.getItem('user'));
    this.props.UserDetailById({ id: userData._id }).then((res) => {
      if (res.type === 'USER_SUCCESS' && res.payload.userData.status === 200) {
        this.setState({ isLoading: false, settings: res.payload.userData.data.settings });
      }
    });
  }

  unmountActions() {
    localStorage.removeItem('aws_url');
    window.removeEventListener('resize', this.checkSizeMobile);
  }
  checkCardValues(state) {
    const paymentGateway = getLocationPaymentGateway();
    if (paymentGateway === 'paytrace') {
      const { cardNumberValidation, cardExpiryValidation, cardCscValidation, errorCard, cardNumberEmpty } =
        validateCardValues(this.state, state);
      if (cardNumberEmpty) {
        return true;
      }
      if (!cardNumberValidation || !cardCscValidation || !cardExpiryValidation) {
        this.setState({ errorCard });
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  fetchAddressDetailss() {
    this.setState({ isLoading: true });
    let userData = JSON.parse(localStorage.getItem('user'));
    this.props.UserDetailById({ id: userData?._id }).then((response) => {
      if (response.type === 'USER_SUCCESS' && response.payload.userData.status === 200) {
        this.setState({
          addressSettings: {
            ...this.state.addressSettings,
            shippingAddress: response.payload.userData.data.settings?.virtualTerminalSettings?.shippingAddress,
            billingAddress: response.payload.userData.data.settings?.virtualTerminalSettings?.billingAddress,
          },
          isLoading: false,
        });
      }
    });
  }

  handleChange(event, _t) {
    const { id, value, misMatchError, error, validationMarkup } = changeHandler(event);
    if (!validationMarkup) {
      return false;
    }
    this.setState({ [id]: value, error, misMatchError, isSubmit: false, successMessages: false });
  }

  checkTsepFieldErrors(misMatchError, errorFlag) {
    if (this.state.tsepFieldError) {
      window.scrollTo(0, 0, 0);
      misMatchError.push(this.state.tsepFieldError);
      this.setState({ misMatchError });
      errorFlag = true;
    } else {
      errorFlag = false;
    }
    return errorFlag;
  }

  handleSubmitAdd(_e, _t) {
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true });
    const paymentGateway = getLocationPaymentGateway();
    const locationPaydetails = JSON.parse(localStorage.getItem('locationPaydetails'));
    console.log('locationPaydetails', locationPaydetails);
    this.setState({ locationId: locationPaydetails.id });
    //check cutom fields values
    const values = [...this.state.customFields];
    values.forEach((key, val, customField) => {
      return customField[val] === null || customField[val] === ''
        ? ((error[key.label + 'Error'] = true), (errorFlag = true))
        : (error[key.label + 'Error'] = false);
    });
    if (this.checkCardValues(this.state.card)) {
      for (const key of Object.keys(state)) {
        const { errorFlagValue, errorObj } = validateInputFields(key, state);
        if (errorFlagValue) {
          errorFlag = errorFlagValue;
          error = { ...errorObj };
          break;
        }
      }

      const zipCodeError2 = validateZipCode(state);
      if (zipCodeError2.length > 0) {
        this.setState({ misMatchError: zipCodeError2 });
        return false;
      }
      const addressError = handleAddressSettingError(this.state);
      if (addressError.length > 0) {
        misMatchError = addressError;
        errorFlag = true;
      }
      if (paymentGateway === 'multipass') {
        const fieldError = this.checkTsepFieldErrors(misMatchError, error);
        if (fieldError) {
          errorFlag = true;
        }
      }
      if (errorFlag) {
        this.setState({ misMatchError, logoError, error, successMessages });
        return false;
      } else {
        if (paymentGateway === 'paytrace') {
          this.initiatePaytraceAddCustomer(misMatchError, successMessages);
        } else {
          this.initiateMultipassAddCustomer(misMatchError, successMessages);
        }
      }
    }
  }

  setCardPayload() {
    const paymentGateway = getLocationPaymentGateway();
    let cardPayload = {
      card: { ...this.state.card, cardNumber: '', cardCsc: '' },
      phone: makePhoneNumberForDB(this.state.phone),
      encrypted_number: null,
      encrypted_csc: null,
    };
    if (paymentGateway === 'multipass') {
      const tsepResp = this.state?.tsepSuccessResponse;
      cardPayload.encrypted_number = tsepResp ? tsepResp.tsepToken : null;
      cardPayload.encrypted_csc = tsepResp ? tsepResp.cvv2 : null;
      cardPayload.card = { ...cardPayload.card, cardExpiryDate: tsepResp?.expirationDate };
    } else {
      cardPayload.encrypted_number = localStorage.getItem('0');
      cardPayload.encrypted_csc = localStorage.getItem('1');
    }
    return cardPayload;
  }

  initiateMultipassAddCustomer(successMessages) {
    const cardpayload = this.setCardPayload();
    this.setState(
      {
        isLoading: true,
        ...cardpayload,
        phone: makePhoneNumberForDB(this.state.phone),
      },
      () => {
        window.scrollTo(0, 0, 0);
        this.sendAddCustomerRequest(successMessages);
      }
    );
  }
  initiatePaytraceAddCustomer(successMessages) {
    window.paytrace.submitEncrypted('#DemoFormCustomer');
    const cardpayload = this.setCardPayload();
    this.setState(
      {
        isLoading: true,
        ...cardpayload,
        phone: makePhoneNumberForDB(this.state.phone),
      },
      () => {
        localStorage.removeItem('0');
        localStorage.removeItem('1');
        window.scrollTo(0, 0, 0);
        this.sendAddCustomerRequest(successMessages);
      }
    );
  }

  sendAddCustomerRequest(successMessages) {
    this.props.addCustomer(this.state, {}, this.state.paytraceCredentials).then((result) => {
      if (result !== undefined && result.type === 'CUSTOMER_SUCCESS') {
        this.setState({ ...initialState, isLoading: false, successMessages: true }, () => {
          setTimeout(() => {
            this.setState({ redirect: true });
          }, 2000);
        });
      }
      if (result !== undefined && result.type === 'CUSTOMER_ERROR') {
        this.setState({
          misMatchError: this.props.info.customerInfo.data.message,
          isLoading: false,
          successMessages,
        });
      }
    });
  }
  handleClose = () => {
    this.setState({ isOpen: false });
  };
  handleChangeCustom(event, t, i) {
    let error = {};
    const values = [...this.state.customFields];
    values[i].value = event.target.value;
    this.setState({ customFields: values, error }, () => {});
  }
  handleAdds() {
    this.setState({ isOpen: true });
  }

  handleLabel = (data1) => {
    const values = [...this.state.customFields];
    values.push({ value: null, label: data1.LableName });
    this.setState({ customFields: values });
  };
  handleRemoved(i) {
    const values1 = [...this.state.customFields];
    values1.splice(i, 1);
    this.setState({ customFields: values1 });
  }
  handleChangedCard(event) {
    let error = {};
    let { id, value } = event.target;
    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    if (id === 'cardNumber' && value[0] === '3' && value.length === 16 && value[value.length - 1] === '_') {
      value = value.slice(0, -1);
    }
    if (id == 'cardExpiryDate') {
      const month = value.split('/')[0];
      if (month.length == 2 && (parseInt(month) <= 0 || parseInt(month) > 12)) {
        error[id + 'Error'] = true;
      }
    }
    this.updateCardState1(id, value, error);
    const expiryNodes = this.refExpireDate.current;
    const cscCardNode = this.refCscCard.current;
    changeFocus(id, value, expiryNodes, cscCardNode);
  }

  handleChangeCheckbox(event) {
    this.setState({ card: { ...this.state.card, [event.target.id]: event.target.checked } });
  }

  handleAddressChange = (event, type, isBillingAddressSameAsShippingAddress = false) => {
    let { id, value } = event.target;

    if (!isAddressInputValid(id, value)) {
      return;
    }

    if (type === 'shippingAddress' || isBillingAddressSameAsShippingAddress) {
      const shippingAddressState = {
        ...this.state.shippingAddress,
        [id]: value,
      };
      this.setState({ shippingAddress: shippingAddressState });
    }
    if (type === 'billingAddress') {
      const billingAddressState = {
        ...this.state.billingAddress,
        [id]: value,
      };
      this.setState({ billingAddress: billingAddressState });
    }
  };

  handleIsBillingAddressSameAsShippingAddress = (isBillingAddressSameAsShippingAddress = false) => {
    if (isBillingAddressSameAsShippingAddress) {
      this.setState({ shippingAddress: { ...this.state.billingAddress } });
    }
  };

  checkSizeMobile() {
    const mobileSize = 850; // Set the threshold for mobile size (adjust as needed)
    this.setState({ isMobile: window.innerWidth < mobileSize });
  }

  checkCardPaymentType(firstNumber) {
    checkCreditOrDebit(config.BIN_CHECKER_URL, config.BIN_API_KEY, firstNumber.substring(0, 6))
      .then((data) => {
        this.setState({
          card: {
            ...this.state.card,
            customerToken: true,
            cardType: data?.data?.card,
            creditOrDebit: data.data.type ? data.data.type : null,
            binCodesResponse: data.data,
          },
          creditOrDebit: data?.data.type ?? null,
        });
      })
      .catch((err) => {
        console.log('err112', err);
      });
  }

  updateCardState1(id_, value_, error) {
    let misMatchError = [];
    this.setState(
      {
        card: { ...this.state.card, [id_]: value_ },
        errorCard: { ...this.state.errorCard, [id_]: error[id_ + 'Error'] },
        error,
        misMatchError,
        isSubmit: false,
      },
      () => {
        let firstNumber = this.state.card.cardNumber.substring(0, 14);
        if (firstNumber && id_ == 'cardNumber') {
          this.checkCardPaymentType(firstNumber);
        }

        this.setState({ card: { ...this.state.card } });
        if (firstNumber[0] === '3') {
          this.setState({ americanExpress: true });
        } else {
          this.setState({ americanExpress: false });
        }
      }
    );
  }

  handleChangeSaveCustomerUser(event) {
    console.log(event.target.id, 'is');
    this.setState({
      customerUserCreation: event.target.checked,
    });
  }

  capitalizeFirstLetters = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  render() {
    const { classes, t } = this.props;
    const { redirect } = this.state;
    const { isMobile } = this.state;
    const paymentGateway = getLocationPaymentGateway();
    if (paymentGateway === 'multipass') {
      autoFillMultipassFields();
    }
    return (
      <div className="rightPanel">
        {redirect ? <Redirect to="/Customers" /> : ''}
        <Topheader />
        <div className="contentPart">
          <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
            <Container component="main" maxWidth="xl">
              {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
              {this.state.successMessages ? (
                <SuccessMessage successes={[this.props.info.customerInfo.data.message]} />
              ) : (
                ''
              )}
              {this.state.tsepFieldError ? <ErrorMessage errors={[this.state.tsepFieldError]} /> : ''}
              <h3>Add Customer</h3>
              <form className={classes.form} noValidate ref={this.myRef} id={'DemoFormCustomer'}>
                <DialogBox
                  open={this.state.isOpen}
                  handleClose={this.handleClose}
                  message={<TextfieldLable handleClose={this.handleClose} handleLabels={this.handleLabel} />}
                />
                <Grid container item xs={12} spacing={1}>
                  <Grid container item sm={6} xs={12}>
                    <Textfield
                      required={true}
                      error={this.state.error.firstNameError}
                      id="firstName"
                      helperText={getNameError(this.state, 'firstName')}
                      type="text"
                      labels={t('FirstName')}
                      value={this.state.firstName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={false}
                    />
                  </Grid>
                  <Grid container item sm={6} xs={12}>
                    <Textfield
                      required={true}
                      helperText={getNameError(this.state, 'lastName')}
                      error={this.state.error.lastNameError}
                      id="lastName"
                      type="text"
                      labels={t('LastName')}
                      value={this.state.lastName}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={false}
                    />
                  </Grid>
                  <Grid container item sm={6} xs={12}>
                    <Textfield
                      required={false}
                      error={this.state.error.emailError}
                      id="email"
                      type="text"
                      labels={t('Email')}
                      value={this.state.email}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={false}
                    />
                  </Grid>
                  <Grid container item sm={6} xs={12}>
                    <Textfield
                      required={false}
                      error={this.state.error.phoneError}
                      id="phone"
                      type="tel"
                      labels={t('Phone')}
                      value={this.state.phone}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                      focus={false}
                      inputProps={{
                        pattern: '[0-9]*',
                        inputMode: 'numeric',
                      }}
                    />
                  </Grid>
                  {this.state.customerPortal ? (
                    <Grid container item sm={12} xs={12} md={12}>
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                          color="primary"
                          checked={this.state.customerUserCreation}
                          onChange={(e) => {
                            this.handleChangeSaveCustomerUser(e);
                          }}
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                          value={this.state.customerUserCreation}
                        />
                        <span style={{ padding: '13px', paddingLeft: '2px' }}>Send a customer portal signup link</span>
                      </label>
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid container item sm={6} xs={12}>
                    <Textfield
                      required={false}
                      helperText={this.state.error.customer_idError ? rules.customer_id.messages.blankspace : ''}
                      error={this.state.error.customer_idError}
                      id="customer_id"
                      type="text"
                      labels={t('Customer ID')}
                      value={this.state.customer_id}
                      onChange={(e) => this.handleChange(e, t)}
                      width={true}
                      focus={false}
                    />
                  </Grid>
                  <Grid container item sm={6} xs={12}></Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <span>
                      {this.state.creditOrDebit
                        ? this.capitalizeFirstLetters(this.state.creditOrDebit) + ' Card'
                        : 'Credit Card / Debit Card'}
                    </span>
                    {paymentGateway === 'multipass' ? (
                      <TsepCard
                        handleChangeCheckbox={(_e) => {}}
                        tsepFieldError={this.state.tsepFieldError}
                        tsepSuccessResponse={this.state.tsepSuccessResponse}
                        customerToken={this.state.card?.customerToken}
                        customerTokenUpdate={this.state.card?.customerTokenUpdate}
                        cardWalletID={this.state?.cardWalletID}
                        maskedCardNumber={this.state.mask_number}
                        cardPanelCustomerShow={this.state.cardPanelCustomerShow}
                        tsepAuth={this.props.CurrentLocation.tsepAuth}
                      />
                    ) : (
                      <>
                        <PaytraceCard
                          card={this.state.card}
                          cardPanelShow={this.state.cardPanelShow}
                          updateCard={false}
                          cardPanelCustomerShow={false}
                          addNewCard={false}
                          backToMaskCard={this.backToMaskCard}
                          cardRef={this.refCard}
                          errorCard={this.state.errorCard}
                          americanExpress={this.state.americanExpress}
                          handleChangeCard={this.handleChangedCard}
                          isRefundManual={false}
                          cscCardRef={this.refCscCard}
                          isOneTimeCustomer={false}
                          customerDetails={null}
                          handleChangeCheckbox={(_e) => {}}
                          handleChangeCheckboxUpdate={this.handleChangeCheckbox}
                          expiryDateRef={this.refExpireDate}
                          name={this.state.name}
                          t={this.props.t}
                        />
                      </>
                    )}
                  </Grid>

                  <Grid container spacing={3} direction="row">
                    {checkAddressEnabled(this.state.addressSettings?.billingAddress) && (
                      <Grid item md={12} sm={12}>
                        <AddressFieldsDropDown
                          type="billing"
                          state={this.state}
                          align={isMobile ? 'column' : 'row'}
                          handleChange={this.handleAddressChange}
                          handleSameAddress={this.handleIsBillingAddressSameAsShippingAddress}
                        />
                      </Grid>
                    )}
                    {checkAddressEnabled(this.state.addressSettings?.shippingAddress) && (
                      <Grid item md={12} sm={12}>
                        <AddressFieldsDropDown
                          type="shipping"
                          state={this.state}
                          align={isMobile ? 'column' : 'row'}
                          handleChange={this.handleAddressChange}
                          handleSameAddress={this.handleIsBillingAddressSameAsShippingAddress}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid container item sm={12} xs={12} justify="flex-end">
                    <Buttons
                      className={classesCus.ml_2}
                      variant="contained"
                      color="secondary"
                      text={t('Add More')}
                      onClick={(e) => this.handleAdds(e)}
                    />
                  </Grid>
                  {this.state.customFields.map((field, idx) => {
                    return (
                      <Grid item sm={6} xs={6} key={idx}>
                        <div key={`${field}-${idx}`}>
                          <CloseIcon onClick={() => this.handleRemoved(idx)} />

                          <Textfield
                            error={this.state.error[field.label + 'Error']}
                            id={field.label}
                            type="text"
                            labels={field.label}
                            value={field.value || ''}
                            width
                            onChange={(e) => this.handleChangeCustom(e, t, idx)}
                            focus
                          />
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xs={12} sm={12} className="mt-2">
                  <Buttons
                    className={classesCus.ml_2}
                    id="saveCustomer"
                    variant="contained"
                    color="primary"
                    text={t('Save')}
                    onClick={(e) => this.handleSubmitAdd(e, t)}
                  />
                  <Buttons
                    className={classesCus.ml_2}
                    variant="contained"
                    color="secondary"
                    text={t('Back')}
                    onClick={this.props.history.goBack}
                  />
                </Grid>
              </form>
            </Container>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

const ApplyingStyles = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  return <AddCustomers theme={theme} classes={classes} {...props} />;
};

function mapStateToProps(state) {
  return {
    info: state.CustomerReducer,
    infoUser: state.AdminUserReducer,
    CurrentLocation: state.LocationReducer,
  };
}

export default connect(mapStateToProps, { UserDetailById, addCustomer, editCustomer, setTsepAuth })(
  withTranslation()(withRouter(ApplyingStyles))
);

AddCustomers.propTypes = {
  UserDetailById: PropTypes.func,
  history: PropTypes.any,
  classes: PropTypes.any,
  info: PropTypes.any,
  t: PropTypes.any,
  addCustomer: PropTypes.func,
  CurrentLocation: PropTypes.any,
  setTsepAuth: PropTypes.func,
};
