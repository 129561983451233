import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from '../../components/Topheader';
import LoadingOverlay from 'react-loading-overlay';
import { Table } from '../../components/Table';
import PropTypes from 'prop-types';
import { ErrorMessage } from '../../components/Message/ErrorMessage';
import config from '../../constants/config';
import { DataApi } from '../../helpers/PostDataApi';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import AddApiKeyForm from '../CompanyDetails/AddApiKeyForm';
import { SuccessMessage } from '../../components/Message/SuccessMessage';

class ApiKey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideShow: true,
      isLoading: false,
      reload: false,
      misMatchError: [],
      userData: { permissions: {} },
      revokeApiModal: false,
      revokeApiKeyData: [],
      successMessages: '',
      addApiKey: false,
    };
  }

  componentDidMount() {
    this.getUserData();
  }

  getUserData(_query) {
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('user'));
      let token = user.token;
      let url = config.BASEURL;

      const payload = {
        userId: user?._id,
      };
      DataApi(payload, url + 'getApiKeyDetails', token, true)
        .then((result) => {
          resolve({
            data: result.data.response ? [result.data.response] : [],
          });
        })
        .catch((error) => {
          console.error('fetching user detials error : ', error);
          reject(error);
        });
    });
  }

  revokeApiKey() {
    return (
      <>
        <Dialog
          aria-labelledby="revoke-api-key-dialog-title"
          aria-describedby="revoke-api-key-dialog-description"
          open={this.state.revokeApiModal}
          onClose={() => this.setState({ revokeApiModal: false })}
        >
          <DialogTitle id="revoke-api-key-dialog-title">Revoke API Key</DialogTitle>
          <DialogContent>
            <Typography id="revoke-api-key-dialog-description">
              Are you sure you want to revoke this API key?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ revokeApiModal: false })} color="primary">
              No
            </Button>
            <Button onClick={(e) => this.submitRevokeApiRequest(e)} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  initiateRevokeApiKey(_e, data) {
    this.setState({ revokeApiModal: true, revokeApiKeyData: data });
  }

  submitRevokeApiRequest(e) {
    e.preventDefault();
    let misMatchError = [];
    let user = JSON.parse(localStorage.getItem('user'));
    let token = user.token;
    const payload = {
      userId: user && user?._id,
    };

    this.setState({ isLoading: true });
    DataApi(payload, config.BASEURL + 'revokeApiKey', token, true)
      .then((response) => {
        if (response.status === 200 && response.data && response.data.statusCode === 200) {
          this.setState({
            isLoading: false,
            misMatchError: [],
            revokeApiModal: false,
            successMessages: response.data?.message,
          });
          window.location.reload();
        } else {
          this.handleMismatchError(response.data?.message, misMatchError);
        }
      })
      .catch((err) => {
        console.log(err);
        this.handleMismatchError(err.message ?? 'Unable to revoke API key', misMatchError);
      });
  }

  handleMismatchErrors = (error, misMatchErrors) => {
    misMatchErrors.push(error);
    this.setState({
      misMatchErrors,
      isLoading: false,
    });

    return null;
  };
  openKeyForm = (e, status) => {
    e.preventDefault();
    console.log('openKeyForm', status);
    if (status === 1 || status) this.setState({ addApiKey: true });
    else this.setState({ addApiKey: false });
  };

  render() {
    const ApiKeysColumns = [
      { title: 'Key Name', field: 'apiKeyName' },
      { title: 'Username', field: 'userName' },
      // { title: 'Active Status', field: 'status', lookup: { true: 'Active', false: 'In Active' } }
    ];
    const user = JSON.parse(localStorage.getItem('user'));

    return (
      <div className="rightPanel">
        <Topheader />
        <div className="contentPart">
          {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
          {this.state.successMessages ? <SuccessMessage successes={[this.state.successMessages]} /> : ''}
          <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
            {this.revokeApiKey()}
            {this.state.addApiKey ? (
              <AddApiKeyForm
                props={this.props}
                handleChangeTab={this.openKeyForm}
                isApiUser={true}
                userId={user?._id}
              />
            ) : (
              <Table
                tableReload={(e) => this.tableReload(e)}
                options={{
                  exportButton: true,
                  search: true,
                }}
                columns={ApiKeysColumns}
                permissions={user && user?.permissions}
                data={(query) => this.getUserData(query)}
                title="API Keys"
                search={false}
                paging={false}
                apiUser={true}
                revokeApiKeyAction={(e, data) => this.initiateRevokeApiKey(e, data)}
                openApiKeyForm={this.openKeyForm}
              />
            )}
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

ApiKey.propTypes = {
  userInfo: PropTypes.any,
  history: PropTypes.any,
  uploadBulkInvoices: PropTypes.func,
  t: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    userInfo: state.AdminUserReducer,
    locationCurrent: state.LocationReducer,
  };
}

export default connect(mapStateToProps)(withTranslation()(ApiKey));
