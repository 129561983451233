import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classes from './Company.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { Topheader } from './../../components/Topheader';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import FlexAlert from './../../components/Message/Alert';
import { CompanyDetailById, companyEdit, countryDataList } from '../../actions/CompanyAction';
import TypableSelect from './../../helpers/TypableSelect';
import FormControl from '@material-ui/core/FormControl';
import { config } from '../../constants';
import validator from '../../helpers/checkValidations';
import Resizer from 'react-image-file-resizer';
import LoadingOverlay from 'react-loading-overlay';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { handleCompanyCheckboxChange } from './CommonFunctions';
import PropTypes from 'prop-types';
import { TextFlex } from '../../components/TextFlex';
import CompanySettings from './CompanySettings';

const filter = createFilterOptions();

const getValidationStyle = (cond) => {
  if (cond) {
    return { width: '100%', border: '2px solid red' };
  } else {
    return { width: '100%' };
  }
};

const mapToFormatCity = (state) => {
  return state.map((name) => ({ value: name, title: name }));
};

const mapToFormatStates = (state) => {
  return Object.keys(state).map((name) => ({ value: name, label: name }));
};

const mapToFormat = (values) => {
  return values.map((Obj) => ({ value: Obj.Country, label: Obj.Country }));
};

class EditCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
      sideShow: true,
      ACH: true,
      IsInvoicePageCustomized: true,
      customInvoicePageFields: [
        {
          key: 'FieldOneText',
          value: '',
          enabled: true,
          required: true,
        },
        {
          key: 'FieldTwoText',
          value: '',
          enabled: true,
          required: true,
        },
        {
          key: 'FieldThreeText',
          value: '',
          enabled: true,
          required: true,
        },
        {
          key: 'FieldFourText',
          value: '',
          enabled: true,
          required: true,
        },
        {
          key: 'FieldFiveText',
          value: '',
          enabled: true,
          required: true,
        },
        {
          key: 'FieldSixText',
          value: '',
          enabled: true,
          required: true,
        },
        {
          key: 'FieldSevenText',
          value: '',
          enabled: true,
          required: true,
        },
      ],

      isLoading: true,
      dbaName: '',
      email: '',
      contactName: '',
      website: '',
      mcc: '',
      address1: '',
      address2: '',
      state: '',
      city: '',
      stateList: {},
      cityList: [],
      Countrydata: [],
      zip: '',
      timeZone: new Date(),
      misMatchError: [],
      editData: [],
      logoError: [],
      successMessages: false,
      image: null,
      selectedFile: null,
      imageUrl: null,
      loaded: false,
      error: {
        nameError: false,
        dbaNameError: false,
        emailError: false,
        contactNameError: false,
        websiteError: false,
        mccError: false,
        address1Error: false,
        stateError: false,
        cityError: false,
        zipError: false,
        timeZoneError: false,
        fileError: false,
        FieldOneText: false,
        FieldTwoText: false,
        FieldThreeText: false,
        FieldFourText: false,
        FieldFiveText: false,
        FieldSixText: false,
        FieldSevenText: false,
      },
      customFields: [],
      customTransactionColumnFields: [],
      customTransactionColumnEnabled: false,
      customFieldsEnabled: false,
      customFieldsError: false,
      textFlexPermissions: {
        textFlex: false,
        customId: false,
        customerName: false,
        phoneNumber: false,
        amount: false,
      },
      paxSettings: {
        hidePayTraceLocations: false,
      },
    };
  }

  getCompanyData() {
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    this.props.CompanyDetailById(params.id).then((res) => {
      if (res.payload.companyData.data.response.image != 'null' && res.payload.companyData.data.response.image) {
        const uploadImageUrl = this.props.PAYTRACE_AWS_PATH + res.payload.companyData.data.response.image;
        this.setState({ imageUrl: uploadImageUrl });
      }
      this.setState({ ...res.payload.companyData.data.response, isLoading: false });
      if (
        !res.payload?.companyData?.data.response.customFields.some(
          (field) => field?.value == undefined || field?.value == null
        )
      ) {
        this.setState({ customFields: [{ label: 0, value: null, enabled: false }] });
      }
      this.setState({ customFields: res?.payload?.companyData?.data?.response?.customFields });
    });
  }
  componentDidMount() {
    this.getCompanyData();
    this.getcountryDataList();
  }
  getcountryDataList() {
    this.setState({ isLoading: true });
    this.props.countryDataList().then((res) => {
      this.setState({ Countrydata: res.payload.companyData.data, isLoading: false });
    });
  }

  handleClickMenu(_e) {
    if (this.state.sideShow) this.setState({ sideShow: false });
    else this.setState({ sideShow: true });
  }
  handleClickRefresh() {
    this.getCompanyData();
  }
  handleDateChange = (date) => {
    this.setState({ timeZone: date });
  };
  areCustomFieldsValid() {
    return this.state.customFieldsEnabled && this.state.customFields.some((cf) => cf?.value);
  }
  handleChange = (event, _t) => {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({ [id]: value, error, misMatchError });
  };
  handleChangeRequestSettings = (event, _t, index) => {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    let fieldsArray = this.state.customInvoicePageFields;

    fieldsArray[index].value = value;
    validator(id, value) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false);
    this.setState({ customInvoicePageFields: fieldsArray, error, misMatchError });
  };
  handleSubmit(_e, _t) {
    let misMatchError = [];
    let logoError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;

    Object.keys(state).forEach((key) => {
      if (key === 'address2' && state['address2'] === '') {
        return false;
      }
      if (key === 'phoneNumber' || key === 'amount') {
        return false;
      }
      if (key === 'website' && state['website'] === '') {
        return false;
      }

      if (validator(key, state[key])) {
        return validator(key, state[key])
          ? ((error[key + 'Error'] = true), (errorFlag = true))
          : (error[key + 'Error'] = false);
      } else {
        return state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')
          ? ((error[key + 'Error'] = true), (errorFlag = true))
          : '';
      }
    });

    if (this.state.customFieldsEnabled && !this.areCustomFieldsValid()) {
      error['customFields Error'] = true;
      errorFlag = true;
      this.setState({ customFieldsError: true });
    }

    if (errorFlag) {
      console.log('>>', error);
      this.setState({ misMatchError, error, logoError, successMessages });
      return false;
    } else {
      delete this.state._id;
      const data = new FormData();
      for (const key in this.state) {
        if (
          key === 'customFields' ||
          key === 'textFlexPermissions' ||
          key === 'customTransactionColumnFields' ||
          key === 'customInvoicePageFields' ||
          key === 'paxSettings' ||
          key === 'user'
        ) {
          data.append(key, JSON.stringify(this.state[key]));
        } else {
          data.append(key, this.state[key]);
        }
      }
      console.log('multipartFormData', data);
      if (window.confirm('Are you sure want to update?')) {
        this.setState({ isLoading: true });
        console.log('this.state', this.state);
        this.props.companyEdit(data).then((result) => {
          if (result !== undefined && result.type === 'COMPANY_SUCCESS') {
            const uploadImageUrl = this.props.PAYTRACE_AWS_PATH + result.payload.companyData.data.response.image;
            if (result.payload.companyData.data.response.image != 'null') {
              this.setState({ imageUrl: uploadImageUrl, successMessages: true, isLoading: false, loaded: false });
            }
            this.setState({ successMessages: true, isLoading: false, loaded: false });
          }
          if (result !== undefined && result.type === 'COMPANY_ERROR') {
            this.setState({ misMatchError: this.props.info.companyInfo.data.message, successMessages });
          }
        });
      }
    }
  }
  setStatesSet(value) {
    if (value !== null) this.setState({ cityList: this.state.stateList[value.label], state: value.label, city: '' });
  }
  setCitySet(event, value) {
    if (value !== null && Object.prototype.hasOwnProperty.call(value, 'value')) {
      this.setState({ city: value.title });
    } else if (value && value.inputValue) {
      this.setState({ city: value.inputValue });
    } else {
      this.setState({ city: value });
    }
  }
  setCountryState(value) {
    if (value !== null) {
      this.setState({
        stateList: this.state.Countrydata.filter((item) => item.Country === value.label)[0].State,
        country: value.label,
      });
    }
  }

  //upload image
  onChangeHandler = (event, t) => {
    let logoError = [];
    let error = false;
    let selectedFile = event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : '';
    let image = event.target.files[0];
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (image && !allowedExtensions.exec(image.name)) {
      logoError.push(t('CompanyLogoFileTypeNotCorrect'));
      error = true;
      this.setState({ error: { ...this.state.error, fileError: true }, logoError, image: null, loaded: false });
    }
    const size = 5 * 1048576;
    let imgName = '';
    if (image) {
      imgName = image.name.replace(/\s+/g, '-');
      // var imgNameWithOutExt = imgName.split('.')[0] + Date.now() + '.' + imgName.split('.')[1];
      if (image.size > size) {
        logoError.push(t('CompanyLogoFileSizeNotCorrect'));
        this.setState({ error: { ...this.state.error, fileError: true }, logoError, image: null });
      }
    }
    if (!error) {
      Resizer.imageFileResizer(
        event.target.files[0],
        150,
        150,
        'JPEG',
        100,
        0,
        (uri) => {
          let file = new File([uri], imgName);
          this.setState({ selectedFile: selectedFile, image: file, loaded: true, logoError });
        },
        'blob'
      );
    }
  };
  handleChangeCheckbox = (event) => {
    const updatedStates = handleCompanyCheckboxChange(event, this.state);
    this.setState(updatedStates);
  };
  handleChangeCheckboxRequestSettings = (event, index) => {
    let fieldsArray = this.state.customInvoicePageFields;
    fieldsArray[index][event.target.id] = event.target.checked;
    this.setState({ customInvoicePageFields: fieldsArray });
  };
  setCustomFields(fields) {
    console.log('parent customFields', fields);
    if (
      (fields[0]?.enabled === false || fields[0]?.autoGenerate === true) &&
      this.state.textFlexPermissions.customId === true
    ) {
      this.setState({
        textFlexPermissions: { ...this.state.textFlexPermissions, customerName: true, customId: false },
      });
    }
    this.setState({ customFields: fields });
  }
  setCustomTransactionColumnFields(fields) {
    this.setState({ customTransactionColumnFields: fields });
  }
  enableCustomFields(isCustomFieldsEnabled) {
    if (isCustomFieldsEnabled === false) {
      this.setState({
        textFlexPermissions: { ...this.state.textFlexPermissions, customerName: true, customId: false },
      });
    }
    this.setState({ customFieldsEnabled: isCustomFieldsEnabled });
    this.forceUpdate();
  }
  enableCustomTransactionFields(isCustomTransactionFieldEnabled) {
    this.setState({ customTransactionColumnEnabled: isCustomTransactionFieldEnabled });
  }

  setCustomerSettings = (settings) => {
    this.setState({ paxSettings: { ...settings.paxSettings } });
  };

  render() {
    const { t } = this.props;
    const { Countrydata } = this.state;
    return (
      <div className="rightPanel">
        <Topheader />
        <LoadingOverlay active={this.state.isLoading} spinner text="Fetching your response ...">
          <Container component="main" maxWidth="xl">
            {this.state.customFieldsError && <FlexAlert severity={'error'} message={'Null Custom Field Not Allowed'} />}
            {this.state.misMatchError && this.state.misMatchError.length > 0 ? (
              <ErrorMessage errors={[this.state.misMatchError]} />
            ) : (
              ''
            )}
            {this.state.successMessages ? (
              <SuccessMessage successes={[this.props.info.companyInfo.data.message]} />
            ) : (
              ''
            )}
            <form className={classes.form} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Textfield
                    required={true}
                    error={this.state.error.dbaNameError}
                    id="dbaName"
                    type="text"
                    labels={t('DBAName')}
                    value={this.state.dbaName}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    focus={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Textfield
                    required={true}
                    error={this.state.error.contactNameError}
                    id="contactName"
                    type="text"
                    labels={t('ContactName')}
                    value={this.state.contactName}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    required={true}
                    error={this.state.error.emailError}
                    id="email"
                    type="email"
                    labels={t('Email')}
                    value={this.state.email}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    id="website"
                    type="text"
                    labels={t('Website')}
                    value={this.state.website}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Textfield
                    required={true}
                    error={this.state.error.mccError}
                    id="mcc"
                    type="text"
                    labels={t('MCC')}
                    value={this.state.mcc}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Textfield
                    required={true}
                    error={this.state.error.address1Error}
                    id="address1"
                    type="text"
                    labels={t('Address1')}
                    value={this.state.address1}
                    width={true}
                    rowsMax={'2'}
                    onChange={(e) => this.handleChange(e, t)}
                    multiline={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Textfield
                    id="address2"
                    type="text"
                    labels={t('Address2')}
                    value={this.state.address2}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                    rowsMax={'2'}
                    multiline={true}
                  />
                </Grid>
                <Grid item xs={6} sm={3} style={{ marginTop: '2.8rem' }}>
                  <FormControl fullWidth style={getValidationStyle(!this.state.country && this.state.isSubmit)}>
                    <TypableSelect
                      placeholder={'Select country name'}
                      suggestions={mapToFormat(Countrydata)}
                      onChange={(val) => this.setCountryState(val)}
                      isSelected={this.state.country}
                      isClearable={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3} style={{ marginTop: '2.8rem' }}>
                  <FormControl fullWidth style={getValidationStyle(!this.state.state && this.state.isSubmit)}>
                    <TypableSelect
                      placeholder={'Select state name'}
                      suggestions={mapToFormatStates(this.state.stateList)}
                      onChange={(val) => this.setStatesSet(val)}
                      isSelected={this.state.state}
                      isClearable={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3} style={{ marginTop: '1.0rem' }}>
                  <FormControl fullWidth style={getValidationStyle(!this.state.city && this.state.isSubmit)}>
                    <Autocomplete
                      value={this.state.city}
                      onChange={(e, value) => this.setCitySet(e, value)}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            title: `Add "${params.inputValue}"`,
                          });
                        }
                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="city"
                      options={mapToFormatCity(this.state.cityList)}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.title;
                      }}
                      renderOption={(option) => option.title}
                      freeSolo
                      renderInput={(params) => <TextField {...params} label="city" />}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <Textfield
                    required={true}
                    error={this.state.error.zipError}
                    id="zip"
                    type="text"
                    labels={t('Zip')}
                    value={this.state.zip}
                    width={true}
                    onChange={(e) => this.handleChange(e, t)}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextFlex
                    handleChangeCheckbox={this.handleChangeCheckbox}
                    textFlexPermissions={this.state.textFlexPermissions}
                    customFields={this.state.customFields}
                    customFieldsEnabled={this.state.customFieldsEnabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <h3>Settings</h3>
                  <CompanySettings
                    settings={{ paxSettings: this.state.paxSettings }}
                    onChange={this.setCustomerSettings}
                  />
                </Grid>
                <Grid item xs={12}>
                  {(this.props.userInfo.permissions && this.props.userInfo.permissions.editCompany) ||
                  this.state.user.role === config.SUPER_ADMIN_ROLE ? (
                    <Buttons
                      id="signUp"
                      type="button"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={(e) => this.handleSubmit(e, t)}
                      text={t('Update')}
                    />
                  ) : (
                    ''
                  )}
                  <Buttons
                    className={classes.ml_2}
                    variant="contained"
                    color="secondary"
                    text={t('Back')}
                    onClick={this.props.history.goBack}
                  />
                </Grid>
              </Grid>
            </form>
          </Container>
        </LoadingOverlay>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    info: state.CompanyReducer,
    userInfo: state.AdminUserReducer,
    PAYTRACE_AWS_PATH: state.EnvironmentVariablesReducer.data.PAYTRACE_AWS_PATH,
  };
}

EditCompany.propTypes = {
  t: PropTypes.func,
  CompanyDetailById: PropTypes.func,
  match: PropTypes.object,
  info: PropTypes.object,
  PAYTRACE_AWS_PATH: PropTypes.string,
  history: PropTypes.object,
  userInfo: PropTypes.object,
  countryDataList: PropTypes.func,
  companyEdit: PropTypes.func,
};

export default connect(mapStateToProps, { CompanyDetailById, companyEdit, countryDataList })(
  withTranslation()(EditCompany)
);
