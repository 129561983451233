import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import timezonesList from '../../constants/Timezones';
import { withTranslation } from 'react-i18next';
import classes from './Company.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { locationDetailById, locationEdit } from '../../actions/LocationAction';
import queryString from 'query-string';
import { countryDataList } from '../../actions/CompanyAction';
import Checkbox from '@material-ui/core/Checkbox';
import validator from './../../helpers/checkValidations';
import LoadingOverlay from 'react-loading-overlay';
import PropTypes from 'prop-types';
import { handleCompanyCheckboxChange } from '../Company/CommonFunctions';
import { CustomFields } from '../Company/CompanyCustomFields';
import Resizer from 'react-image-file-resizer';
import { changeHandler, checkErrorFields } from './CommonFunctions';
import { CustomBuild, CustomBuildFields } from '../../components/CustomBuild';
import LocationForm from './LocationForm';
import { TIME_SLOTS } from '../../helpers/constants';

class EditLocationsCompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.mapToFormatCity = this.mapToFormatCity.bind(this);
    this.mapToFormatCountry = this.mapToFormatCountry.bind(this);
    this.mapToFormatStates = this.mapToFormatStates.bind(this);
    this.maptoFormatTimezones = this.maptoFormatTimezones.bind(this);
    this.handleTimeZoneChange = this.handleTimeZoneChange.bind(this);
    this.setCitySet = this.setCitySet.bind(this);
    this.setAutoSettlementTime = this.setAutoSettlementTime.bind(this);
    this.setCountryState = this.setCountryState.bind(this);
    this.setStatesSet = this.setStatesSet.bind(this);
    this.getValidationStyle = this.getValidationStyle.bind(this);
    this.handleSaveAndContinue = this.handleSaveAndContinue.bind(this);
    this.uploadKey = this.uploadKey.bind(this);
    this.goBackLocationForm = this.goBackLocationForm.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.state = {
      sideShow: true,
      isSubmit: false,
      isLoading: true,
      locationName: '',
      email: '',
      mId: '',
      phone: '',
      contactName: '',
      logoError: [],
      address1: '',
      address2: '',
      country: '',
      state: '',
      city: '',
      stateList: {},
      cityList: [],
      Countrydata: [],
      zip: '',
      company: '',
      notes: '',
      timeZone: new Date(),
      localTimeZone: '',
      misMatchError: [],
      successMessages: false,
      paytraceKey: null,
      paytracePassword: '',
      imageUrl: null,
      paytraceUserName: '',
      merchantForm: false,
      ACH: true,
      confirmAccountNumber: false,
      customerPortal: false,
      multiInvoicePayment: false,
      customFields: [],
      customTransactionColumnFields: [],
      customTransactionColumnEnabled: false,
      paxTransactionEnable: false,
      paxLocationId: null,
      customTowingBuild: false,
      customDefaultBuild: true,
      customInvoicePageFields: [],
      customFieldsEnabled: false,
      customFieldsError: false,
      textFlexPermissions: {
        textFlex: false,
        customId: false,
        customerName: false,
        phoneNumber: false,
        amount: false,
      },
      error: {
        nameError: false,
        paytracePasswordError: false,
        paytraceUserNameError: false,
        locationNameError: false,
        phoneError: false,
        emailError: false,
        contactNameError: false,
        mccError: false,
        address1Error: false,
        stateError: false,
        cityError: false,
        zipError: false,
        timeZoneError: false,
      },
      timezones: timezonesList,
      localTimeZoneLabel: '',
      autoSettlementTime: '',
      autoSettlementTimeLabel: '',
    };
  }

  mapToFormatCountry(values) {
    return values.map((Obj) => ({ value: Obj.Country, label: Obj.Country }));
  }
  mapToFormatStates(state) {
    return Object.keys(state).map((name) => ({ value: name, label: name }));
  }

  mapToFormatCity(state) {
    return state.map((name) => ({ value: name, title: name }));
  }

  maptoFormatTimezones(state) {
    return Object.keys(state).map((name) => ({ value: name, label: state[name] }));
  }

  getValidationStyle(cond) {
    if (cond) {
      return { width: '100%', border: '2px solid red', padding: '10px 0px 0px 0px' };
    } else {
      return { width: '100%' };
    }
  }

  getcountryDataList() {
    this.setState({ isLoading: true });
    this.props.countryDataList().then((res) => {
      this.setState({ Countrydata: res.payload.companyData.data, isLoading: false });
      this.setState({
        stateList: res.payload.companyData.data[0]?.State,
      });
    });
  }

  getLocationData() {
    let search = this.props.location.search;
    let param = queryString.parse(search);
    this.setState({ isLoading: true });
    this.props.locationDetailById(param.locations).then((res) => {
      if (res.payload.locationData.data.response.image != 'null' && res.payload.locationData.data.response.image) {
        const uploadImageUrl = this.props.PAYTRACE_AWS_PATH + res.payload.locationData.data.response.image;
        this.setState({ imageUrl: uploadImageUrl });
      }
      if (res.payload.locationData.data.response?.gateway == 'multipass') {
        const {
          multipassUserId,
          multipassPassword,
          multipassDeviceId,
          multipassmerchantId,
          multipassTSEPId,
          transactionKey,
        } = res.payload.locationData.data.response.multipassCredentials;
        this.setState({
          multipassDeviceId,
          multipassPassword,
          multipassUserId,
          multipassmerchantId,
          multipassTSEPId,
          transactionKey,
          currentMultipassUserId: multipassUserId,
        });
      }
      this.setState({ ...res.payload.locationData.data.response, isLoading: false }, () => {
        this.setState({
          localTimeZoneLabel: this.state.timezones[this.state.localTimeZone],
          autoSettlementTimeLabel: TIME_SLOTS[res.payload.locationData.data.response.autoSettlementTime]?.label,
        });
      });
    });
  }
  componentDidMount() {
    this.getLocationData();
    this.getcountryDataList();
  }
  handleClickMenu(_e) {
    if (this.state.sideShow) this.setState({ sideShow: false });
    else this.setState({ sideShow: true });
  }

  handleTimeZoneChange(e) {
    if (e && e.value && e.label) {
      this.setState({ localTimeZone: e.value });
      this.setState({ localTimeZoneLabel: e.label });
    } else {
      this.setState({ localTimeZone: '' });
      this.setState({ localTimeZoneLabel: '' });
    }
  }

  handleChange = (event, _t) => {
    const { id, value, misMatchError, error, validationMarkup } = changeHandler(event);
    if (!validationMarkup) {
      return false;
    }
    this.setState({
      [id]: value,
      error,
      misMatchError,
      isSubmit: false,
      successMessages: false,
    });
  };

  handleSubmit(_e, _t) {
    let misMatchError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true });
    if (this.state.gateway == 'multipass') {
      delete this.state.paytraceUserName;
      delete this.state.paytracePassword;
      delete this.state.paytraceKey;
    }
    Object.keys(state).map((key, _value) => {
      if (
        (key === 'address2' && state['address2'] === '') ||
        key === 'autoSettlementTime' ||
        key === 'autoSettlementTimeLabel'
      ) {
        return false;
      }
      if (key === 'notes' && state['notes'] === '') {
        return false;
      }
      if (key === 'paxLocationId' && state['paxLocationId'] === '') {
        return false;
      }
      if (key === 'phone' && state['phone'] === '') {
        return false;
      }
      if (key === 'localTimeZone') {
        return false;
      }
      if (validator(key, state[key])) {
        return validator(key, state[key])
          ? ((error[key + 'Error'] = true), (errorFlag = true))
          : (error[key + 'Error'] = false);
      } else {
        return state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')
          ? ((error[key + 'Error'] = true), (errorFlag = true))
          : '';
      }
    });
    const errorObj = checkErrorFields(this.state, error, errorFlag, misMatchError);
    if (this.state.customFieldsEnabled && this.state.customFields[0]?.value === '') {
      errorObj.misMatchError.push('Custom Field Name can not be empty');
      errorObj.errorFlag = true;
    }
    if (errorObj.errorFlag) {
      this.setState({ misMatchError: errorObj.misMatchError, error, successMessages });
      return false;
    } else {
      delete this.state._id;
      if (this.state.paxTerminals) {
        delete this.state.paxTerminals;
      }

      if (window.confirm('Are you sure want to update?')) {
        this.setState({ isLoading: true });
        const data = new FormData();
        for (const key in this.state) {
          if (
            key == 'customFields' ||
            key === 'textFlexPermissions' ||
            key === 'customTransactionColumnFields' ||
            key === 'customInvoicePageFields'
          ) {
            data.append(key, JSON.stringify(this.state[key]));
          } else {
            data.append(key, this.state[key]);
          }
        }
        if (
          this.state.gateway == 'multipass' &&
          this.state.mulitpassUserId !== '' &&
          this.state.multipassUserId !== this.state.currentMultipassUserId
        ) {
          data.append('shouldGenerateTransactionKey', true);
        }

        if (!this.state.user) {
          data.append('user', JSON.parse(localStorage.getItem('user'))?._id);
        }
        this.props.locationEdit(data, this.state.id).then((result) => {
          window.scrollTo(0, 0);
          if (result !== undefined && result.type === 'LOCATION_SUCCESS') {
            this.setState({ successMessages: true, misMatchError, isLoading: false }, () => {
              setTimeout(() => {
                this.goBack(_e);
              }, 2000);
            });
          }
          if (result !== undefined && result.type === 'LOCATION_ERROR') {
            misMatchError.push(this.props.info.locationInfo.data.message);
            this.setState({
              misMatchError,
              isLoading: false,
              successMessages,
            });
          }
        });
      }
    }
  }

  setCountryState(val) {
    if (val !== null)
      this.setState({
        stateList: this.state.Countrydata.filter((obj) => obj.Country === val.label)[0].State,
        country: val.label,
      });
  }
  setStatesSet(val) {
    if (val !== null)
      this.setState({
        cityList: this.state.stateList[val.label],
        state: val.label,
        city: '',
      });
  }

  setCitySet(event, val) {
    if (val !== null && Object.prototype.hasOwnProperty.call(val, 'value')) {
      this.setState({ city: val.title });
    } else if (val && val.inputValue) {
      this.setState({ city: val.inputValue });
    } else {
      this.setState({ city: val });
    }
  }
  setAutoSettlementTime(newAutoSettlementTime) {
    this.setState({
      autoSettlementTime: newAutoSettlementTime?.value || '',
      autoSettlementTimeLabel: newAutoSettlementTime?.label || '',
    });
  }

  goBack(e) {
    this.props.handleChangeTab(e, 1);
  }

  goBackLocationForm() {
    this.setState({ merchantForm: false });
  }
  //upload paytace key
  uploadKey = (event, _t) => {
    let file = event.target.files[0];
    this.setState({ paytraceKey: file });
  };

  handleChangeCheckbox = (event) => {
    const updatedStates = handleCompanyCheckboxChange(event, this.state);
    this.setState(updatedStates);
  };
  setCustomFields(fields) {
    if (
      (fields[0]?.enabled === false || fields[0]?.autoGenerate === true || fields[0]?.required === true) &&
      this.state.textFlexPermissions.customId === true
    ) {
      this.setState({
        textFlexPermissions: { ...this.state.textFlexPermissions, customerName: true, customId: false },
      });
    }
    this.setState({ customFields: fields, misMatchError: [] });
  }
  setCustomTransactionColumnFields(fields) {
    this.setState({ customTransactionColumnFields: fields });
  }
  enableCustomFields(isCustomFieldsEnabled) {
    if (isCustomFieldsEnabled === false) {
      this.setState({
        textFlexPermissions: { ...this.state.textFlexPermissions, customerName: true, customId: false },
      });
    }
    this.setState({ customFieldsEnabled: isCustomFieldsEnabled });
    this.forceUpdate();
  }
  enableCustomTransactionFields(isCustomTransactionFieldEnabled) {
    this.setState({ customTransactionColumnEnabled: isCustomTransactionFieldEnabled });
  }

  handleChangeCallerFields = (fields) => {
    this.setState({ customInvoicePageFields: fields });
  };

  handleChangeCustomBuild = (value) => {
    const checkboxName = value.target.name;
    const checked = value.target.checked;
    if (checkboxName === 'towing' && checked) {
      this.setState({ customTowingBuild: true, customDefaultBuild: false });
    } else if (checkboxName === 'default' && checked) {
      this.setState({ customDefaultBuild: true, customTowingBuild: false });
    }
  };

  handleSaveAndContinue = () => {
    this.setState({ merchantForm: true });
  };

  //upload image
  onChangeHandler = (event, t) => {
    let logoError = [];
    let error = false;
    let selectedFile = event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : '';
    let image = event.target.files[0];
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (image && !allowedExtensions.exec(image.name)) {
      logoError.push(t('CompanyLogoFileTypeNotCorrect'));
      error = true;
      this.setState({ logoError, image: null, preview: false });
    }
    let imgNameWithOutExt = '';
    if (image) {
      let imgName = image.name.replace(/\s+/g, '-');
      imgNameWithOutExt = imgName.split('.')[0] + Date.now() + '.' + imgName.split('.')[1];

      const size = 5 * 1048576;
      if (image.size > size) {
        logoError.push(t('CompanyLogoFileSizeNotCorrect'));
        this.setState({ logoError, image: null });
      }
    }
    if (!error) {
      Resizer.imageFileResizer(
        event.target.files[0],
        150,
        150,
        'JPEG',
        100,
        0,
        (uri) => {
          let file = new File([uri], imgNameWithOutExt);
          this.setState({ selectedFile: selectedFile, logo: file, preview: true, logoError });
        },
        'blob'
      );
    }
  };

  render() {
    const { t } = this.props;
    const { merchantForm, Countrydata, customInvoicePageFields, customTowingBuild, customDefaultBuild } = this.state;
    return (
      <LoadingOverlay
        className={classes.tpTableFullW}
        active={this.state.isLoading}
        spinner
        text="Fetching your response ..."
      >
        <Container component="main" maxWidth="md">
          {this.state.misMatchError && this.state.misMatchError.length > 0
            ? this.state.misMatchError.map((error, index) => {
                return <ErrorMessage errors={[error]} key={index} />;
              })
            : ''}
          {this.state.successMessages ? <SuccessMessage successes={[this.props.info.locationInfo.data.message]} /> : ''}
          <form className={classes.form} noValidate>
            {merchantForm ? (
              <Grid spacing={3}>
                <Grid item>
                  <h3>Merchant Permission</h3>
                  <label>
                    <Checkbox
                      id="ACH"
                      checked={this.state.ACH}
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.ACH}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>ACH</span>
                  </label>
                </Grid>
                {this.state.ACH && (
                  <Grid item>
                    <label style={{ paddingLeft: '30px' }}>
                      <Checkbox
                        id="confirmAccountNumber"
                        checked={this.state.confirmAccountNumber}
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.confirmAccountNumber}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>Confirm Account Number Field</span>
                    </label>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <label>
                    <Checkbox
                      id="customerPortal"
                      checked={this.state.customerPortal}
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.customerPortal}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Customer Portal</span>
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <label>
                    <Checkbox
                      id="multiInvoicePayment"
                      checked={this.state.multiInvoicePayment}
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.multiInvoicePayment}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <span>Customer Portal Pay Multiple Invoices</span>
                  </label>
                </Grid>
                <Grid item>
                  <CustomFields
                    setCustomFields={this.setCustomTransactionColumnFields.bind(this)}
                    enableCustomFields={this.enableCustomTransactionFields.bind(this)}
                    customFields={this.state.customTransactionColumnFields}
                    customFieldsEnabled={this.state.customTransactionColumnEnabled}
                    title="Custom Transaction Column"
                    displayAutoGenerateCheckBox={false}
                  />
                </Grid>
                <Grid item>
                  <CustomFields
                    setCustomFields={this.setCustomFields.bind(this)}
                    enableCustomFields={this.enableCustomFields.bind(this)}
                    customFields={this.state.customFields}
                    customFieldsEnabled={this.state.customFieldsEnabled}
                    title="Custom Fields"
                    displayAutoGenerateCheckBox={true}
                    displayRequiredCheckBox={true}
                  />
                </Grid>
                <Grid item>
                  <CustomBuild
                    required={true}
                    customTowingBuild={customTowingBuild}
                    customDefaultBuild={customDefaultBuild}
                    type="text"
                    width={true}
                    handleChange={this.handleChangeCustomBuild}
                  />

                  {customTowingBuild && customTowingBuild && (
                    <>
                      <CustomBuildFields
                        fields={customInvoicePageFields}
                        handleChange={this.handleChangeCallerFields}
                      />
                    </>
                  )}
                </Grid>
                {this.state.gateway === 'multipass' ? (
                  <Grid item>
                    <label>
                      <Checkbox
                        id="paxTransactionEnable"
                        checked={this.state.paxTransactionEnable}
                        onChange={(e) => {
                          this.handleChangeCheckbox(e);
                        }}
                        value={this.state.paxTransactionEnable}
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                        }}
                      />
                      <span>PAX Transaction</span>
                    </label>
                  </Grid>
                ) : (
                  ''
                )}

                <div style={{ padding: 20 }}>
                  <Grid item>
                    <Buttons
                      className={classes.btn_space}
                      id="merchantForm"
                      type="button"
                      variant="contained"
                      color="info"
                      onClick={(_e) => this.setState({ merchantForm: false })}
                      text="Back"
                    />

                    <Buttons
                      id="signUp"
                      type="button"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={(e) => this.handleSubmit(e, t)}
                      text={t('Save')}
                    />
                  </Grid>
                </div>
              </Grid>
            ) : (
              <>
                <LocationForm
                  handleChange={this.handleChange}
                  mapToFormatCountry={this.mapToFormatCountry}
                  mapToFormatCity={this.mapToFormatCity}
                  mapToFormatStates={this.mapToFormatStates}
                  maptoFormatTimezones={this.maptoFormatTimezones}
                  handleTimeZoneChange={this.handleTimeZoneChange}
                  state={this.state}
                  setCitySet={this.setCitySet}
                  getValidationStyle={this.getValidationStyle}
                  onChangeHandler={this.onChangeHandler}
                  uploadKey={this.uploadKey}
                  goBack={this.goBack}
                  setCountryState={this.setCountryState}
                  setAutoSettlementTime={this.setAutoSettlementTime}
                  setStatesSet={this.setStatesSet}
                  handleSaveAndContinue={this.handleSaveAndContinue}
                  goBackLocationForm={this.goBackLocationForm}
                  Countrydata={Countrydata}
                  selectGateway={(gateway) => {
                    this.setState({ gateway: gateway });
                  }}
                  t={t}
                />
              </>
            )}
          </form>
        </Container>
      </LoadingOverlay>
    );
  }
}

EditLocationsCompanyDetails.propTypes = {
  locationDetailById: PropTypes.func,

  countryDataList: PropTypes.func,

  handleChangeTab: PropTypes.func,

  locationEdit: PropTypes.func,

  location: PropTypes.any,

  t: PropTypes.any,

  info: PropTypes.any,
  PAYTRACE_AWS_PATH: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    info: state.LocationReducer,
    PAYTRACE_AWS_PATH: state.EnvironmentVariablesReducer.data.PAYTRACE_AWS_PATH,
  };
}
export default connect(mapStateToProps, { locationDetailById, countryDataList, locationEdit })(
  withTranslation()(withRouter(EditLocationsCompanyDetails))
);
