import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CardActions } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core/';
import { Buttons } from '../../../components/Buttons';
import MaskedInput from 'react-maskedinput';
import classNames from 'classnames';
import { Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import translation from '../../../locales/en-US/translation.json';

const PaytraceCard = (props) => {
  const {
    updateCard,
    cardPanelShow,
    cardPanelCustomerShow = false,
    addNewCard,
    backToMaskCard,
    cardRef,
    errorCard,
    americanExpress,
    handleChangeCard,
    isRefundManual = false,
    isRefund = false,
    cscCardRef,
    isOneTimeCustomer = false,
    customerDetails,
    mask_number,
    card,
    handleChangeCheckboxUpdate,
    handleChangeCheckbox,
    expiryDateRef,
    handleSubmitAddNewCard,
    name,
    t,
  } = props;
  function getDisplayStatus() {
    let displayStatus = 'block';
    if (!card.isOpen || !cardPanelShow) displayStatus = 'none';
    if (isRefund && !isRefundManual) displayStatus = 'none';
    if (isRefund && isRefundManual) displayStatus = 'block';

    return displayStatus;
  }

  function getAddNewCardDisplayStatus() {
    let displayStatus = 'none';
    if (card.isOpen && cardPanelCustomerShow && !isRefund) displayStatus = 'block';

    return displayStatus;
  }

  return (
    <>
      <Card data-cy="paytrace-card" className={classNames(['cardData', 'customCardWraper', getDisplayStatus()])}>
        <div className="Card_header">
          {!updateCard ? (
            <Typography variant="h5" component="h2">
              Card Details
            </Typography>
          ) : cardPanelCustomerShow || addNewCard ? (
            <>
              <Typography variant="h5" component="h2">
                Card Details
              </Typography>
              <a
                className="cardBackButton"
                onClick={(e) => backToMaskCard(e)}
                onKeyDown={(e) => backToMaskCard(e)}
                tabIndex="0" // This makes the element focusable
              >
                back
              </a>
            </>
          ) : (
            <></>
          )}
        </div>
        <CardContent ref={cardRef} className="cardContents">
          {errorCard.cardNumber ? (
            <label className={classNames(['MuiTypography-gutterBottom', errorCard.cardNumber ? 'errorCard' : ''])}>
              {t('cardNumberInvalid')}
            </label>
          ) : (
            <label className={classNames(['MuiTypography-gutterBottom', errorCard.cardNumber ? 'errorCard' : ''])}>
              {t('cardNumber')}
            </label>
          )}
          <MaskedInput
            type="text"
            value={card.cardNumber}
            className="pt-encrypt"
            mask={americanExpress ? '111111111111111' : '1111111111111111'}
            labels={'cardNumber'}
            id="cardNumber"
            name="encrypted_number"
            size="21"
            onChange={(event) => handleChangeCard(event)}
          />
          <Grid container item xs={12}>
            <Grid item md={6} xs={12}>
              {errorCard.cardExpiryDate ? (
                <label
                  className={classNames(['MuiTypography-gutterBottom', errorCard.cardExpiryDate ? 'errorCard' : ''])}
                >
                  {'cardExpiryDateInvalid'}
                </label>
              ) : (
                <label
                  className={classNames(['MuiTypography-gutterBottom', errorCard.cardExpiryDate ? 'errorCard' : ''])}
                >
                  {translation.cardExpiryDate}
                </label>
              )}
              <MaskedInput
                mask="11/1111"
                value={card.cardExpiryDate}
                name="cardExpiryDate"
                id="cardExpiryDate"
                placeholder="mm/yyyy"
                ref={expiryDateRef}
                onChange={(event) => handleChangeCard(event)}
              />
            </Grid>
            {isRefundManual === false || isRefundManual === undefined ? (
              <Grid className="margin-bottom" item md={6} xs={12}>
                {errorCard.cardCsc ? (
                  <label className={classNames(['MuiTypography-gutterBottom', errorCard.cardCsc ? 'errorCard' : ''])}>
                    {'Card Csv Invalid'}
                  </label>
                ) : (
                  <label className={classNames(['MuiTypography-gutterBottom', errorCard.cardCsc ? 'errorCard' : ''])}>
                    {translation.cardCsc}
                  </label>
                )}
                <MaskedInput
                  type="text"
                  value={card.cardCsc}
                  mask={americanExpress ? '1111' : '111'}
                  className="pt-encrypt"
                  id="cardCsc"
                  name="encrypted_csc"
                  ref={cscCardRef}
                  onChange={(event) => handleChangeCard(event)}
                />
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </CardContent>
        {isRefundManual === false
          ? !isOneTimeCustomer && (
              <CardActions>
                {!updateCard ? (
                  <>
                    {' '}
                    <Checkbox
                      id="customerToken"
                      labels="creatNewUser"
                      checked={card.customerToken ? true : false}
                      onChange={(e) => {
                        handleChangeCheckbox(e);
                      }}
                      value={card.customerToken ? true : false}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <label>Save card for future payments</label>
                  </>
                ) : (
                  <>
                    {' '}
                    <Checkbox
                      id="customerTokenUpdate"
                      labels="creatNewUser"
                      checked={card.customerTokenUpdate ? true : false}
                      onChange={(e) => {
                        handleChangeCheckboxUpdate(e);
                      }}
                      value={card.customerTokenUpdate ? true : false}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <label>Update card for future payments</label>
                  </>
                )}
              </CardActions>
            )
          : ''}
      </Card>
      <div className={getAddNewCardDisplayStatus()}>
        <Card className={classNames([cardPanelCustomerShow ? 'maskData' : 'blur', 'customCardWraper'])}>
          <div className="Card_header">
            <Typography variant="h5" component="h2" style={{ textTransform: 'capitalize' }}>
              Name :{' '}
              {customerDetails && customerDetails.firstName
                ? `${customerDetails.firstName} ${customerDetails.lastName ? customerDetails.lastName : ''}`
                : name
                ? name
                : ''}
            </Typography>
          </div>
          <CardContent ref={cardRef} className="cardContents">
            <label className="MuiTypography-gutterBottom">{t('maskCardNumber')}</label>
            <Grid container item xs={12}>
              {mask_number ? mask_number : customerDetails ? customerDetails.masked_card_number : ''}
            </Grid>
          </CardContent>
          <CardActions>
            <Buttons
              disabled={isRefund && !isRefundManual}
              id="AddNewCard"
              type="button"
              variant="contained"
              color="secondary"
              className={'addNewCardBtn'}
              onClick={(e) => handleSubmitAddNewCard(e)}
              text={'Add Another Card'}
            />
          </CardActions>
        </Card>
      </div>
    </>
  );
};

PaytraceCard.propTypes = {
  updateCard: PropTypes.any,
  cardPanelShow: PropTypes.any,
  cardPanelCustomerShow: PropTypes.any,
  addNewCard: PropTypes.any,
  backToMaskCard: PropTypes.any,
  cardRef: PropTypes.any,
  errorCard: PropTypes.any,
  americanExpress: PropTypes.any,
  handleChangeCard: PropTypes.any,
  isRefundManual: PropTypes.bool,
  isRefund: PropTypes.bool,
  cscCardRef: PropTypes.any,
  isOneTimeCustomer: PropTypes.any,
  customerDetails: PropTypes.any,
  mask_number: PropTypes.any,
  card: PropTypes.any,
  handleChangeCheckboxUpdate: PropTypes.any,
  handleChangeCheckbox: PropTypes.any,
  expiryDateRef: PropTypes.any,
  handleSubmitAddNewCard: PropTypes.any,
  name: PropTypes.any,
  t: PropTypes.any,
};

export default PaytraceCard;
