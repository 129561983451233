import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Topheader } from './../../components/Topheader';
import { CustomerDetailsId } from '../../actions/CustomerAction';
import { setupPaymentGatewayProperties } from '../../helpers/functions/paymentGateway.properties.js';
import { getFeeCalculations } from '../../helpers/functions/feeCalculations';
import { getCsrfToken, createRecurrence } from '../../actions/PaymentAction';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { initialAddressSettings, initialAddressState } from '../../helpers/addressSettingsState';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import classes from './Customers.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import LoadingOverlay from 'react-loading-overlay';
import { getLocationPaymentGateway } from '../../helpers/commonFunction';
import { validateZipCode } from '../../helpers/functions/validateZipCode';
import { userSettings, settingDataByCompanyId, getUserSettingsByLocationId } from '../../actions/AdminUserAction';
// // import config from './../../constants/config'
import moment from 'moment';
import { Button, Checkbox } from '@material-ui/core';
import TypableSelect from './../../helpers/TypableSelect';
import validator from '../../helpers/checkValidations';
import FormControl from '@material-ui/core/FormControl';
import { Modal, Dialog, DialogContent, ListItem, MenuItem, TextField, DialogTitle } from '@material-ui/core';
import { List } from '@material-ui/core';
import { rules } from '../../constants/IntakeFormConstants';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LinkTabs } from '../../components/Tabs';
import AddressFieldsDropDown from '../../components/AddressFieldsDropDown/AddressFieldsDropDown';
import { checkAddressEnabled, validateCityInAddress } from '../../helpers/functions/addressFieldsEnabled';
import { handleAddressSettingError } from '../../helpers/functions/validateAddressfields';
import DatePickerInput from '../../components/DatePicker/DatePickerInput.js';

const mapToFormat = (values) => {
  return values.map((Obj) => ({ value: Obj.value, label: Obj.lable }));
};

const getValidationStyle = (cond) => {
  if (cond) {
    return { width: '100%', border: '2px solid red', padding: '10px 0px' };
  } else {
    return { width: '100%' };
  }
};

const frequency = [
  { value: '1', lable: 'Annually' },
  { value: '8', lable: 'Semi-Annually' },
  { value: 'A', lable: 'Trimesterly' },
  { value: '2', lable: 'Quarterly' },
  { value: '9', lable: 'Bi-Monthly' },
  { value: '3', lable: 'Monthly' },
  { value: '4', lable: 'Bi-Weekly' },
  { value: '7', lable: '1st and 15th' },
  { value: '5', lable: 'Weekly' },
  { value: '6', lable: 'Daily' },
];

class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      sideShow: true,
      REFUND: false,
      isLoading: true,
      isSubmit: false,
      customer_receipt: false,
      ach_recurring: false,
      credit_recurring: true,
      firstName: '',
      lastName: '',
      createdBy: '',
      companyId: '',
      recurringType: 'C',
      routingNumber: null,
      accountNumber: null,
      email: '',
      phone: '',
      customFields: [],
      paytraceCredentials: {},
      ...initialAddressSettings,
      ...initialAddressState,
      base_amount: '',
      amount: '',
      svc_fee: 0,
      // base_amount: 0,
      // amount: 0,
      frequency: '',
      customer_id: '',
      masked_card_number: '',
      StartDate: new Date(),
      EndDate: new Date(),
      totalCount: 1,
      misMatchError: [],
      csrf: { 'csrf-token': '', 'csrf-secret': '' },
      error: {
        customer_idError: false,
        amountError: false,
        phoneError: false,
        totalCountError: false,
        firstNameError: false,
        lastNameError: false,
      },
      settings: {},
      permissions: {},
      SVCFeeModal: false,
      showMaskedNumberError: false,
    };
  }

  componentDidMount() {
    const paymentGateway = getLocationPaymentGateway();
    this.setPaymentGatewayProperties(paymentGateway);
    this.fetchAddressInfo();
    this.getCustomerDetails();
    this.getCsrfTokens();
    this.getCSVFeeValue();
    this.settingDataByCompanyId();
    this.setUserData();
  }

  setUserData() {
    let userData = JSON.parse(localStorage.getItem('user'));
    // console.log('User Data --->', userData)
    this.setState({
      createdBy: userData._id,
      companyId: userData.company._id,
      permissions: userData.permissions,
      settings: userData.settings ? userData.settings : {},
    });
  }

  setPaymentGatewayProperties(paymentGatewayVal) {
    const { misMatchErrors, paytraceCredentials, multipassCredentials } =
      setupPaymentGatewayProperties(paymentGatewayVal);
    if (misMatchErrors) {
      this.setState({ misMatchErrors });
    } else {
      paymentGatewayVal === 'paytrace'
        ? this.setState({ paytraceCredentials })
        : this.setState({ multipassCredentials });
    }
  }

  getCSVFeeValue() {
    const svcDiscount = this.state.settings.virtualTerminalSettings?.InvoiceDiscount ? true : false;
    const feeType = this.state.settings.virtualTerminalSettings?.InvoiceSVCFeeType;
    const svcFee = this.state.settings.virtualTerminalSettings?.InvoiceSVCFee;
    let base_amount = this.state.base_amount;
    let amount = this.state.amount;
    let svc_fee = this.state.svc_fee;
    if (svcDiscount) {
      if (feeType === '%') {
        svc_fee = Number((base_amount * svcFee) / 100).toFixed(2);
      } else {
        svc_fee = Number(svcFee).toFixed(2);
      }
      amount = Number(+base_amount + +svc_fee).toFixed(2);
    } else {
      amount = base_amount;
      svc_fee = 0;
    }
    this.setState({ amount, svc_fee });
  }

  getCsrfTokens() {
    this.props.getCsrfToken().then((res) => {
      this.setState({ csrf: { ...res.payload.paymentData.data } });
    });
  }

  settingDataByCompanyId() {
    // console.log(this.state.userDetails)
    this.setState({ isLoading: true });
    const location = JSON.parse(localStorage.getItem('locationPaydetails'));
    // Get the setting Data from the locationId and companyId.
    this.props
      .getUserSettingsByLocationId({ location: location ? location.id : '' })
      .then((res) => {
        console.log('userSetting Details =========>', res);
        this.setState({ settings: res.payload.userData.data.response });
      })
      .catch((err) => {
        console.log('userSetting Error ====>', err);
      });
  }

  changeFrequency(value) {
    const count = +this.state.totalCount > 0 ? this.state.totalCount : 0;
    let endDate = moment(this.state.StartDate);
    let EndDate = endDate;
    switch (value) {
      case '1':
        EndDate = endDate.add(count, 'Y');
        break;
      case '8':
        EndDate = endDate.add(count / 2, 'Y');
        break;
      case 'A':
        EndDate = endDate.add(count * 3, 'M');
        break;
      case '2':
        EndDate = endDate.add(count * 4, 'M');
        break;
      case '9':
        EndDate = endDate.add(count * 2, 'M');
        break;
      case '3':
        EndDate = endDate.add(count, 'M');
        break;
      case '4':
        EndDate = endDate.add(count * 2, 'W');
        break;
      case '7':
        EndDate = endDate.add(count / 2, 'M');
        break;
      case '5':
        EndDate = endDate.add(count, 'W');
        break;
      default:
        EndDate = endDate.add(count, 'd');
        break;
    }
    this.setState({ EndDate: EndDate.toDate() });
  }

  handleChangeAmount(event, amount) {
    let errors = {};
    let misMatchError = [];
    let { id } = event.target;
    if (id === 'base_amount' && amount === '0') {
      alert('Amount should be greater than zero.');
      return false;
    }
    let errorKey = id + 'Error';
    errors[errorKey] = validator(id, amount);
    this.setState({ [id]: amount, error: errors, misMatchError, isSubmit: false }, () => {
      if (id === 'base_amount') {
        const feeComponents = getFeeCalculations(this.state?.settings, amount);
        // Set calculations based on fee settings here
        this.setState({
          amount: parseFloat(feeComponents.totalAmount).toFixed(2),
          tax: feeComponents.salesTaxFee,
          svc_fee: parseFloat(feeComponents.svcFee).toFixed(2),
        });
      }
    });
  }

  handleChange(event, _t) {
    let error = {};
    let misMatchError = [];
    let { id, value } = event.target;
    let errorKey = id + 'Error';
    error[errorKey] = validator(id, value);
    this.setState({ [id]: value, error, misMatchError, isSubmit: false }, () => {
      if (id === 'frequency' || id === 'StartDate' || id === 'totalCount') {
        this.changeFrequency(this.state.frequency);
      }
    });
  }
  getCustomerDetails() {
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    this.props.CustomerDetailsId(params).then((res) => {
      let transaction = res.payload.customerData.data;
      // console.log(transaction.response, "customer details>>>>>>>>>>>>>>>>>>");
      if (transaction.success) {
        const sendCustomerReceipt = transaction.response.email ? true : false;
        this.setState({
          ...transaction.response,
          billingAddress: validateCityInAddress(transaction.response.billingAddress),
          customer_receipt: sendCustomerReceipt,
          ...{ customer_id: transaction.response.customer_id },
          isLoading: false,
        });
      }
    });
  }

  inputFormValidation(value) {
    if (this.state[value] === '') {
      delete this.state[value];
    }
  }

  checkPhone(key) {
    return key === 'phoneNumber' || key === 'phone';
  }

  checkEmail(key) {
    return key === 'email';
  }

  checkPaymentValid(result) {
    return result !== undefined && result.type === 'PAYMENT_ERROR';
  }

  validatePayloadError(result) {
    return (
      (result.payload.error.data && result.payload.error.data.statusCode === 403) ||
      (result.payload.error.data && result.payload.error.data.statusCode === 400) ||
      result.payload.error.data.statusCode === 401
    );
  }

  fetchAddressInfo() {
    const location = JSON.parse(localStorage.getItem('locationPaydetails'));
    this.props.getUserSettingsByLocationId({ location: location ? location.id : '' }).then((resp) => {
      if (resp.type === 'USER_SUCCESS' && resp.payload.userData.status === 200) {
        this.setState({
          addressSettings: {
            ...this.state.addressSettings,
            shippingAddress: resp.payload.userData.data?.response?.virtualTerminalSettings?.shippingAddress,
            billingAddress: resp.payload.userData.data?.response?.virtualTerminalSettings?.billingAddress,
          },
        });
      }
    });
  }

  handleSubmitRecurring(e, _t) {
    e.preventDefault();
    let misMatchError = [];
    let state = this.state;
    let error = {};
    let successMessages = false;
    let errorFlag = false;
    this.setState({ isSubmit: true });
    this.inputFormValidation('lastName');
    this.inputFormValidation('phone');
    this.inputFormValidation('phoneNumber');
    this.inputFormValidation('cardType');
    this.inputFormValidation('creditOrDebit');
    this.inputFormValidation('customerAccountNumber');
    this.inputFormValidation('customerAddress');
    this.inputFormValidation('customerCity');
    if (state.totalCount < 1) {
      error['totalCountError'] = true;
      errorFlag = true;
    }
    if (state.masked_card_number == '') {
      this.setState({ showMaskedNumberError: true });
    }

    const addressError = handleAddressSettingError(this.state);
    if (addressError.length > 0) {
      misMatchError = addressError;
      this.setState({ misMatchError });
      return false;
    }

    const zipCodeErrorss = validateZipCode(this.state);
    if (zipCodeErrorss.length > 0) {
      this.setState({ misMatchError: zipCodeErrorss });
      return false;
    }

    Object.keys(state).forEach((key) => {
      if (validator(key, state[key])) {
        if (this.checkPhone(key)) {
          return (error[key + 'Error'] = false);
        }
        if (this.checkEmail(key)) {
          if (!this.state.customer_receipt) {
            return (error[key + 'Error'] = false);
          }
        }
        return validator(key, state[key])
          ? ((error[key + 'Error'] = true), (errorFlag = true))
          : (error[key + 'Error'] = false);
      } else {
        if (key === 'zip' || key === 'address') {
          return error[key + 'Error'];
        } else {
          if (state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')) {
            return (error[key + 'Error'] = true), (errorFlag = true);
          } else {
            return error[key + 'Error'];
          }
        }
      }
    });

    if (errorFlag) {
      console.log('>>', error);
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      if (window.confirm('Are you sure you want to start recurring?')) {
        this.setState({ isLoading: true });
        this.props.createRecurrence(this.state, this.state.csrf, this.state.paytraceCredentials).then((result) => {
          if (result !== undefined && result.type === 'PAYMENT_SUCCESS') {
            window.scrollTo(0, 0);
            this.setState({ isLoading: false, successMessages: true, misMatchError }, () => {
              setTimeout(() => {
                this.setState({ successMessages: false });
                this.setState({ redirect: true });
              }, 2000);
            });
          }
          if (this.checkPaymentValid(result)) {
            window.scrollTo(0, 0);
            if (this.validatePayloadError(result)) {
              let message = result.payload.error.data.message;
              misMatchError.push(message);
              this.setState({
                isLoading: false,
                misMatchError,
                successMessages,
              });
            }
            if (this.props.info.paymentInfo.data.response.length > 0) {
              let responseErrors = JSON.parse(this.props.info.paymentInfo.data.response);
              if (responseErrors.response_code === 1) {
                for (let key in responseErrors.errors) {
                  let i = 0;
                  misMatchError.push(responseErrors.errors[key][i] + '\n');
                }
                this.setState({
                  isLoading: false,
                  misMatchError,
                  successMessages,
                });
              } else {
                misMatchError.push(responseErrors.approval_message);
              }
            }
            this.setState({
              isSubmit: false,
              isLoading: false,
              misMatchError,
              successMessages,
            });
          }
        });
      }
    }
  }

  handleDateChange = (date, key) => {
    this.setState({ [key]: date }, () => {
      if (key == 'StartDate') this.changeFrequency(this.state.frequency);
    });
  };
  setClientValue(val) {
    let misMatchError = [];
    if (val !== null) {
      this.setState({ frequency: val.value, misMatchError }, () => {
        this.changeFrequency(val.value);
      });
    } else {
      this.setState({ frequency: '', misMatchError });
    }
  }
  handleChangeCheckbox(event) {
    this.setState({ [event.target.id]: event.target.checked });
  }

  handleChangeRecurringCheck(event) {
    if (event.target.id === 'ach_recurring') {
      this.setState({ ach_recurring: true, credit_recurring: false, recurringType: 'A' });
    } else {
      this.setState({ ach_recurring: false, credit_recurring: true, recurringType: 'C' });
    }
  }

  handleAdd() {
    this.setState({ isOpen: true });
  }

  handleLabels = (data) => {
    const values = [...this.state.customFields];

    values.push({ value: null, label: data.LableName });
    this.setState({ customFields: values });
  };
  handleRemove(i) {
    const values = [...this.state.customFields];
    values.splice(i, 1);
    this.setState({ customFields: values });
  }

  handleSVCModelClose() {
    this.setState({ SVCFeeModal: false });
  }

  handleSettingChanges(e, name, obj) {
    const settings = { ...this.state.settings };
    if (typeof obj === 'object') {
      settings[name] = obj.value;
    } else {
      settings[name] = e.target.value;
    }
    this.setState({ settings }, () => {
      this.getCsrfTokens();
    });
  }

  handleChangeAddress = (event, type, isBillingAddressSameAsShippingAddress = false) => {
    let { id, value } = event.target;
    if (type === 'shippingAddress' || isBillingAddressSameAsShippingAddress) {
      const addressShipping = {
        ...this.state.shippingAddress,
        [id]: value,
      };
      this.setState({ shippingAddress: addressShipping });
    }
    if (type === 'billingAddress') {
      const billingAddress = {
        ...this.state.billingAddress,
        [id]: value,
      };
      this.setState({ billingAddress });
    }
  };

  handleIsBillingAddressSameAsShippingAddress = (isBillingAddressSameAsShippingAddress = false) => {
    if (isBillingAddressSameAsShippingAddress) {
      this.setState({ shippingAddress: { ...this.state.billingAddress } });
    }
  };

  render() {
    const { t } = this.props;
    const { redirect } = this.state;
    const {
      match: { params },
    } = this.props;
    console.log(this.state, '---state');
    const IOSSwitch = withStyles((theme) => ({
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
      },
      switchBase: {
        padding: 1,
        '&$checked': {
          transform: 'translateX(16px)',
          color: theme.palette.common.white,
          '& + $track': {
            backgroundColor: '#52d869',
            opacity: 1,
            border: 'none',
          },
        },
        '&$focusVisible $thumb': {
          color: '#52d869',
          border: '6px solid #fff',
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
      },
      checked: {},
      focusVisible: {},
    }))(({ classes, ...props }) => {
      return (
        <Switch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          {...props}
        />
      );
    });

    return (
      <div className="rightPanel">
        {redirect ? <Redirect to="/Recurring" /> : ''}
        <Topheader />
        <div className="contentPart">
          <LinkTabs id={params.id} />
          <LoadingOverlay active={this.state.isLoading} spinner text="Loading your content...">
            <Container component="main" maxWidth="xl">
              {this.state.misMatchError.length > 0 ? <ErrorMessage errors={[this.state.misMatchError]} /> : ''}
              {this.state.successMessages ? (
                <SuccessMessage successes={[this.props.info.paymentInfo.data.message]} />
              ) : (
                ''
              )}
              {this.state.showMaskedNumberError ? (
                <ErrorMessage errors={['The card details are empty. Go to Customers to add the card details.']} />
              ) : (
                ''
              )}
              <form className={classes.form} noValidate>
                <Dialog
                  onClose={() => this.handleSVCModelClose()}
                  aria-labelledby="simple-dialog-title"
                  open={this.state.SVCFeeModal}
                >
                  <DialogTitle>
                    Fee Name: {this.state.settings?.virtualTerminalSettings?.DiscountFeeName || 'SVC Fee'}
                  </DialogTitle>
                  <DialogContent>
                    <List>
                      <ListItem>
                        <FormControlLabel
                          value="start"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.settings
                                  ? this.state.settings.virtualTerminalSettings?.InvoiceDiscount
                                  : false
                              }
                              onChange={(e) => {
                                this.handleSettingChanges(e, 'virtualTerminalSettings.InvoiceDiscount', {
                                  value: !this.state.settings.virtualTerminalSettings?.InvoiceDiscount,
                                });
                              }}
                              value={
                                this.state.settings
                                  ? this.state.settings.virtualTerminalSettings?.InvoiceDiscount
                                  : false
                              }
                            />
                          }
                          label="Discount Enable"
                          labelPlacement="start"
                        />
                      </ListItem>
                      {/* <ListItem>
                      <Typography>Fee Name: SVC Fee</Typography>
                    </ListItem> */}
                      <ListItem>
                        <FormControlLabel
                          value={'%' && this.state.settings.virtualTerminalSettings?.InvoiceSVCFeeType}
                          control={
                            <TextField
                              fullWidth={true}
                              disabled={!this.state.settings.virtualTerminalSettings?.InvoiceDiscount}
                              id="outlined-select-currency"
                              select
                              label=""
                              onChange={(e) =>
                                this.handleSettingChanges(e, 'virtualTerminalSettings.InvoiceSVCFeeType')
                              }
                              variant="outlined"
                            >
                              <MenuItem value="%"> % </MenuItem>
                              <MenuItem value="$"> $ </MenuItem>
                            </TextField>
                          }
                          label="Fee Type"
                          labelPlacement="start"
                        />
                        {/* <label style={{marginRight:"5px"}}>Fee Type </label> */}
                      </ListItem>
                      <ListItem>
                        <FormControlLabel
                          value="start"
                          control={
                            <Textfield
                              required={true}
                              error={this.state.error.InvoiceSVCFeeError}
                              disabled={!this.state.settings.virtualTerminalSettings?.InvoiceDiscount}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {' '}
                                    {this.state.settings.virtualTerminalSettings?.InvoiceSVCFeeType}
                                  </InputAdornment>
                                ),
                                input: { fontSize: '40px' },
                              }}
                              id="InvoiceSVCFee"
                              type="text"
                              value={this.state.settings.virtualTerminalSettings?.InvoiceSVCFee}
                              width={true}
                              onChange={(e) => this.handleSettingChanges(e, 'virtualTerminalSettings.InvoiceSVCFee')}
                              focus={true}
                            />
                          }
                          label="SVC Fee"
                          labelPlacement="start"
                        />
                        {/* <label style={{marginRight:"5px"}}>Fee Type </label> */}
                      </ListItem>
                      <ListItem />
                      <ListItem>
                        <Grid item xs={12} sm={12}>
                          {' '}
                          <Buttons
                            className={classes.ml_2}
                            variant="contained"
                            color="primary"
                            text={t('Save')}
                            onClick={() => {
                              this.getCSVFeeValue();
                              this.handleSVCModelClose();
                            }}
                          />
                          <Buttons
                            className={classes.ml_2}
                            variant="contained"
                            color="secondary"
                            text={t('Back')}
                            onClick={() => this.handleSVCModelClose()}
                          />
                        </Grid>
                      </ListItem>
                    </List>
                  </DialogContent>
                </Dialog>

                <input type="hidden" name="_csrf" value={this.state.csrf['csrf-token']} />
                <input type="hidden" name="_csrfSecret" value={this.state.csrf['csrf-secret']} />
                {this.state.error.customer_idError ? (
                  <h3 className="errorMsg">Unable to process recurring as user card details are empty.</h3>
                ) : (
                  ''
                )}
                {!this.props.user.permissions.recurring ? (
                  <h3 className="errorMsg">Kindly contact to super admin for recurring access.</h3>
                ) : (
                  ''
                )}
                {this.state.status_code === 'P' ? (
                  <h3 className="errorMsg">
                    This transaction not settle yet! please try after{' '}
                    {24 - Math.round((new Date() - new Date(this.state.DATETIME)) / 3600000)} hours.
                  </h3>
                ) : (
                  ''
                )}
                {this.state.status_code === 'V'
                  ? 'This transaction has been voided on ' +
                    (this.state.UPDATE_VOID_DATE
                      ? new Date(this.state.UPDATE_VOID_DATE).toLocaleString()
                      : new Date().toLocaleString())
                  : ''}
                {this.state.REFUND ? (
                  <h3 className={classes.successMsg}>
                    This transaction has been refunded on {new Date(this.state.REFUND_DATE).toString()}{' '}
                  </h3>
                ) : (
                  ''
                )}
                <Grid container spacing={3} className="ViewTxnFormBox">
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="firstName"
                      onChange={(e) => this.handleChange(e, t)}
                      error={this.state.error && this.state.error.firstNameError}
                      helperText={this.state.error.firstNameError ? rules.firstName.messages.characterlimit : ''}
                      type="text"
                      labels={t('FirstName')}
                      value={this.state.firstName ? this.state.firstName : ' '}
                      // disabled={true}
                      width={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="lastName"
                      onChange={(e) => this.handleChange(e, t)}
                      error={this.state.error && this.state.error.lastNameError}
                      helperText={this.state.error.lastNameError ? rules.lastName.messages.characterlimit : ''}
                      type="text"
                      labels={t('LastName')}
                      value={this.state.lastName ? this.state.lastName : ' '}
                      // disabled={true}
                      width={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      error={this.state.error.emailError}
                      id="email"
                      type="email"
                      labels={t('Email')}
                      value={this.state.email ? this.state.email : ''}
                      //disabled={this.customer_receipt ?}
                      onChange={(e) => this.handleChange(e, t)}
                      width={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      // error={this.state.error.phoneError}
                      id="phone"
                      type="text"
                      labels={t('Phone')}
                      value={this.state.phone}
                      // disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  {checkAddressEnabled(this.state.addressSettings?.billingAddress) && (
                    <Grid item md={12} sm={12}>
                      <AddressFieldsDropDown
                        type="billing"
                        state={this.state}
                        handleChange={this.handleChangeAddress}
                        handleSameAddress={this.handleIsBillingAddressSameAsShippingAddress}
                      />
                    </Grid>
                  )}
                  <Grid container spacing={3} direction="row">
                    {checkAddressEnabled(this.state.addressSettings?.shippingAddress) && (
                      <Grid item md={12} sm={12}>
                        <AddressFieldsDropDown
                          type="shipping"
                          state={this.state}
                          handleChange={this.handleChangeAddress}
                          handleSameAddress={this.handleIsBillingAddressSameAsShippingAddress}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {' '}
                    <label>
                      <b>Recurring Billing Schedule</b>
                    </label>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <CurrencyTextField
                      required={true}
                      modifyValueOnWheel={false}
                      label={t('Amount')}
                      variant="outlined"
                      value={this.state.base_amount}
                      currencySymbol="$"
                      minimumValue="0"
                      outputFormat="string"
                      decimalCharacter="."
                      digitGroupSeparator=","
                      id="base_amount"
                      textAlign="left"
                      onChange={(e, value) => this.handleChangeAmount(e, value)}
                      onBlur={(event, value) => this.handleChangeAmount(event, value)}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  {this.state.settings.virtualTerminalSettings?.InvoiceDiscount && (
                    <Grid item xs={12} sm={3}>
                      <Textfield
                        disabled={true}
                        error={this.state.error.amountError}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        required={true}
                        id="svc_fee"
                        type="text"
                        labels={
                          <span>
                            <Button
                              disabled={true}
                              style={{ marginTop: '-10px' }}
                              variant="outlined"
                              onClick={() => {
                                this.setState({ SVCFeeModal: true });
                              }}
                            >
                              {this.state.settings.virtualTerminalSettings?.DiscountFeeName ?? 'Svc Fee'}
                            </Button>
                            ({this.state.settings.virtualTerminalSettings?.InvoiceSVCFeeType}
                            {this.state.settings.virtualTerminalSettings?.InvoiceDiscount
                              ? this.state.settings.virtualTerminalSettings?.InvoiceSVCFee
                              : '0'}
                            )
                          </span>
                        }
                        value={this.state.svc_fee > 0 ? this.state.svc_fee : '0.00'}
                        onChange={(e) => this.handleChange(e, t)}
                        width={true}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3}>
                    <Textfield
                      error={this.state.error.amountError}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      required={true}
                      id="amount"
                      type="text"
                      labels={'Total Amount'}
                      value={this.state.amount > 0 ? this.state.amount : '0.00'}
                      onChange={(e) => this.handleChange(e, t)}
                      width={true}
                      placeholder="0.00"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl
                      className="frequencyInput"
                      label="Frequency type"
                      fullWidth
                      style={getValidationStyle(this.state.frequency === '' && this.state.isSubmit)}
                    >
                      <TypableSelect
                        placeholder={'Frequency type'}
                        suggestions={mapToFormat(frequency)}
                        onChange={(val) => this.setClientValue(val)}
                        isClearable={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      error={this.state.error.totalCountError}
                      InputProps={{ inputProps: { min: 1 } }}
                      required={true}
                      id="totalCount"
                      type="number"
                      labels={t('Number of Billing Cycles')}
                      value={this.state.totalCount}
                      onChange={(e) => this.handleChange(e, t)}
                      width={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DatePickerInput
                      id="StartDate"
                      label={t('StartDate')}
                      value={this.state.StartDate}
                      onChange={(date) => {
                        this.handleDateChange(date, 'StartDate');
                      }}
                      format="MM/dd/yyyy"
                      closeOnSelect
                    />
                    <Tooltip
                      title="If the start date is today, the card will be charged tomorrow."
                      aria-label="add"
                      style={{ position: 'relative' }}
                    >
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DatePickerInput
                      id="EndDate"
                      label={t('EndDate')}
                      value={this.state.EndDate}
                      onChange={(date) => {
                        this.handleDateChange(date, 'EndDate');
                      }}
                      format="MM/dd/yyyy"
                      closeOnSelect
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Textfield
                      id="MASKCARDNUMBER"
                      type="text"
                      labels={t('cardNumber')}
                      value={this.state.MASKCARDNUMBER ? this.state.MASKCARDNUMBER : this.state.masked_card_number}
                      disabled={true}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>

                  {this.state?.customFields?.map((field, idx) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <Grid item sm={6} xs={6}>
                        <div key={`${field}-${idx}`}>
                          <Textfield
                            id={field.label}
                            type="text"
                            labels={field.label}
                            value={field.value || ''}
                            width={true}
                            onChange={(e) => this.handleChangeCustom(e, t, idx)}
                            focus={true}
                          />
                        </div>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12} sm={8} style={{ marginTop: '3.25%' }}>
                    <Checkbox
                      id="customer_receipt"
                      labels="creatNewUser"
                      onChange={(e) => {
                        this.handleChangeCheckbox(e);
                      }}
                      value={this.state.customer_receipt}
                      checked={this.state.customer_receipt}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                      }}
                    />
                    <label>Send an email receipt</label>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {this.props.user.permissions.recurring ? (
                      <Buttons
                        className={classes.ml_2}
                        variant="contained"
                        color="primary"
                        text={t('Recurring')}
                        onClick={(e) => this.handleSubmitRecurring(e, t)}
                      />
                    ) : (
                      ''
                    )}
                    <Buttons
                      className={classes.ml_2}
                      variant="contained"
                      color="secondary"
                      text={t('Back')}
                      onClick={this.props.history.goBack}
                    />
                  </Grid>
                </Grid>
              </form>
              <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open={this.state.SVCFeeModal}
                handleClose={() => {}}
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
              >
                <div className={classes.paper}>
                  <h2 id="server-modal-title">Server-side modal</h2>
                  <p id="server-modal-description">If you disable JavaScript, you will still see me.</p>
                </div>
              </Modal>
            </Container>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

CustomerDetails.propTypes = {
  getCsrfToken: PropTypes.func,
  info: PropTypes.any,
  getUserSettingsByLocationId: PropTypes.func,
  match: PropTypes.any,
  user: PropTypes.any,
  history: PropTypes.any,
  createRecurrence: PropTypes.func,
  t: PropTypes.func,
  CustomerDetailsId: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    info: state.PaymentReducer,
    user: state.AdminUserReducer,
  };
}

export default connect(mapStateToProps, {
  getCsrfToken,
  userSettings,
  settingDataByCompanyId,
  getUserSettingsByLocationId,
  createRecurrence,
  CustomerDetailsId,
})(withTranslation()(CustomerDetails));
